import { useEffect, useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import SelectField from 'shared/ui/fields/SelectField';

import colors from 'shared/constants/colors';
import { vehicleTypeViewData } from 'shared/constants/vehicle';
import GroupModalVehicleTable from 'shared/modals/GroupModalVehicleTable';
import {
  FormRow,
  AddVehiclesSelectWrapper,
  AddVehiclesFiltersWrapper,
} from 'shared/styles/components/Form';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { getMakeOptions, getModelOptions, getYearOptions } from 'shared/utilities/vehicle';

const DEFAULT_SEARCH_VALUE = 'Search license plates and vehicle names';
const AddVehiclesToGroupForm = ({ formikProps, vehicles, groupVehicles }) => {
  //
  const [availableGroupVehicles, setAvailableGroupVehicles] = useState([]);
  const [filteredVehicles, setFilteredVehicles] = useState([]);

  //
  const [yearOptions, setYearOptions] = useState([]);
  const [modelOptions, setModelOptions] = useState([]);
  const [makeOptions, setMakeOptions] = useState([]);

  const [selectedYear, setSelectedYears] = useState(null);
  const [selectedMake, setSelectedMakes] = useState(null);
  const [selectedModel, setSelectedModels] = useState(null);
  const [selectedType, setSelectedTypes] = useState(null);
  const [searchValue, setSearchValue] = useState(DEFAULT_SEARCH_VALUE);

  useEffect(() => {
    setAvailableGroupVehicles(
      vehicles?.filter(v => !groupVehicles.map(gv => gv.vehicle_id).includes(v.vehicle_id)),
    );
  }, [vehicles, groupVehicles]);

  useEffect(() => {
    if (availableGroupVehicles) {
      setYearOptions(getYearOptions(availableGroupVehicles));
      setModelOptions(getModelOptions(availableGroupVehicles));
      setMakeOptions(getMakeOptions(availableGroupVehicles));
    }
  }, [availableGroupVehicles]);

  useEffect(() => {
    if (availableGroupVehicles) {
      setFilteredVehicles(
        availableGroupVehicles.filter(
          v =>
            (selectedYear === null || selectedYear.value === parseInt(v.meta.mfg_year)) &&
            (selectedModel === null || selectedModel.value === v.meta.model) &&
            (selectedMake === null || selectedMake.value === v.meta.make) &&
            (selectedType === null || selectedType.value === v.meta.vehicle_type) &&
            (searchValue === DEFAULT_SEARCH_VALUE ||
              v.meta.license_plate.toLowerCase().indexOf(searchValue.toLowerCase()) > -1 ||
              v.meta.label.toLowerCase().indexOf(searchValue.toLowerCase()) > -1) &&
            !groupVehicles.map(gv => gv.vehicle_id).includes(v.vehicle_id),
        ),
      );
    }
  }, [
    availableGroupVehicles,
    selectedYear,
    selectedMake,
    selectedModel,
    selectedType,
    searchValue,
  ]);

  return (
    <div>
      <FormRow>
        <IconSvgComponent style={styles.searchIcon} svgFileName={'search'} alt="Search" />

        <DebounceInput
          ref={input => (searchInput = input)}
          type="text"
          value={searchValue}
          onFocus={() => setSearchValue(searchValue === DEFAULT_SEARCH_VALUE ? '' : searchValue)}
          onBlur={event => {
            if (event.target.value === DEFAULT_SEARCH_VALUE) {
              setSearchValue(DEFAULT_SEARCH_VALUE);
            }
          }}
          onChange={event => {
            setSearchValue(event.target.value);
          }}
          style={styles.search}
        />
      </FormRow>
      <input value={formikProps.values.vehicles} style={styles.vehiclesInput} />
      <AddVehiclesFiltersWrapper>
        <AddVehiclesSelectWrapper>
          <SelectField
            value={selectedType}
            options={vehicleTypeViewData.generateOptions()}
            onChange={selectedType => setSelectedTypes(selectedType || null)}
            placeholder={'Type'}
          />
        </AddVehiclesSelectWrapper>
        <AddVehiclesSelectWrapper>
          <SelectField
            value={selectedYear}
            options={yearOptions}
            onChange={selectedYear => setSelectedYears(selectedYear || null)}
            placeholder={'Year'}
          />
        </AddVehiclesSelectWrapper>
        <AddVehiclesSelectWrapper>
          <SelectField
            value={selectedMake}
            options={makeOptions}
            onChange={selectedMake => setSelectedMakes(selectedMake || null)}
            placeholder={'Make'}
          />
        </AddVehiclesSelectWrapper>
        <AddVehiclesSelectWrapper>
          <SelectField
            value={selectedModel}
            options={modelOptions}
            onChange={selectedModel => setSelectedModels(selectedModel || null)}
            placeholder={'Model'}
          />
        </AddVehiclesSelectWrapper>
      </AddVehiclesFiltersWrapper>
      <FormRow>
        {filteredVehicles && (
          <GroupModalVehicleTable
            vehicles={filteredVehicles}
            setVehicleInputValue={nextVehicles => {
              formikProps.setFieldValue('vehicles', nextVehicles.join(','));
            }}
          />
        )}
      </FormRow>
    </div>
  );
};

export default AddVehiclesToGroupForm;

const styles = {
  closeButton: {
    marginRight: 20,
  },
  formHead: {
    marginBottom: 42,
  },
  vehiclesInput: {
    display: 'none',
  },
  selectsContainer: {
    marginBottom: 26,
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  filterSelect: {
    maxHeight: 36,
    flex: '0 1 22.5%',
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  search: {
    display: 'block',
    width: '100%',
    height: '35px',
    padding: '0 38px',
    background: 'white',
    borderRadius: '5px',
    border: `1px solid ${colors.haze}`,
    fontSize: '12px',
    fontWeight: 600,
    opacity: '0.43',
    color: 'rgba(33, 31, 80, 0.43)',
  },
  searchIcon: {
    left: '13px',
    transform: 'translateY(150%) translateX(100%)',
  },
};
