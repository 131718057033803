import {
  CardContainer,
  CardLabel,
  CardName,
  CardOuterWrapper,
  CardRow,
  CardValue,
} from 'shared/styles/components/MobileCard';

export default function MaintenanceLogCard({ log }) {
  return (
    <CardOuterWrapper>
      <CardContainer otaLogs>
        <CardRow>
          <CardName>{log.maintenanceTypeDisplay}</CardName>
        </CardRow>
        <CardRow potentialLongValue title={log.formattedTime}>
          <CardLabel>Date:</CardLabel>
          <CardValue>{log.formattedTime}</CardValue>
        </CardRow>
        <CardRow potentialLongValue title={log.odometerDisplay}>
          <CardLabel>Odometer:</CardLabel>
          <CardValue>{log.odometerDisplay}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Engine Hours:</CardLabel>
          <CardValue>{log.engineHoursDisplay}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Interval:</CardLabel>
          <CardValue>{log.intervalDisplay}</CardValue>
        </CardRow>
        <CardRow potentialLongValue title={log.notesDisplay}>
          <CardLabel>Notes:</CardLabel>
          <CardValue>{log.notesDisplay}</CardValue>
        </CardRow>
        <CardRow potentialLongValue title={log.markedCompletedByDisplay}>
          <CardLabel>Marked Completed By:</CardLabel>
          <CardValue>{log.markedCompletedByDisplay}</CardValue>
        </CardRow>
      </CardContainer>
    </CardOuterWrapper>
  );
}
