import { IntervalInputDiv } from 'shared/styles/components/Maintenance';
import AnimatedField from 'shared/ui/fields/AnimatedField';
import { validateInterval } from 'app/features/maintenance/utilities/validators';
import { stripTrailingPercentage } from 'app/features/maintenance/utilities/formatting';
import { maintenanceIntervalConstants } from 'app/features/maintenance/data/maintenanceIntervalConstants';

const IntervalNumberField = ({
  formikProps: { values, touched, handleBlur, errors, handleChange },
}) => {
  const oilLifeInterval = values.intervalType?.value === maintenanceIntervalConstants.OIL_LIFE;
  const reminderOilLifeWithPercentage = values.interval
    ? stripTrailingPercentage(values.interval) + '%'
    : values.interval;
  return (
    <IntervalInputDiv>
      <AnimatedField
        name="interval"
        placeholder="Interval"
        value={oilLifeInterval ? reminderOilLifeWithPercentage : values.interval}
        handleChange={handleChange}
        touched={touched.interval}
        handleBlur={handleBlur}
        validateInstantly
        validationError={errors.interval}
        required
      />
    </IntervalInputDiv>
  );
};

export default IntervalNumberField;
