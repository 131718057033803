import { useEffect, useRef } from 'react';

const useInterval = ({ callback, delay, dependencies }) => {
  const savedCallback = useRef();
  const handle = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      handle.current = setInterval(tick, delay);
      return () => clearInterval(handle.current);
    }
  }, [delay].concat(dependencies));

  return () => {
    return clearInterval(handle.current);
  };
};
export default useInterval;
