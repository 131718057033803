import React, { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

import {
  addChannelToWhitelist,
  fetchProductsAndChannels,
  fetchWhitelists,
  removeChannelFromWhitelist,
} from 'core/redux/whitelists/actions';
import ChannelSelectTable from 'features/ota/whitelist/ChannelSelectTable';
import colors from 'shared/constants/colors';
import useAPIData from 'shared/hooks/useAPIData';
import PageListWrapper from 'shared/styles/components/PageList';
import { SectionBody, SectionHead, SectionTitle } from 'shared/styles/components/Section';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import SortableTableWithoutPagination from 'shared/ui/table/SortableTableWithoutPagination';

const getWhitelistChannels = ({ productWithChannels, whitelist }) => {
  const selectedChannels = (whitelist &&
    whitelist.channels &&
    (whitelist.channels.includes('PRODUCTION')
      ? whitelist.channels
      : whitelist.channels.concat(['PRODUCTION']))) || ['PRODUCTION'];
  const returnData =
    productWithChannels &&
    productWithChannels.channels.map(channel => ({
      value: channel,
      label: channel,
      selected: selectedChannels.includes(channel),
    }));
  return returnData;
};

const CreateWhitelistPage = ({
  productsWithChannels,
  fetchWhitelists,
  fetchProductsAndChannels,
  addChannelToWhitelist,
  removeChannelFromWhitelist,
}) => {
  const { productId: productIdParam } = useParams();

  const productId = parseInt(productIdParam);

  const whitelists = useSelector(state => state.whitelists.whitelists);
  const whitelist = whitelists.find(wl => wl.product_id === productId);

  const [searchValue, setSearchValue] = useState('');
  const channelsLoaded = useAPIData({
    fetchAPIData: fetchProductsAndChannels,
  });

  const whitelistsLoaded = useAPIData({ fetchAPIData: fetchWhitelists });

  const productWithChannels =
    channelsLoaded &&
    productsWithChannels.find(product => product.product_id === parseInt(productId));
  return channelsLoaded && whitelistsLoaded !== false ? (
    <PageListWrapper>
      <React.Fragment>
        <SectionHead style={styles.tableHead}>
          <div style={styles.tableTitleContainer}>
            <SectionTitle style={styles.tableTitle}>
              {productWithChannels ? `${productWithChannels.product_name}` : 'Select Product'}
            </SectionTitle>
          </div>
          {productId ? null : (
            <div style={styles.searchContainer}>
              <IconSvgComponent style={styles.searchIcon} svgFileName={'search'} alt="Search" />

              <DebounceInput
                type="text"
                value={searchValue}
                onBlur={e => setSearchValue(e.target.value)}
                onChange={e => setSearchValue(e.target.value)}
                style={styles.search}
              />
            </div>
          )}
          <div
            style={{
              ...styles.tableTitleContainer,
              ...styles.aligner,
            }}
          >
            <SectionTitle style={styles.tableTitle}>
              {productWithChannels ? `${productWithChannels.product_name}` : 'Select Product'}
            </SectionTitle>
          </div>
        </SectionHead>
        <SectionBody>
          {productWithChannels ? (
            <ChannelSelectTable
              productId={productId}
              channels={getWhitelistChannels({
                productId: productId,
                productWithChannels,
                whitelist,
              })}
              onSelect={channel =>
                addChannelToWhitelist({
                  productId: productId,
                  channel: channel,
                })
              }
              onDeselect={channel =>
                removeChannelFromWhitelist({
                  productId: productId,
                  channel: channel,
                })
              }
            />
          ) : (
            <SortableTableWithoutPagination
              className="table-simple"
              columns={[
                {
                  title: 'Product',
                  dataIndex: 'product_name',
                  key: 'product',
                  onHeaderCell: () => ({
                    style: styles.whitelistNameHeaderCell,
                  }),
                  onCell: () => ({ style: styles.whitelistNameCell }),
                  render(value, row) {
                    return (
                      <Link
                        to={{
                          pathname: `/whitelists/edit/${row.product_id}`,
                          referrer: '/whitelists/edit',
                        }}
                        style={styles.whitelistNameLink}
                      >
                        <span style={styles.whitelistNameCell}>
                          <IconSvgComponent
                            svgFileName={'device'}
                            alt="Device"
                            svgStyle={styles.rowWhitelistIcon}
                          />
                          {value}
                        </span>
                      </Link>
                    );
                  },
                },
              ]}
              data={
                productsWithChannels &&
                productsWithChannels.filter(
                  product =>
                    product.product_name &&
                    product.product_name.toLowerCase().includes(searchValue.toLowerCase()),
                )
              }
              rowKey={whitelist => whitelist.id}
              rowClassName={(record, i) => `row-${i}`}
            />
          )}
        </SectionBody>
      </React.Fragment>
    </PageListWrapper>
  ) : (
    <LoadingOverlay />
  );
};

export default connect(
  state => {
    return {
      filteredOrganizationId: state.user.filteredOrganizationId,
      productsWithChannels: state.whitelists.productsWithChannels,
    };
  },
  dispatch => ({
    fetchWhitelists: () => dispatch(fetchWhitelists()),
    fetchProductsAndChannels: () => dispatch(fetchProductsAndChannels()),
    addChannelToWhitelist: ({ productId, channel }) =>
      dispatch(addChannelToWhitelist({ productId, channel })),
    removeChannelFromWhitelist: ({ productId, channel }) =>
      dispatch(removeChannelFromWhitelist({ productId, channel })),
  }),
)(CreateWhitelistPage);

const styles = {
  tableHead: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  aligner: {
    visibility: 'hidden',
  },
  tableTitleContainer: {
    flex: '0 0 auto',
  },
  tableTitle: {
    display: 'inline-block',
  },
  whitelistNameHeaderCell: {
    paddingLeft: 0,
  },
  whitelistNameCell: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    color: colors.midnight,
    fontSize: 14,
    paddingLeft: 0,
  },
  whitelistNameLink: {
    textDecoration: 'none',
  },
  rowWhitelistIcon: {
    marginRight: 10,
    fontSize: 0,
    width: '24px',
  },
  backIcon: {
    marginBottom: 7,
    marginRight: 15,
  },
  searchContainer: {
    marginTop: -20,
    flex: '.6 0 auto',
  },
  search: {
    display: 'block',
    maxWidth: 500,
    width: '100%',
    height: '35px',
    padding: '0 38px',
    backgroundColor: colors.concrete,
    borderRadius: '2px',
    border: `none`,
    fontSize: '12px',
    fontWeight: 600,
    opacity: '0.43',
    color: 'rgba(33, 31, 80, 0.43)',
  },
  searchIcon: {
    left: '13px',
    transform: 'translateY(150%) translateX(100%)',
  },
};
