/**
 * @ External Dependecies
 */
import { createGlobalStyle } from 'styled-components';

export const GlobalModalStyles = createGlobalStyle`
	.ReactModal__Overlay
	{
		overflow: auto;
	}

	.ReactModal__Body--open
	{
		height: 100%;
		overflow: hidden;
	}
	.ReactModal__Default {
		position: fixed;
		left: 0;
		top: 0;
		display: flex;
		width: 100%;
		height: 100%;
		padding: 50px 15px;
		background: rgba(33, 31, 80, 0.21);
		z-index: 99999;
		overflow-y: auto;
		overflow-x: hidden;

		.ReactModal__Content {
			position: relative !important;
			left: auto !important;
			right: auto !important;
			top: auto !important;
			bottom: auto !important;
			max-width: 680px;
			flex: 1 1 680px;
			margin: auto;
			padding: 48px 52px 49px 45px !important;
			background: #fff;
			box-shadow: 0 41px 51px rgba(0, 0, 0, 0.27);
			border-radius: 2px !important;
			border: none !important;
		}

		.ReactModal__Btn-close {
			position: absolute;
			right: 21px;
			top: 13px;
			background: none;
			border: none;
		}
	}
`;
