import styled from 'styled-components';

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: rgba(33, 31, 80, 0.21);
  z-index: 100;
`;

export default Overlay;
