import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { logWcpError } from 'app/core/error/utilities/ErrorLogger';

export const useFetchGroupByIdQuery = ({ groupId, enabled }) => {
  const dispatch = useDispatch();
  const [group, setGroup] = useState(null);

  const fetchGroupByIdQuery = useQuery({
    queryKey: ['fetchGroupByIdQuery'],
    queryFn: async () =>
      await apiCall({
        endpoint: 'cloudfrontAPI',
        path: `/group/${groupId}`,
        method: 'get',
      }),
    enabled,
    select: response => {
      return response.body;
    },
    onError: err => {
      logWcpError(err);
      dispatch(groupFetchError({ err }));
    },
  });

  useEffect(() => {
    if (fetchGroupByIdQuery.data) {
      setGroup(fetchGroupByIdQuery.data);
    }
  }, [fetchGroupByIdQuery.data]);

  return [fetchGroupByIdQuery, group];
};
