import React from 'react';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';

const RenameGroupMenuItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Rename</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default RenameGroupMenuItem;
