import { v4 as uuidv4 } from 'uuid';

import { findAlertNotificationSelector } from 'app/features/alerts/services/providers/AlertFormsProvider/utilities/findAlertNotificationSelector';
import { validateAndExtractPayload } from 'app/shared/utilities/reducerHelpers/validateAndExtractPayload';

// addAlertRecipientAction → addAlertRecipientReducer
export const addAlertRecipientReducer = (state, payload) => {
  const { alertNotificationGuid } = validateAndExtractPayload({
    payload,
    mustInclude: ['alertNotificationGuid'],
  });

  // find the alert notification
  const alertNotification = findAlertNotificationSelector(state, alertNotificationGuid);

  // create a new recipient row
  const newRecipientRow = {
    recipientRowGuid: uuidv4(),
  };

  // add a new recipient row to the alert notification
  alertNotification.alertRecipientRows.push(newRecipientRow);

  // return the updated state
  return {
    ...state,
  };
};
