import { RedText } from 'shared/styles/components/ManageAlerts';
import WcpTable from 'app/shared/components/controls/WcpTable/WcpTable';
import { SORT_ORDER } from 'shared/constants/table';
import { TruncatedTextWithWrap } from 'shared/styles/components/TruncatedText';
import ManageAlertsLogsActionMenu from 'app/features/alerts/ui/components/AlertLogsSummary/menus/ManageAlertsLogsActionMenu';
import { addDisplayCommas } from 'app/features/maintenance/utilities/formatting';

const ManageAlertsLogsTable = ({ alertLogs, handleUpdateListingCount }) => {
  let alertLogsTableData = alertLogs?.map(alertLog => {
    return {
      timestamp: alertLog.timestamp,
      formattedTime: alertLog.formattedTime,
      vehicleName: alertLog.vehicleName,
      alertTypeDisplay: alertLog.alertTypeDisplay,
      triggerValueDisplay: alertLog.triggerValueDisplay,
      measuredValueWithCommas: addDisplayCommas(alertLog.measuredValueDisplay),
      measuredValue: alertLog.measuredValueDisplay,
      testModeEnabled: alertLog.testModeEnabled,
      formattedUserDisplayNames: alertLog.formattedUserDisplayNames,
      aggregateAlertMethod: alertLog.aggregateAlertMethod,
    };
  });

  const getRecipientTooltip = alertLog => {
    return `Recipients: ${alertLog.testModeEnabled ? 'TEST MODE - ' : ''}${
      alertLog.formattedUserDisplayNames
    }
    `;
  };
  const columns = [
    {
      title: 'Timestamp',
      displayProperty: 'formattedTime',
      sortProperty: 'timestamp',
      width: '25%',
      render({ rowData, cellJsx }) {
        return <span title={'Timestamp: ' + rowData.formattedTime}>{cellJsx}</span>;
      },
    },
    {
      title: 'Vehicle',
      displayProperty: 'vehicleName',
      width: '10%',
      render({ rowData, cellJsx }) {
        return (
          <span title={`Vehicle: ${rowData.vehicleName} `}>
            <TruncatedTextWithWrap>{cellJsx}</TruncatedTextWithWrap>
          </span>
        );
      },
    },
    {
      title: 'Condition',
      displayProperty: 'alertTypeDisplay',
      width: '15%',
    },

    {
      title: 'Alert Value',
      displayProperty: 'triggerValueDisplay',
      width: '10%',
    },
    {
      title: 'Measured Value',
      displayProperty: 'measuredValueWithCommas',
      sortProperty: 'measuredValue',
      width: '10%',
      render({ rowData, cellJsx }) {
        return <RedText title={`Value: ${rowData.measuredValueWithCommas}`}>{cellJsx}</RedText>;
      },
    },

    {
      title: 'Recipients',
      displayProperty: 'formattedUserDisplayNames',
      render({ rowData, cellJsx }) {
        return (
          <span title={getRecipientTooltip(rowData)}>
            <TruncatedTextWithWrap>
              {rowData.testModeEnabled ? 'TEST MODE - ' : ''}
              {cellJsx}
            </TruncatedTextWithWrap>
          </span>
        );
      },
    },
    {
      title: 'Method',
      displayProperty: 'aggregateAlertMethod',
    },
    {
      title: '',
      className: 'action',
      searchEnabled: false,
      render({ rowData }) {
        return <ManageAlertsLogsActionMenu log={rowData} />;
      },
    },
  ];

  return (
    <WcpTable
      tableClassName="table-simple"
      rowClassName={(record, i) => `row-${i}`}
      columns={columns}
      tableSourceData={alertLogsTableData ? alertLogsTableData : []}
      rowKey={(record, i) => i}
      emptyText="No alert logs are currently associated with this alert"
      setListingCount={handleUpdateListingCount}
      defaultSortOrder={SORT_ORDER.DESC}
    />
  );
};

export default ManageAlertsLogsTable;
