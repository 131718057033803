import { offlineTimeUnitConstants } from 'app/features/alerts/data/enums/offlineTimeUnitConstants';

export const offlineTimeUnitConverterToBackend = (unitOfMeasure, offlineTime) => {
  switch (unitOfMeasure) {
    case offlineTimeUnitConstants.MINUTES:
      return offlineTime;
    case offlineTimeUnitConstants.HOURS:
      return offlineTime * 60;
    case offlineTimeUnitConstants.DAYS:
      return offlineTime * 1440;
    case offlineTimeUnitConstants.WEEKS:
      return offlineTime * 10080;

    default:
      return offlineTime;
  }
};
