import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import { TableCheckboxFilterWrapper } from 'shared/styles/components/PageList';
import { TableFilterSpan } from 'shared/styles/components/Table';

const MaintenanceSummaryFilterCheckbox = ({ checked, handleChecked }) => {
  return (
    <TableCheckboxFilterWrapper maintenance>
      <Checkbox singleCheck>
        <CheckboxInner isChecked={checked} onClick={handleChecked} />
      </Checkbox>
      <TableFilterSpan>
        Only show next scheduled event for each vehicle by maintenance type
      </TableFilterSpan>
    </TableCheckboxFilterWrapper>
  );
};

export default MaintenanceSummaryFilterCheckbox;
