import { getValueOrFallbackIfUndefined } from 'app/shared/utilities/reducerHelpers/getValueOrFallbackIfUndefined';
import { validateAndExtractPayload } from 'app/shared/utilities/reducerHelpers/validateAndExtractPayload';
import {
  vehicleAddonDictionary,
  vehicleAddonOptions,
} from 'features/fleet/vehicles/constants/vehicleAddonDictionary';

// updateAlertAddonAction  →  updateAlertAddonReducer
export const updateAlertAddonReducer = (state, payload) => {
  const { alertAddonValue } = validateAndExtractPayload({
    payload,
    mustInclude: ['alertAddonValue'],
  });

  return {
    ...state,
    alert: {
      ...state.alert,
      alertDetails: {
        ...state.alert.alertDetails,
        alertInfo: {
          ...state.alert.alertDetails.alertInfo,
          triggerValueObject: {
            ...state.alert.alertDetails.alertInfo.triggerValueObject,
            addon: getValueOrFallbackIfUndefined(
              alertAddonValue,
              state.alert.alertDetails.alertInfo.triggerValueObject.addon,
            ),
          },
        },
        decorated: {
          ...state.alert.alertDetails.decorated,
          alertAddonDropdownOption: {
            label: vehicleAddonOptions.getLabelByValue(alertAddonValue),
            value: alertAddonValue,
          },
        },
      },
    },
  };
};
