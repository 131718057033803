import { getRecipientGroupByIdApi } from 'app/features/recipientGroups/api/get/getRecipientGroupByIdApi';
import useGenericQuery from 'app/shared/hooks/reactQuery/useGenericQuery';

const useFetchRecipientGroupByIdQuery = ({ recipientGroupId, autoRun = true }) => {
  const [queryResult, queryKey] = useGenericQuery({
    queryKeyPrefix: 'getRecipientGroupByIdApi',
    autoRun,
    apiFn: getRecipientGroupByIdApi,
    params: { recipientGroupId },
  });

  return [queryResult.data, queryResult.refetch, queryKey];
};

export default useFetchRecipientGroupByIdQuery;
