import {
  getDaysSince,
  getShortDateInIsoFormat,
  formatISOTimestampToShortDate,
  getFutureDate,
} from 'shared/utilities/time';

export const getNextDueDate = (createdDate, currentDueDate) => {
  const days = getDaysSince({
    fromDateTime: getShortDateInIsoFormat(formatISOTimestampToShortDate(createdDate)),
    toDateTime: getShortDateInIsoFormat(currentDueDate),
  });
  const newDueDate = getFutureDate(days);
  return formatISOTimestampToShortDate(newDueDate);
};
