// dropdown data
export const cooldownPeriodDropdownData = [
  //all values are to be sent to the backend in minutes
  { value: 2, label: 'Every 2 minutes' },
  { value: 5, label: 'Every 5 minutes' },
  { value: 30, label: 'Every 30 minutes' },
  { value: 60, label: 'Every 1 hour' },
  { value: 360, label: 'Every 6 hours' },
  { value: 720, label: 'Every 12 hours' },
  { value: 1440, label: 'Every 24 hours' },
  { value: 2880, label: 'Every 2 days' },
  { value: 4320, label: 'Every 3 days' },
  { value: 5760, label: 'Every 4 days' },
  { value: 7200, label: 'Every 5 days' },
  { value: 8640, label: 'Every 6 days' },
  { value: 10080, label: 'Every 1 week' },
];

// getters
export const getCooldownPeriodValueFromLabel = label => {
  return cooldownPeriodDropdownData.find(i => i.label === label)?.value;
};

export const getCooldownPeriodLabelFromValue = value => {
  return cooldownPeriodDropdownData.find(i => i.value === value)?.label;
};
