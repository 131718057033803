/**
 * @ External Dependecies
 */
import styled from 'styled-components';

export default styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 11100;
  background: rgba(33, 31, 80, 0.21);
  opacity: 0;
  visibility: hidden;
  transition: visibility 0.3s, opacity 0.3s, left 0.3s;

  ${props =>
    props.visible &&
    `
		opacity: 1;
		visibility: visible;
  `}

  ${props =>
    props.theprofile &&
    `
		opacity: 0;
		visibility: visible;
	`}
`;
