import { SimpleDropdownBtn, SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';

const DeactivateAlertMenuItem = ({ isActive, onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>
        {`${isActive ? 'Deactivate' : 'Activate'} Alert`}
      </SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default DeactivateAlertMenuItem;
