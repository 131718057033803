import React, { useState } from 'react';
import ReactDOM from 'react-dom';

export default ({ tabContainer, tabs, portalNode }) => {
  const TabContainer = tabContainer ? tabContainer : 'div';
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabElements = tabs.map((tab, index) => {
    const handleClick = () => {
      tab.handleClick && tab.handleClick();
      setSelectedIndex(index);
    };
    return tab.tabElement ? (
      React.cloneElement(tab.tabElement, {
        onClick: handleClick,
        active: selectedIndex === index,
      })
    ) : (
      <div onClick={handleClick}>{tab.label}</div>
    );
  });
  return (
    <React.Fragment>
      {tabContainer ? <TabContainer>{tabElements}</TabContainer> : <div>{tabElements}</div>}
      {portalNode ? (
        ReactDOM.createPortal(tabs[selectedIndex].content, portalNode)
      ) : (
        <div style={styles.contentContainer}>{tabs ? tabs[selectedIndex].content : null}</div>
      )}
    </React.Fragment>
  );
};

const styles = {
  contentContainer: {
    overflow: 'visible',
  },
};
