import { useState, useEffect } from 'react';
import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import CreateGroupButton from 'features/fleet/groups/components/buttons/CreateGroupButton';
import { useFetchGroupsQuery } from 'features/fleet/groups/hooks/useFetchGroupsQuery';
import { Link } from 'react-router-dom';
import { permissionData } from 'shared/constants/users';
import PageListWrapper, { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import WcpTable from 'app/shared/components/controls/WcpTable/WcpTable';
import GroupListActionMenu from 'features/fleet/groups/components/menus/GroupListActionMenu';
import React from 'react';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import ListingCount from 'shared/components/ListingCount';

const GroupsListComponent = (props) => {
  const [fetchGroupsQuery, groups] = useFetchGroupsQuery();
  const [listingCount, setListingCount] = useState(groups?.length);

  useEffect(() => {
    setListingCount(groups?.length);
  }, [groups]);
  let groupTableData = groups?.map((group) => {
    return {
      groupName: group.group_name,
      groupId: group.group_id,
      vehicleCount: group.vehicle_count.toString(),
      organizationId: group.organization_id,
      vehicleId: group.vehicle_id,
    };
  });

  return fetchGroupsQuery.isFetched && groups ? (
    <>
      <PageListWrapper>
        <PageListHead>
          <PageListTitle>Groups</PageListTitle>
          <PermissionProtectedElement requiredPermissions={[permissionData.editfleet]}>
            <CreateGroupButton />
          </PermissionProtectedElement>
        </PageListHead>

        <ListingCount
          listingCount={listingCount}
          totalCount={groups?.length}
          itemTypeName={'Group'}
        />

        <WcpTable
          tableClassName="table-simple"
          rowClassName={(record, i) => `row-${i}`}
          tableSourceData={groupTableData ? groupTableData : []}
          columns={[
            {
              title: 'Group Name',
              displayProperty: 'groupName',
              className: 'name',
              width: '45%',
              render({ rowData, cellJsx }) {
                return (
                  <span className="vehicleNameAndIcon" title={`Group Name: ${rowData.groupName}`}>
                    <IconSvgComponent svgFileName={'folder'} alt="Groups" />

                    <Link
                      to={`${encodeURIComponent(rowData.groupName)}`}
                      style={{ textDecoration: 'none' }}
                    >
                      {cellJsx}
                    </Link>
                  </span>
                );
              },
            },

            {
              title: 'Total Number of Vehicles',
              displayProperty: 'vehicleCount',
              width: '45%',
              render({ rowData, cellJsx }) {
                return (
                  <div title={'Total Number of Vehicles: ' + rowData.vehicleCount}>
                    <IconSvgComponent
                      svgFileName={'car-outline'}
                      style={styles.totalVehiclesIcon}
                      alt="Vehicle"
                    />
                    <span>{cellJsx}</span>
                  </div>
                );
              },
            },
            {
              className: 'action',
              searchEnabled: false,
              width: '5%',
              render({ rowData }) {
                return <GroupListActionMenu group={rowData} />;
              },
            },
          ]}
          rowKey={(record, i) => i}
          setListingCount={setListingCount}
          emptyText="No groups are currently associated with this organization"
        />
      </PageListWrapper>
    </>
  ) : (
    <LoadingOverlay />
  );
};

const styles = {
  totalVehiclesIcon: {
    marginBottom: 5,
    marginRight: 12,
  },
};

export default GroupsListComponent;
