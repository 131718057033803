export const firmwareStatus = {
  UP_TO_DATE: {
    displayName: 'Up To Date',
    status: 'UP_TO_DATE',
    progress: 100,
  },
  DOWNLOAD_SCHEDULED: {
    displayName: 'Downloading',
    status: 'DOWNLOAD_SCHEDULED',
    progress: 0,
  },
  ERROR_DOWNLOADING: {
    displayName: 'Downloading',
    status: 'ERROR_DOWNLOADING',
    progress: 0,
  },
  READY_TO_UPDATE: {
    displayName: 'Ready to Update',
    status: 'READY_TO_UPDATE',
    progress: 0,
    buttonText: 'Update Now',
  },
  UPDATE_AUTHORIZED: {
    displayName: 'Update Authorized',
    status: 'UPDATE_AUTHORIZED',
    progress: 33,
  },
  BOOTLOADING: {
    displayName: 'Bootloading',
    status: 'BOOTLOADING',
    progress: 66,
  },
  ERROR_BOOTLOADING: {
    displayName: 'Update Failed',
    status: 'ERROR_BOOTLOADING',
    progress: 0,
    buttonText: 'Retry',
  },
  EXTERNAL_UPDATE: {
    displayName: 'Update Complete (Via Command)',
    status: 'EXTERNAL_UPDATE',
    progress: 100,
  },
};
