/**
 * @ External Dependecies
 */
import styled from 'styled-components';

/**
 * @ Internal Dependecies
 */
import theme from 'shared/styles/global/theme';
import colors from 'shared/constants/colors';
import device from 'shared/constants/sizing';

const Header = styled.header`
  touch-action: none;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 9000;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${theme.header_height};
  background: ${colors.cotton};
  grid-column: 1/-1;

  ${props =>
    props.alt &&
    `
		flex: 0 0 100%;
		padding-left: 31px;
		box-shadow: 0 2px 40px 0 rgba(0, 0, 0, .09);
	`};

  ${props =>
    props.fullscreenMapMode &&
    `
		display: none;
	`};
`;

export const HeaderInner = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  flex: 1 1 100%;
`;

export const HeaderAside = styled.div`
  max-width: 100%;
  flex: 0 0 auto;

  ${props =>
    props.medium &&
    `
		max-width: 320px;
	`};

  ${props =>
    props.fit &&
    `
		flex: 1 1 auto;

	`};

  ${props =>
    props.flexAlignCenter &&
    `
		display: flex;
		align-items: center;
	`};
`;

export const WCPLogo = styled.img`
  width: 55px;

  @media ${device.medium} {
    width: 80px;
  }
`;

export const OrganizationHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;

  @media ${device.large} {
    grid-template-columns: 1fr 1fr;
  }
`;
export const OrganizationHeaderWrapper = styled.div`
  margin-top: 10px;
`;

export const SearchResultsList = styled.ul`
  width: 300px;
  position: absolute;
  top: 68px;
  left: 0px;
  border-radius: 3px;
  max-height: 180px;
  overflow: auto;
  @media ${device.large} {
    max-height: 70vh;
  }

  ${props =>
    props.$restrictedHeightResults &&
    `
    max-height: 100px;
    @media ${device.large} {
      max-height: 120px;
  }

	`};
`;

export const PermissionsHeaderDiv = styled.div`
  @media ${device.large} {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`;

export const NameAndButtonsDiv = styled.div`
  display: grid;
  width: 100%;
  grid-template-areas:
    'Col'
    'Col';
  grid-template-rows: 50px 1fr;
  margin-bottom: 57px;
  @media ${device.large} {
    display: flex;
    justify-content: space-between;
  }
`;

export default Header;
