import AlertLogsSummary from 'app/features/alerts/ui/components/AlertLogsSummary/AlertLogsSummary';
import { useState } from 'react';
import PageListWrapper, {
  PageListActionButtonContainer,
  PageListHead,
  PageListTitle,
} from 'shared/styles/components/PageList';
const ManageAlertsLogsPage = () => {
  const [alertName, setAlertName] = useState(null);

  return (
    <PageListWrapper>
      <PageListHead stackable>
        <PageListTitle>
          {alertName ? `Activity Logs for ${alertName}` : `Please choose a date range`}
        </PageListTitle>
        <PageListActionButtonContainer></PageListActionButtonContainer>
      </PageListHead>
      <AlertLogsSummary handleSetAlertName={alertName => setAlertName(alertName)} />
    </PageListWrapper>
  );
};

export default ManageAlertsLogsPage;
