import { getRecipientGroupsApi } from 'app/features/recipientGroups/api/get/getRecipientGroupsApi';
import useGenericQuery from 'app/shared/hooks/reactQuery/useGenericQuery';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';

const useFetchRecipientGroupsQuery = () => {
  const [queryResult, queryKey] = useGenericQuery({
    queryKeyPrefix: 'getRecipientGroupsApi',
    apiFn: getRecipientGroupsApi,
    onErrorCallback: err => {
      logWcpError(err);
    },
  });

  return [queryResult.data, queryKey];
};

export default useFetchRecipientGroupsQuery;
