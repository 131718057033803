import { useEffect, useRef } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';

import store from 'core/redux/store';

import 'mapbox-gl/dist/mapbox-gl.css';

// modeled on mapbox IControl interface https://docs.mapbox.com/mapbox-gl-js/api/markers/#icontrol
// encapsulates a custom control that can be added to the mapbox map
// important to note that the React component passed in is rendered outside of the React tree and thus cannot rely on any external state
class MapboxCustomControls {
  // constructor takes in a React component to render
  constructor(controlsComponent) {
    this.controlsComponent = controlsComponent;
  }

  // called when the control is added to the map
  onAdd(map) {
    // save map reference
    this._map = map;

    // initialize the control container DOM element
    this._container = document.createElement('div');
    this._container.className = 'mapboxgl-ctrl';

    // create a root node for ReactDOM to render into
    let root = ReactDOM.createRoot(this._container);

    // render the controls component into the root node
    root.render(<Provider store={store}>{this.controlsComponent}</Provider>);

    // return the container DOM element
    return this._container;
  }

  // called when the control is removed from the map
  onRemove() {
    // remove the control container DOM element
    this._container.parentNode.removeChild(this._container);

    // remove the map reference
    this._map = undefined;
  }
}

// custom hook to add a custom control to the mapbox map
const useMapboxCustomControl = ({
  renderComponent,
  triggerRerender,
  mapboxApi,
  isMapboxLoaded,
  position,
}) => {
  // ref to hold the custom map control
  const mapboxCustomControlRef = useRef(null);

  // sets up a new DOM map control based on the passed in React component adds it to the map and returns it
  const createMapboxCustomControl = () => {
    // remove existing control if it exists
    if (mapboxApi.hasControl(mapboxCustomControlRef.current)) {
      mapboxApi.removeControl(mapboxCustomControlRef.current);
    }

    // create new DOM control
    let newMapboxControl = new MapboxCustomControls(renderComponent);

    // add new controls
    mapboxApi.addControl(newMapboxControl, position);

    // return the new control
    return newMapboxControl;
  };

  // creates a new map control when the mapbox api is loaded or when explicitly triggered
  useEffect(() => {
    // do not attempt to add controls if mapbox is not loaded
    if (!mapboxApi || !isMapboxLoaded) return;

    // generates new map control and saves it to ref
    mapboxCustomControlRef.current = createMapboxCustomControl();
  }, [isMapboxLoaded, triggerRerender]);
};

export default useMapboxCustomControl;
