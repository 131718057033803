// helper to map form data to request params
export const mapPlaybackFormToRequestParams = ({ playbackRequestForm }) => {
  // destructure playback request form data
  return {
    startTime: playbackRequestForm.startTime,
    endTime: playbackRequestForm.endTime,
    startDate: playbackRequestForm.startDate,
    endDate: playbackRequestForm.endDate,
    vehicleId: playbackRequestForm.selectedVehicleId,
    granularity: playbackRequestForm.selectedGranularity.value,
  };
};
