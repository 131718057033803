import { Formik } from 'formik';
// 3rd party
import { createContext, useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';

// core
import {
  editVehicleAddons,
  editVehicleDeviceById,
  editVehicleMaintenance,
  editVehicleMetaById,
  flattenDeviceData,
  undecorateVehicleObject,
} from 'core/api/vehicles';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { addNotification } from 'core/redux/ui/actions';
import VehicleAddonFeatures from 'features/fleet/vehicles/components/controls/addons/VehicleAddonFeatures';
import VehicleAssociatedGroups from 'features/fleet/vehicles/components/controls/associatedGroups/VehicleAssociatedGroups';
import VehicleDeviceContainer from 'features/fleet/vehicles/components/devices/VehicleDeviceContainer';
import { SimBanner } from 'features/fleet/vehicles/components/forms/custom/SimBanner';
import VehicleDeviceIdField from 'features/fleet/vehicles/components/forms/custom/VehicleDeviceIdField';
import VehicleFormVinField from 'features/fleet/vehicles/components/forms/custom/VehicleFormVinField';
import MaintenanceSection from 'features/fleet/vehicles/components/forms/MaintenanceSection';
import VehicleHaasAlertingSelect from 'features/fleet/vehicles/components/forms/select/VehicleHaasAlertingSelect';
import VehicleLicenseStateSelect from 'features/fleet/vehicles/components/forms/select/VehicleLicenseStateSelect';
import VehicleTypeSelect from 'features/fleet/vehicles/components/forms/select/VehicleTypeSelect';
import VehicleAliasField from 'features/fleet/vehicles/components/forms/textField/VehicleAliasField';
import VehicleLicensePlateField from 'features/fleet/vehicles/components/forms/textField/VehicleLicensePlateField';
import VehicleMakeField from 'features/fleet/vehicles/components/forms/textField/VehicleMakeField';
import VehicleModelField from 'features/fleet/vehicles/components/forms/textField/VehicleModelField';
import VehicleNameField from 'features/fleet/vehicles/components/forms/textField/VehicleNameField';
import VehicleNotes from 'features/fleet/vehicles/components/forms/textField/VehicleNotes';
import VehicleYearField from 'features/fleet/vehicles/components/forms/textField/VehicleYearField';
import useVerifyVsgIdQuery from 'features/fleet/vehicles/hooks/useVerifyVsgIdQuery';
// shared
import Button from 'shared/styles/components/Button';
import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import FormWrapper, {
  CheckboxTextSpan,
  Form,
  FormActions,
  FormAsterisk,
  FormBody,
  FormCol,
  FormFoot,
  FormGridRow,
  FormHead,
  FormIcon,
  FormLegend,
  FormMessage,
  FormSectionLabel,
  FormTitle,
  SimNotActive,
} from 'shared/styles/components/Form';
import {
  CheckboxWrapperDiv,
  HaasIntegrationWrapperDiv,
  HaasOptionWrapper,
  OutOfServiceWrapper,
} from 'shared/styles/components/VehicleEdit';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { validateVehicle } from 'shared/utilities/validators';
import { getFormattedDuid } from 'shared/utilities/vehicle';
import OutOfServiceModal from '../modals/OutOfServiceModal';
import { SectionDivider } from 'shared/styles/components/SectionDivider';
import { process207 } from 'features/fleet/vehicles/components/forms/utilties/process207';

export const VehicleAddonApi = createContext(null);

const VehicleEditFormContainer = ({ handleRedirect, vehicle, dispatchNotification }) => {
  const [associatedGroups, setAssociatedGroups] = useState([]);
  const [updatedDeviceConfiguration, setUpdatedDeviceConfiguration] = useState(
    JSON.stringify(vehicle?.device) === '{}' ? null : vehicle?.device, // test for empty object to fix empty row in UI
  );
  const [maintenanceItems, setMaintenanceItems] = useState(vehicle?.maintenance);
  const [deviceIdDirty, setDeviceIsDirty] = useState(false);
  const [haasDirty, setHaasDirty] = useState(false);
  const [deviceContainerDirty, setDeviceContainerDirty] = useState(false);
  const [enhancedTelematicsDirty, setEnhancedTelematicsDirty] = useState(false);
  const [associatedGroupsDirty, setAssociatedGroupsDirty] = useState(false);
  const [addonsDirty, setAddonsDirty] = useState(false);
  const [maintenanceDirty, setMaintenanceDirty] = useState(false);
  const [maintenanceValid, setMaintenanceValid] = useState(true);

  const [resetPreferencesModalActive, setResetPreferencesModalActive] = useState(false);
  const [resetVsgConfirmed, setResetVsgConfirmed] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [addonValidationErrors, setAddonValidationErrors] = useState(false);

  const [prefillMake, setPrefillMake] = useState(null);
  const [prefillModel, setPrefillModel] = useState(null);
  const [prefillYear, setPrefillYear] = useState(null);
  const showSimBanner = vehicle?.cell?.state !== 'active';
  const [vehicleAddons, setVehicleAddons] = useState([]);
  const [haasIntegrationEnabled, setHaasIntegrationEnabled] = useState(vehicle?.meta.haas_enabled);
  const [outOfServiceEnabled, setOutOfServiceEnabled] = useState(vehicle?.meta.out_of_service);
  const [outOfServiceDirty, setOutOfServiceDirty] = useState(false);
  const [outOfServiceModalData, setOutOfServiceModalData] = useState({ show: false });
  const [enhancedTelematicsEnabled, setEnhancedTelematicsEnabled] = useState(
    vehicle?.meta.enhanced_telematics_enabled,
  );
  {
    /* Default public alerting option to true until Haas fully supports it*/
  }

  useEffect(() => {
    // remove id property from maintenance items
    const currentMaintenanceItems = maintenanceItems.map(item => {
      const { id, ...rest } = item;
      return rest;
    });

    if (!_.isEqual(vehicle.maintenance, currentMaintenanceItems)) {
      setMaintenanceDirty(true);
    }
  }, [maintenanceItems]);

  const [
    vsgIsValidAndAcknowledged,
    verifyVsgIdModalActive,
    setVerifyVsgIdModalActive,
    setVerifyVsgWarningConfirmed,
  ] = useVerifyVsgIdQuery();

  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);

  const organization = useSelector(state =>
    state.organizations.organizations.find(
      o => o.organizationId === state.user.filteredOrganizationId,
    ),
  );

  useEffect(() => {
    const previousMaintenanceItems = vehicle.maintenance;

    // TODO temporary - take care of this in mapping
    const currentMaintenanceItems = maintenanceItems.map(item => {
      const { id, ...rest } = item;
      return rest;
    });

    const isDirty = !_.isEqual(currentMaintenanceItems, previousMaintenanceItems);

    if (isDirty) {
      setMaintenanceDirty(true);
    }

    //setMaintenanceItems(vehicle?.maintenance);
  }, [maintenanceItems]);

  useEffect(() => {
    setVehicleAddons(JSON.parse(JSON.stringify(vehicle.addons)));
  }, []);

  useEffect(() => {
    setAddonsDirty(!_.isEqual(vehicle.addons, vehicleAddons));
  }, [vehicleAddons]);

  const postEditVehicleMeta = async vehicleFormData => {
    // need to call both vehicle and device endpoints
    const { response } = await editVehicleMetaById(vehicleFormData);

    return !!response;
  };

  const postVehicleAddons = async () => {
    let addons = vehicleAddons.map(addon => ({
      type: addon.type,
      assigned_inputs: addon.assigned_inputs,
      alert_level: addon.alert_level,
      addon_meta: {},
    }));

    const { response } = await editVehicleAddons(
      vehicle.vehicle_id,
      filteredOrganizationId,
      addons,
    );

    return !!response;
  };

  const postVehicleMaintenance = async () => {
    const { response } = await editVehicleMaintenance({
      vehicleId: vehicle.vehicle_id,
      organizationId: filteredOrganizationId,
      maintenanceItems,
    });

    return !!response;
  };

  const postEditVehicleDevice = async () => {
    const { response, status } = await editVehicleDeviceById({
      ...vehicle,
      ...{ device: updatedDeviceConfiguration },
    });

    process207({ status, response, vehicleName: vehicle.meta.label, dispatchNotification });
    return !!response;
  };

  const confirmHandler = async (values, formikActions) => {
    if (deviceIdDirty && !resetVsgConfirmed) {
      setResetPreferencesModalActive(true);
    } else {
      values.duid = values.duid.replace(/-/g, '');
      values.vin = values.vin.toUpperCase();
      let isValidAndAcknowledged = await vsgIsValidAndAcknowledged(values.duid);
      if (!isValidAndAcknowledged) {
        setVerifyVsgIdModalActive(true);
      } else {
        const vehicleFormData = {
          ...undecorateVehicleObject({
            vehicle: {
              ...values,
              ...(vehicle
                ? {
                    vehicleId: vehicle.vehicle_id,
                  }
                : {}),
              organizationId: filteredOrganizationId,
              groups: associatedGroups,
              haasIntegrationEnabled,
              outOfServiceEnabled,
              enhancedTelematicsEnabled,
            },
          }),
        };
        try {
          let success = false;
          success = await postEditVehicleMeta(vehicleFormData);
          if (addonsDirty) {
            success = await postVehicleAddons();
          }
          if (maintenanceDirty) {
            success = await postVehicleMaintenance();
          }

          if (vehicleFormData.device && !deviceIdDirty && deviceContainerDirty) {
            success = await postEditVehicleDevice(vehicleFormData);
          }

          if (success) {
            dispatchNotification.vehicleEditSuccess({ vehicleName: vehicleFormData.label });

            handleRedirect();
          }
        } catch (err) {
          logWcpError(err);
          setServerError(err);
        }

        formikActions.setSubmitting(false);
      }
    }
  };

  const handleOutOfService = () => {
    if (!outOfServiceEnabled) {
      setOutOfServiceModalData(prevState => ({ ...prevState, show: true }));
    }
    setOutOfServiceEnabled(!outOfServiceEnabled);
    setOutOfServiceDirty(true);
  };

  return (
    vehicle && (
      <>
        {
          <OutOfServiceModal
            modalData={outOfServiceModalData}
            handleCloseModal={() =>
              setOutOfServiceModalData(prevState => ({ ...prevState, show: false }))
            }
          />
        }
        <FormWrapper>
          <Formik
            initialValues={{
              ...(vehicle &&
                vehicle.meta && {
                  label: vehicle.meta.label,
                  vin: vehicle.meta.vin,
                  duid: getFormattedDuid(vehicle),
                  licensePlate: vehicle.meta.license_plate,
                  licenseState: vehicle.meta.license_state,
                  type: vehicle.meta.vehicle_type,
                  model: vehicle.meta.model,
                  make: vehicle.meta.make,
                  year: vehicle.meta.mfg_year,
                  notes: vehicle.meta.notes,
                  alias: vehicle.meta.alias,
                  haasPublicAlerting: vehicle.meta.haas_public_alerting,
                  odometer: vehicle.obd?.odometer ? vehicle.obd.odometer : 'N/A',
                  engineHours: vehicle.obd?.engine_hours ? vehicle.obd.engine_hours : 'N/A',
                  oilLife: vehicle.obd?.oil_life ? vehicle.obd?.oil_life : '',
                }),
              device: updatedDeviceConfiguration || '',
            }}
            validate={validateVehicle}
            validateOnChange={true}
            validateOnBlur={false}
            onSubmit={(values, formikActions) => confirmHandler(values, formikActions)}
          >
            {formikProps => {
              return (
                <Form onSubmit={formikProps.submitForm}>
                  <FormHead>
                    <FormIcon>
                      <IconSvgComponent svgFileName={vehicle ? 'edit-pencil-blue' : 'plus'} />
                    </FormIcon>
                    <FormTitle>Vehicle Details and Connectivity</FormTitle>
                  </FormHead>

                  <FormBody>
                    <FormGridRow>
                      <FormCol>
                        <VehicleNameField formikProps={formikProps} />
                      </FormCol>
                      <FormCol>
                        <VehicleDeviceIdField
                          formikProps={formikProps}
                          resetPreferencesModalActive={resetPreferencesModalActive}
                          handleConfirmVsgResetPrefencesAccepted={isAccepted => {
                            // close modal
                            setResetPreferencesModalActive(false);

                            // submit if accepted
                            if (isAccepted) {
                              setResetVsgConfirmed(true);
                              formikProps.submitForm();
                            }
                          }}
                          handleDeviceIdDirty={setDeviceIsDirty}
                          verifyVsgIdModalActive={verifyVsgIdModalActive}
                          handleConfirmVerifyVsgIdAccepted={isAccepted => {
                            // close modal
                            setVerifyVsgIdModalActive(false);

                            // submit if accepted
                            if (isAccepted) {
                              setVerifyVsgWarningConfirmed(true);
                              formikProps.submitForm();
                            }
                          }}
                        />
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <VehicleFormVinField
                          formikProps={formikProps}
                          setVinPrefills={(make, model, year) => {
                            setPrefillMake(make);
                            setPrefillModel(model);
                            setPrefillYear(year);
                          }}
                        />
                      </FormCol>
                      <FormCol>
                        <VehicleLicensePlateField formikProps={formikProps} />
                        <VehicleLicenseStateSelect formikProps={formikProps} />
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <VehicleTypeSelect formikProps={formikProps} />
                      </FormCol>
                      <FormCol>
                        <VehicleMakeField formikProps={formikProps} prefillValue={prefillMake} />
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <VehicleModelField formikProps={formikProps} prefillValue={prefillModel} />
                      </FormCol>
                      <FormCol>
                        <VehicleYearField formikProps={formikProps} prefillValue={prefillYear} />
                      </FormCol>
                    </FormGridRow>
                    <FormGridRow>
                      <FormCol>
                        <VehicleAliasField formikProps={formikProps} />
                      </FormCol>
                    </FormGridRow>

                    <FormGridRow>
                      <FormCol>
                        <OutOfServiceWrapper>
                          <FormSectionLabel gtt>Vehicle Status</FormSectionLabel>
                          <Checkbox singleCheck>
                            <CheckboxInner
                              isChecked={outOfServiceEnabled}
                              onClick={() => {
                                handleOutOfService();
                              }}
                            />
                          </Checkbox>
                          <CheckboxTextSpan>This vehicle is out of service</CheckboxTextSpan>
                        </OutOfServiceWrapper>
                      </FormCol>
                    </FormGridRow>
                    <VehicleNotes formikProps={formikProps} />

                    {showSimBanner ? (
                      <SimNotActive>
                        <SimBanner vehicle={vehicle} simMessage={true} />
                      </SimNotActive>
                    ) : null}

                    {/* Devices */}
                    {
                      <VehicleDeviceContainer
                        // deviceData={flattenDeviceData(updatedDeviceConfiguration)} // this line should be restored before merging
                        deviceData={flattenDeviceData(updatedDeviceConfiguration)}
                        handleChange={d => {
                          setDeviceContainerDirty(
                            JSON.stringify(d) !== JSON.stringify(vehicle.device),
                          );
                          setUpdatedDeviceConfiguration(d);
                        }}
                      />
                    }
                    <SectionDivider />
                    <VehicleAddonFeatures
                      vehicleAddons={vehicleAddons}
                      updateVehicleAddons={va => {
                        setVehicleAddons(va);
                      }}
                      preventSubmit={value => {
                        setAddonValidationErrors(value);
                      }}
                    />

                    {organization.haasEnabled && organization.haasSetup && (
                      <>
                        <SectionDivider />
                        <HaasIntegrationWrapperDiv>
                          <FormSectionLabel>HAAS Integration</FormSectionLabel>
                          <CheckboxWrapperDiv>
                            <Checkbox singleCheck>
                              <CheckboxInner
                                isChecked={haasIntegrationEnabled}
                                onClick={() => {
                                  setHaasIntegrationEnabled(!haasIntegrationEnabled);
                                  setHaasDirty(true);
                                }}
                              />
                            </Checkbox>
                            <CheckboxTextSpan>Enable HAAS Integration</CheckboxTextSpan>
                          </CheckboxWrapperDiv>

                          <HaasOptionWrapper>
                            <VehicleHaasAlertingSelect formikProps={formikProps} />
                          </HaasOptionWrapper>
                        </HaasIntegrationWrapperDiv>
                      </>
                    )}
                    <SectionDivider />
                    <>
                      <MaintenanceSection
                        vehicle={vehicle}
                        formikProps={formikProps}
                        handleSetMaintenanceItems={({ maintenanceItems }) =>
                          setMaintenanceItems(maintenanceItems)
                        }
                        handleMaintenanceItemsDirty={({ isDirty }) => {
                          setMaintenanceDirty(isDirty);
                        }}
                        setEnhancedTelematicsEnabled={setEnhancedTelematicsEnabled}
                        enhancedTelematicsEnabled={enhancedTelematicsEnabled}
                        setEnhancedTelematicsDirty={setEnhancedTelematicsDirty}
                        handleMaintenanceItemsValid={({ isValid }) => {
                          setMaintenanceValid(isValid);
                        }}
                      />
                      <SectionDivider />
                    </>

                    {/* Associated Groups */}
                    <VehicleAssociatedGroups
                      vehicle={vehicle}
                      updateVehicleAssociatedGroups={ag => {
                        setAssociatedGroups(ag);
                      }}
                      handleIsDirty={isDirty => {
                        setAssociatedGroupsDirty(isDirty);
                      }}
                    />
                    <FormMessage message={serverError}>{serverError}</FormMessage>
                  </FormBody>

                  <FormFoot>
                    <FormLegend>
                      <FormAsterisk>*</FormAsterisk> &mdash; required fields
                    </FormLegend>
                    <FormActions>
                      <Button
                        type="button"
                        onClick={handleRedirect}
                        withRightSpacer
                        mediumAlt
                        default
                      >
                        Cancel
                      </Button>
                      <ButtonWithLoader
                        isLoading={formikProps.isSubmitting}
                        disabled={
                          (!formikProps.dirty &&
                            !haasDirty &&
                            !associatedGroupsDirty &&
                            !enhancedTelematicsDirty &&
                            !addonsDirty &&
                            !maintenanceDirty &&
                            !deviceContainerDirty &&
                            !outOfServiceDirty) ||
                          addonValidationErrors ||
                          !maintenanceValid
                        }
                        confirmText={'Save Changes'}
                        loadingStyleProp={'submittingWithSpinnerModal'}
                        notLoadingStyleProp={'mediumAlt'}
                        clickHandler={formikProps.submitForm}
                      />
                    </FormActions>
                  </FormFoot>
                </Form>
              );
            }}
          </Formik>
        </FormWrapper>
      </>
    )
  );
};
export default connect(null, dispatch => ({
  dispatchNotification: {
    vehicleEditSuccess: ({ vehicleName }) =>
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.SUCCESS,
            'Vehicle Update Success',
            `Successfully updated "${vehicleName}"`,
          ),
        }),
      ),
    vehicleEditDeviceWarning: ({ message }) =>
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.WARNING, 'Warning', `${message}`),
        }),
      ),
    vehicleEditError: ({ vehicleName, err }) =>
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, `Error Updating "${vehicleName}"`, err),
        }),
      ),
  },
}))(VehicleEditFormContainer);
