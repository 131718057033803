/**
 * @ External Dependencies
 */
import { createAction } from 'redux-actions';

/**
 * @ Action creators
 */
export const openSidebar = createAction('OPEN_SIDEBAR');
export const endOpenSidebarTransition = createAction('END_OPEN_SIDEBAR');
export const closeSidebar = createAction('CLOSE_SIDEBAR');
export const endCloseSidebarTransition = createAction('END_CLOSE_SIDEBAR');
export const toggleSidebar = createAction('TOGGLE_SIDEBAR');
export const requestEndCloseSidebar = event => {
  return dispatch => {
    if (event.target.nodeName === 'ASIDE') {
      dispatch(endCloseSidebarTransition());
    }
  };
};
