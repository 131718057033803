import React, { useState } from 'react';
import { vehicleLockStatusViewData, vehicleTypeViewData } from 'shared/constants/vehicle';
import {
  CardLabel,
  CardValue,
  CardRow,
  CardName,
  CardType,
  ShowCollapseButton,
  CardContainer,
  ButtonWrapper,
  CardOuterWrapper,
  VehicleIcon,
  CardIconWrapper,
  CardCollapsedInfo,
  ActionMenuBtnWrapper,
  CardExpandedInfo,
  CardSimNotActive,
  CardBottomWrapper,
} from 'shared/styles/components/MobileCard';
import { firmwareStatus } from 'features/ota/shared/constants/firmwareStatus';
import ProgressBar from 'features/ota/shared/components/ProgressBar';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import { permissionData } from 'shared/constants/users';

export default function OtaUpdateCard({
  update,
  vehicleStatuses,
  handleUpdateFirmware,
  updatePostLoading,
}) {
  const [expanded, setExpanded] = useState(false);
  const handleToggleShowCollapse = () => {
    setExpanded(!expanded);
  };

  const displayVehicleIcon = vehicleType => {
    switch (vehicleType) {
      case 'AMBULANCE':
        return 'car4';
      case 'TOW':
        return 'car2';
      case 'DOT':
        return 'car1';
      case 'FIRE':
        return 'car5';
      case 'POLICE':
        return 'car3';
      default:
        return 'car3';
    }
  };

  return (
    <CardOuterWrapper>
      <CardContainer otaExpanded>
        <CardIconWrapper>
          <VehicleIcon svgFileName={displayVehicleIcon(update.vehicleType)} alt="Vehicle" />
          <CardCollapsedInfo>
            <CardRow header>
              <CardName>{update.label}</CardName>
            </CardRow>
            <CardRow header>
              <CardType>{vehicleTypeViewData[update.vehicleType].title}</CardType>
            </CardRow>
          </CardCollapsedInfo>
        </CardIconWrapper>
        <CardExpandedInfo expanded>
          <CardRow>
            <CardLabel>Device ID:</CardLabel>
            <CardValue>{update.deviceId}</CardValue>
          </CardRow>

          <>
            <CardRow>
              <CardLabel>Initial Firmware Version:</CardLabel>
              <CardValue>{update.versionInitial}</CardValue>
            </CardRow>
            <CardRow>
              <CardLabel>Firmware Status:</CardLabel>
              <CardValue>{firmwareStatus[update.firmwareStatus].displayName}</CardValue>
            </CardRow>
            <CardRow>
              <CardLabel>Update Progress:</CardLabel>
              <CardValue>
                <ProgressBar progress={firmwareStatus[update.firmwareStatus].progress} />
              </CardValue>
            </CardRow>
            <CardRow>
              {firmwareStatus[update.firmwareStatus].hasOwnProperty('buttonText') && (
                <PermissionProtectedElement requiredPermissions={[permissionData.editotafirmware]}>
                  <ButtonWithLoader
                    disabled={
                      !vehicleStatuses[update.vehicleId].isActive ||
                      vehicleStatuses[update.vehicled]?.isResponding
                    }
                    loadingStyleProp={'otaUpdateSubmittingWithSpinner'}
                    notLoadingStyleProp={'otaUpdate'}
                    confirmText={firmwareStatus[update.firmwareStatus].buttonText}
                    isLoading={update.deviceId === updatePostLoading}
                    clickHandler={async () => {
                      await handleUpdateFirmware(update);
                    }}
                  />
                </PermissionProtectedElement>
              )}
            </CardRow>
          </>
        </CardExpandedInfo>
      </CardContainer>
    </CardOuterWrapper>
  );
}
