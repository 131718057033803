import { createAction } from 'redux-actions';

import {
  getVehicles as getVehiclesAPI,
  getVehiclesMeta as getVehiclesMetaAPI,
  getMapModeVehicles as getMapModeVehiclesAPI,
  getVehicleVINInformation as getVehicleVINInformationAPI,
  decorateVehicle,
} from 'core/api/vehicles';
import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import {
  lockVehicles as lockVehicleAPI,
  unlockVehicles as unlockVehicleAPI,
} from 'core/api/vehicles';
import { variableScopeAllowedUserRoles } from 'shared/constants/users';
import { loginIsFresh } from 'shared/utilities/localStore';
import { submitLogout } from 'core/redux/user/actions';

export const requestVehicles = createAction('REQUEST_VEHICLES');
export const receiveVehicles = createAction('RECEIVE_VEHICLES');
export const rejectVehicles = createAction('REJECT_VEHICLES');

export const requestVehiclesMeta = createAction('REQUEST_VEHICLES_META');
export const receiveVehiclesMeta = createAction('RECEIVE_VEHICLES_META');
export const rejectVehiclesMeta = createAction('REJECT_VEHICLES_META');

export const requestEditVehicle = createAction('REQUEST_EDIT_VEHICLE');
export const rejectEditVehicle = createAction('REJECT_EDIT_VEHICLE');
export const successEditVehicle = createAction('SUCCESS_EDIT_VEHICLE');

export const requestCreateVehicle = createAction('REQUEST_CREATE_VEHICLE');
export const rejectCreateVehicle = createAction('REJECT_CREATE_VEHICLE');
export const successCreateVehicle = createAction('SUCCESS_CREATE_VEHICLE');

export const requestVehicle = createAction('REQUEST_VEHICLE');
export const receiveVehicle = createAction('RECEIVE_VEHICLE');
export const rejectVehicle = createAction('REJECT_VEHICLE');

export const requestLockVehicles = createAction('REQUEST_LOCK_VEHICLES');
export const successLockVehicles = createAction('SUCCESS_LOCK_VEHICLES');
export const rejectLockVehicles = createAction('REJECT_LOCK_VEHICLES');

export const requestUnlockVehicles = createAction('REQUEST_UNLOCK_VEHICLES');
export const successUnlockVehicles = createAction('SUCCESS_UNLOCK_VEHICLES');
export const rejectUnlockVehicles = createAction('REJECT_UNLOCK_VEHICLES');

export const requestLockVehicle = createAction('REQUEST_LOCK_VEHICLE');
export const successLockVehicle = createAction('SUCCESS_LOCK_VEHICLE');
export const rejectLockVehicle = createAction('REJECT_LOCK_VEHICLE');

export const requestUnlockVehicle = createAction('REQUEST_UNLOCK_VEHICLE');
export const successUnlockVehicle = createAction('SUCCESS_UNLOCK_VEHICLE');
export const rejectUnlockVehicle = createAction('REJECT_UNLOCK_VEHICLE');

export const requestVehicleVINInformation = createAction('REQUEST_VEHICLE_VIN_INFORMATION');
export const receiveVehicleVINInformation = createAction('RECEIVE_VEHICLE_VIN_INFORMATION');
export const rejectVehicleVINInformation = createAction('REJECT_VEHICLE_VIN_INFORMATION');

export const fetchVehicles = () => {
  return async (dispatch, getState) => {
    // cancel extraneous fetches - this is a quick solution to WCP-2777; correct way will involve more redux work
    if (getState().vehicles.loadingVehicles) {
      console.log('VEHICLE FETCH KILLED!');
      //console.trace();
      return true;
    } else {
      console.log('VEHICLE FETCH STARTED...');
    }
    const isLoggedIn = loginIsFresh();
    if (isLoggedIn) {
      dispatch(requestVehicles());
      try {
        const userState = getState().user;
        const filteredOrganizationId = variableScopeAllowedUserRoles.includes(
          userState.user.userRole,
        )
          ? userState.filteredOrganizationId
          : userState.user.organizationId;

        const fullscreenMapMode = getState().ui.fullscreenMapMode;

        let vehicleResponse = null;

        if (fullscreenMapMode) {
          const { response } = await getMapModeVehiclesAPI(filteredOrganizationId);
          vehicleResponse = response;
        } else {
          const { response } = await getVehiclesAPI({ organizationId: filteredOrganizationId });
          vehicleResponse = response;
        }

        dispatch(
          receiveVehicles(
            vehicleResponse.message.map(vehicle =>
              decorateVehicle({
                vehicleFromAPI: vehicle,
              }),
            ),
          ),
        );
        return true;
      } catch (e) {
        console.log('error getting vehicle metadata:', e);
        dispatch(rejectVehicles(e));
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.ERROR, 'Error Getting Vehicles', e),
          }),
        );
        return false;
      }
    } else {
      // if not logged in, kick user out
      dispatch(submitLogout({ isTimedOut: false }));
      return false;
    }
  };
};

export const fetchVehiclesMeta = () => {
  return async (dispatch, getState) => {
    const isLoggedIn = loginIsFresh();
    if (isLoggedIn) {
      dispatch(requestVehiclesMeta());
      try {
        const userState = getState().user;
        const filteredOrganizationId = variableScopeAllowedUserRoles.includes(
          userState.user.userRole,
        )
          ? userState.filteredOrganizationId
          : userState.user.organizationId;

        let vehicleResponse = null;

        const { response } = await getVehiclesMetaAPI(filteredOrganizationId);
        vehicleResponse = response;
        dispatch(
          receiveVehiclesMeta(
            vehicleResponse.message.map(vehicle =>
              decorateVehicle({
                vehicleFromAPI: vehicle,
              }),
            ),
          ),
        );
        return true;
      } catch (e) {
        console.log('error getting vehicle metadata:', e);
        dispatch(rejectVehiclesMeta(e));
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.ERROR, 'Error Getting Vehicles', e),
          }),
        );
        return false;
      }
    } else {
      // if not logged in, kick user out
      dispatch(submitLogout({ isTimedOut: false }));
      return false;
    }
  };
};

export const getVehicleVINInformation = vin => {
  return async dispatch => {
    try {
      const { response } = await getVehicleVINInformationAPI(vin);
      if (response.message) {
        dispatch(receiveVehicleVINInformation());
        return response.message;
      }
    } catch (e) {
      console.log(e);
      dispatch(rejectVehicleVINInformation(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error getting VIN information', e),
        }),
      );
    }
  };
};
