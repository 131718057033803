import { DateTime } from 'luxon';

import { firmwareStatuses } from 'shared/constants/firmwares';
import {
  BoldedCount,
  Content,
  ContentInner,
  Icon,
  OtaSummaryItemNotes,
  Text,
  Title,
  Wrapper,
} from 'shared/styles/components/OtaUpdates';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const ConfigurationUpdateSummaryItem = ({
  description,
  uploadedDate,
  configurationName,
  productName,
  showTotals,
  deviceUpdates,
}) => {
  const completeUpdates =
    deviceUpdates &&
    deviceUpdates.filter((u) => u.config_status === firmwareStatuses.UP_TO_DATE.status);
  const allDevicesComplete = deviceUpdates
    ? completeUpdates?.length === deviceUpdates?.length
    : false;
  return (
    <Wrapper>
      <Content className="col">
        <ContentInner offsetLeft={true}>
          <Icon>
            <IconSvgComponent svgFileName={allDevicesComplete ? 'check-success' : 'gears'} />
          </Icon>
          <Title>{configurationName}</Title>
          <Text>ACM Type: {productName}</Text>
          <Text>
            {uploadedDate === 'UNKNOWN'
              ? 'Uploaded prior to 8/15/2022'
              : `Uploaded on: ${DateTime.fromISO(uploadedDate).toLocaleString()}`}
          </Text>

          {showTotals && (
            <>
              <Text success updateComplete={allDevicesComplete} status>
                Deployment Complete for{' '}
                <BoldedCount>
                  {completeUpdates?.length} of {deviceUpdates?.length} Vehicles
                </BoldedCount>
              </Text>
            </>
          )}
        </ContentInner>
      </Content>
      <OtaSummaryItemNotes>{description}</OtaSummaryItemNotes>
    </Wrapper>
  );
};

export default ConfigurationUpdateSummaryItem;
