export const groupPoints = points => {
  if (!points?.length) {
    return [];
  }

  let filteredPoints = [];
  filteredPoints[0] = {
    status: points[0].status,
    points: [[points[0].long, points[0].lat]],
    id: `line-${new Date(points[0].time).toISOString()}`,
  };
  let count = 0;

  for (let i = 1; i < points?.length; i++) {
    const currentPoint = points[i];
    const previousPoint = points[i - 1];
    const currentTimeISO = new Date(currentPoint.time).toISOString();
    const previousTimeISO = new Date(previousPoint.time).toISOString();

    const timeDifference = (new Date(currentTimeISO) - new Date(previousTimeISO)) / 1000; // Convert to seconds
    if (timeDifference > 60) {
      count = ++count;
      filteredPoints[count] = {
        status: currentPoint.status,
        id: `line-${currentTimeISO}`,
        points: [],
      };
    } else if (currentPoint.status !== previousPoint.status) {
      filteredPoints[count].points.push([currentPoint.long, currentPoint.lat]);
      count = ++count;
      filteredPoints[count] = {
        status: currentPoint.status,
        id: `line-${currentTimeISO}`,
        points: [],
      };
    }

    filteredPoints[count].points.push([currentPoint.long, currentPoint.lat]);
  }

  return filteredPoints;
};
