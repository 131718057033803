import { useEffect, useState } from 'react';

export default ({ importPath }) => {
  const [module, setModule] = useState(null);

  useEffect(() => {
    const importModule = async () => {
      const module = await import(`assets/svg/${importPath}.svg`);
      setModule(module);
    };

    importModule();
  }, [importPath]);

  return module && module.default;
};
