export const handleSelectChange = (innerHandler, field) => selectedOption => {
  innerHandler(field, selectedOption.value);
};

export const handleSelectBlur = (innerHandler, field, touched) => e => {
  innerHandler({
    ...touched,
    [field]: true,
  });
};
