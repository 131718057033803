export const vehicleAddonAlertLevels = [
  {
    label: 'None',
    value: 0,
  },
  {
    label: 'Low',
    value: 1,
  },
  {
    label: 'Medium',
    value: 2,
  },
  {
    label: 'High',
    value: 3,
  },
];
