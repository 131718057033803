export const modalTypes = {
  createUser: 'CREATE_USER',
  editUser: 'EDIT_USER',
  createOrganization: 'CREATE_ORGANIZATION',
  editOrganization: 'EDIT_ORGANIZATION',
  deactivateOrganization: 'DEACTIVATE_ORGANIZATION',
  deactivateUser: 'DEACTIVATE_USER',
  userLimit: 'USER_LIMIT',
  userCreationActiveLimit: 'USER_CREATION_ACTIVE_LIMIT',
};
