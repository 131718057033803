import { useEffect, useState } from 'react';

import DeleteMaintenanceEventMenuItem from 'app/features/maintenance/components/MaintenanceSummary/menus/DeleteMaintenanceEventMenuItem';
import EditVehicleMenuIem from 'app/features/maintenance/components/MaintenanceSummary/menus/EditVehicleMenuIem';
import MarkCompletedMenuItem from 'app/features/maintenance/components/MaintenanceSummary/menus/MarkCompletedMenuItem';
import SuppressAlertsMenuItem from 'app/features/maintenance/components/MaintenanceSummary/menus/SuppressAlertsMenuItem';
import ViewMaintenanceLogsMenuItem from 'app/features/maintenance/components/MaintenanceSummary/menus/ViewMaintenanceLogsMenuItem';
import DeleteMaintenanceEventModal from 'app/features/maintenance/components/MaintenanceSummary/modals/DeleteMaintenanceEventModal';
import MarkMaintenanceCompletedModal from 'app/features/maintenance/components/MaintenanceSummary/modals/MarkMaintenanceCompletedModal';
import SuppressAlertsModal from 'app/features/maintenance/components/MaintenanceSummary/modals/SuppressAlertsModal';
import { maintenanceTypeConstants } from 'app/features/maintenance/data/maintenanceTypeConstants';
import { SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';
import ActionMenu from 'shared/ui/table/ActionMenu';

const MaintenanceActionMenu = ({ event }) => {
  const [suppressAlertsModalData, setSuppressAlertsModalData] = useState({ show: false });
  const [markMaintenanceCompletedModalData, setMarkMaintenanceCompletedModalData] = useState({
    show: false,
  });
  const [deleteMaintenanceEventModalData, setDeleteMaintenanceEventModalData] = useState({
    show: false,
  });

  useEffect(() => {
    setSuppressAlertsModalData({
      show: false,
      event: event,
    });
    setMarkMaintenanceCompletedModalData({
      show: false,
      event: event,
    });
    setDeleteMaintenanceEventModalData({
      show: false,
      event: event,
    });
  }, [event]);
  return (
    <>
      <ActionMenu>
        <SimpleDropdownItem>
          <MarkCompletedMenuItem
            onMenuItemClick={() =>
              setMarkMaintenanceCompletedModalData(prevState => ({ ...prevState, show: true }))
            }
          />
        </SimpleDropdownItem>
        <SimpleDropdownItem>
          <DeleteMaintenanceEventMenuItem
            onMenuItemClick={() =>
              setDeleteMaintenanceEventModalData(prevState => ({ ...prevState, show: true }))
            }
          />
        </SimpleDropdownItem>
        {!event.isSuppressed &&
          event.maintenanceType.value === maintenanceTypeConstants.DIAGNOSTIC && (
            <SimpleDropdownItem>
              <SuppressAlertsMenuItem
                onMenuItemClick={() =>
                  setSuppressAlertsModalData(prevState => ({ ...prevState, show: true }))
                }
              />
            </SimpleDropdownItem>
          )}
        <SimpleDropdownItem>
          <ViewMaintenanceLogsMenuItem vehicleId={event.vehicleId} />
        </SimpleDropdownItem>
        <SimpleDropdownItem>
          <EditVehicleMenuIem vehicleId={event.vehicleId} />
        </SimpleDropdownItem>
      </ActionMenu>
      {/* Modals */}
      <>
        <SuppressAlertsModal
          modalData={suppressAlertsModalData}
          handleCloseModal={() =>
            setSuppressAlertsModalData(prevState => ({ ...prevState, show: false }))
          }
        />
        <MarkMaintenanceCompletedModal
          modalData={markMaintenanceCompletedModalData}
          handleCloseModal={() =>
            setMarkMaintenanceCompletedModalData(prevState => ({ ...prevState, show: false }))
          }
        />
        <DeleteMaintenanceEventModal
          modalData={deleteMaintenanceEventModalData}
          handleCloseModal={() =>
            setDeleteMaintenanceEventModalData(prevState => ({ ...prevState, show: false }))
          }
        />
      </>
    </>
  );
};

export default MaintenanceActionMenu;
