import styled from 'styled-components';

export const AlertBarWrapper = styled.div`
  position: relative;
  padding: 6px 15px;
  font-size: 16px;
  text-align: center;

  p {
    margin-bottom: 0;
  }

  a {
    display: inline-block;
    margin: 0 28px;
  }

  background: #d0021b;
  color: #fff;
`;

export const ButtonClose = styled.button.attrs({
  type: 'button',
})`
  position: absolute;
  top: 50%;
  right: 20px;
  background: none;
  border: 0 none;
  display: inline-flex;
  align-items: center;
  transform: translateY(-50%);

  .svg-icon {
    margin-left: 7px;
    padding-top: 3px;
  }
`;

export const AlertMain = styled.div`
  margin: 0px 100px 0px 100px;
`;
