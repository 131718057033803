import { useEffect, useState } from 'react';

import { SimpleDropdownBtn, SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';
import ActionMenu from 'shared/ui/table/ActionMenu';
import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import { permissionData } from 'shared/constants/users';
import { handleDownloadFromURL } from 'core/api/API';
import { downloadConfiguration } from 'core/api/configuration';
import ModalPortal from 'shared/modals/ModalPortal';
import ConfigurationArchiveModal from 'shared/modals/ConfigurationArchiveModal';
import ConfigurationDeleteModal from 'shared/modals/ConfigurationDeleteModal';
import ConfigurationEditModal from 'shared/modals/ConfigurationEditModal';
import { editConfiguration } from 'core/redux/configurations/actions';
import { useDispatch } from 'react-redux';

const ConfigurationLibraryActionMenu = ({ configData }) => {
  const [editModalActive, setEditModalActive] = useState(false);
  const [deleteModalActive, setDeleteModalActive] = useState(false);
  const [archiveModalActive, setArchiveModalActive] = useState(false);

  const dispatch = useDispatch();

  const unarchiveConfiguration = (configuration, configurationId) => {
    dispatch(editConfiguration(configuration, configurationId));
  };
  return (
    <>
      <ActionMenu>
        <PermissionProtectedElement requiredPermissions={[permissionData.editotaconfig]}>
          <SimpleDropdownItem>
            <SimpleDropdownBtn
              onClick={() =>
                setEditModalActive(configData?.orgdata ? configData.orgdata : configData)
              }
            >
              Edit
            </SimpleDropdownBtn>
          </SimpleDropdownItem>
        </PermissionProtectedElement>
        <SimpleDropdownItem>
          <SimpleDropdownBtn
            onClick={handleDownloadFromURL({
              downloadServiceCall: downloadConfiguration,
              resourceId: configData?.orgdata ? configData.orgdata.configId : configData.configId,
              filename: configData?.orgdata ? configData.orgdata.configName : configData.configName,
            })}
          >
            Download
          </SimpleDropdownBtn>
        </SimpleDropdownItem>
        <PermissionProtectedElement requiredPermissions={[permissionData.editotaconfig]}>
          <SimpleDropdownItem>
            <SimpleDropdownBtn
              onClick={() =>
                configData.archived === 'false'
                  ? setArchiveModalActive(configData?.orgdata ? configData.orgdata : configData)
                  : unarchiveConfiguration(
                      { archived: 'false', action: 'Unarchive' },
                      configData?.orgdata ? configData.orgdata.configId : configData.configId,
                    )
              }
            >
              {configData.archived === 'false' ? 'Archive' : 'Unarchive'}
            </SimpleDropdownBtn>
          </SimpleDropdownItem>
        </PermissionProtectedElement>
      </ActionMenu>
      {/* Modals */}
      <>
        {editModalActive ? (
          <ModalPortal onRequestClose={() => setEditModalActive(false)}>
            <ConfigurationEditModal
              data={editModalActive}
              onSuccess={() => setEditModalActive(false)}
            />
          </ModalPortal>
        ) : null}
        {deleteModalActive ? (
          <ModalPortal onRequestClose={() => setDeleteModalActive(false)}>
            <ConfigurationDeleteModal
              onSuccess={() => setDeleteModalActive(false)}
              configurationId={deleteModalActive}
            />
          </ModalPortal>
        ) : null}
        {archiveModalActive ? (
          <ModalPortal onRequestClose={() => setArchiveModalActive(false)}>
            <ConfigurationArchiveModal
              onSuccess={() => setArchiveModalActive(false)}
              configuration={archiveModalActive}
            />
          </ModalPortal>
        ) : null}
      </>
    </>
  );
};

export default ConfigurationLibraryActionMenu;
