import { useFormik } from 'formik';
import { useNavigate } from 'react-router';

import useCreateRecipientGroupMutation from 'app/features/recipientGroups/hooks/mutations/useCreateRecipientGroupMutation';
import { validateRecipientGroup } from 'app/features/recipientGroups/utilities/validators';

const useCreateRecipientGroupForm = () => {
  const navigate = useNavigate();
  const createRecipientGroupMutation = useCreateRecipientGroupMutation();

  const formik = useFormik({
    initialValues: {},
    validate: validateRecipientGroup,
    validateOnChange: true,
    validateOnBlur: false,
    onSubmit: async values => {
      await createRecipientGroupMutation.mutateAsync({ newRecipientGroup: values });
      navigate('/managerecipientgroups');
    },
  });

  return formik;
};

export default useCreateRecipientGroupForm;
