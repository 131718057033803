import { alertMethodConstants } from 'app/features/alerts/data/enums/alertMethodConstants';
import CustomAlertMethodOption from 'app/features/alerts/ui/components/AlertForms/shared/modals/CustomAlertMethodOption';
import { determineCustomAlertMethod } from 'app/shared/utilities/determineCustomAlertMethod';
import { useEffect, useState } from 'react';

const CustomAlertRowBuilder = ({ recipientRow, handleCheckboxesChanged }) => {
  // for each user name, create an object
  let updatedRecipientGroupMembersInitialState = recipientRow.recipientGroup.recipientGroupMembers.map(
    rgm => {
      let recipientUserName = rgm.recipientUserName;
      let sms =
        rgm.recipientUserAlertMethod === alertMethodConstants.SMS ||
        rgm.recipientUserAlertMethod === alertMethodConstants.SMS_AND_EMAIL;

      let email =
        rgm.recipientUserAlertMethod === alertMethodConstants.EMAIL ||
        rgm.recipientUserAlertMethod === alertMethodConstants.SMS_AND_EMAIL;

      let recipientUserAlertMethod = determineCustomAlertMethod(sms, email);

      return {
        recipientUserName,
        sms,
        email,
        recipientUserAlertMethod,
      };
    },
  );

  const [updatedGroupMemberOptions, setUpdatedGroupMemberOptions] = useState(
    updatedRecipientGroupMembersInitialState,
  );

  useEffect(() => {
    console.log('updatedGroupMemberOptions', updatedGroupMemberOptions);
    handleCheckboxesChanged(updatedGroupMemberOptions);
  }, [updatedGroupMemberOptions]);

  return recipientRow.recipientGroup.recipientGroupMembers.map((r, i) => (
    <CustomAlertMethodOption
      key={i}
      currentRecipientGroupMember={r}
      updatedGroupMemberOptions={updatedGroupMemberOptions}
      setUpdatedGroupMemberOptions={setUpdatedGroupMemberOptions}
    />
  ));
};

export default CustomAlertRowBuilder;
