import { NotesInputDiv } from 'shared/styles/components/Maintenance';
import AnimatedField from 'shared/ui/fields/AnimatedField';

const NotesField = ({ formikProps: { values, touched, handleBlur, errors, handleChange } }) => {
  return (
    <NotesInputDiv>
      <AnimatedField
        smallerTextMobile
        placeholder="Notes"
        name="notes"
        touched={touched.notes}
        handleBlur={handleBlur}
        validationError={errors.notes}
        value={values.notes}
        handleChange={handleChange}
      />
    </NotesInputDiv>
  );
};

export default NotesField;
