import { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import WcpTable from 'app/shared/components/controls/WcpTable/WcpTable';
import ManageRecipientGroupsActionMenu from 'app/features/recipientGroups/components/ManageRecipientGroupsSummary/menus/ManageRecipientGroupsActionMenu';
import { getUserNameDisplayFromUserList } from 'app/shared/utilities/getUserNameDisplayFromUserList';

const ManageRecipientGroupsSummaryTable = ({ recipientGroups, handleUpdateListingCount }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    recipientGroups?.forEach(group => {
      group.formattedUserDisplayNames = getUserNameDisplayFromUserList(group.recipientGroupMembers);
    });
  }, [recipientGroups]);
  const columns = [
    {
      title: 'Recipient Group Name',
      displayProperty: 'recipientGroupName',
      className: 'name',
      width: '30%',
      render({ rowData, cellJsx }) {
        return (
          <span
            className="vehicleNameAndIcon"
            title={'Recipient Group Name: ' + rowData.recipientGroupName}
          >
            <Link
              to={{
                pathname: `/editrecipientgroup/${encodeURIComponent(rowData.recipientGroupId)}`,
                state: pathname,
              }}
              style={{ textDecoration: 'none' }}
            >
              {cellJsx}
            </Link>
          </span>
        );
      },
    },
    {
      title: 'Members',
      displayProperty: 'formattedUserDisplayNames',
    },

    {
      title: '',
      className: 'action',
      searchEnabled: false,
      render({ rowData }) {
        return <ManageRecipientGroupsActionMenu recipientGroup={rowData} />;
      },
    },
  ];

  return (
    <WcpTable
      tableClassName="table-simple"
      columns={columns}
      tableSourceData={recipientGroups ? recipientGroups : []}
      emptyText="No recipient groups are currently associated with this organization"
      setListingCount={handleUpdateListingCount}
    />
  );
};

export default ManageRecipientGroupsSummaryTable;
