import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { refreshToken } from 'core/api/login';
import ErrorBoundary from 'app/core/error/components/ErrorBoundary';
import WcpLayout from 'app/core/layout/WcpLayout';
import { loginIsFresh } from 'shared/utilities/localStore';
import LoadingWrapper from 'shared/components/LoadingWrapper';
import { FIELD_SOLUTION_ENGINEER, userRoleOptions as userRoles } from 'shared/constants/users';

const getPermissionSet = userState => {
  return userState?.user?.userRole !== userRoles[FIELD_SOLUTION_ENGINEER].value
    ? userState?.user?.permissions
    : userState?.orgPermissions?.permissions || userState?.user?.permissions;
};

const calculateRedirectPath = ({
  featureDisabled,
  redirectIfSubscriptionExpired,
  daysUntilExpiration,
  requiredPermission,
  permissions,
  restrictedRoles,
  userRole,
  redirectTo,
  requiredRole,
}) => {
  const isLoggedIn = loginIsFresh();
  // if the user is not logged in, redirect to '/login'
  if (!isLoggedIn) return '/login';

  // Navigate if the subscription is expired, or
  // if the user does not have the required permission, or
  // if the user has a restricted role, or
  if (
    featureDisabled ||
    (redirectIfSubscriptionExpired && daysUntilExpiration < 0) ||
    (requiredPermission && !permissions?.includes(requiredPermission)) ||
    restrictedRoles?.includes(userRole)
  ) {
    // if there's a specific route based on user role, redirect to that route
    // otherwise, redirect to '/livemap'
    return redirectTo?.hasOwnProperty(userRole) ? redirectTo : '/livemap';
  }

  // If none of the above conditions are met and the user is logged in with the
  // required role (if any) and permission (if any), no redirect is required.
  if (
    (!requiredRole || requiredRole.includes(userRole)) &&
    (!requiredPermission || permissions?.includes(requiredPermission))
  ) {
    return null;
  }
  // dont redirect by default
  return null;
};

const AuthenticatedRoute = props => {
  const {
    children,
    featureDisabled,
    redirectTo,
    redirectIfSubscriptionExpired,
    requiredPermission,
    requiredRole,
    restrictedRoles,
  } = props;
  const userRole = useSelector(state => state.user.user.userRole);

  const needsMfaSetup = useSelector(state => state.user.needsMfaSetup);
  const userState = useSelector(state => state.user);
  const daysUntilExpiration = useSelector(state => state.ui.daysUntilExpiration);

  const permissions = useMemo(() => getPermissionSet(userState), [userState]);
  const redirectPath = calculateRedirectPath({
    featureDisabled,
    requiredPermission,
    redirectIfSubscriptionExpired,
    daysUntilExpiration,
    permissions,
    restrictedRoles,
    userRole,
    redirectTo,
    requiredRole,
  });

  useEffect(() => {
    refreshToken();
  }, []);

  return !loginIsFresh() ? (
    <Navigate to="/login" />
  ) : (
    <LoadingWrapper isLoading={!userRole}>
      {redirectPath ? (
        <Navigate
          to={{
            pathname: redirectPath,
          }}
        />
      ) : needsMfaSetup ? (
        <Navigate to={'/twofactorselection'} />
      ) : (
        <WcpLayout>
          <ErrorBoundary>{children}</ErrorBoundary>
        </WcpLayout>
      )}
    </LoadingWrapper>
  );
};

export default AuthenticatedRoute;
