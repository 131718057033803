import { alertsMapping } from 'app/features/alerts/services/mapping/definitions/alertsMapping';
import { apiCall } from 'core/api/API';
import { mapToFrontend } from 'app/shared/utilities/mapping/mapper';

export const getAlertByIdApi = async ({ organizationId, alertId }) => {
  const apiResponse = await apiCall({
    method: 'get',
    path: `/alerts/${alertId}`,
    queryStringParameters: {
      organization_id: `${organizationId}`,
    },
  });

  let alert = apiResponse.data.message;
  let mappedAlert = mapToFrontend(alert, alertsMapping, false);
  return mappedAlert;
};
