import { granularityOptions } from 'app/features/playback/data/ui/dropdown/granularityOptions';
import useVehiclePlaybackProviderContext from 'app/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import useMakeFormikField from 'app/shared/components/controls/WcpForm/hooks/useMakeFormikField';
import { useFormikContext } from 'formik';
import { DateRangeValidationDiv, FormLabelAnimated } from 'shared/styles/components/Form';
import StyledReactSelect from 'shared/styles/components/SelectField';
import { PlaybackSpeedDiv } from 'shared/styles/components/VehiclePlayback';

const VehiclePlaybackFormGranularityDropdown = () => {
  const {
    actions: { setPlaybackRequestFormAction },
    state: {
      playbackRequestForm: { selectedGranularity, readOnly },
    },
  } = useVehiclePlaybackProviderContext();
  const formik = useFormikContext();

  const {
    validationResult: { errorMessage },
  } = useMakeFormikField({
    fieldName: 'selectedGranularity',
    fieldValue: selectedGranularity,
    touched: true,
    triggerValidation: true,
  });

  return (
    <PlaybackSpeedDiv>
      <FormLabelAnimated animated>Granularity</FormLabelAnimated>
      <StyledReactSelect
        granularity
        name="selectedGranularity"
        placeholder="Granularity"
        defaultValue={{
          label: granularityOptions.find(g => g.value === formik.values.selectedGranularity).label,
        }}
        onChange={e => {
          setPlaybackRequestFormAction({
            selectedGranularity: granularityOptions.find(g => g.label === e.label).value,
          });
        }}
        isDisabled={readOnly}
        isClearable={false}
        isSearchable={false}
        options={granularityOptions}
      />
      <DateRangeValidationDiv>{errorMessage}</DateRangeValidationDiv>
    </PlaybackSpeedDiv>
  );
};

export default VehiclePlaybackFormGranularityDropdown;
