import { createContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

import { alertsSummaryActionReducerMap } from 'app/features/alerts/services/providers/AlertsSummaryProvider/mapping/alertsSummaryActionReducerMap';
import useFetchAlertsQuery from 'app/features/alerts/services/reactQuery/queries/useFetchAlertsQuery';
import useFetchOrganizationUsersQuery from 'app/features/recipientGroups/hooks/queries/useFetchOrganizationUsersQuery';
import useFetchRecipientGroupsQuery from 'app/features/recipientGroups/hooks/queries/useFetchRecipientGroupsQuery';
import useFetchVehicleGroupsQuery from 'app/features/vehicleGroups/hooks/queries/useFetchVehicleGroupsQuery';
import useFetchVehiclesQuery from 'app/features/vehicles/hooks/queries/useFetchVehiclesQuery';
import IsFetchingWrapper from 'shared/components/IsFetchingWrapper';
import useProviderState from 'shared/hooks/useProviderState';

// create context
export const ManageAlertsContext = createContext(null);

const AlertsSummaryProvider = ({ children }) => {
  // create provider (state and actions)
  const [state, actions] = useProviderState(
    {
      actionReducerMap: alertsSummaryActionReducerMap,
      initialState: {
        alerts: [],
        allRecipientGroups: [],
        vehicles: [],
        vehicleGroups: [],
        organizationUsers: [],
      },
    },
    true, // use v2 style action dispatchers
  );
  // fetch initial data
  const [fetchedAlerts, alertsQueryKey] = useFetchAlertsQuery();
  const [fetchedVehicleGroups, vehicleGroupsQueryKey] = useFetchVehicleGroupsQuery();
  const [fetchedRecipientGroups, recipientGroupsQueryKey] = useFetchRecipientGroupsQuery();
  const [fetchedOrganizationUsers, organizationUsersQueryKey] = useFetchOrganizationUsersQuery();
  const [fetchedVehicles, vehiclesQueryKey] = useFetchVehiclesQuery({
    apiFlags: {
      addons: false,
      cell: false,
      device: false,
      gps: false,
      meta: true,
      maintenance: false,
      obd: false,
    },
  });

  // useEffect(() => {
  //   if (fetchedAlerts) {
  //     //for each recipient in each alert, create a recipientRowGuid
  //     fetchedAlerts.forEach(a => {
  //       if (a.alertRecipients) {1
  //         a.alertRecipients.forEach(alertRecipient => {
  //           alertRecipient.recipientRowGuid = uuidv4();
  //         });
  //       }
  //     });
  //   }
  // }, [fetchedAlerts]);

  useEffect(() => {
    let alert = state.alerts?.find(a => a.alertId === null);
    if (alert) {
      fetchedAlerts = [alert, ...fetchedAlerts];
    }
    // set api data in state if data is returned from api
    actions.setApiDataAction({
      alerts: fetchedAlerts,
      vehicles: fetchedVehicles,
      vehicleGroups: fetchedVehicleGroups,
      organizationUsers: fetchedOrganizationUsers,
    });

    actions.updateAlertsListingCountAction({ listingCount: fetchedAlerts?.length });
  }, [
    fetchedAlerts,
    fetchedVehicles,
    fetchedVehicleGroups,
    fetchedRecipientGroups,
    fetchedOrganizationUsers,
  ]);

  // this shouldbe integrated with the provider query keys
  let queryKeysForFetching = [
    alertsQueryKey,
    vehiclesQueryKey,
    vehicleGroupsQueryKey,
    organizationUsersQueryKey,
  ].filter(Boolean);

  // render provider children wrapped with loading indicator
  return (
    <ManageAlertsContext.Provider
      value={{
        queryKeys: {
          recipientGroupsQueryKey,
          vehiclesQueryKey,
          vehicleGroupsQueryKey,
          organizationUsersQueryKey,
        },
        actions,
        state,
      }}
    >
      <IsFetchingWrapper queryKeys={queryKeysForFetching || []}>{children}</IsFetchingWrapper>
    </ManageAlertsContext.Provider>
  );
};

export default AlertsSummaryProvider;
