import React, { useState } from 'react';

import GeofenceMiniCard from 'features/geofences/components/GeofenceMiniCard';
import {
  CategoryToggleDiv,
  GeofenceCategoryDiv,
  GeofenceCategoryHeaderDiv,
} from 'shared/styles/components/Geofences';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const GeofenceCategorySection = ({ categoryName, matchingGeofences }) => {
  const [categoryIsExpanded, setCategoryIsExpanded] = useState(true);

  return (
    <>
      <GeofenceCategoryHeaderDiv>
        <CategoryToggleDiv
          onClick={() => {
            setCategoryIsExpanded(current => !current);
          }}
        >
          {categoryIsExpanded ? (
            <IconSvgComponent svgFileName={'expanded-toggle'} alt="Collapse " title="Collapse" />
          ) : (
            <IconSvgComponent svgFileName={'collapsed-toggle'} alt="Expand" title="Expand" />
          )}
        </CategoryToggleDiv>
        {categoryName}
      </GeofenceCategoryHeaderDiv>
      <GeofenceCategoryDiv isExpanded={categoryIsExpanded}>
        {matchingGeofences.map(gf => (
          <GeofenceMiniCard
            id={gf.geofenceId}
            key={gf.geofenceId}
            name={gf.label}
            enabled={gf.enabled}
            lastEntered={gf.latest}
            expirationTime={gf.expirationTime}
          ></GeofenceMiniCard>
        ))}
      </GeofenceCategoryDiv>
    </>
  );
};

export default GeofenceCategorySection;
