// removeChildAlertAction →
export const removeChildAlertReducer = (state, payload) => {
  const { notificationIndex } = payload;

  const alert = state.alert;
  alert.alertNotifications?.splice(notificationIndex, 1);
  return {
    ...state,
  };
};
