import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import AnimatedField from 'shared/ui/fields/AnimatedField';

const VehicleYearField = ({
  formikProps: { values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldError },
  prefillValue,
}) => {
  useEffect(() => {
    if (prefillValue) {
      setFieldValue('year', prefillValue);
      setFieldError('year', false);
    }
  }, [prefillValue]);

  return (
    <AnimatedField
      name="year"
      placeholder="Vehicle Year"
      value={values.year}
      touched={touched.year}
      validationError={errors.year}
      handleChange={handleChange}
      handleBlur={handleBlur}
      forceAnimated={!!prefillValue}
      disabled={false}
      required
    />
  );
};

export default VehicleYearField;
