import { ScheduleMaintenanceFiltersDiv } from 'shared/styles/components/Maintenance';
import FilteredDropdown from 'app/shared/components/controls/FilteredDropdown/legacy/FilteredDropdown';
import { getModelOptions } from 'shared/utilities/vehicle';

const ScheduleMaintenanceFilters = ({ groups, vehicles, setScheduleMaintenanceFilters }) => {
  const groupOptions = groups?.map(group => ({ label: group.group_name, value: group.group_name }));
  const modelOptions = getModelOptions(vehicles)?.map(v => ({ label: v.label, value: v.label }));

  return (
    <ScheduleMaintenanceFiltersDiv>
      <FilteredDropdown
        title={'Groups'}
        placeholderLabel={'All Groups'}
        dropdownOptions={groupOptions}
        handleItemSelect={selectedItem => {
          if (selectedItem) {
            setScheduleMaintenanceFilters({
              selectedGroup: groups.find(g => g.group_name === selectedItem.label),
            });
          } else {
            setScheduleMaintenanceFilters({ selectedGroup: null });
          }
        }}
        styleOptions={{}}
        defaultItem={null}
      />
      <FilteredDropdown
        title={'Models'}
        placeholderLabel={'All Models'}
        dropdownOptions={modelOptions}
        handleItemSelect={selectedItem => {
          if (selectedItem) {
            setScheduleMaintenanceFilters({
              selectedModel: vehicles.find(v => v.meta.model === selectedItem.label).meta.model,
            });
          } else {
            setScheduleMaintenanceFilters({ selectedModel: null });
          }
        }}
        styleOptions={{}}
        defaultItem={null}
      />
    </ScheduleMaintenanceFiltersDiv>
  );
};

export default ScheduleMaintenanceFilters;
