import React from 'react';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';

const EditVehicleAliasItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Edit Vehicle Alias</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default EditVehicleAliasItem;
