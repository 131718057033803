import { useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { setCurrentOrganization } from 'core/redux/user/actions';
import OrganizationSearch from 'core/widgets/organizationFilter/OrganizationSearch';
import colors from 'shared/constants/colors';
import { variableScopeAllowedUserRoles } from 'shared/constants/users';
import DropdownMenu, { CurrentSelectionContainer } from 'shared/styles/components/DropdownMenu';
import { ClearFilterIcon } from 'shared/styles/components/Icons';
import { TruncatedText } from 'shared/styles/components/TruncatedText';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { sortAscendingAlpha } from 'shared/utilities/general';
import { getFilteredOrgIdInLocal, setLastMapLocationInLocal } from 'shared/utilities/localStore';

const OrganizationFilter = ({
  organizations,
  userOrganizationId,
  filteredOrganizationId,
  setFilteredOrganizationId,
  isDropdownActive,
  toggleDropdown,
  userRole,
  username,
}) => {
  const navigate = useNavigate();
  const isUserAdmin = variableScopeAllowedUserRoles.includes(userRole);
  if (isUserAdmin) {
    let filteredOrgLocal = getFilteredOrgIdInLocal();

    if (filteredOrganizationId !== filteredOrgLocal) {
      setFilteredOrganizationId({
        organizationId: filteredOrgLocal || userOrganizationId,
        userRole,
        username,
      });
    }
    setLastMapLocationInLocal(null);
  }

  const resetOrganizationFilter = () => {
    setFilteredOrganizationId({ organizationId: userOrganizationId, userRole: userRole, username });
    navigate('/livemap');
  };

  // Focus on search input when opened
  const searchInput = useRef();
  window.searchInput = searchInput;
  useEffect(() => {
    if (isDropdownActive) {
      searchInput.current.focus();
    }
  }, [isDropdownActive]);

  const handleToggleDropdown = isUserAdmin ? toggleDropdown : null;

  // Have organizations loaded: organizations are being fetched by requestLoggedInStatus in store
  const haveOrganizationsLoaded = organizations.length > 0;

  // Check if current filtered organization is different than current user's organization
  const isFiltered = filteredOrganizationId !== userOrganizationId;

  // Grab organization props from the right organization
  const findOrganizationId = isFiltered ? filteredOrganizationId : userOrganizationId;
  const { organizationName } =
    (haveOrganizationsLoaded && organizations.find(a => a.organizationId === findOrganizationId)) ||
    {};

  return (
    <DropdownMenu active={isDropdownActive} $truncateText>
      {haveOrganizationsLoaded ? (
        <CurrentSelectionContainer onClick={handleToggleDropdown}>
          <IconSvgComponent
            svgStyle={styles.organizationsIcon(handleToggleDropdown)}
            svgFileName={'organizations'}
            alt="Organizations"
          />
          <div style={styles.organizationName(handleToggleDropdown)}>
            <TruncatedText title={organizationName}>{organizationName}</TruncatedText>
          </div>
          <>
            {isFiltered && (
              <ClearFilterIcon
                onClick={() => {
                  handleToggleDropdown();
                  resetOrganizationFilter();
                }}
                svgFileName={'red-x'}
                title="Clear organization filter"
                alt="Clear organization filter"
              />
            )}
            {handleToggleDropdown && (
              <IconSvgComponent
                svgFileName={'dropdown-caret'}
                svgStyle={styles.searchToggleButton}
                title="Change organization filter"
                alt="Change organization filter"
              />
            )}
          </>
        </CurrentSelectionContainer>
      ) : (
        <div style={styles.currentOrganizationContainerSkeleton}>
          <div style={styles.iconSkeleton} />
          <div style={styles.organizationNameSkeleton} />
        </div>
      )}

      <OrganizationSearch
        userRole={userRole}
        username={username}
        organizations={organizations.sort((o1, o2) =>
          sortAscendingAlpha(o1.organizationName, o2.organizationName),
        )}
        searchInput={searchInput}
        setFilteredOrganizationId={setFilteredOrganizationId}
        toggleSearchControl={handleToggleDropdown}
      />
    </DropdownMenu>
  );
};

export default connect(
  state => ({
    organizations: state.organizations.organizations,
    userOrganizationId: state.user.user.organizationId,
    username: state.user.user.username,
    userRole: state.user.user.userRole,
    filteredOrganizationId: state.user.filteredOrganizationId,
  }),
  dispatch => ({
    setFilteredOrganizationId: ({ organizationId, userRole, username }) =>
      dispatch(setCurrentOrganization({ organizationId, userRole, username })),
  }),
)(OrganizationFilter);

const styles = {
  currentOrganizationContainerSkeleton: {
    display: 'flex',
    alignItems: 'center',
    marginRight: '15px',
  },
  iconSkeleton: {
    width: '32px',
    height: '32px',
    marginRight: '6px',
    borderRadius: '100%',
    backgroundColor: '#EAEAEA',
    animation: 'loadingSkeleton 1.2s infinite',
  },
  organizationNameSkeleton: {
    width: '125px',
    height: '20px',
    backgroundColor: '#EAEAEA',
    animation: 'loadingSkeleton 1.2s infinite',
  },
  organizationsIcon: isAdmin => ({
    width: '22px',
    height: '22px',
    ...(isAdmin ? { cursor: 'pointer' } : {}),
  }),
  organizationName: isAdmin => ({
    marginLeft: '6px',
    marginRight: '6px',
    minWidth: '0',
    ...(isAdmin ? { cursor: 'pointer' } : {}),
  }),
  searchToggleButton: {
    cursor: 'pointer',
  },
  searchIcon: {
    height: '22px',
    width: '22px',
    marginRight: '10px',
  },
  filterDropdownBody: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '5px 15px 5px 15px',
    width: '300px',
    height: '70px',
  },
  searchContainer: {
    height: '100%',
    width: '100%',
  },
  searchInput: {
    border: 'none',
    height: '100%',
    width: '100%',
    color: `${colors.midnight}`,
    fontSize: '1.1rem',
  },
  searchResultItem: (index, highlightedIndex) => ({
    listStyleType: 'none',
    color: '#8a909c',
    fontSize: '1.1rem',
    backgroundColor: 'white',
    fontWeight: 'normal',
    borderTop: index === 0 ? '1px solid #EEEFF0' : 'none',
    borderBottom: '1px solid #EEEFF0',
    padding: '18px 10px 18px 20px',
    backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
  }),
};
