import { SORT_ORDER } from 'shared/constants/table';
import WcpTable from 'app/shared/components/controls/WcpTable/WcpTable';
import { TruncatedTextWithWrap } from 'shared/styles/components/TruncatedText';

const MaintenanceLogsTable = ({ records, handleSetListingCount }) => {
  const columns = [
    {
      title: 'Completed Date',
      displayProperty: 'formattedTime',
      sortProperty: 'completed_date',
      width: '15%',
      render({ rowData, cellJsx }) {
        return <span title={'Timestamp: ' + rowData.formattedTime}>{cellJsx}</span>;
      },
    },
    {
      title: 'Odometer',
      displayProperty: 'completed_odometer',
    },
    {
      title: 'Engine Hours',
      displayProperty: 'completed_engine_hours',
    },
    {
      title: 'Interval',
      displayProperty: 'interval_unit',
    },
    {
      title: 'Type',
      displayProperty: 'maintenanceTypeDisplay',
    },
    {
      title: 'Notes',
      displayProperty: 'notesDisplay',
      width: '35%',
      render({ rowData, cellJsx }) {
        return (
          <span title={'Notes: ' + rowData.notesDisplay}>
            <TruncatedTextWithWrap>{cellJsx}</TruncatedTextWithWrap>
          </span>
        );
      },
    },
    {
      title: 'Marked Completed By',
      displayProperty: 'markedCompletedByDisplay',
      width: '15%',
      render({ rowData, cellJsx }) {
        return (
          <span title={'Marked Completed By: ' + rowData.markedCompletedByDisplay}>
            <TruncatedTextWithWrap>{cellJsx}</TruncatedTextWithWrap>
          </span>
        );
      },
    },
  ];

  return (
    <WcpTable
      tableClassName="table-simple"
      rowClassName={(record, i) => `row-${i}`}
      columns={columns}
      tableSourceData={records || []}
      rowKey={(record, i) => i}
      emptyText="No maintenance logs are currently associated with this vehicle"
      defaultSortOrder={SORT_ORDER.DESC}
      setListingCount={handleSetListingCount}
    />
  );
};

export default MaintenanceLogsTable;
