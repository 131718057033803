/*
  This reducer is used to set the data from the API response to the state.
*/
export const setPlaybackApiDataReducer = (state, payload) => {
  const { allVehicles, associatedVehicleId, summary, locationData } = payload;

  // find vehicle
  const selectedVehicle =
    state.allVehicles?.find(v => v.vehicle_id === associatedVehicleId) ||
    state.playbackData.selectedVehicle;

  // merge into state if defined otehrwise keep the current state
  return {
    ...state,
    allVehicles: allVehicles || state.allVehicles,
    playbackData: {
      selectedVehicle,
      summary: summary || state.playbackData.summary,
      locationData: locationData || state.playbackData.locationData,
    },
  };
};
