/*
  This reducer is used to open or close the playback sidebar
*/
export const setPlaybackSidebarReducer = (state, payload) => {
  const { isOpen } = payload;

  // return the updated state
  return {
    ...state,
    playbackSidebar: {
      isOpen,
    },
  };
};
