import React from 'react';
import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import { CheckboxWrapperDiv } from 'shared/styles/components/VehicleEdit';
import { CheckboxTextSpan } from 'shared/styles/components/Form';
import useMakeFormikField from 'app/shared/components/controls/WcpForm/hooks/useMakeFormikField';
import useAlertFormsProviderContext from 'app/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';

const IncludeLocationCheckbox = ({ notificationIndex }) => {
  const {
    actions: { updateAlertNotificationDetailsAction },
    state: {
      alert: { alertNotifications },
    },
  } = useAlertFormsProviderContext();

  const { isLocationIncluded } = alertNotifications[notificationIndex];

  useMakeFormikField({ fieldName: 'isLocationIncluded', fieldValue: isLocationIncluded });

  return (
    <CheckboxWrapperDiv>
      <Checkbox singleCheck>
        <CheckboxInner
          isChecked={isLocationIncluded}
          onClick={() => {
            updateAlertNotificationDetailsAction({
              notificationIndex,
              isLocationIncluded: !isLocationIncluded,
            });
          }}
        />
      </Checkbox>
      <CheckboxTextSpan>Include Vehicle Location</CheckboxTextSpan>
    </CheckboxWrapperDiv>
  );
};

export default IncludeLocationCheckbox;
