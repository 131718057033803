import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import { TableCheckboxFilterWrapper } from 'shared/styles/components/PageList';
import { TableFilterSpan } from 'shared/styles/components/Table';

const ScheduleMaintenanceSelectAllCheckbox = ({
  selectedVehicles,
  selectableVehicles,
  setSelectedVehicles,
}) => {
  const isEveryFilteredVehicleSelected = () =>
    selectableVehicles.length > 0 &&
    selectableVehicles.every((fv) =>
      selectedVehicles.map((v) => v.vehicleId)?.includes(fv.vehicleId),
    );
  return (
    <TableCheckboxFilterWrapper>
      <Checkbox singleCheck>
        <CheckboxInner
          // true if all filtered vehicles are selected
          isChecked={isEveryFilteredVehicleSelected()}
          // if isChecked, deselect all vehicles that are filtered
          // else select all vehicles that are filtered
          onClick={() =>
            isEveryFilteredVehicleSelected()
              ? //select all
                setSelectedVehicles({
                  selectedVehicles: [
                    ...selectedVehicles.filter(
                      (sv) => !selectableVehicles.map((fv) => fv.vehicleId).includes(sv.vehicleId),
                    ),
                  ],
                })
              : //deselect all
                setSelectedVehicles({
                  selectedVehicles: [
                    ...selectedVehicles,
                    ...selectableVehicles.filter(
                      (fv) => !selectedVehicles.map((sv) => sv.vehicleId)?.includes(fv.vehicleId),
                    ),
                  ],
                })
          }
        />
      </Checkbox>
      <TableFilterSpan>Select All</TableFilterSpan>
    </TableCheckboxFilterWrapper>
  );
};

export default ScheduleMaintenanceSelectAllCheckbox;
