import React, { useEffect, useRef, useState } from 'react';

const useFilteredDropdown = ({
  defaultItem,
  dropdownOptions,
  handleItemSelect,
  clearOnSelection,
}) => {
  // store the previously selected item so we can compare it to the current selected item
  const previouslySelectedItem = useRef(defaultItem !== null);

  // the currently selected item
  const [selectedItem, setSelectedItem] = useState(defaultItem);

  // the dropdown options
  const [options, setOptions] = useState(dropdownOptions);

  // the dropdown is open or closed
  const [isOpen, setIsOpen] = useState(false);

  // the search value in the search input
  const [searchText, setSearchText] = useState('');

  // the currently hovered item in the dropdown
  const [hoveredItem, setHoveredItem] = useState(null);

  // reset the search input when the dropdown is closed
  useEffect(() => {
    setSearchText('');
  }, [isOpen]);

  useEffect(() => {
    setOptions(dropdownOptions);
  }, [dropdownOptions]);

  useEffect(() => {
    if (defaultItem !== previouslySelectedItem.current) {
      setSelectedItem(defaultItem);
    }
  }, [defaultItem]);

  useEffect(() => {
    // call handleItemSelect handler if the selected item has changed
    if (selectedItem !== previouslySelectedItem.current) {
      handleItemSelect(selectedItem);
      setIsOpen(false);
    }
    //TODO: do this when the clear X
    if (clearOnSelection && selectedItem) {
      setSelectedItem(defaultItem);
      setHoveredItem(null);
      previouslySelectedItem.current = null;
    }
    //TODO: add property to clear hovered select when the item has been removed form the options options on selection
  }, [selectedItem]);

  const openStyle = isOpen
    ? {
        position: 'relative',
        display: 'block',
        zIndex: 1,
      }
    : {};

  return {
    selectedItem,
    setSelectedItem,
    options,
    isOpen,
    setIsOpen,
    searchText,
    setSearchText,
    hoveredItem,
    setHoveredItem,
    openStyle,
  };
};

export default useFilteredDropdown;
