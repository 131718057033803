import MaintenanceLogCard from 'app/features/maintenance/components/MaintenanceLogs/mobile/MaintenanceLogCard';
import { Cards } from 'shared/styles/components/MobileCard';

const MaintenanceLogsCardContainer = ({ records }) => {
  return (
    <div>
      <Cards>
        {records.map(fld => (
          <MaintenanceLogCard log={fld} />
        ))}
      </Cards>
    </div>
  );
};

export default MaintenanceLogsCardContainer;
