import {
  ActionMenuBtnWrapper,
  CardCollapsedInfo,
  CardContainer,
  CardIconWrapper,
  CardLabel,
  CardName,
  CardOuterWrapper,
  CardRow,
  CardType,
  CardValue,
} from 'shared/styles/components/MobileCard';
import { getConfigTypeLabel } from 'app/features/configurations/utilities/configUtilities';
import { formatISOTimestampToLongDateWithTime } from 'shared/utilities/time';

const ConfigurationLibraryCard = ({ configuration, actionMenuComponent }) => {
  return (
    <CardOuterWrapper>
      <CardContainer configLibrary>
        <ActionMenuBtnWrapper>{actionMenuComponent(configuration)}</ActionMenuBtnWrapper>
        <CardIconWrapper>
          <CardCollapsedInfo>
            <CardRow header>
              <CardName></CardName>
            </CardRow>
            <CardRow header>
              <CardType></CardType>
            </CardRow>
            <CardRow></CardRow>
          </CardCollapsedInfo>
        </CardIconWrapper>

        <CardRow itemNamePotentialLongValue title={configuration.configName}>
          <CardLabel>Configuration Name:</CardLabel>
          <CardValue>{configuration.configName}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Configuration Type:</CardLabel>
          <CardValue>{getConfigTypeLabel(configuration.acmProductId)}</CardValue>
        </CardRow>
        <CardRow potentialLongValue title={configuration.configId}>
          <CardLabel>Configuration ID:</CardLabel>
          <CardValue>{configuration.configId}</CardValue>
        </CardRow>
        <CardRow potentialLongValue title={configuration.uploadedTime}>
          <CardLabel>Uploaded on:</CardLabel>
          <CardValue>
            {configuration.uploadedTime !== 'UNKNOWN'
              ? formatISOTimestampToLongDateWithTime(configuration.uploadedTime)
              : configuration.uploadedTime}
          </CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Supported Devices:</CardLabel>
          <CardValue>{configuration.deviceCount}</CardValue>
        </CardRow>
      </CardContainer>
    </CardOuterWrapper>
  );
};
export default ConfigurationLibraryCard;
