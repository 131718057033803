import React from 'react';
import { useContext } from 'react';
import { useEffect } from 'react';

import { LivemapApi } from 'features/livemap/components/LivemapContextProvider';
import { getVehicleGpsCoordinates } from 'shared/utilities/map/MapAnimationUtils';

// This is a custom hook that is used to follow a vehicle on the map or fly to a set of coordinates
const useFlyToCoordinates = ({ mapboxApi, flyToCoordinates, focusedZoomLevel }) => {
  let zoomLevel = focusedZoomLevel > 15 ? focusedZoomLevel : 15;
  // fly to coordinates
  useEffect(() => {
    if (mapboxApi && flyToCoordinates.length !== 0) {
      mapboxApi.flyTo({
        center: flyToCoordinates,
        zoom: zoomLevel,
        // duration: 12000,
        animationType: 'flyTo',
      });
    }
  }, [flyToCoordinates]);
};

export default useFlyToCoordinates;
