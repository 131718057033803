import { determineStatusDisplay } from 'app/features/maintenance/utilities/formatting';
import { displayVehicleIcon } from 'features/fleet/vehicles/helpers/utilities';
import {
  ActionMenuBtnWrapper,
  CardCollapsedInfo,
  CardContainer,
  CardIconWrapper,
  CardLabel,
  CardName,
  CardOuterWrapper,
  CardRow,
  CardType,
  CardValue,
  VehicleIcon,
} from 'shared/styles/components/MobileCard';
import { getUserNameDisplayFromUserList } from 'app/shared/utilities/getUserNameDisplayFromUserList';

const RecipientGroupSummaryCard = ({ recipientGroup, actionMenuComponent }) => {
  return (
    <CardOuterWrapper>
      <CardContainer recipientGroup>
        <ActionMenuBtnWrapper>{actionMenuComponent(recipientGroup)}</ActionMenuBtnWrapper>
        <CardIconWrapper>
          <CardCollapsedInfo>
            <CardRow header>
              <CardName potentialLongValue title={recipientGroup.recipientGroupName}>
                {recipientGroup.recipientGroupName}
              </CardName>
            </CardRow>
            <CardRow potentialLongValue>
              <CardLabel>Members: </CardLabel>
              <CardType
                title={getUserNameDisplayFromUserList(recipientGroup.recipientGroupMembers)}
              >
                {getUserNameDisplayFromUserList(recipientGroup.recipientGroupMembers)}
              </CardType>
            </CardRow>
          </CardCollapsedInfo>
        </CardIconWrapper>
      </CardContainer>
    </CardOuterWrapper>
  );
};

export default RecipientGroupSummaryCard;
