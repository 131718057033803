import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { datadogConfig, datadogLogsConfig } from 'tools/datadog/datadogConfig';

export const initializeWcpLogger = () => {
  console.log('***Initializing Datadog***', datadogRum);

  if (!datadogRum.getInitConfiguration()) {
    datadogRum.init(datadogConfig);
    datadogLogs.init(datadogLogsConfig);
  }
};
