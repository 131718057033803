import { SimpleDropdownBtn, SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';

const DeleteAlertMenuItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Delete Alert</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default DeleteAlertMenuItem;
