import { setPlaybackSidebarReducer } from 'app/features/playback/state/provider/reducers/setPlaybackSidebarReducer';
import { setPlaybackAnimationControlsReducer } from 'app/features/playback/state/provider/reducers/setPlaybackAnimationControlsReducer';
import { setPlaybackRequestFormReducer } from 'app/features/playback/state/provider/reducers/setPlaybackRequestFormReducer';
import { setPlaybackApiDataReducer } from 'app/features/playback/state/provider/reducers/setPlaybackApiDataReducer';

// define actions
export const SET_PLAYBACK_SIDEBAR_IS_OPEN = 'setPlaybackSidebarAction';
export const SET_PLAYBACK_API_DATA = 'setPlaybackApiDataAction';
export const SET_PLAYBACK_REQUEST_FORM = 'setPlaybackRequestFormAction';
export const SET_PLAYBACK_CONTROLS = 'setPlaybackAnimationControlsAction';

// map actions to reducers
export const vehiclePlaybackActionReducerMap = {
  // setPlaybackSidebarAction
  [SET_PLAYBACK_SIDEBAR_IS_OPEN]: setPlaybackSidebarReducer,
  // setPlaybackApiDataAction
  [SET_PLAYBACK_API_DATA]: setPlaybackApiDataReducer,
  // setPlaybackRequestFormAction
  [SET_PLAYBACK_REQUEST_FORM]: setPlaybackRequestFormReducer,
  // setPlaybackAnimationControlsAction
  [SET_PLAYBACK_CONTROLS]: setPlaybackAnimationControlsReducer,
};
