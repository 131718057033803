import React from 'react';
import {
  AnalyticsInfographics,
  LargeResult,
  LargeResultLabel,
  SmallLabel,
  BarResults,
  BarResultsWrapper,
  Bar,
  ExportWrapper,
  VehicleCount,
  NoDataDiv,
} from 'shared/styles/components/Analytics';
import { ActionButtonWithIcon } from 'shared/styles/components/Button';
import { createExportDownload } from 'shared/utilities/csvExport';

const AnalyticsDashboardResultsSummary = ({ dashboardQueryResults, startAndEndDate }) => {
  const prepareDataForExport = () => {
    let resultsForExport = dashboardQueryResults.vehicles.map(v => ({
      Vehicle_Name: v.vehicle_name,
      Total_Hours: v.hours_total,
      Hours_Offline: v.hours_offline,
      Hours_Responding: v.hours_responding,
      Hours_Online: v.hours_online,
      Responding_Events: v.responding_count,
      Top_Responding_Speed: v.top_responding_speed,
      Top_Not_Responding_Speed: v.top_not_responding_speed,
    }));

    let csvFileName = `wcp_analytics_dashboard_export ${startAndEndDate}`;
    createExportDownload(resultsForExport, csvFileName);
  };

  const onlinePercentage =
    dashboardQueryResults && dashboardQueryResults.summary.hours_total != 0
      ? Math.round(
          (dashboardQueryResults.summary.hours_online / dashboardQueryResults.summary.hours_total) *
            1000,
        ) / 10
      : 0;

  const offlinePercentage =
    dashboardQueryResults && dashboardQueryResults.summary.hours_total != 0
      ? Math.round(
          (dashboardQueryResults.summary.hours_offline /
            dashboardQueryResults.summary.hours_total) *
            1000,
        ) / 10
      : 0;

  const respondingPercentage =
    dashboardQueryResults && dashboardQueryResults.summary.hours_total != 0
      ? Math.round(
          (dashboardQueryResults.summary.hours_responding /
            dashboardQueryResults.summary.hours_total) *
            1000,
        ) / 10
      : 0;

  return (
    <>
      {dashboardQueryResults.summary.hours_total == 0 && (
        <NoDataDiv analytics>
          There is no data to report. Please consider a broader date range.
        </NoDataDiv>
      )}
      <AnalyticsInfographics>
        <div>
          <LargeResult online>{onlinePercentage}%</LargeResult>
          <LargeResultLabel>Online</LargeResultLabel>
          <SmallLabel>Out of {dashboardQueryResults.summary.hours_total} total hours</SmallLabel>
        </div>
        <div>
          <LargeResult responding>{respondingPercentage}%</LargeResult>
          <LargeResultLabel>Responding</LargeResultLabel>
          <SmallLabel>Out of {dashboardQueryResults.summary.hours_total} total hours</SmallLabel>
        </div>
        <div>
          <LargeResult charcoal>
            {dashboardQueryResults.summary.average_responding_speed
              ? dashboardQueryResults.summary.average_responding_speed
              : 'N/A'}{' '}
            mph
          </LargeResult>
          <LargeResultLabel>Avg Responding Speed</LargeResultLabel>
          <SmallLabel>Determined by OBD or GPS</SmallLabel>
        </div>
        <ExportWrapper>
          <VehicleCount>{dashboardQueryResults.vehicles.length} Total Vehicles</VehicleCount>
          <ActionButtonWithIcon onClick={prepareDataForExport} export>
            Export to .csv
          </ActionButtonWithIcon>
        </ExportWrapper>
        <BarResultsWrapper>
          <BarResults>
            <LargeResult responding bars>
              {dashboardQueryResults.summary.hours_responding}
            </LargeResult>
            <div>
              <Bar barWidth={respondingPercentage} responding></Bar>
              <SmallLabel>Hours Responding</SmallLabel>
            </div>
          </BarResults>
          <BarResults>
            <LargeResult online bars>
              {dashboardQueryResults.summary.hours_online}
            </LargeResult>
            <div>
              <Bar barWidth={onlinePercentage} online></Bar>
              <SmallLabel>Hours Online</SmallLabel>
            </div>
          </BarResults>
          <BarResults>
            <LargeResult offline bars>
              {dashboardQueryResults.summary.hours_offline}
            </LargeResult>
            <div>
              <Bar barWidth={offlinePercentage} offline></Bar>
              <SmallLabel>Hours Offline</SmallLabel>
            </div>
          </BarResults>
          <BarResults>
            <LargeResult charcoal bars>
              {dashboardQueryResults.summary.hours_total}
            </LargeResult>
            <div>
              <Bar barWidth={100} totalHours></Bar>
              <SmallLabel>Total Hours</SmallLabel>
            </div>
          </BarResults>
        </BarResultsWrapper>
      </AnalyticsInfographics>
    </>
  );
};

export default AnalyticsDashboardResultsSummary;
