import React, { useEffect, useRef, useState } from 'react';

const newUseFilteredDropdown = ({
  defaultOption,
  dropdownOptions,
  handleItemSelect,
  clearOnSelection,
}) => {
  // store the previously selected item so we can compare it to the current selected item
  const previouslySelectedOptionRef = useRef(null);

  // the currently selected item
  const [selectedOption, setSelectedOption] = useState(defaultOption);

  // the dropdown options
  const [options, setOptions] = useState(dropdownOptions);

  // the dropdown is open or closed
  const [isOpen, setIsOpen] = useState(false);

  // the search value in the search input
  const [searchText, setSearchText] = useState('');

  // the currently hovered item in the dropdown
  const [hoveredItem, setHoveredItem] = useState(null);

  useEffect(() => {
    setOptions(dropdownOptions);
  }, [dropdownOptions]);

  // reset the search input when the dropdown is closed
  useEffect(() => {
    setSearchText('');
  }, [isOpen]);

  useEffect(() => {
    if (
      defaultOption?.value !== previouslySelectedOptionRef.current?.value &&
      previouslySelectedOptionRef.current !== null
    ) {
      previouslySelectedOptionRef.current = defaultOption?.value;
    }
  }, [defaultOption?.value]);

  useEffect(() => {
    // call handleItemSelect handler if the selected item has changed
    if (
      selectedOption?.value !== previouslySelectedOptionRef.current?.value &&
      selectedOption?.value !== defaultOption?.value
    ) {
      handleItemSelect(selectedOption);
      setIsOpen(false);
    }
    //TODO: do this when the clear X
    if (clearOnSelection && selectedOption) {
      setSelectedOption(defaultOption);
      setHoveredItem(null);
      previouslySelectedOptionRef.current = null;
    }
    //TODO: add property to clear hovered select when the item has been removed form the options options on selection
  }, [selectedOption?.value]);

  const openStyle = isOpen
    ? {
        position: 'relative',
        display: 'block',
        zIndex: 1,
      }
    : {};

  return {
    selectedOption,
    setSelectedOption,
    options,
    isOpen,
    setIsOpen,
    searchText,
    setSearchText,
    hoveredItem,
    setHoveredItem,
    openStyle,
  };
};

export default newUseFilteredDropdown;
