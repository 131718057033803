import React, { createContext, useEffect, useMemo } from 'react';

import { granularityOptions } from 'app/features/playback/data/ui/dropdown/granularityOptions';
import useLoadVehiclePlaybackStateWithApiData from 'app/features/playback/state/provider/hooks/useLoadVehiclePlaybackStateWithApiData';
import { vehiclePlaybackProviderInitialSetup } from 'app/features/playback/state/provider/setup/vehiclePlaybackProviderInitialSetup';
import IsFetchingWrapper from 'shared/components/IsFetchingWrapper';
import useProviderState from 'shared/hooks/useProviderState';
import { getYesterday } from 'shared/utilities/time';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
export const VehiclePlaybackContext = createContext(null);

// Provider for vehicle playback feature
const VehiclePlaybackProvider = ({ routeParams, children }) => {
  // initialize provider state and actions
  const [state, actions] = useProviderState(vehiclePlaybackProviderInitialSetup, true);

  // destructure actions
  const {
    setPlaybackApiDataAction,
    setPlaybackAnimationControlsAction,
    setPlaybackRequestFormAction,
  } = actions;

  // destructure state
  const {
    playbackData: { locationData, summary },
    playbackRequestForm: { selectedVehicleId, startDate, endDate, selectedGranularity },
  } = state;

  // set form data whenever component mounts and/or route params change
  useEffect(() => {
    // get default granularity
    const defaultGranularity = granularityOptions[0].value;

    // if route params are passed in, set form data with route params and make api call
    if (routeParams) {
      // destructure route params
      const { vehicleId, startDate, endDate } = routeParams;

      // set form data based on route params
      setPlaybackRequestFormAction({
        selectedVehicleId: vehicleId,
        startDate,
        endDate,
        selectedGranularity: defaultGranularity, // default granularity to normal
        readOnly: true, // set form to read only
      });
    }
    // if no route params are passed in, set form data to existing playbackRequestForm from state or default values
    else {
      // get yesterday's date
      let yesterday = getYesterday();

      const params = {
        selectedVehicleId: selectedVehicleId || null,
        startDate: startDate || yesterday,
        endDate: endDate || yesterday,
        selectedGranularity: selectedGranularity || defaultGranularity,
        readOnly: false,
      };

      setPlaybackRequestFormAction(params);
    }
  }, [routeParams]);

  // get data from api

  const {
    fetchPlaybackDataResult,
    fetchVehiclesResult,
    refetchPlaybackData,
    queryKeys: { fetchVehiclesQueryKey, fetchPlaybackDataQueryKey },
  } = useLoadVehiclePlaybackStateWithApiData({
    playbackRequestForm: state.playbackRequestForm,
    setPlaybackApiDataAction,
  });

  // update playback data in state
  useEffect(() => {
    setPlaybackApiDataAction({
      locationData,
      summary,
    });

    // reset playback params
    setPlaybackAnimationControlsAction({
      currentPlaybackDataIndex: 0,
      selectedPlaybackDataIndex: null,
    });
  }, [locationData, summary]);

  // set up provider value with necessary data
  const keysForWrapper = [fetchVehiclesQueryKey].filter(Boolean);

  const readyToRender = fetchVehiclesResult.isSuccess;

  return (
    <VehiclePlaybackContext.Provider
      value={{
        routeParams,
        queryKeys: { fetchVehiclesQueryKey, fetchPlaybackDataQueryKey },
        refetches: { refetchPlaybackData },
        actions,
        state,
      }}
    >
      {readyToRender ? (
        <IsFetchingWrapper queryKeys={keysForWrapper}>{children}</IsFetchingWrapper>
      ) : (
        <LoadingOverlay />
      )}
    </VehiclePlaybackContext.Provider>
  );
};

export default VehiclePlaybackProvider;
