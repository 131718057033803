import React from 'react';
import {
  CardLabel,
  CardValue,
  CardRow,
  CardName,
  CardContainer,
  CardOuterWrapper,
} from 'shared/styles/components/MobileCard';

export default function OtaLogCard({ log, config }) {
  return (
    <CardOuterWrapper>
      <CardContainer
        otaLogs
        config={config}
        otaLogsNoAuthorizer={log.authorizer === 'N/A'}
        otaLogsConfigNoAuthorizer={config && log.authorizer === 'N/A'}
      >
        <CardRow>
          <CardName>{log.vehicle_name}</CardName>
        </CardRow>
        <CardRow potentialLongValue title={log.formattedTime}>
          <CardLabel>Timestamp:</CardLabel>
          <CardValue>{log.formattedTime}</CardValue>
        </CardRow>

        {config ? (
          <CardRow potentialLongValue title={log.config_name}>
            <CardLabel>Config Name:</CardLabel>
            <CardValue>{log.config_name}</CardValue>
          </CardRow>
        ) : (
          <>
            <CardRow potentialLongValue title={log.product_name}>
              <CardLabel>Device Name:</CardLabel>
              <CardValue>{log.product_name}</CardValue>
            </CardRow>
            <CardRow potentialLongValue title={log.device_id}>
              <CardLabel>Device ID:</CardLabel>
              <CardValue>{log.device_id}</CardValue>
            </CardRow>
          </>
        )}
        {!config && (
          <CardRow>
            <CardLabel>Previous Version:</CardLabel>
            <CardValue>{log.version_starting ? log.version_starting : 'N/A'}</CardValue>
          </CardRow>
        )}
        {!config && (
          <CardRow>
            <CardLabel>Version:</CardLabel>
            <CardValue>{log.version}</CardValue>
          </CardRow>
        )}
        <CardRow>
          <CardLabel>Action:</CardLabel>
          <CardValue>{log.statusDisplayName}</CardValue>
        </CardRow>
        {log.authorizer !== 'N/A' && (
          <CardRow>
            <CardLabel>Authorizer:</CardLabel>
            <CardValue>{log.authorizerDisplay}</CardValue>
          </CardRow>
        )}
      </CardContainer>
    </CardOuterWrapper>
  );
}
