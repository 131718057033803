import { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { getVehicles } from 'core/api/vehicles';
import { checkLogin } from 'shared/utilities/user';
import { decorateVehicle, sortAscendingAlphaLabel } from 'shared/utilities/vehicle';
import { FIRMWARE_REFETCH_INTERVAL } from 'shared/constants/firmwares';
import { vehicleFetchError } from 'features/fleet/vehicles/helpers/notifications';

export const useFetchVehiclesQuery = ({
  key = '',
  autoRun = true,
  enableRefetchInterval = false,
  cachingEnabled = true,
  apiFlags = null,
} = {}) => {
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const dispatch = useDispatch();

  const vehicleQuery = useQuery({
    queryKey: ['fetchVehiclesQuery' + key, filteredOrganizationId],
    queryFn: () => getVehicles({ organizationId: filteredOrganizationId, apiFlags }),
    enabled: autoRun && checkLogin(),

    onError: (err) => dispatch(vehicleFetchError({ err })),
    refetchInterval: enableRefetchInterval === false ? false : FIRMWARE_REFETCH_INTERVAL,
    cacheTime: cachingEnabled ? 5 * 60 * 1000 : 0,
    select: (response) => {
      try {
        let vehicles = response?.body?.message.sort(sortAscendingAlphaLabel).map(decorateVehicle);

        // TEMP MAP FIX
        vehicles = vehicles.map((vehicle) => {
          return {
            ...vehicle,
            vehicleId: vehicle.vehicle_id,
          };
        });

        return vehicles;
      } catch (err) {
        console.log('error', err);
      }
    },
  });

  return [vehicleQuery, vehicleQuery.data];
};
