import styled from 'styled-components';

export const WCPLogo = styled.img`
  width: 250px;
  display: block;
  margin-left: auto;
  margin-right: auto;
`;

export const UnsubscribeWrapperDiv = styled.div`
  margin: 50px auto;
  width: 400px;
`;

export const UnsubscribeText = styled.div`
  margin-top: 50px;
  text-align: center;
`;
