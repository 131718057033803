/*
 * External dependencies
 */
import { handleActions } from 'redux-actions';

/*
 * Actions
 */
import { receiveWhitelists, receiveProductsAndChannels } from './actions';

const defaultState = {
  whitelists: [],
  productsWithChannels: [],
};

const whitelists = handleActions(
  {
    [receiveWhitelists]: (state, action) => {
      let whitelists = action.payload.map(w => {
        if (w.channels.indexOf('PRODUCTION')) {
          w.channels.unshift('PRODUCTION');
        }
        return w;
      });
      return {
        ...state,
        whitelists,
      };
    },
    [receiveProductsAndChannels]: (state, action) => ({
      ...state,
      productsWithChannels: action.payload,
    }),
  },
  defaultState,
);

export default whitelists;
