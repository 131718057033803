import { SimpleDropdownBtn, SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';

const DeleteRecipientGroupMenuItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Delete Recipient Group</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default DeleteRecipientGroupMenuItem;
