import React from 'react';
import { connect } from 'react-redux';

import { selectUserRole } from 'core/redux/user/selectors';

const ProtectedElement = ({ allowedRoles, userRole, children, unprivilegedElements, ...props }) => (
  <>
    {allowedRoles.includes(userRole)
      ? { ...children }
      : unprivilegedElements
      ? { ...unprivilegedElements }
      : null}
  </>
);

export default connect(state => ({
  userRole: selectUserRole(state.user.user),
}))(ProtectedElement);
