/**
 * @ External Dependencies
 */
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

/**
 * @ Styled Components
 */
import {
  FormLabelAnimated,
  FormFieldAnimated,
  FormAsterisk,
  FieldError,
} from 'shared/styles/components/Form';

import colors from 'shared/constants/colors';

/**
 * Class for animated textarea
 *
 * @class AnimatedTextarea (name)
 */
export default ({
  id,
  name,
  initialValue,
  placeholder,
  required,
  value,
  touched,
  validationError,
  handleBlur: propHandleBlur,
  handleChange: propHandleChange,
  handleKeyUp: propHandleKeyUp,
}) => {
  const [focused, setFocused] = useState(value ? true : false);

  const handleChange = useCallback(e => {
    if (typeof propHandleChange === 'function') {
      propHandleChange(e);
    }

    handleFieldBlur(e);
  }, []);
  const handleFieldBlur = useCallback(e => {
    typeof propHandleBlur === 'function' && propHandleBlur(e);
    if (e.target.value === '') {
      setFocused(false);
    } else {
      setFocused(true);
    }
  }, []);
  const handleFieldFocus = useCallback(e => {
    setFocused(true);
  }, []);
  const handleKeyUp = useCallback(e => {
    if (focused) {
      // eslint-disable-next-line
      propHandleKeyUp ? propHandleKeyUp.apply(this, [e]) : null;
    }
  });

  return (
    <div>
      <FormLabelAnimated
        animated={focused}
        offset={3}
        htmlFor={id}
        style={{
          paddingLeft: '10px',
          paddingTop: '14px',
        }}
      >
        {placeholder}
        {required ? <FormAsterisk>*</FormAsterisk> : null}
      </FormLabelAnimated>

      <Textarea
        name={name}
        onBlur={handleFieldBlur}
        onFocus={handleFieldFocus}
        onChange={handleChange}
        onKeyUp={handleKeyUp}
        value={value}
      />
      <FieldError>{touched && validationError}</FieldError>
    </div>
  );
};

const FieldContainer = styled.div`
  position: relative;
`;

const Textarea = styled.textarea`
  width: 100%;
  height: 82px;
  padding-top: 20px;
  padding-left: 10px;
  border-color: ${colors.concrete};
`;
