import { useEffect } from 'react';

import ScheduleMaintenanceVehicleCard from 'app/features/maintenance/components/MaintenanceScheduler/mobile/ScheduleMaintenanceVehicleCard';
import { Cards } from 'shared/styles/components/MobileCard';
import { SectionCount, SectionToggle } from 'shared/styles/components/Section';
import CollapsibleHeader from 'shared/ui/accordions/CollapsibleHeader';

const ScheduleMaintenanceVehicleCardContainer = ({
  vehicles,
  selectedVehicles,
  setSelectedVehicles,
  selectableVehicles,
  handleUpdateListingCount,
}) => {
  const getIsVehicleSelected = (vehicle) => {
    return selectedVehicles?.map((sv) => sv.vehicleId)?.includes(vehicle.vehicleId);
  };
  useEffect(() => {
    handleUpdateListingCount();
  }, []);

  return (
    <CollapsibleHeader
      cards
      initialCollapsedState={false}
      ToggleComponent={({ collapsed, setCollapsed }) => (
        <SectionCount
          onClick={() => {
            setCollapsed(!collapsed);
          }}
        >
          <SectionToggle collapsed={collapsed} />
        </SectionCount>
      )}
    >
      <Cards>
        {vehicles?.map((vehicle) => (
          <ScheduleMaintenanceVehicleCard
            vehicle={vehicle}
            isSelected={getIsVehicleSelected(vehicle)}
            selectedVehicles={selectedVehicles}
            setSelectedVehicles={setSelectedVehicles}
            selectableVehicles={selectableVehicles}
          />
        ))}
      </Cards>
    </CollapsibleHeader>
  );
};

export default ScheduleMaintenanceVehicleCardContainer;
