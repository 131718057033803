import { SimpleDropdownBtn, SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';

const MarkCompletedMenuItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Mark Completed</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default MarkCompletedMenuItem;
