import { css } from 'styled-components';

export const sizes = {
  tablet: 1024,
  tablet_portrait: 1023,
  mobile: 767,
  mobile_portrait: 375,
};

export const media = Object.keys(sizes).reduce((accumulator, label) => {
  accumulator[label] = (...args) => css`
    @media (max-width: ${sizes[label]}px) {
      ${css(...args)}
    }
  `;
  return accumulator;
}, {});
