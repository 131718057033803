import React from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { selectUserRole } from 'core/redux/user/selectors';
import colors from 'shared/constants/colors';
import { customerTypeDictionary } from 'shared/constants/organizations';
import { ActionButtonText, ActionButtonWithIcon } from 'shared/styles/components/Button';
import {
  NameAndButtonsDiv,
  OrganizationHeader,
  OrganizationHeaderWrapper,
} from 'shared/styles/components/Header';
import { CardLabel, CardName, CardRow, CardValue } from 'shared/styles/components/MobileCard';
import { PageListActionButtonContainer, PageListTitle } from 'shared/styles/components/PageList';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

function OrganizationDetailHeader({
  resource,
  resourceIdKey,
  toggleCreateResourceModal,
  createResourceAccessRoles,
  createResourceButtonText,
  userRole,
  handleOpenEditModal,
}) {
  const location = useLocation();
  return (
    <div style={styles.outerContainer}>
      <NameAndButtonsDiv>
        <div>
          <Link
            to={{
              pathname: (location.state && location.state.referrer) || '/organizations',
              state: { referrer: location.state && location.state.referrer },
            }}
          ></Link>
          <PageListTitle>{resource.organizationName}</PageListTitle>
        </div>
        <PageListActionButtonContainer>
          <ActionButtonWithIcon
            orgDetail
            orgEdit
            onClick={e => handleOpenEditModal(e, resource.organizationId)}
          >
            Edit Organization
          </ActionButtonWithIcon>
          {createResourceAccessRoles.includes(userRole) ? (
            <ActionButtonWithIcon
              orgDetail
              create
              onClick={toggleCreateResourceModal(resource && resource[resourceIdKey])}
            >
              <ActionButtonText>{createResourceButtonText}</ActionButtonText>
              <IconSvgComponent
                svgFileName={'plus-simple'}
                style={styles.buttonPlusIcon}
                alt="Create"
              />
            </ActionButtonWithIcon>
          ) : null}
        </PageListActionButtonContainer>
      </NameAndButtonsDiv>
      <OrganizationHeaderWrapper>
        <CardName contactName>Contact Name: {resource.contactName}</CardName>
        <OrganizationHeader>
          <CardRow>
            <CardLabel>Contact Email:</CardLabel>
            <CardValue>{resource.contactEmail}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Start Date:</CardLabel>
            <CardValue>{resource.startDate}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Contact Phone:</CardLabel>
            <CardValue>{resource.contactPhone}</CardValue>
            {resource.contactPhoneExtension && (
              <React.Fragment>
                <CardLabel style={{ marginLeft: '10px' }}>Extension:</CardLabel>
                <CardValue>{resource.contactPhoneExtension}</CardValue>
              </React.Fragment>
            )}
          </CardRow>
          <CardRow>
            <CardLabel>End Date:</CardLabel>
            <CardValue>{resource.endDate}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Address:</CardLabel>
            <CardValue>{resource.address1}</CardValue>
            <CardValue>{resource.address2}</CardValue>
            <CardValue>{resource.city}</CardValue>
            <CardValue>{resource.state}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Vehicle Limit:</CardLabel>
            <CardValue>{resource.vehicleLimit}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Customer Type:</CardLabel>
            <CardValue>
              {resource?.customerType
                ? customerTypeDictionary.find(ct => ct.value === resource?.customerType)?.label
                : 'Not Specified'}
            </CardValue>
          </CardRow>
        </OrganizationHeader>
      </OrganizationHeaderWrapper>
    </div>
  );
}

export default connect(state => ({
  userRole: selectUserRole(state.user.user),
}))(OrganizationDetailHeader);

const styles = {
  backIcon: {
    marginBottom: 7,
    marginRight: 15,
  },
  buttonPlusIcon: {
    position: 'absolute',
    right: 15,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  form: editToggled => ({
    marginTop: 20,
    flexDirection: 'row',
    display: 'flex',
    paddingRight: '10%',
  }),
  organizationId: {
    display: 'inline-block',
    color: colors.midnight,
    flexBasis: '225px',
    minWidth: '225px',
  },
  organizationIdLabel: {
    display: 'block',
    fontSize: '9px',
  },
  contactName: editToggled => ({
    display: editToggled ? 'inline-block' : 'none',
    marginLeft: 10,
  }),
  outerContainer: {
    marginBottom: '57px',
  },
  innerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
};
