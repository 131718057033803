import {
  requiredValidator,
  regexValidator,
  alphanumericExtendedRegex,
  alphanumericExtendedRegexError,
  characterLimitValidator,
} from 'shared/utilities/validators';
import { findDuplicateValues } from 'app/shared/utilities/findDuplicateValues';

const recipientGroupValidators = {
  recipientGroupNameField: requiredValidator(
    characterLimitValidator(
      regexValidator(alphanumericExtendedRegex, alphanumericExtendedRegexError),
    ),
  ),
  recipientGroupMembersField: requiredValidator(),
};

export const validateRecipientGroup = values => {
  const recipientGroupNameErrors = recipientGroupValidators.recipientGroupNameField(
    values.recipientGroupNameField,
    36,
  );
  let recipientGroupMembersErrors = [];
  let usersToValidate = values?.recipientGroupMembersField?.filter(u => u);

  if (usersToValidate) {
    if (usersToValidate.length < 1) {
      recipientGroupMembersErrors.push('Please choose at least one recipient');
    }
    for (let u of usersToValidate) {
      const error = recipientGroupValidators.recipientGroupMembersField(u.userName);
      if (error) {
        recipientGroupMembersErrors.push(error);
      }
    }
  }

  let duplicateUserNameErrors = 'Please choose unique recipients';
  let userNames = [];
  if (usersToValidate) {
    for (let u of usersToValidate) {
      userNames.push(u.userName);
    }
  }

  let duplicates = findDuplicateValues(userNames);
  let validationErrors = {
    ...(recipientGroupNameErrors ? { recipientGroupNameField: recipientGroupNameErrors } : {}),
    ...(recipientGroupMembersErrors.length > 0
      ? { recipientGroupMembersField: recipientGroupMembersErrors[0] }
      : {}),
    ...(duplicates.length > 0 ? { duplicateUserNameErrors: duplicateUserNameErrors } : {}),
  };

  return validationErrors;
};
