import { alertTypeConstants } from 'app/features/alerts/data/enums/alertTypeConstants';
import DropdownOptions from 'app/shared/utilities/dropdown/DropdownOptionsLegacy';

// dropdown data
export const alertTypeDropdownOptions = new DropdownOptions([
  { value: alertTypeConstants.SPEED_EXCEEDS, label: 'Vehicle Speed Exceeds' },
  { value: alertTypeConstants.NR_SPEED_EXCEEDS, label: 'Vehicle Not-Responding Speed Exceeds' },
  { value: alertTypeConstants.OFFLINE_TIME_EXCEEDS, label: 'Vehicle Offline Time Exceeds' },
  { value: alertTypeConstants.ADDON_ACTIVE, label: 'Addon Active/Inactive' },
  { value: alertTypeConstants.CHECK_ENGINE_LIGHT, label: 'Check Engine Light' },
  { value: alertTypeConstants.MAINTENANCE_DUE, label: 'Assigned Maintenance Due' },
]);
