import { useEffect, useRef } from 'react';
import NotificationSystem from 'react-notification-system';
import { useDispatch, useSelector } from 'react-redux';

import { successAddNotification, successRemoveNotification } from 'core/redux/ui/actions';

const ToastNotifier = () => {
  const dispatch = useDispatch();
  const notificationsToAdd = useSelector(state => state.ui.notificationsToAdd);
  const notificationSystem = useRef();

  useEffect(() => {
    notificationsToAdd.forEach(notification => {
      notificationSystem.current?.addNotification({
        ...notification,
        onAdd: ({ uid: notificationUid }) => {
          dispatch(successAddNotification({ notificationUid }));
        },
        onRemove: ({ notificationUid }) => {
          dispatch(successRemoveNotification({ notificationUid }));
        },
      });
    });
  }, [notificationsToAdd]);

  return <NotificationSystem ref={notificationSystem} style={ToastNotificationStyles} />;
};

export default ToastNotifier;

const ToastNotificationStyles = {
  NotificationItem: {
    DefaultStyle: {
      borderTop: 'none',
      color: '#fff',
      borderRadius: '3px',
    },
    info: {
      backgroundColor: '#333',
    },
    success: {
      backgroundColor: '#4ca311',
    },
    warning: {
      backgroundColor: '#5f2449',
    },
    error: {
      backgroundColor: '#c62339',
    },
  },
  Title: {
    DefaultStyle: {
      color: '#fff',
    },
    success: {},
    error: {},
  },
  Dismiss: {
    DefaultStyle: {
      fontFamily: 'sans-serif',
      backgroundColor: 'none',
      color: '#ffffff',
    },
    success: {},
    error: {},
  },
};
