export const mapUserDataToCsv = ({ users }) => {
  return users.map(u => {
    return {
      User_Name: u.username,
      First_Name: u.givenName,
      Last_Name: u.familyName,
      Title: u.userTitle,
      Contact_Email: u.email,
      Role: u.role,
      Status: u.status,
    };
  });
};

export const mapUserActivityLogsToCsv = ({ userActivityLogs }) => {
  return userActivityLogs.map(l => {
    return {
      Timestamp: l.formattedTime,
      Action: l.action,
      Name: l.name,
      Details: l.details,
    };
  });
};
