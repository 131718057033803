import { useQuery, useQueryClient } from '@tanstack/react-query';
import { getFirmwaresBySingleVehicle } from 'core/api/firmware';
import { checkForUserPermission } from 'core/redux/user/selectors';
import { updateFetchError } from 'features/ota/firmware/helpers/notifications';
import { useEffect } from 'react';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FIRMWARE_REFETCH_INTERVAL } from 'shared/constants/firmwares';
import {
  FIELD_SOLUTION_ENGINEER,
  ORGANIZATION_ADMIN,
  permissionData,
  userRoleOptions,
} from 'shared/constants/users';
import { checkLogin } from 'shared/utilities/user';

const useFetchFirmwareBySingleVehicleQuery = ({
  key = '',
  autoRun = true,
  enableRefetchInterval = false,
  vehicleId,
} = {}) => {
  const dispatch = useDispatch();

  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const user = useSelector((state) => state.user);
  const userRole = useSelector((state) => state.user.user.userRole);

  const [hasPermissions, setHasPermissions] = useState(false);

  useEffect(() => {
    let hasPermissions =
      checkForUserPermission(user, permissionData.viewotafirmware) &&
      ((userRole && userRoleOptions[ORGANIZATION_ADMIN].value === userRole) ||
        userRoleOptions[FIELD_SOLUTION_ENGINEER].value === userRole);

    setHasPermissions(hasPermissions);
  }, [user, userRole]);

  // gets firmware updates
  const fetchUpdatesQuery = useQuery({
    queryKey: ['fetchFirmwareUpdatesByVehicleQuery' + key],
    queryFn: () => {
      return getFirmwaresBySingleVehicle({
        organizationId: filteredOrganizationId,
        vehicleId: vehicleId,
      });
    },

    enabled: autoRun && checkLogin() && !!hasPermissions,
    select: (response) => {
      return response?.body?.message?.vehicles[0];
    },
    onError: (err) => dispatch(updateFetchError({ err })),
    refetchInterval: enableRefetchInterval === false ? false : FIRMWARE_REFETCH_INTERVAL,
  });

  return fetchUpdatesQuery;
};

export default useFetchFirmwareBySingleVehicleQuery;
