import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { Formik } from 'formik';
import { checkLogin } from 'shared/utilities/user';
import { verifySoftwareTokenMfa, verifySmsMfa, loginWithMfa } from 'core/redux/user/actions';

import AnimatedField from 'shared/ui/fields/AnimatedField';
import { StyledFormRow } from 'shared/styles/components/LoginSignup';

import whelenLogo from 'assets/images/whelenlogo@2x.png';
import wcpLogo from 'assets/images/wcpLogo@2x.png';
import {
  Wrapper,
  StyledFormWrapper,
  LoginWrapper,
  StyledHead,
  StyledBody,
  StyledTitle,
  StyledSubtitle,
  StyledIconWrapper,
  StyledFoot,
  StyledButton,
  WhelenLogo,
  WCPLogo,
  PreLogoText,
} from 'shared/styles/components/LoginSignup';

import { Form } from 'shared/styles/components/Form.js';

import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import TwoFactorSubtitle from 'core/components/authentication/components/TwoFactorSubtitle';

const TwoFactorVerification = ({
  //data
  username,
  session,
  mfaType,
  mfaDestination,
  authenticationAppSecretCode,
  smsVerificationCode,
  selectedMfaMethod,
  needsMfaSetup,
  loginWithMfa,
  verifySoftwareTokenMfa,
  verifySmsMfa,
}) => {
  const [redirectRoute, setRedirectRoute] = useState(false);

  const onSubmit = async (values, actions) => {
    const { userMfaCode } = values;
    let success;
    const isLoggedIn = checkLogin();
    // first time 2fa login: Auth App
    // fyi this never gets hit
    if (authenticationAppSecretCode) {
      success = await verifySoftwareTokenMfa({
        userMfaCode,
      });
    }
    // first time 2fa login: SMS
    // fyi this never gets hit
    else if (smsVerificationCode) {
      success = await verifySmsMfa({
        userMfaCode,
        smsVerificationCode,
      });
    }
    // regular 2fa login
    else {
      if (!needsMfaSetup && !isLoggedIn) {
        let response = await loginWithMfa({
          username,
          session,
          mfaType,
          userMfaCode,
        });

        // successful authentication
        success = response && response.message && response.message.AuthenticationResult;
      } else {
        success = true;
      }
    }

    // stop button loading
    actions.setSubmitting(false);

    if (success) {
      // successful authentication
      setRedirectRoute('livemap');
    } else {
      // failed authentication
      setRedirectRoute('login');
    }
  };

  const refUserMfaCode = useRef();
  useEffect(() => {
    if (refUserMfaCode.current !== null) {
      refUserMfaCode.current.focus();
    }
  }, []);

  return redirectRoute ? (
    <Navigate to={`/${redirectRoute}`} />
  ) : selectedMfaMethod ? (
    <Navigate to={`/${redirectRoute}`} />
  ) : (
    <Formik
      initialValues={{
        userMfaCode: '',
      }}
      onSubmit={onSubmit}
    >
      {({ values, errors, touched, dirty, isSubmitting, handleChange, handleBlur, submitForm }) => (
        <Wrapper>
          <LoginWrapper>
            <Form onSubmit={submitForm}>
              <StyledFormWrapper>
                <StyledHead>
                  <StyledIconWrapper>
                    <WCPLogo src={wcpLogo} alt="Whelen Cloud Platform®" />
                  </StyledIconWrapper>

                  <StyledTitle>{'Two-Factor Authentication'}</StyledTitle>
                  <StyledSubtitle>
                    <TwoFactorSubtitle
                      mfaType={mfaType}
                      mfaDestination={mfaDestination}
                      authenticationAppSecretCode={authenticationAppSecretCode}
                      username={username}
                    />
                  </StyledSubtitle>
                  <>
                    {errors.name && (
                      <div
                        style={{
                          fontWeight: '300',
                          color: '#745d23',
                          fontSize: '12px',
                          padding: '10px 25px',
                          marginTop: '20px',
                          marginBottom: '-45px',
                          backgroundColor: '#fbecc3',
                        }}
                      >
                        <div>{errors.name}</div>
                      </div>
                    )}
                  </>
                </StyledHead>

                <StyledBody>
                  <StyledFormRow>
                    <AnimatedField
                      id="userMfaCode"
                      name="userMfaCode"
                      type="text"
                      touched={touched.userMfaCode}
                      validationError={errors.userMfaCode}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      placeholder="Enter Code"
                      value={values.userMfaCode}
                      innerRef={refUserMfaCode}
                      required
                      autoComplete="off"
                    />
                  </StyledFormRow>
                </StyledBody>

                <StyledFoot>
                  <ButtonWithLoader
                    isLoading={isSubmitting}
                    confirmText={'Log In'}
                    loadingStyleProp={'loginWithSpinner'}
                    notLoadingStyleProp={'loginButton'}
                    clickHandler={submitForm}
                  />

                  <PreLogoText>POWERED BY</PreLogoText>
                  <WhelenLogo src={whelenLogo} alt="Whelen" />
                </StyledFoot>
              </StyledFormWrapper>
            </Form>
          </LoginWrapper>
        </Wrapper>
      )}
    </Formik>
  );
};

export default connect(
  state => ({
    username: state.user.username || state.user.user.username,
    session: state.user.session,
    mfaType: state.user.mfaType,
    mfaDestination: state.user.mfaDestination,
    authenticationAppSecretCode: state.user.authenticationAppSecretCode,
    smsVerificationCode: state.user.smsVerificationCode,
    selectedMfaMethod: state.user.selectedMfaMethod,
    needsMfaSetup: state.user.needsMfaSetup,
  }),
  dispatch => ({
    loginWithMfa: async ({ username, session, mfaType, userMfaCode }) => {
      return dispatch(await loginWithMfa({ username, session, mfaType, userMfaCode }));
    },
    verifySoftwareTokenMfa: async ({ userMfaCode }) => {
      return dispatch(await verifySoftwareTokenMfa({ userMfaCode }));
    },
    verifySmsMfa: async ({ smsVerificationCode, userMfaCode }) => {
      return dispatch(await verifySmsMfa({ smsVerificationCode, userMfaCode }));
    },
  }),
)(TwoFactorVerification);
