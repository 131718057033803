import { useQueryClient } from '@tanstack/react-query';
import { v4 as uuidv4 } from 'uuid';

import RecipientGroupNameField from 'app/features/alerts/ui/components/AlertForms/shared/fields/RecipientGroupNameField';
import useCreateRecipientGroupMutation from 'app/features/recipientGroups/hooks/mutations/useCreateRecipientGroupMutation';
import { getUserNameDisplayFromUserList } from 'app/shared/utilities/getUserNameDisplayFromUserList';
import { FormRow } from 'shared/styles/components/Form';
import { CreateRecipientGroupModalFormWrapper } from 'shared/styles/components/ManageAlerts';
import GenericFormModal from 'shared/ui/modals/GenericFormModal';
import { validateRecipientGroupFromAlert } from 'shared/utilities/validators/recipientGroupNameValidator';
import useAlertFormsProviderContext from 'app/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';

const CreateRecipientGroupFromAlertModal = ({
  alertNotificationGuid,
  modalData: { show, recipientsToGroup },
  handleCloseModal,
}) => {
  const providerContext = useAlertFormsProviderContext();

  let {
    state: { organizationUsers },
    actions: { setAlertRecipientRowsAction },
  } = providerContext;

  const queryClient = useQueryClient();
  const createRecipientGroupMutation = useCreateRecipientGroupMutation();

  return (
    show && (
      <GenericFormModal
        customWidth={550}
        title={'Create Recipient Group'}
        initialValues={{
          recipientGroupNameField: '',
        }}
        submitHandler={async (values, formikActions) => {
          let recipientGroupMembers = recipientsToGroup.map(r =>
            organizationUsers.find(u => u.userName === r),
          );

          let newRecipientGroup = {
            recipientGroupName: values.recipientGroupNameField,
            recipientGroupMembers,
          };

          let newlyCreatedRecipientGroup = await createRecipientGroupMutation.mutateAsync({
            newRecipientGroup,
          });

          newRecipientGroup = {
            ...newRecipientGroup,
            recipientGroupMembers,
            recipientGroupId: newlyCreatedRecipientGroup.data.message.new_item.group_id,
          };

          await queryClient.invalidateQueries({ queryKey: ['getRecipientGroupsApi'] });

          setAlertRecipientRowsAction({
            alertNotificationGuid,
            alertRecipientRow: { recipientRowGuid: uuidv4(), recipientGroup: newRecipientGroup },
          });

          formikActions.setSubmitting(false);
          handleCloseModal();
        }}
        validator={validateRecipientGroupFromAlert}
        validateOnChange={true}
        validateOnBlur={false}
        handleCloseModal={handleCloseModal}
        submitButtonText={'Create'}
      >
        {formikProps => (
          <CreateRecipientGroupModalFormWrapper>
            <FormRow>
              <RecipientGroupNameField formikProps={formikProps} />
              <div>
                Members:{' '}
                {getUserNameDisplayFromUserList(
                  recipientsToGroup.map(r => organizationUsers.find(u => u.userName === r)),
                )}
              </div>
            </FormRow>
          </CreateRecipientGroupModalFormWrapper>
        )}
      </GenericFormModal>
    )
  );
};

export default CreateRecipientGroupFromAlertModal;
