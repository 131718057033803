import React from 'react';
import { useEffect } from 'react';
import { useRef } from 'react';
import AnimatedField from 'shared/ui/fields/AnimatedField';

const VehicleModelField = ({
  formikProps: { values, touched, errors, handleChange, handleBlur, setFieldValue, setFieldError },
  prefillValue,
}) => {
  useEffect(() => {
    if (prefillValue) {
      setFieldValue('model', prefillValue);
      setFieldError('model', false);
    }
  }, [prefillValue]);

  return (
    <AnimatedField
      name="model"
      placeholder="Vehicle Model"
      value={values.model}
      touched={touched.model}
      validationError={errors.model}
      handleChange={handleChange}
      handleBlur={handleBlur}
      forceAnimated={!!prefillValue}
      disabled={false}
      required
    />
  );
};

export default VehicleModelField;
