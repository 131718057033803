import {
  getDaysSince,
  getMonthsSince,
  getNow,
  getShortDateInIsoFormat,
} from 'shared/utilities/time';

export const vehiclePlaybackDateValidator = nextValidator => (value, ...rest) => {
  const differenceDays = getDaysSince({
    fromDateTime: getShortDateInIsoFormat(value),
    toDateTime: getNow(),
  });
  const differenceMonths = getMonthsSince({
    fromDateTime: getShortDateInIsoFormat(value),
    toDateTime: getNow(),
  });
  if (differenceDays < 0) {
    return 'Please enter a date not in the future';
  }
  if (differenceMonths > 35) {
    return 'Please enter a date less than 3 years ago';
  }
  if (typeof nextValidator === 'function') {
    return nextValidator(...[value, ...rest]);
  }
};
