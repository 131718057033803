import { apiCall, fetchAPICall, getAuthToken } from 'core/api/API';
import { configurationPath, configurationUploadPath } from 'shared/constants/api';
import { getGuardedObjectProp } from 'shared/utilities/general';
import apiUrl from 'shared/utilities/url';

// TODO: move these to general utilities when refactoring configurations
export const convertToCamelCase = key => {
  return key
    .split('_')
    .map((segment, index) =>
      index > 0 ? `${segment[0].toUpperCase()}${segment.substring(1)}` : segment,
    )
    .join('');
};

export const convertToUnderscore = key => {
  return [...key.matchAll(/([a-z]+)|([A-Z][a-z]+|[A-Z]+(?![a-z]))/g)]
    .map(match => match[0].toLowerCase())
    .join('_');
};

const decorateObjectKeys = (object, decorator, exclude = []) =>
  Object.prototype.toString.call(object) === '[object Object]' &&
  Object.entries(object)
    .map(entry => {
      const valueTypes = {
        '[object Object]': entryValue => decorateObjectKeys(entryValue, decorator),
        '[object Array]': entryValue =>
          entryValue.map(arrayElement => decorateObjectKeys(arrayElement, decorator)),
      };
      const valueType = Object.prototype.toString.call(entry[1]);
      const value = valueTypes[valueType] ? valueTypes[valueType](entry[1]) : entry[1];

      return !exclude.includes(entry[0]) ? [decorator(entry[0]), value] : [entry[0], entry[1]];
    })
    .reduce(
      (decoratedObject, entry) => ({
        ...decoratedObject,
        [entry[0]]: entry[1],
      }),
      {},
    );

export const convertKeysToCamelCase = (object, exclude) =>
  decorateObjectKeys(object, convertToCamelCase, exclude);

export const convertKeysToUnderscore = (object, exclude) =>
  decorateObjectKeys(object, convertToUnderscore, exclude);

/**
 * @ Services
 */
export const getConfigurations = async organizationId => {
  const response = await apiCall({
    endpoint: 'cloudfrontAPI',
    path: configurationPath,
    method: 'get',
    queryStringParameters: {
      ...(organizationId ? { organization_id: organizationId } : {}),
    },
  });

  return decorateConfigurationsResponse(response);
};

export const getConfigurationUpdates = async organizationId => {
  const response = await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/config/update',
    method: 'get',
    queryStringParameters: {
      ...(organizationId ? { organization_id: organizationId } : {}),
      version: 2,
      include_completed: true,
    },
  });

  return response; // decorateConfigurationsResponse(response);
};

export const uploadConfiguration = async ({ file, organizationId }) => {
  const body = new FormData();
  body.append('file', file[0]);
  if (organizationId) {
    body.append('organization_id', organizationId);
  }

  return await fetchAPICall({
    baseURI: apiUrl,
    resourcePath: `${configurationUploadPath}`,
    method: 'POST',
    headers: {
      Authorization: getAuthToken(),
    },
    body,
  });
};

export const updateConfiguration = async (configuration, configurationId) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${configurationPath}/${configurationId}`,
    method: 'put',
    body: undecorateConfigurationObject(configuration),
  });
};

export const deleteConfiguration = async ({ configurationId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${configurationPath}/${configurationId}`,
    method: 'del',
  });
};

export const updateConfigurations = async ({ configurationId, vehicleIds, organizationId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/config/update/${configurationId}`,
    method: 'post',
    queryStringParameters: {
      organization_id: organizationId,
      version: 2,
    },
    body: {
      vehicle_ids: vehicleIds,
    },
  });
};

export const downloadConfiguration = async configurationId => {
  return await apiCall({
    path: `/config/lib/${configurationId}/download`,
    method: 'get',
  });
};

/**
 * @ Decorators
 */
export const decorateConfigurationObject = configuration => {
  const camelCasedConfiguration = convertKeysToCamelCase(configuration, ['vehicles']);
  return {
    ...camelCasedConfiguration,
    ...(camelCasedConfiguration.supportedDevices
      ? { supportedDevices: addSupportedInstallations(camelCasedConfiguration.supportedDevices) }
      : {}),
  };
};

export const undecorateConfigurationObject = configuration =>
  convertKeysToUnderscore(configuration, ['vehicles']);

export const addSupportedInstallations = supportedDevices => {
  const supportedDevicesWithInstallations = supportedDevices.map((device, index, devices) => {
    return {
      ...device,
      supportedInstallations: devices.filter(
        filterDevice => filterDevice.productId === device.productId,
      ).length,
    };
  });
  return supportedDevicesWithInstallations;
};

export const getConfigurationCounts = vehiclesByStatus => {
  const upToDateStatus = firmwareStatuses.UP_TO_DATE.status;
  const completedCount = getGuardedObjectProp('length', vehiclesByStatus[upToDateStatus]) || 0;
  const eligibleVehiclesCount = Object.values(vehiclesByStatus).reduce(
    (count, statusGroup) => count + statusGroup.length,
    0,
  );

  return {
    all: eligibleVehiclesCount,
    completed: completedCount,
  };
};

export const getConfigurationsOtaLogs = async ({ organizationId, startDate, endDate }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: '/config/logs/',
    method: 'get',
    queryStringParameters: {
      organization_id: organizationId,
      start_date: startDate,
      end_date: endDate,
    },
  });
};

const decorateConfigurationsResponse = response =>
  response &&
  response.response.message && {
    response: {
      message: response.response.message.map(configuration =>
        decorateConfigurationObject(configuration),
      ),
    },
  };
