import { useEffect, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';

import { getVehicle } from 'core/api/vehicles';
import { checkLogin } from 'shared/utilities/user';
import { decorateVehicle, sortAscendingAlphaLabel } from 'shared/utilities/vehicle';
import { FIRMWARE_REFETCH_INTERVAL } from 'shared/constants/firmwares';
import { mapToFrontend } from 'app/shared/utilities/mapping/mapper';
import {
  mapMaintenanceTotalMilageData,
  vehicleMaintenanceMapping,
} from 'app/features/maintenance/utilities/mapping';
import { vehicleFetchError } from 'features/fleet/vehicles/helpers/notifications';

const tempMap = (v) => {
  return v;
};

export const useFetchSingleVehicleQuery = ({
  key = '',
  autoRun = true,
  enableRefetchInterval = false,
  vehicleId,
  apiFlags = {
    addons: false,
    cell: true,
    device: true,
    gps: true,
    meta: true,
    maintenance: false,
    obd: false,
  },
  mappingEnabled = false,
} = {}) => {
  const dispatch = useDispatch();

  const vehicleQuery = useQuery({
    queryKey: ['fetchSingleVehicleQuery' + key, vehicleId],
    queryFn: () => {
      return getVehicle({ vehicleId, apiFlags });
    },

    enabled: autoRun && checkLogin(),
    select: (response) => {
      let vehicle = response?.body?.message;
      let decoratedVehicle = null;

      if (mappingEnabled) {
        // TODO create mapping for vehicle entity and replace obsolete decorateVehicle
        decoratedVehicle = decorateVehicle(vehicle);

        // just mapping maintenance for now
        decoratedVehicle.maintenance = decoratedVehicle.maintenance.map((item) => {
          let mappedMaintenanceItem = mapToFrontend(item, vehicleMaintenanceMapping);

          mappedMaintenanceItem = mapMaintenanceTotalMilageData({
            maintenanceEvent: mappedMaintenanceItem,
          });

          return mappedMaintenanceItem;
        });
      } else {
        decoratedVehicle = decorateVehicle(vehicle);
      }

      // more mapping
      decoratedVehicle.vehicleId = vehicle.vehicle_id;

      return decoratedVehicle;
    },
    onError: (err) => dispatch(vehicleFetchError({ err })),
    refetchInterval: enableRefetchInterval === false ? false : FIRMWARE_REFETCH_INTERVAL,
  });

  return vehicleQuery;
};
