import { useEffect, useState } from 'react';
// components
import Media from 'react-media';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { getLogsAnalytics } from 'core/api/analytics';
import AnalyticsDashboardCard from 'features/analytics/dashboard/components/mobile/AnalyticsDashboardCard';
import {
  AnalyticsCheckboxFilterWrapper,
  AnalyticsDropdown,
} from 'shared/styles/components/Analytics';
import { ActionButtonText, TextButton } from 'shared/styles/components/Button';
import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import { SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';
import { Cards } from 'shared/styles/components/MobileCard';
import StyledReactSelect from 'shared/styles/components/SelectField';
import { TableFilterSpan } from 'shared/styles/components/Table';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import ViewLogsMenuItem from 'shared/ui/menus/ViewLogsMenuItem';
import ActionMenu from 'shared/ui/table/ActionMenu';
import SortableTableWithoutPagination from 'shared/ui/table/SortableTableWithoutPagination';
import { sizes } from 'shared/utilities/media';

const AnalyticsDashboardResultsTable = ({ vehicleReportData, startDate, endDate }) => {
  const navigate = useNavigate();
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const [resultsFilterCheckboxIsChecked, setResultsFilterCheckboxIsChecked] = useState(false);
  const [percentOfflineDropdownValue, setPercentOfflineDropdownValue] = useState(50);
  const [onlineOfflineDropdownValue, setOnlineOfflineDropdownValue] = useState('offline');
  const [filteredReportData, setFilteredReportData] = useState(vehicleReportData);
  const [logsQueryResults, setLogsQueryResults] = useState(null);
  const [topRespondingSpeedVehicleId, setTopRespondingSpeedVehicleId] = useState(null);

  const getActionMenu = vehicle => {
    return (
      <ActionMenu>
        <SimpleDropdownItem>
          <ViewLogsMenuItem vehicle={vehicle} />
        </SimpleDropdownItem>
      </ActionMenu>
    );
  };
  useEffect(() => {
    if (resultsFilterCheckboxIsChecked) {
      setFilteredReportData(
        vehicleReportData.filter(
          v =>
            ((onlineOfflineDropdownValue === 'offline' ? v.hours_offline : v.hours_online) /
              v.hours_total) *
              100 >
            percentOfflineDropdownValue,
        ),
      );
    } else {
      setFilteredReportData(vehicleReportData);
    }
  }, [
    vehicleReportData,
    resultsFilterCheckboxIsChecked,
    percentOfflineDropdownValue,
    onlineOfflineDropdownValue,
  ]);

  const getTopSpeedEvent = async vehicleId => {
    startDate, endDate;
    try {
      let data = await getLogsAnalytics({
        startDate: startDate,
        endDate: endDate,
        groupId: null,
        vehicleId: vehicleId,
        organizationId: filteredOrganizationId,
      });
      setLogsQueryResults(data.response.message);
      setTopRespondingSpeedVehicleId(vehicleId);
    } catch (e) {
      console.log('Analytics query failed:' + e);
    }
  };

  useEffect(() => {
    if (logsQueryResults) {
      let topRepsondingSpeedOfQuery = null;
      let topRespondingSpeedEventTimestamp = null;
      let topRespondingSpeedEventDuration = null;
      for (let i = 0; i < logsQueryResults.events.length; i++) {
        if (logsQueryResults.events[i].responding) {
          const eventTopSpeed = logsQueryResults.events[i].top_speed;
          if (eventTopSpeed > topRepsondingSpeedOfQuery) {
            topRepsondingSpeedOfQuery = eventTopSpeed;
            topRespondingSpeedEventTimestamp = logsQueryResults.events[i].timestamp;
            topRespondingSpeedEventDuration = logsQueryResults.events[i].duration;
          }
        }
      }
      // redirect to vehicle playback with the specified event details
      navigate(
        `/playback/${encodeURIComponent(topRespondingSpeedVehicleId)}/${encodeURIComponent(
          topRespondingSpeedEventTimestamp,
        )}/${encodeURIComponent(topRespondingSpeedEventDuration)}`,
      );
    }
  }, [topRespondingSpeedVehicleId]);

  const columns = [
    {
      title: 'Vehicle Name',
      dataIndex: 'vehicle_name',
      className: 'name',
      key: 'a',
      width: '15%',
      render(vehicleName, vehicle) {
        return (
          <span className="vehicleNameAndIcon" title={'Vehicle Name: ' + vehicleName}>
            {vehicle.loading_status ? (
              <TableSpinner />
            ) : (
              <IconSvgComponent svgFileName={'car-outline'} alt="Vehicle" />
            )}
            {vehicleName}
          </span>
        );
      },
    },
    {
      title: 'Hours Online',
      dataIndex: 'hours_online',
      key: 'b',
      render(value) {
        return <span title={'Hours Online: ' + value}>{value}</span>;
      },
    },
    {
      title: 'Hours Offline',
      dataIndex: 'hours_offline',
      key: 'c',
      render(value) {
        return <span title={'Hours Offline: ' + value}>{value}</span>;
      },
    },
    {
      title: 'Hours Responding',
      dataIndex: 'hours_responding',
      key: 'd',
      render(value) {
        return <span title={'Hours Responding: ' + value}>{value}</span>;
      },
    },
    {
      title: 'Responding Events',
      dataIndex: 'responding_count',
      key: 'e',
      render(value) {
        return <span title={'Responding Events: ' + value}>{value}</span>;
      },
    },
    {
      title: 'Top Responding Speed',
      dataIndex: 'top_responding_speed',
      key: 'f',
      render(value, vehicle) {
        return value !== 0 ? (
          <TextButton
            topSpeed
            type="button"
            onClick={() => getTopSpeedEvent(vehicle.vehicle_id)}
            title={'Top Responding Speed: ' + value}
          >
            <ActionButtonText>{value}</ActionButtonText>
          </TextButton>
        ) : (
          <span title={'Top Responding Speed: ' + value}>{value}</span>
        );
      },
    },

    {
      title: '',
      dataIndex: '',
      className: 'action',
      key: 'g',
      render: (vehicle, commands) => getActionMenu(vehicle, commands),
    },
  ];

  let percentOptions = [];
  for (let i = 0; i <= 10; i++) {
    let percentageValue = i * 10;
    let percentageText = percentageValue + '%';
    percentOptions.push({ label: percentageText, value: percentageValue });
  }
  let onlineOfflineOptions = [];
  onlineOfflineOptions.push(
    {
      label: 'offline',
      value: 'offline',
    },
    {
      label: 'online',
      value: 'online',
    },
  );

  return (
    <>
      <AnalyticsCheckboxFilterWrapper>
        <Checkbox singleCheck>
          <CheckboxInner
            isChecked={resultsFilterCheckboxIsChecked}
            onClick={() => setResultsFilterCheckboxIsChecked(!resultsFilterCheckboxIsChecked)}
          />
        </Checkbox>

        <TableFilterSpan>
          Only display vehicles
          <AnalyticsDropdown>
            <StyledReactSelect
              name="onlineOffline"
              defaultValue={{ label: onlineOfflineDropdownValue }}
              onChange={e => setOnlineOfflineDropdownValue(e.value)}
              isClearable={false}
              isSearchable={false}
              options={onlineOfflineOptions}
            />
          </AnalyticsDropdown>
          for more than
          <AnalyticsDropdown>
            <StyledReactSelect
              name="percentOffline"
              defaultValue={{
                label: percentOptions.find(p => p.value === percentOfflineDropdownValue).label,
              }}
              onChange={e => setPercentOfflineDropdownValue(e.value)}
              isClearable={false}
              isSearchable={false}
              options={percentOptions}
            />
          </AnalyticsDropdown>
          of the time.
        </TableFilterSpan>
      </AnalyticsCheckboxFilterWrapper>
      <Media
        queries={{
          tablet: { maxWidth: sizes.tablet },
          mobile: { maxWidth: sizes.mobile },
        }}
      >
        {matches =>
          matches.tablet ? (
            <div>
              <Cards>
                {filteredReportData.map(vehicle => (
                  <AnalyticsDashboardCard
                    vehicle={vehicle}
                    actionMenuComponent={() => getActionMenu(vehicle)}
                  />
                ))}
              </Cards>
            </div>
          ) : (
            <div>
              <SortableTableWithoutPagination
                className="table-simple"
                rowClassName={(record, i) => `row-${i}`}
                columns={columns}
                data={filteredReportData}
                rowKey={record => record.vehicle_id}
                emptyText="No data to display. Please click calculate to proceed."
              />
            </div>
          )
        }
      </Media>
    </>
  );
};

export default AnalyticsDashboardResultsTable;
