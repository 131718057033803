import { DropdownLink } from 'shared/styles/components/DropdownMenu';

const ViewMaintenanceLogsMenuItem = ({ vehicleId }) => {
  return (
    <DropdownLink to={`/maintenancelogs/${encodeURIComponent(vehicleId)}`}>
      View Maintenance Logs
    </DropdownLink>
  );
};

export default ViewMaintenanceLogsMenuItem;
