import MapStyleToggleButton from 'features/livemap/components/mapControls/MapStyleToggleButton';
import useMapboxCustomControl from 'shared/hooks/useMapboxCustomControl';

const useMapStyleToggleButton = ({ mapboxApi, isMapboxLoaded }) => {
  useMapboxCustomControl({
    renderComponent: (
      <MapStyleToggleButton
        setMapStyle={mapStyle => {
          console.log(isMapboxLoaded);
          mapboxApi.setStyle(mapStyle);
        }}
      />
    ),
    mapboxApi,
    isMapboxLoaded,
    position: 'bottom-right',
    triggerRerender: true,
  });
};

export default useMapStyleToggleButton;
