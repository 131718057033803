import GroupNameField from 'features/fleet/groups/components/form/fields/text/GroupNameField';
import { useUpdateGroupMutation } from 'features/fleet/groups/hooks/useUpdateGroupMutation';
import { FormRow } from 'shared/styles/components/Form';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import GenericFormModal from 'shared/ui/modals/GenericFormModal';
import { validateGroup } from 'shared/utilities/validators';

const RenameGroupModal = ({ modalData: { show, groupId, groupName }, handleCloseModal }) => {
  const updateGroupMutation = useUpdateGroupMutation();

  return (
    show && (
      <GenericFormModal
        customWidth={550}
        icon={<IconSvgComponent svgFileName="edit-pencil-blue" alt="Edit" />}
        title={'Rename Group'}
        initialValues={{
          groupName,
        }}
        submitHandler={async (values, formikActions) => {
          let result = await updateGroupMutation.mutateAsync({
            groupId,
            groupName: values.groupName,
          });

          formikActions.setSubmitting(false);
          handleCloseModal();
        }}
        validator={validateGroup}
        handleCloseModal={handleCloseModal}
        errorMessage={updateGroupMutation.isError ? updateGroupMutation.error : null}
        submitButtonText={'Rename'}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {formikProps => (
          <FormRow>
            <GroupNameField formikProps={formikProps} />
          </FormRow>
        )}
      </GenericFormModal>
    )
  );
};

export default RenameGroupModal;
