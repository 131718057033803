import useVehiclePlaybackProviderContext from 'app/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import { SpeedSummary } from 'shared/styles/components/VehiclePlayback';

const VehiclePlaybackSpeedSummary = () => {
  const {
    state: {
      playbackData: {
        summary: { topSpeedActive, averageSpeedActiveMoving },
      },
    },
  } = useVehiclePlaybackProviderContext();

  return (
    <>
      <SpeedSummary>Top speed: {topSpeedActive} MPH</SpeedSummary>
      <SpeedSummary title={'Average speed is calculated by excluding idle time'}>
        Average speed: {averageSpeedActiveMoving ? `${averageSpeedActiveMoving} MPH` : 'N/A'}
      </SpeedSummary>
    </>
  );
};

export default VehiclePlaybackSpeedSummary;
