import { v4 as uuidv4 } from 'uuid';

export const addMaintenanceItemReducer = (state, payload) => {
  const { maintenanceItem } = payload;

  return {
    ...state,
    maintenanceItems: [...state.maintenanceItems, { id: uuidv4(), ...maintenanceItem }],
  };
};
