import { firmwareStatuses } from 'shared/constants/firmwares';

import {
  Content,
  ContentInner,
  Icon,
  OtaSummaryItemNotes,
  Text,
  Title,
  Wrapper,
  BoldedCount,
  OtaSummaryItemWrapperDiv,
} from 'shared/styles/components/OtaUpdates';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { formatISOTimestampToShortDate } from 'shared/utilities/time';

export const getCompletedFirmwareUpdates = ({ deviceUpdates, version }) => {
  return (
    deviceUpdates?.filter(
      u => u.firmwareStatus === firmwareStatuses.UP_TO_DATE.status && u.versionCurrent === version,
    ) || 0
  );
};

export const getFailedUpdates = ({ deviceUpdates, version }) => {
  return deviceUpdates?.filter(
    u =>
      (u.firmwareStatus === firmwareStatuses.UP_TO_DATE.status && u.versionCurrent !== version) ||
      u.firmwareStatus === firmwareStatuses.ERROR_BOOTLOADING.status,
  );
};
const FirmwareUpdateSummaryItem = ({
  children,
  releaseDate,
  releaseNotes,
  channel,
  version,
  deviceUpdates,
  showTotals,
}) => {
  const failedUpdates = getFailedUpdates({ deviceUpdates, version });
  const completeUpdates = getCompletedFirmwareUpdates({ deviceUpdates, version });
  const allDevicesComplete = completeUpdates.length === deviceUpdates.length;

  return (
    <OtaSummaryItemWrapperDiv key={version}>
      <Wrapper>
        <Content className="col">
          <ContentInner offsetLeft={true}>
            <Icon>
              <IconSvgComponent svgFileName={allDevicesComplete ? 'check-success' : 'cloud'} />
            </Icon>

            {version && <Title>{`Version ${version}`}</Title>}
            {channel && <Text>Channel: {channel}</Text>}
            {releaseDate && (
              <Text>{`Released: ${formatISOTimestampToShortDate(releaseDate)}`}</Text>
            )}
            {showTotals && (
              <>
                <Text success updateComplete={allDevicesComplete} status>
                  <BoldedCount>
                    {completeUpdates.length} of {deviceUpdates.length} Devices up to Date
                  </BoldedCount>
                </Text>
                {failedUpdates.length > 0 && (
                  <Text
                    title={`
                      Vehicle Name(s):
                      ${failedUpdates.map(v => v.label).join(', ')}
                      \nVehicle ID(s):
                      ${failedUpdates.map(v => v.connectivity_device_id).join(', ')}
                    `}
                    failed
                  >
                    {failedUpdates.length} Failed
                  </Text>
                )}
              </>
            )}
          </ContentInner>
        </Content>
        <OtaSummaryItemNotes>{releaseNotes}</OtaSummaryItemNotes>
      </Wrapper>{' '}
      {children}
    </OtaSummaryItemWrapperDiv>
  );
};

export default FirmwareUpdateSummaryItem;
