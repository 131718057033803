import { handleActions, combineActions } from 'redux-actions';

import {
  requestOrganizations,
  receiveOrganizations,
  receiveOrganizationFSEs,
  requestUpdateOrganization,
  rejectUpdateOrganization,
  requestDeactivateOrganization,
  rejectDeactivateOrganization,
  requestActivateOrganization,
  rejectActivateOrganization,
} from './actions';

const defaultState = {
  organizations: [],
  associatedFSEs: {},
  organizationsLoading: false,
};

const organizations = handleActions(
  {
    [requestOrganizations]: (state, action) => {
      return {
        ...state,
        organizationsLoading: true,
      };
    },
    [receiveOrganizations]: (state, action) => {
      const associatedFSEEntries = Object.entries(state.associatedFSEs);
      return {
        ...state,
        organizationsLoading: false,
        organizations: action.payload.map(organization => {
          const associatedFSEEntry = associatedFSEEntries.find(
            fseEntry => fseEntry[0] === organization.organizationId,
          );
          return {
            ...organization,
            loadingStatus: false,
            associatedFSE: associatedFSEEntry && associatedFSEEntry[1],
          };
        }),
      };
    },
    [requestUpdateOrganization]: (state, { payload: organizationId }) => {
      return {
        ...state,
        organizations: state.organizations.map(organization => {
          organization.loadingStatus =
            organization.loadingStatus || organization.organizationId === organizationId;
          return organization;
        }),
      };
    },
    [requestDeactivateOrganization]: (state, { payload: organizationId }) => {
      return {
        ...state,
        organizations: state.organizations.map(organization => {
          organization.loadingStatus =
            organization.loadingStatus || organization.organizationId === organizationId;
          return organization;
        }),
      };
    },
    [requestActivateOrganization]: (state, { payload: organizationId }) => {
      return {
        ...state,
        organizations: state.organizations.map(organization => {
          organization.loadingStatus =
            organization.loadingStatus || organization.organizationId === organizationId;
          return organization;
        }),
      };
    },

    [combineActions(
      rejectActivateOrganization,
      rejectDeactivateOrganization,
      rejectUpdateOrganization,
    )]: (state, { payload: { organizationId } }) => {
      return {
        ...state,
        organizations: state.organizations.map(organization => {
          organization.loadingStatus =
            organization.loadingStatus && organization.organizationId !== organizationId;
          return organization;
        }),
      };
    },
    [receiveOrganizationFSEs]: (state, action) => {
      const affectedOrganizationIndex = state.organizations.findIndex(
        organization => organization.organizationId === action.payload.organizationId,
      );
      const nextOrganizations = state.organizations.slice();
      nextOrganizations[affectedOrganizationIndex] = {
        ...state.organizations[affectedOrganizationIndex],
        associatedFSE: action.payload.associated_fse,
      };
      return {
        ...state,
        organizations: nextOrganizations,
        associatedFSEs: {
          ...state.associatedFSEs,
          [action.payload.organizationId]: action.payload.associated_fse,
        },
      };
    },
  },
  defaultState,
);

export default organizations;
