import { configurationsOtaLogsFetchError } from 'features/ota/configurations/helpers/notifications';
import { useGenericFetchQuery } from 'shared/hooks/useGenericFetchQuery';

import { getConfigurationsOtaLogs } from 'core/api/configuration';

export const useFetchConfigurationsOtaLogsQuery = initialQueryParams => {
  const { fetch, isLoading, data } = useGenericFetchQuery({
    apiFn: getConfigurationsOtaLogs,
    hookName: useFetchConfigurationsOtaLogsQuery.name,
    initialQueryParams,
    errorCallback: configurationsOtaLogsFetchError,
  });

  return {
    fetch,
    isLoading,
    data,
  };
};
