import CreateGroupModal from 'features/fleet/groups/components/modals/CreateGroupModal';
import React from 'react';
import { useState } from 'react';
import { ActionButtonText, ActionButtonWithIcon } from 'shared/styles/components/Button';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const CreateGroupButton = () => {
  const [createGroupModalData, setCreateGroupModalActive] = useState({ show: false });

  return (
    <>
      <ActionButtonWithIcon create onClick={() => setCreateGroupModalActive({ show: true })}>
        <IconSvgComponent svgFileName="plus-simple" alt="Create" />
        <ActionButtonText>Create Group</ActionButtonText>
      </ActionButtonWithIcon>
      <CreateGroupModal
        modalData={createGroupModalData}
        handleCloseModal={() => setCreateGroupModalActive({ show: false })}
      />
    </>
  );
};

export default CreateGroupButton;
