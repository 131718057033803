import { useEffect } from 'react';

import MapPerformanceModeButton from 'features/livemap/components/mapControls/MapPerformanceModeButton';
import useMapboxCustomControl from 'shared/hooks/useMapboxCustomControl';

const usePerformanceModeButton = ({
  mapboxApi,
  isMapboxLoaded,
  isPerformanceMode,
  handlePerformanceModeChange,
}) => {
  useEffect(() => {
    handlePerformanceModeChange(isPerformanceMode);
  }, [isPerformanceMode]);

  useMapboxCustomControl({
    renderComponent: (
      <MapPerformanceModeButton
        isPerformanceMode={isPerformanceMode}
        handlePerformanceModeChange={handlePerformanceModeChange}
      />
    ),
    triggerRerender: isPerformanceMode,
    mapboxApi,
    isMapboxLoaded,
    position: 'top-right',
  });
};

export default usePerformanceModeButton;
