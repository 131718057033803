export const getConfigTypeLabel = id => {
  switch (id) {
    case 13:
      return 'Core';
    case 51:
      return 'Core-SH';
    case 52:
      return 'Core-S 24V';
    case 38:
      return 'Core-R';
    case 43:
      return 'Core-S';
    default:
      return 'Not VSG Compatible';
  }
};
