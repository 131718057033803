/**
 * @ External Dependecies
 */
import styled from 'styled-components';

import colors from 'shared/constants/colors';

export const AddonActivityDiv = styled.div`
  margin-left: 30px;
  display: grid;
  grid-template-areas: 'FormCol  FormCol';
  grid-template-columns: 130px 1fr;
`;

export const AddonActivityCategoryToggleDiv = styled.div`
  cursor: pointer;
`;

export const AddonActivityContainerDiv = styled.div`
  margin-bottom: 20px;
  display: none;

  ${props =>
    props.isExpanded &&
    `
     display: block;
   `};
`;

export const AddonActivityCategoryHeaderDiv = styled.div`
  display: grid;
  grid-template-areas: 'FormCol FormCol FormCol';
  grid-template-columns: 20px max-content 200px;
  grid-gap: 10px;
  margin-bottom: 5px;
`;

export const AlertDiv = styled.div`
  display: none;

  ${props =>
    props.showAlert &&
    `
     display: block;
   `};
`;

export const VehicleName = styled.div`
  margin-top: 2px;
  font-weight: 800;
  font-size: 12px;
  overflow: hidden;
  white-space: nowrap;
  word-break: break-all;
  text-overflow: ellipsis;
  width: 130px;

  cursor: pointer;

  ${props =>
    props.vehicleAlert &&
    `
     color: ${colors.cherry};
   `};
`;
export const ActivityTime = styled.div`
  display: grid;
  justify-self: end;
  font-weight: 400;
  font-size: 11px;
  color: ${colors.haze};
  padding: 3px;
`;

export const LivemapAddonsCheckboxFilterWrapper = styled.div`
  margin-top: 3px;
  margin-left: 10px;
`;

export const AddonFiltersWrapperDiv = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  margin-bottom: 10px;
`;
export const AllAddonsModalContainerDiv = styled.div`
  height: 400px;
  margin-top: 20px;
`;

export const AllAddonsButtonWrapperDiv = styled.div`
  display: none;
  ${props =>
    props.isOpen &&
    `
     display: block;
     position: absolute;
     bottom: 0px;
     padding-left: 15px;
   `};
`;
