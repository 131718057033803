import { useEffect } from 'react';

export const useFlyToGeofence = (mapboxRef, selectedGeofenceData, unsavedChanges) => {
  const determineGeofenceBoundsCoordinates = () => {
    if (selectedGeofenceData) {
      const lats = selectedGeofenceData.points.map(p => p[1]);
      const minLat = Math.min(...lats);
      const maxLat = Math.max(...lats);
      const lngs = selectedGeofenceData.points.map(p => p[0]);
      const minLng = Math.min(...lngs);
      const maxLng = Math.max(...lngs);
      mapboxRef.fitBounds(
        [
          [minLng, minLat],
          [maxLng, maxLat],
        ],
        { padding: { top: 150, bottom: 150, left: 300, right: 50 }, maxZoom: 25 },
      );
      mapboxRef.sessionBounds = [
        [minLng, minLat],
        [maxLng, maxLat],
      ];
    }
  };

  useEffect(() => {
    if (!unsavedChanges) {
      determineGeofenceBoundsCoordinates();
    }
  }, [selectedGeofenceData]);
};
