import { useNavigate } from 'react-router-dom/dist';
import { useDispatch } from 'react-redux';
import { postRecipientGroupApi } from 'app/features/recipientGroups/api/post/postRecipientGroupApi';
import {
  recipientGroupCreateError,
  recipientGroupCreateSuccess,
} from 'app/features/recipientGroups/utilities/notifications';
import useGenericMutation from 'app/shared/hooks/reactQuery/useGenericMutation';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';

const useCreateRecipientGroupMutation = () => {
  const dispatch = useDispatch();

  const mutation = useGenericMutation({
    apiFn: postRecipientGroupApi,
    onSuccessCallback: () => {
      dispatch(recipientGroupCreateSuccess());
    },
    onErrorCallback: err => {
      dispatch(recipientGroupCreateError());
      logWcpError(err);
    },
  });

  return mutation;
};

export default useCreateRecipientGroupMutation;
