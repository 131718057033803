import React from 'react';

import colors from 'shared/constants/colors';
import { mfaTypes } from 'shared/constants/users';
import QRCode from 'qrcode.react';

const TwoFactorSubtitle = ({ mfaType, mfaDestination, authenticationAppSecretCode, username }) => {
  if (mfaType === mfaTypes.SMS) {
    return (
      <div style={{ marginBottom: '25px', marginLeft: 20 }}>
        {`A text message with your code has been sent ` +
          (mfaDestination ? `to ${mfaDestination}` : `to your phone`)}
      </div>
    );
  } else if (mfaType === mfaTypes.AUTHENTICATION_APP) {
    return authenticationAppSecretCode && authenticationAppSecretCode !== null ? (
      <>
        <div>
          {`Please install an authenticator app and scan the QR code. We recommend Google Authenticator:`}
          <div style={{ margin: '25px' }}>
            <QRCode
              value={`otpauth://totp/${encodeURI(
                username,
              )}?secret=${authenticationAppSecretCode}&issuer=${encodeURI(
                'Whelen Cloud Platform®',
              )}`}
            />
          </div>
          {`If you are unable to scan the QR Code, please enter this code manually into your app:`}
          <div
            style={{
              border: '1px solid black',
              borderRadius: '10px',
              backgroundColor: colors.cloud,
              padding: '10px',
              margin: '25px',
              wordWrap: 'break-word',
            }}
          >
            {authenticationAppSecretCode}
          </div>
        </div>
        <div>{`Then, enter the code that your Authenticator App generated below:`}</div> <br />
      </>
    ) : (
      <div>{`Please enter the code that your Authenticator App generated below:`}</div>
    );
  } else {
    return <></>;
  }
};
export default TwoFactorSubtitle;
