export const granularityOptions = [
  {
    label: 'Normal',
    value: 5,
  },
  {
    label: 'Fine',
    value: 1,
  },
];
