import styled from 'styled-components';
import React from 'react';
import { debugLogHelper } from 'tools/debugging/debugTools';

const StyledDetails = styled.details`
  font-weight: bold;

  summary {
    padding: 4px;
    width: 200px;
    background-color: #eeeeee;
    border: none;
    box-shadow: 1px 1px 2px #bbbbbb;
    cursor: pointer;
  }

  p {
    background-color: #eeeeee;
    padding: 4px;
    margin: 0;
    box-shadow: 1px 1px 2px #bbbbbb;
  }
`;
const VisualDebug = props => {
  return Object.entries(props)?.map(([label, value], index) => {
    return (
      <StyledDetails key={index}>
        <summary>{label}</summary>
        <p>
          <pre>{JSON.stringify(value, null, label === 'depth' ? value : 2)}</pre>
        </p>
      </StyledDetails>
    );
  });
};

export default VisualDebug;
