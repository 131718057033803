import React from 'react';

import DeleteContent from './DeleteContent';

export default ({
  organizationId,
  confirmPrompt,
  confirmText,
  cancelText,
  confirmHandler,
  cancelHandler,
}) => {
  return (
    <DeleteContent
      resourceId={organizationId}
      confirmPrompt={confirmPrompt}
      confirmText={confirmText}
      cancelText={cancelText}
      confirmHandler={confirmHandler}
      cancelHandler={cancelHandler}
    />
  );
};
