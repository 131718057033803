import { AddVehiclesToGroupModal } from 'features/fleet/groups/components/modals/AddVehiclesToGroupModal';
import { useEffect } from 'react';
import { useState } from 'react';
import { ActionButtonText, ActionButtonWithIcon } from 'shared/styles/components/Button';
import { PageListActionButtonContainer } from 'shared/styles/components/PageList';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const AddVehiclesButton = ({ groupId, groupVehicles }) => {
  const [addVehiclesToGroupModalData, setAddVehiclesToGroupModalData] = useState({
    show: false,
    groupId,
    groupVehicles,
  });

  useEffect(() => {
    setAddVehiclesToGroupModalData(prevState => ({ ...prevState, groupVehicles }));
  }, [groupVehicles]);

  return (
    <>
      <PageListActionButtonContainer>
        <ActionButtonWithIcon
          groupDetail
          onClick={() =>
            setAddVehiclesToGroupModalData(prevState => ({ ...prevState, show: true }))
          }
        >
          <ActionButtonText>Add Vehicles</ActionButtonText>
          <IconSvgComponent
            svgFileName={'plus-simple'}
            alt="Add Vehicles"
            style={styles.buttonPlusIcon}
          />
        </ActionButtonWithIcon>
      </PageListActionButtonContainer>

      <AddVehiclesToGroupModal
        modalData={addVehiclesToGroupModalData}
        handleCloseModal={() =>
          setAddVehiclesToGroupModalData(prevState => ({ ...prevState, show: false }))
        }
      />
    </>
  );
};

export default AddVehiclesButton;

const styles = {
  buttonPlusIcon: {
    position: 'absolute',
    right: 15,
    top: '50%',
    transform: 'translateY(-50%)',
  },
};
