import _ from 'lodash';

export const updateMaintenanceItemReducer = (state, payload) => {
  // pull out maintenanceItems from state
  const { maintenanceItems } = state;
  const { maintenanceItem } = payload;

  // find the index of the maintenance item to update
  const index = maintenanceItems?.findIndex(item => item.id === maintenanceItem.id);

  // can't update an item that doesn't exist
  if (index === -1) {
    return state;
  }
  // set isDirty to true if the maintenance item is different from the one in state
  let isDirty = !_.isEqual(maintenanceItem, maintenanceItems[index]);

  // set the maintenance item at the index to the updated maintenance item
  maintenanceItems[index] = maintenanceItem;

  //filter out undefineds
  let selectedIntervalTypes = maintenanceItems.map(i => i.intervalType?.value).filter(i => i);

  return { ...state, isDirty, maintenanceItems, selectedIntervalTypes };
};
