/**
 * @ External Dependecies
 */
import styled, { css } from 'styled-components';

import colors from 'shared/constants/colors';
import device from 'shared/constants/sizing';
import { SectionBody } from 'shared/styles/components/Section';

const PageListWrapper = styled.div`
  background-color: ${colors.fog};
  height: 100%;
  flex: 1 1 100%;
  padding: 40px 10px 260px 10px;
  @media ${device.large} {
    padding: 48px 83px 260px 83px;
  }
  .head-wrapper {
    display: block;
    .lastseen {
      color: ${colors.haze};
    }
  }
  ${props =>
    props.centered &&
    props.centered === true &&
    css`
      display: flex;
      flex-direction: column;

      ${SectionBody} {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }

      .svg-icon {
        margin-bottom: 15px;
      }
    `}
`;

export const PageListHead = styled.div`
  margin-bottom: 57px;
  display: flex;
  justify-content: space-between;

  ${props =>
    props.logs &&
    `
    margin-bottom: 25px;
  `};

  ${props =>
    props.otaByVehicleDetail &&
    `
    margin-bottom: 10px;
  `};
  ${props =>
    props.stackable &&
    `
  flex-direction: column;

  @media ${device.medium} {
    flex-direction: unset;
  }
  `};
  ${props =>
    props.otaSummary &&
    `
    margin-bottom: 10px;
  `};
  ${props =>
    props.noBottomMargin &&
    `
    margin-bottom: 0px;
  `};
`;

export const PageListTitle = styled.h4`
  display: inline-block;
`;

export const PageListActionButtonContainer = styled.div`
  display: block;
  @media ${device.medium} {
    display: flex;
    align-items: center;
  }
`;

export const LockButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  padding: 0px 20px 10px 40px;
  @media ${device.medium} {
    padding: 0px;
  }
`;

export const SearchBox = styled.div`
  display: flex;
  width: 230px;

  ${props =>
    props.smallSearch &&
    `
    width:155px;
  `};
  .searchButton {
    background: black;
    height: 25px;
    border: none;
  }
  .search {
    display: block;
    width: 100%;
    height: 25px;
    padding: 5px 10px;
    background: white;
    border-radius: 2px;
    border-left: 1px solid lightgrey;
    border-right: none;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    font-size: 11px;
    font-weight: 600;
  }

  .search-icon {
    padding: 5px 10px;
    stroke: white;
  }

  .Search-box-button {
    display: block;
    height: 25px;
    background: white;
    border-left: none;
    border-right: none;
    border-top: 1px solid lightgrey;
    border-bottom: 1px solid lightgrey;
    width: 35px;
    font-size: 11px;
    font-weight: 600;
  }
`;

export const PaginationStyles = styled.div`
  .pagination li {
    display: inline-block;
    padding: 5px;
  }
  .pagination li:not(.active, .next-page, .previous):hover {
    background-color: ${colors.charcoal};
  }
  .pagination li:not(.active, .next-page, .previous):hover a {
    color: ${colors.cotton};
  }

  .pagination .active a {
    color: ${colors.midnight};
    text-decoration: none;
  }

  .pagination li :not(.active, .next-page, .previous) a {
    color: ${colors.haze};
    text-decoration: none;
  }

  .pagination .next-page a {
    text-decoration: none;
  }
  .pagination .previous a {
    text-decoration: none;
  }
`;
export const TableFilters = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0px 20px 0px;
  .pagination {
    display: flex;
    justify-content: flex-end;
    color: rgb(28, 28, 28);
    line-height: 1.2;
    font-size: 14px;
  }
  .page-size-dd {
    margin-top: 5px;
    margin-right: 10px;
    display: flex;
  }
  .pagination li.disabled {
    cursor: default;
    color: rgb(138, 144, 156);
  }
  .pagination li.disabled a {
    text-decoration: none;
    cursor: default;
  }
  .show-label {
    margin-right: 10px;
    color: ${colors.haze};
  }
`;

export const TableCheckboxFilterWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 1px;

  @media ${device.large} {
    margin-top: 20px;
    margin-bottom: -38px;
    margin-left: 250px;
  }
  ${props =>
    props.maintenance &&
    `
    width:auto;
    margin-right:0;
    @media ${device.large} {
      min-width:400px;
      margin-right:300px;
  }

  `};
`;

export const TableCheckboxFilterSpan = styled.span`
  margin-left: 5px;
  font-size: 20px;
  @media ${device.large} {
    font-size: 16px;
  }
`;

export const ExportDiv = styled.div`
  float: right;
`;

export const ExportWrapper = styled.div`
  display: flow-root;
  margin-bottom: -20px;
  margin-right: -20px;
`;

export const IconSpacerSpan = styled.span`
  margin-left: 5px;
`;
export const DateWrapper = styled.div`
  margin-top: 40px;
`;

export default PageListWrapper;
