import { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { useIsFetching } from '@tanstack/react-query';
import ManageAlertsLogsTable from 'app/features/alerts/ui/components/AlertLogsSummary/tables/ManageAlertsLogsTable';
import ListingCount from 'shared/components/ListingCount';
import MediaWrapper from 'shared/components/MediaWrapper';
import { ExportDiv, ExportWrapper, DateWrapper } from 'shared/styles/components/PageList';
import ManageAlertsLogCardContainer from 'app/features/alerts/ui/components/AlertLogsSummary/mobile/ManageAlertsLogCardContainer';
import DateRangeSubmitForm from 'shared/components/DateRangeSubmitForm';
import { formatISOTimestampToShortDate, getAMonthAgo, getNow } from 'shared/utilities/time';
import { validateLogsRange } from 'shared/utilities/validators';
import AlertLogsExportButton from 'app/features/alerts/ui/components/AlertLogsSummary/buttons/AlertLogsExportButton';
import { mockAlertLogs } from 'app/features/alerts/data/mock/ManageAlertsLogsMockData';
import useFetchAlertLogsQuery from 'app/features/alerts/services/reactQuery/queries/useFetchAlertLogsQuery';

const AlertLogsSummary = ({ handleSetAlertName }) => {
  // temp use of mock logs until we have real logs
  //const alertLogs = mockAlertLogs;

  //will use this in near future
  const [alertLogs, setAlertLogs] = useState([]);
  const [alertLogsCount, setAlertLogsCount] = useState(0);
  const [listingCount, setListingCount] = useState(alertLogsCount ? alertLogsCount : 0);
  const { alertId } = useParams();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [alertLogsData, refetch, alertLogsQueryKey] = useFetchAlertLogsQuery({
    startDate,
    endDate,
    alertId,
  });

  const [serverError, setServerError] = useState(false);

  const defaultStartDate = formatISOTimestampToShortDate(getAMonthAgo());
  const defaultEndDate = formatISOTimestampToShortDate(getNow());

  const isFetching = useIsFetching(alertLogsQueryKey);

  useEffect(() => {
    if (alertLogsData?.length > 0) {
      setAlertLogs(alertLogsData);
      setAlertLogsCount(alertLogsData.length);
      setListingCount(alertLogsData.length);
      handleSetAlertName(alertLogsData[0].alertName);
    }
  }, [alertLogsData]);

  useEffect(() => {
    if (startDate && endDate && alertId) {
      refetch();
    }
  }, [startDate, endDate]);

  return (
    <>
      <DateWrapper>
        <DateRangeSubmitForm
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          isLoading={isFetching}
          submitHandler={({ startDate, endDate }) => {
            setStartDate(startDate);
            setEndDate(endDate);
          }}
          validationFunction={validateLogsRange}
          confirmText="Generate"
        />
      </DateWrapper>
      {serverError && <NoDataDiv>Server error: No message was returned from the server</NoDataDiv>}
      {alertLogsCount > 0 && (
        <ExportWrapper>
          <ExportDiv>
            <AlertLogsExportButton alertLogs={alertLogs} />
          </ExportDiv>
        </ExportWrapper>
      )}
      {alertLogs && (
        <>
          <ListingCount
            listingCount={listingCount}
            totalCount={alertLogsCount}
            itemTypeName={'Log'}
          />

          <MediaWrapper
            mobileComponent={<ManageAlertsLogCardContainer alertLogs={alertLogs} />}
            nonMobileComponent={
              <ManageAlertsLogsTable
                alertLogs={alertLogs}
                handleUpdateListingCount={setListingCount}
              />
            }
          />
        </>
      )}
    </>
  );
};

export default AlertLogsSummary;
