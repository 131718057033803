import RecipientRowsListContainer from 'app/features/alerts/ui/components/AlertNotificationBuilder/containers/RecipientRowsListContainer';
import IncludeLocationCheckbox from 'app/features/alerts/ui/components/AlertForms/shared/checkboxes/IncludeLocationCheckbox';
import CooldownPeriodDropdown from 'app/features/alerts/ui/components/AlertForms/shared/dropdowns/CooldownPeriodDropdown';
import DelayDropdown from 'app/features/alerts/ui/components/AlertForms/shared/dropdowns/DelayDropdown';
import {
  DelayCooldownWrapper,
  NotificationHeader,
  RecipientHeaderWrapper,
} from 'shared/styles/components/ManageAlerts';

const PrimaryNotificationContainer = ({ hasRecipients }) => {
  return (
    <>
      <RecipientHeaderWrapper>
        {hasRecipients && <NotificationHeader>Primary Notification</NotificationHeader>}
      </RecipientHeaderWrapper>
      <RecipientRowsListContainer notificationIndex={0} />
      <DelayCooldownWrapper>
        <DelayDropdown notificationIndex={0} />
        <CooldownPeriodDropdown notificationIndex={0} />
      </DelayCooldownWrapper>
      <IncludeLocationCheckbox notificationIndex={0} />
    </>
  );
};
export default PrimaryNotificationContainer;
