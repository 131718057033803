import { useEffect } from 'react';
import { useState } from 'react';

import { FormAsterisk, FormLabelAnimated } from 'shared/styles/components/Form';
import Select from 'shared/styles/components/SelectField';

const SelectField = props => {
  const [isFocused, setIsFocused] = useState(props.defaultValue ? true : false);

  const handleInputChange = e => {
    if (typeof props.onChange === 'function') {
      props.onChange(e);
    }

    handleFieldBlur(e);
  };

  const handleFieldBlur = e => {
    typeof props.handleBlur === 'function' && props.handleBlur(e);
    if (e.value === props.placeholder) {
      setIsFocused(false);
    } else {
      setIsFocused(true);
    }
  };

  return (
    <>
      <FormLabelAnimated
        animated={isFocused || props.forceAnimated}
        offset={props.labelOffset}
        htmlFor={props.id}
        prefixIcon={props.prefixIcon}
        suffixIcon={props.suffixIcon}
      >
        {props.required ? (
          <span>
            {`${props.placeholder}`} <FormAsterisk>*</FormAsterisk>
          </span>
        ) : (
          props.placeholder
        )}
      </FormLabelAnimated>

      <Select
        {...props}
        defaultValue={{ label: props.defaultValue }}
        isDisabled={props.disabled}
        onChange={handleInputChange}
        placeholder={''}
      />
    </>
  );
};

export default SelectField;
