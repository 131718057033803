import { useQueryClient } from '@tanstack/react-query';

import { deleteAlertApi } from 'app/features/alerts/services/api/delete/deleteAlertApi';
import useAlertsSummaryProviderContext from 'app/features/alerts/services/providers/AlertsSummaryProvider/hooks/useAlertsSummaryProviderContext';
import useGenericMutation from 'app/shared/hooks/reactQuery/useGenericMutation';
import useDispatchErrorNotification, {
  dispatchErrorNotification,
} from 'app/shared/hooks/notifications/useDispatchErrorNotification';
import useDispatchSuccessNotification, {
  dispatchSuccessNotification,
} from 'app/shared/hooks/notifications/useDispatchSuccessNotification';

export const useDeleteAlertMutation = () => {
  const queryClient = useQueryClient();
  const {
    queryKeys: { alertsQueryKey },
  } = useAlertsSummaryProviderContext();

  const [successToast] = useDispatchSuccessNotification();
  const [errorToast] = useDispatchErrorNotification();

  const mutation = useGenericMutation({
    apiFn: deleteAlertApi,
    onSuccessCallback: () => {
      queryClient.invalidateQueries(alertsQueryKey);
      successToast({ message: 'Successfully Deleted Alert' });
    },

    onErrorCallback: err => {
      errorToast({ message: 'Error Deleting Alert' });
    },
  });

  return mutation;
};
