import styled from 'styled-components';
import { Link } from 'react-router-dom';
import colors from 'shared/constants/colors';
import device from 'shared/constants/sizing';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;

export const StyledInput = styled.input`
  display: block;
  width: 100%;
  margin-bottom: 10px;
`;
export const StyledFormWrapper = styled.div`
  padding: 25px;
  max-width: 400px;
  height: 100%;
  margin: 30px auto;
  background-color: ${colors.cotton};

  @media ${device.large} {
    max-width: 471px;
    margin: 87px auto;
    padding: 25px 49px;
    box-shadow: 0 0 40px rgba(0, 0, 0, 0.09);
    background-color: ${colors.cotton};
  }
`;
export const LoginWrapper = styled.div`
  flex: 1 1 100%;

  @media ${device.large} {
    background: ${colors.fog};
  }
`;
export const StyledHead = styled.div`
  text-align: center;
  margin-bottom: 72px;
`;
export const StyledBody = styled.div`
  margin-bottom: 40px;
`;
export const StyledTitle = styled.h4`
  text-align: center;
  color: ${colors.midnight};
  margin: 50px 0px;

  @media ${device.large} {
    margin: 20px auto 40px auto;
  }
`;
export const StyledSubtitle = styled.h5`
  text-align: center;
  font-size: 12px;
  color: ${colors.midnight};
  margin: 50px 0px;

  @media ${device.large} {
    margin: 20px auto 40px auto;
  }
`;
export const StyledIconWrapper = styled.div`
  margin: 16px auto;
  text-align: center;
`;
export const StyledFormRow = styled.div`
  margin-bottom: 53px;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const StyledFoot = styled.div`
  text-align: center;
`;
export const StyledEntry = styled.div`
  margin-bottom: 11px;

  &:last-child {
    margin-bottom: 0;
  }
`;
export const StyledText = styled.p`
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 0.023em;
  margin-top: 15px;
  color: ${colors.midnight};
`;
export const StyledLink = styled(Link)`
  text-decoration: none;
  color: ${colors.midnight};
`;

export const StyledExternalLink = styled.a`
  text-decoration: none;
  color: ${colors.midnight};
`;

export const PreLogoText = styled.p`
  font-size: 8px;
  font-weight: 300;
  margin-top: 50px;
  margin-bottom: -2px;
  color: ${colors.midnight};
`;

export const WhelenLogo = styled.img`
  width: 130px;

  @media ${device.large} {
    width: 100px;
  }
`;

export const WCPLogo = styled.img`
  width: 250px;

  @media ${device.large} {
    width: 150px;
  }
`;

export const LoginMessage = styled.div`
  font-weight: 300;
  color: #745d23;
  font-size: 12px;
  padding: 10px 25px;
  margin-top: -30px;
  margin-bottom: 30px;
  background-color: #fbecc3;
`;
