import React from 'react';
import { connect } from 'react-redux';
import { Formik } from 'formik';
import { getConfigTypeLabel } from 'app/features/configurations/utilities/configUtilities';

import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import Button from 'shared/styles/components/Button';
import FormWrapper, {
  Form,
  FormHead,
  FormIcon,
  FormTitle,
  FormRow,
  FormMessage,
  FieldError,
} from 'shared/styles/components/Form';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import SelectField from 'shared/ui/fields/SelectField';
import { ModalText } from 'shared/styles/components/Modal';
import { fetchConfigurations } from 'core/redux/configurations/actions';
import useAPIData from 'shared/hooks/useAPIData';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { TruncatedText } from 'shared/styles/components/TruncatedText';
import { handleSelectChange, handleSelectBlur } from 'shared/utilities/form';
import { validateSelectedConfiguration } from 'shared/utilities/validators';
import { sortAscendingAlphaOptions } from 'shared/utilities/vehicle';
import { getGuardedObjectProp } from 'shared/utilities/general';
import { useEffect } from 'react';

const AssignConfigurationToGroupContent = ({
  formikProps,

  vehicles,
  group,

  handleCloseModal,
  setDisabled,
  fetchVehicles,
  modalFormMessage,
  fetchConfigurations,
  filteredOrganizationId,
  configurations,
}) => {
  const configurationsLoaded = useAPIData({
    fetchAPIData: fetchConfigurations,
    dependencies: [filteredOrganizationId],
  });

  const groupVehicles = Object.values(vehicles).filter(v =>
    group?.vehicle_id.includes(v.vehicle_id),
  );
  let totalIncompatibleVehicles = [];
  useEffect(() => {
    console.log(totalIncompatibleVehicles.length === groupVehicles.length);
    setDisabled(totalIncompatibleVehicles.length === groupVehicles.length);
  }, [totalIncompatibleVehicles, groupVehicles]);

  const renderInfoMessage = values => {
    let selectedConfig = configurations.find(c => c.configId === values.selectedConfiguration);
    if (!selectedConfig) return '';

    let vehiclesWithControlSystem = groupVehicles.filter(v =>
      getGuardedObjectProp('device.CenComCore.product_id', v),
    );

    let vehiclesWithoutControlSystem = groupVehicles.filter(
      v => !getGuardedObjectProp('device.CenComCore.product_id', v),
    );

    let inCompatibleVehicles = [];

    if (selectedConfig?.acmProductId) {
      if (selectedConfig.acmProductId === 13) {
        // core
        inCompatibleVehicles = vehiclesWithControlSystem.filter(
          v => v.device?.CenComCore?.product_id !== 13,
        );
      } else if (selectedConfig.acmProductId === 38) {
        // core-r
        inCompatibleVehicles = vehiclesWithControlSystem.filter(
          v => v.device?.CenComCore?.product_id !== 38,
        );
      } else if (selectedConfig.acmProductId === 51) {
        // core-sh
        inCompatibleVehicles = vehiclesWithControlSystem.filter(
          v => v.device?.CenComCore?.product_id !== 51,
        );
      } else if (selectedConfig.acmProductId === 52) {
        // core-s 24v
        inCompatibleVehicles = vehiclesWithControlSystem.filter(
          v => v.device?.CenComCore?.product_id !== 52,
        );
      } else if (selectedConfig.acmProductId === 43) {
        // core-s
        inCompatibleVehicles = vehiclesWithControlSystem.filter(
          v => v.device?.CenComCore?.product_id !== 43,
        );
      }
    }

    totalIncompatibleVehicles = [...vehiclesWithoutControlSystem, ...inCompatibleVehicles];
    return (
      <div title={totalIncompatibleVehicles.map(v => v.meta.label).join(', ')}>
        <span style={{ fontWeight: 'bold' }}>ⓘ </span>
        {totalIncompatibleVehicles.length}
        <span> of </span> {groupVehicles.length} <span>vehicles will</span>{' '}
        <span style={{ fontWeight: 'bold' }}>NOT</span>{' '}
        <span>
          receive this configuration because either the control system is not compatible with the
          selected configuration, or the control system is not recognized by the VSG.
        </span>
      </div>
    );
  };
  const filteredOptions =
    configurations.length > 0
      ? configurations
          .filter(configuration => configuration.archived !== 'true')
          .filter(configuration => configuration.acmProductId)
          .map(configuration => ({
            value: configuration.configId,
            label: `${configuration.configName} (${getConfigTypeLabel(
              configuration.acmProductId,
            )})`,
          }))
          .sort(sortAscendingAlphaOptions)
      : [];
  return configurationsLoaded ? (
    <ModalText>
      <FormRow>
        <SelectField
          name="licenseState"
          placeholder={'Configuration'}
          options={filteredOptions}
          onChange={handleSelectChange(formikProps.setFieldValue, 'selectedConfiguration')}
          onBlur={handleSelectBlur(
            formikProps.setTouched,
            'selectedConfiguration',
            formikProps.touched,
          )}
          validationError={formikProps.errors.selectedConfiguration}
          isClearable={false}
          isSearchable={false}
          field="true"
          required
        />
      </FormRow>
      <FieldError>
        {formikProps.touched.selectedConfiguration && formikProps.errors.selectedConfiguration}
      </FieldError>
      Assigning the above configuration will schedule an OTA update for each vehicle in the
      following group:
      <TruncatedText style={styles.groupNameText}>{group.group_name}</TruncatedText>{' '}
      <div>{renderInfoMessage(formikProps.values)}</div>
    </ModalText>
  ) : (
    <LoadingOverlay />
  );
};

export default connect(
  state => ({
    modalFormMessage: state.ui.modalFormMessage,
    configurations: state.configurations.configurations,
    filteredOrganizationId: state.user.filteredOrganizationId,
  }),
  dispatch => ({
    fetchConfigurations: () => dispatch(fetchConfigurations()),
  }),
  null,
)(AssignConfigurationToGroupContent);

const styles = {
  formHead: {
    marginBottom: 42,
  },
  actionContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    marginTop: 10,
  },
  groupNameText: {
    fontWeight: 'bold',
    marginTop: 10,
    marginBottom: 30,
  },
};
