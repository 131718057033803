import { useEffect, useState } from 'react';

import FirmwareUpdateSummaryItem from 'features/ota/firmware/components/byDevice/FirmwareUpdateSummaryItem';
import useFetchFirmwareQuery from 'features/ota/firmware/hooks/useFetchFirmwareQuery';
import useSearchText from 'features/ota/firmware/hooks/useSearchText';
import { mapAndSortFirmwareUpdates } from 'features/ota/firmware/utilities/mapAndSortFirmwareUpdates';
import ExpandCollapseAll from 'features/ota/shared/components/ExpandCollapseAll';
import Searchbox from 'shared/components/Searchbox';
import { firmwareStatuses } from 'shared/constants/firmwares';
import LinkButton from 'shared/styles/components/LinkButton';
import {
  Actions,
  IconAndTitleWrapper,
  SearchAndCollapseAllWrapper,
  SeachAndFilterWrapper,
  OnlyShowPendingUpdatesCheckboxWrapper,
} from 'shared/styles/components/OtaUpdates';
import { SectionBody, SectionStatus, SectionToggle } from 'shared/styles/components/Section';
import CollapsibleHeader from 'shared/ui/accordions/CollapsibleHeader';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { formatISOTimestampToShortDate } from 'shared/utilities/time';
import OnlyShowPendingUpdatesCheckbox from 'features/ota/firmware/components/controls/OnlyShowPendingUpdatesCheckbox';
import {
  getOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal,
  setOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal,
} from 'shared/utilities/localStore';

export const determineAllUpdatesAreComplete = updatesByVersion => {
  let complete = Object.entries(updatesByVersion).map(([version, updates]) => {
    let completed = updates.filter(
      u => u.firmwareStatus === firmwareStatuses.UP_TO_DATE.status && u.versionCurrent === version,
    );

    return completed.length === updates.length;
  });

  return complete.every(p => p);
};
const FirmwareUpdatesSummaryByDevice = () => {
  const [fetchFirmwareUpdatesQuery, firmwareUpdates] = useFetchFirmwareQuery();
  let onlyShowPendingUpdatesPreference = getOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal();

  const [productCollection, setProducts] = useState([]);

  const [allExpanded, setAllExpanded] = useState(true);
  const [filteredDeviceCount, setFilteredDeviceCount] = useState(0);
  const [collapsedIndividualItems, setCollapsedIndividualItems] = useState(0);
  const [onlyShowPendingUpdates, setOnlyShowPendingUpdates] = useState(
    onlyShowPendingUpdatesPreference ? onlyShowPendingUpdatesPreference : false,
  );

  const { searchText, setSearchText } = useSearchText();

  useEffect(() => {
    if (filteredDeviceCount === collapsedIndividualItems && filteredDeviceCount !== 0) {
      setAllExpanded(false);
    } else if (collapsedIndividualItems === 0) {
      setAllExpanded(true);
    }
  }, [collapsedIndividualItems]);
  useEffect(() => {
    if (!firmwareUpdates) return;
    // map firmware updates
    let mappedUpdates = mapAndSortFirmwareUpdates(
      onlyShowPendingUpdates
        ? firmwareUpdates?.filter(
            u => u.firmware_status !== 'UP_TO_DATE' && u.firmware_status !== 'EXTERNAL_UPDATE',
          )
        : firmwareUpdates,
    );

    if (!_.isEmpty(mappedUpdates)) {
      // group updates by product and version
      let updatesByProductNameAndVersion = mappedUpdates.reduce((obj, u) => {
        // create key from productName and productId
        let key = `${u.productName}/${u.productId}`;

        obj[key] = obj[key] || {};
        obj[key][u.version] = obj[key][u.version] || [];
        obj[key][u.version].push(u);

        return obj;
      }, {});

      setProducts(updatesByProductNameAndVersion);
    }
  }, [firmwareUpdates, onlyShowPendingUpdates]);

  useEffect(() => {
    if (productCollection) {
      setFilteredDeviceCount(Object.entries(productCollection).length);
    }
  }, [productCollection]);

  return fetchFirmwareUpdatesQuery.isFetchedAfterMount ? (
    <SectionBody>
      <h5>{`Total of ${filteredDeviceCount} Device Type${
        filteredDeviceCount !== 1 ? 's' : ''
      }`}</h5>
      <SearchAndCollapseAllWrapper>
        <SeachAndFilterWrapper>
          <Searchbox searchText={searchText} setSearchText={setSearchText} />
          <OnlyShowPendingUpdatesCheckboxWrapper>
            <OnlyShowPendingUpdatesCheckbox
              checked={onlyShowPendingUpdates}
              handleChecked={() => {
                setOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal(!onlyShowPendingUpdates),
                  setOnlyShowPendingUpdates(!onlyShowPendingUpdates);
              }}
            />
          </OnlyShowPendingUpdatesCheckboxWrapper>
        </SeachAndFilterWrapper>
        <ExpandCollapseAll
          allExpanded={allExpanded}
          setAllExpanded={setAllExpanded}
          setCollapsedIndividualItems={setCollapsedIndividualItems}
          totalListingCount={filteredDeviceCount}
        >
          COLLAPSE ALL PLACEHOLDER
        </ExpandCollapseAll>
      </SearchAndCollapseAllWrapper>

      <div>
        {!_.isEmpty(productCollection) ? (
          Object.entries(productCollection).map(([productKey, updatesByVersion]) => {
            const [productName, productId] = productKey.split('/');

            // check if any properties for any udpates contain search text and filter accordingly
            // if no search text, show all
            const filteredUpdates = Object.values(updatesByVersion).filter(update => {
              const { releaseNotes, releaseDate, channel, version, label } = update[0];

              return (
                label?.toLowerCase().includes(searchText.toLowerCase()) ||
                productName?.toLowerCase().includes(searchText.toLowerCase()) ||
                releaseNotes?.toLowerCase().includes(searchText.toLowerCase()) ||
                channel?.toLowerCase().includes(searchText.toLowerCase()) ||
                version?.toLowerCase().includes(searchText.toLowerCase()) ||
                formatISOTimestampToShortDate(releaseDate)?.includes(searchText.toLowerCase())
              );
            });

            // assign back to updatesForProductByVersion
            updatesByVersion = Object.fromEntries(
              Object.entries(updatesByVersion).filter(([versionNumber, updates]) => {
                return filteredUpdates.includes(updates);
              }),
            );

            return (
              Object.keys(updatesByVersion).length > 0 && (
                <div key={productId}>
                  <CollapsibleHeader
                    initialCollapsedState={!allExpanded}
                    ToggleComponent={({ collapsed, setCollapsed }) => (
                      <SectionStatus
                        onClick={() => {
                          setCollapsed(!collapsed);
                          collapsed
                            ? setCollapsedIndividualItems(collapsedIndividualItems - 1)
                            : setCollapsedIndividualItems(collapsedIndividualItems + 1);
                        }}
                      >
                        <IconAndTitleWrapper>
                          {determineAllUpdatesAreComplete(updatesByVersion) && collapsed && (
                            <IconSvgComponent
                              svgFileName={'check-success'}
                              title="All updates for this device have completed"
                            />
                          )}
                          {productName} Updates
                        </IconAndTitleWrapper>

                        <SectionToggle collapsed={collapsed} />
                      </SectionStatus>
                    )}
                  >
                    {Object.entries(updatesByVersion).map(([v, updates]) => {
                      const { releaseDate, releaseNotes, channel } = updates[0];
                      return (
                        <FirmwareUpdateSummaryItem
                          key={productName + v}
                          productName={productName}
                          deviceUpdates={updates}
                          releaseDate={releaseDate}
                          releaseNotes={releaseNotes}
                          channel={channel}
                          version={v}
                          showTotals={true}
                        >
                          <Actions className="col">
                            <LinkButton to={`/updates/${productId}/${channel}/${v}`}>
                              View Details
                            </LinkButton>
                          </Actions>
                        </FirmwareUpdateSummaryItem>
                      );
                    })}
                  </CollapsibleHeader>
                </div>
              )
            );
          })
        ) : (
          <>
            <h4>No Updates Available</h4>
          </>
        )}
      </div>
    </SectionBody>
  ) : (
    <LoadingOverlay />
  );
};

export default FirmwareUpdatesSummaryByDevice;
