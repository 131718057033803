import { mapMaintenanceDataToCsv } from 'app/features/maintenance/utilities/mapping';
import ExportCsvButton from 'shared/widgets/ExportCsvButton';

const MaintenanceOverviewExportButton = ({ maintenanceEvents }) => {
  const exportData = mapMaintenanceDataToCsv({ maintenanceEvents });
  return (
    <ExportCsvButton exportData={exportData} fileNamePrefix={'wcp_scheduled_maintenance_export'} />
  );
};

export default MaintenanceOverviewExportButton;
