import useVehiclePlaybackProviderContext from 'app/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import { hourOptions } from 'shared/constants/hourOptions';
import { FormLabelAnimated } from 'shared/styles/components/Form';
import StyledReactSelect from 'shared/styles/components/SelectField';
import { HourDiv } from 'shared/styles/components/VehiclePlayback';
import { useFormikContext } from 'formik';
const VehiclePlaybackFormStartTimeDropdown = () => {
  const {
    actions: { setPlaybackRequestFormAction },
    state: {
      playbackRequestForm: { readOnly, startTime },
    },
  } = useVehiclePlaybackProviderContext();

  return (
    <>
      <FormLabelAnimated animated>Time</FormLabelAnimated>
      <HourDiv>
        <StyledReactSelect
          mediumTextMobile
          name="startTimeField"
          placeholder={readOnly ? 'N/A' : 'Time'}
          defaultValue={{
            label: hourOptions.find(h => h.value === startTime)?.label,
          }}
          onChange={e => setPlaybackRequestFormAction({ startTime: e.value })}
          isDisabled={readOnly}
          isClearable={false}
          isSearchable={false}
          options={hourOptions}
        />
      </HourDiv>
    </>
  );
};

export default VehiclePlaybackFormStartTimeDropdown;
