import ManageRecipientGroupsActionMenu from 'app/features/recipientGroups/components/ManageRecipientGroupsSummary/menus/ManageRecipientGroupsActionMenu';
import { Cards } from 'shared/styles/components/MobileCard';
import RecipientGroupSummaryCard from 'app/features/recipientGroups/components/ManageRecipientGroupsSummary/mobile/RecipientGroupSummaryCard';

const ManageRecipientGroupsSummaryCardContainer = ({ recipientGroups }) => {
  const getActionMenu = recipientGroup => {
    return <ManageRecipientGroupsActionMenu recipientGroup={recipientGroup} />;
  };
  return (
    <div>
      <Cards>
        {recipientGroups.map(r => (
          <RecipientGroupSummaryCard
            recipientGroup={r}
            key={r.id}
            actionMenuComponent={() => getActionMenu(r)}
          />
        ))}
      </Cards>
    </div>
  );
};

export default ManageRecipientGroupsSummaryCardContainer;
