import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const useWcpLogger = () => {
  const { user, filteredOrganizationId } = useSelector(state => state.user);

  useEffect(() => {
    console.log('Refreshing WCP Logger...');

    const datadogUserInfo = {
      id: user.username,
      //name: `${user.givenName} ${user.familyName}`,
      //userTitle: user.userTitle,
      //email: user.email,
      homeOrg: user.orgId,
      targetOrg: filteredOrganizationId,
      userRole: user.userRole,
      permissions: user.permissions,
    };

    if (user.username) {
      datadogRum.setUser(datadogUserInfo);
    }


    datadogLogs.setUser(datadogUserInfo);

  }, [user.username, filteredOrganizationId]);
};

export default useWcpLogger;
