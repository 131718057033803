import { FieldError, FormAsterisk, FormLabelAnimated } from 'shared/styles/components/Form';
import { IntervalInputDiv } from 'shared/styles/components/Maintenance';
import StyledDayPickerInput from 'shared/ui/StyledDayPickerInput';
import { getShortDateFromDatePicker } from 'shared/utilities/time';

const NextDueDateField = ({ formikProps: { values, errors, setFieldValue, touched } }) => {
  return (
    <IntervalInputDiv date>
      <FormLabelAnimated animated>
        Due Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
      </FormLabelAnimated>
      <StyledDayPickerInput
        name="nextDueDate"
        placeholder={values.nextDueDate || 'Select a Date'}
        onDayChange={date => {
          const formattedDate = getShortDateFromDatePicker(date);
          setFieldValue('nextDueDate', formattedDate);
        }}
      />
      <FieldError maintenanceEvents>{touched.nextDueDate && errors.nextDueDate}</FieldError>
    </IntervalInputDiv>
  );
};

export default NextDueDateField;
