import { useParams } from 'react-router-dom';

import VehiclePlaybackProvider from 'app/features/playback/state/provider/VehiclePlaybackProvider';
import VehiclePlayback from 'app/features/playback/ui/components/VehiclePlayback';
import { getEndDateTimeFromStartTimeAndDurationInSeconds } from 'shared/utilities/time';

const VehiclePlaybackPage = () => {
  const { vehicleId, startDateTime, duration } = useParams();

  // get route params, set to null if any are not present
  let routeParams = null;

  if (vehicleId && startDateTime && duration) {
    routeParams = {
      vehicleId: vehicleId,
      startDate: decodeURIComponent(startDateTime),
      endDate: getEndDateTimeFromStartTimeAndDurationInSeconds({
        startDateTime: decodeURIComponent(startDateTime),
        duration: duration,
      }),
    };
  }

  return (
    <VehiclePlaybackProvider routeParams={routeParams}>
      <VehiclePlayback />
    </VehiclePlaybackProvider>
  );
};

export default VehiclePlaybackPage;
