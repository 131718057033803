import { TextButton } from 'shared/styles/components/Button';

const ExpandCollapseAll = ({
  allExpanded,
  setAllExpanded,
  setCollapsedIndividualItems,
  totalListingCount,
}) => {
  return (
    <TextButton
      inlineWithSearch
      onClick={() => {
        setAllExpanded(!allExpanded);
        allExpanded
          ? setCollapsedIndividualItems(totalListingCount)
          : setCollapsedIndividualItems(0);
      }}
    >
      {allExpanded ? 'Collapse All' : 'Expand All'}
    </TextButton>
  );
};
export default ExpandCollapseAll;
