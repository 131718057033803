import AnimatedField from 'shared/ui/fields/AnimatedField';

const MaintenanceTypeField = ({ maintenanceTypeLabel }) => {
  return (
    <AnimatedField
      placeholder="Maintenance Type"
      name="maintenanceType"
      value={maintenanceTypeLabel}
      disabled={true}
    />
  );
};

export default MaintenanceTypeField;
