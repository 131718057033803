import { useEffect, useRef } from 'react';

const useClickOutsideContainerHandler = ({ handler }) => {
  const containerRef = useRef(null);

  useEffect(() => {
    // trigger given handler if clicked on outside of element in containerRef
    const handleClickOutside = event => {
      if (containerRef.current && !containerRef.current.contains(event.target)) {
        handler();
      }
    };
    // bind mousedown event listener
    document.addEventListener('mousedown', handleClickOutside);

    // cleanup
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [containerRef]);

  return containerRef;
};

export default useClickOutsideContainerHandler;
