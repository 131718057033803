/**
 * @ External Dependecies
 */
import styled from 'styled-components';

import colors from 'shared/constants/colors';
import device from 'shared/constants/sizing';
import checkIcon from 'assets/images/check@2x.png';
import playIcon from 'assets/svg/play.svg';
import pauseIcon from 'assets/svg/pause.svg';
import playIconHeader from 'assets/svg/play-header.svg';
import pauseIconHeader from 'assets/svg/pause-header.svg';
import switchOnIcon from 'assets/images/switchOn.png';
import switchOffIcon from 'assets/images/switchOff.png';
const CheckboxGroup = styled.div``;

export const Checkbox = styled.div`
	cursor:pointer;
	position: relative;
	border-bottom: 1px solid rgba(138, 144, 156, 0.15);
	font-size: 14px;
	font-weight: 300;
	color: ${colors.haze};

	&:last-child {
		border: none;
	}

	&:after {
		content: '';
		position: absolute;
		right: 22px;
		top: 50%;
		transform: translateY(-50%);
		width: 8px;
		height: 8px;
		border-radius: 50%;
		background: transparent;
	}

	&.checked {
		background: #f9f9f9;
	}

	&.first-check-header {
		margin-right: 10px;
	}

	&.group-check-header {
		margin-top: -3px;
		margin-right: 10px;
	}

	&.first-check-row {
		margin-right: 45px;
	}

	${props =>
    props.disabled &&
    `
	opacity:.5;
    pointer-events: none;
		`}

	${props =>
    props.liveMap &&
    `
	border-bottom: 0px;
	padding:10px 0 0 0;
	label {
			min-height: 14px;
			padding: 0 0 0 20px;

			&:after {
				left: 0;
				top: 0;
				transform: none;
			}
		}

		@media ${device.large} {
        margin-right: 20px;
		min-width: 0px;
		padding:5px 0 0 0;
      }
	`}

	${props =>
    props.status === 'warning' &&
    `
		&:after {
			background: #f3bb26;
		}
	`}

	${props =>
    props.status === 'error' &&
    `
		&:after {
			background: ${colors.cherry};
		}
	`}

	${props =>
    props.status === 'default' &&
    `
		&:after {
			background: transparent;
			border: 2px solid;
			border-color: ${colors.midnight};
		}
	`}
	${props =>
    props.selectAllCheckbox &&
    `
		margin-bottom:10px;
	`}

	${props =>
    props.singleCheck &&
    `
		display: inline-block;
		vertical-align: middle;
		border: 0 none;

		&:after {
			display: none;
		}

		label {
			min-height: 14px;
			padding: 0 0 0 14px;

			&:after {
				left: 0;
				top: 0;
				transform: none;
			}
		}
	`}
	${props =>
    props.groupTable &&
    `
		margin-top:-5px;
		margin-right:10px;
	`}

${props =>
  props.tableHeader &&
  `
		display: inline-block;
		vertical-align: middle;
		border: 0 none;
		margin-top: 13px;

		&:after {
			display: none;
		}

		label {
			font-size: 10px;
    		font-weight: 600;
			min-height: 14px;
			padding: 0 0 0 20px;

			&:after {
				left: 0;
				top: 0;
				transform: none;
			}
		}
	`}
`;

export const CheckboxLabel = styled.label`
  display: block;
  position: relative;
  padding: 19px 32px 15px 44px;
  letter-spacing: 0.03em;

  &:after {
    content: '';
    position: absolute;
    left: 19px;
    top: calc(50% + 2px);
    transform: translateY(-50%);
    width: 14px;
    height: 14px;
    border-radius: 2px;
    border: 1px solid rgba(138, 144, 156, 0.6);
  }
  ${props =>
    props.liveMap &&
    `
	padding: 5px 2px 2px 44px;
	`}
`;

export const CheckboxInput = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;

  &:disabled + ${CheckboxLabel} {
    &:after {
      width: 14px;
      height: 14px;
      border: none;
      background-color: ${colors.concrete};
      cursor: default;
    }
  }

  &:checked + ${CheckboxLabel} {
    &:after {
      width: 14px;
      height: 14px;
      border: none;
      background-image: url(${checkIcon});
      background-repeat: no-repeat;
      background-size: cover;
    }
  }
`;

export const PlayPauseCheckboxLabel = styled.label`
  position: relative;
  display: inline-block;
  margin-bottom: 10px;
  width: 30px;
  height: 30px;
  background-image: url(${playIcon});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  ${props =>
    props.playPauseHeader &&
    `
	background-image: url(${playIconHeader});
	margin: 10px;
	`}
`;

export const PlayPauseCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
  &:checked + ${PlayPauseCheckboxLabel} {
    background-image: url(${pauseIcon});

    ${props =>
      props.playPauseHeader &&
      `
	background-image: url(${pauseIconHeader});
	margin: 10px;
	`}
  }
`;

export const SwitchCheckboxLabel = styled.label`
  display: inline-block;
  margin: 10px 10px 10px 10px;
  margin-bottom: 10px;
  width: 50px;
  height: 22px;
  background-image: url(${switchOffIcon});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  ${props =>
    props.disabled &&
    `
	opacity: .3;
	`}
`;

export const SwitchCheckbox = styled.input.attrs({
  type: 'checkbox',
})`
  display: none;
  &:checked + ${SwitchCheckboxLabel} {
    background-image: url(${switchOnIcon});
  }
  ${props =>
    props.disabled &&
    `
	opacity: .3;
	`}
`;

export const SwitchTextDiv = styled.div`
  margin-left: 60px;
  margin-top: 2px;
  font-size: 14px;
  font-weight: 700;
`;

export default CheckboxGroup;
