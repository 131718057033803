import VehiclePlaybackHeader from 'app/features/playback/ui/components/VehiclePlaybackHeader/VehiclePlaybackHeader';
import VehiclePlaybackMapboxComponent from 'app/features/playback/ui/components/VehiclePlaybackMapboxComponent/VehiclePlaybackMapboxComponent';
import VehiclePlaybackSidebar from 'app/features/playback/ui/components/VehiclePlaybackSidebar/VehiclePlaybackSidebar';
import {
  SharedMapDiv,
  SidebarAndContentDiv,
} from 'shared/styles/components/SharedMapStyledComponents';

const VehiclePlayback = () => {
  return (
    <SharedMapDiv>
      <VehiclePlaybackHeader />
      <SidebarAndContentDiv>
        <VehiclePlaybackSidebar />
        <VehiclePlaybackMapboxComponent />
      </SidebarAndContentDiv>
    </SharedMapDiv>
  );
};

export default VehiclePlayback;
