import { useIsFetching, useQueryClient } from '@tanstack/react-query';

import RemoveAlertRecipientButton from 'app/features/alerts/ui/components/AlertNotificationBuilder/buttons/RemoveAlertRecipientButton';
import AlertRecipientButtonManager from 'app/features/alerts/ui/components/AlertNotificationBuilder/controls/AlertRecipientButtonManager';
import AlertRecipientAlertMethodDropdown from 'app/features/alerts/ui/components/AlertNotificationBuilder/dropdowns/AlertRecipientAlertMethodDropdown';
import AlertRecipientDropdown from 'app/features/alerts/ui/components/AlertNotificationBuilder/dropdowns/AlertRecipientDropdown';
import { RecipientWrapper } from 'shared/styles/components/ManageAlerts';

const RecipientRowContainer = ({ alertNotification, recipientRow }) => {
  let { alertNotificationGuid, alertRecipientRows, isPrimaryNotification } = alertNotification;
  let { recipientRowGuid } = recipientRow;

  // always show remove alert recipient button if this is the primary notification
  // also show if there are more than one recipient row
  const showRemoveAlertRecipientButton = isPrimaryNotification || alertRecipientRows.length > 1;

  // hackyMcQueryClientJr
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  let queryKey =
    queryCache
      .getAll()
      .map(cache => cache.queryKey)
      .filter(key => key[0] === 'getRecipientGroupByIdApi')
      .find(key => key[1].split(':')[1] === recipientRow?.recipientGroup?.recipientGroupId) || [];

  const isLoading = useIsFetching(queryKey) > 0;

  return (
    <RecipientWrapper>
      <AlertRecipientDropdown alertNotification={alertNotification} recipientRow={recipientRow} />
      <AlertRecipientAlertMethodDropdown
        alertNotification={alertNotification}
        recipientRow={recipientRow}
        disabled={isLoading}
      />
      <AlertRecipientButtonManager
        alertNotification={alertNotification}
        recipientRow={recipientRow}
      />
      {showRemoveAlertRecipientButton && (
        <RemoveAlertRecipientButton
          alertNotificationGuid={alertNotificationGuid}
          recipientRowGuid={recipientRowGuid}
        />
      )}
    </RecipientWrapper>
  );
};

export default RecipientRowContainer;
