/**
 * @ External Dependecies
 */
import styled from 'styled-components';

/**
 * @ Internal Dependecies
 */
import theme from 'shared/styles/global/theme';

export default styled.div`
  position: relative;
  display: flex;
  justify-content: flex-end;
  min-height: calc(100vh - ${theme.header_height});
  ${props =>
    props.fullscreenMapMode &&
    `
		min-height: calc(100vh);
	`};
  style{{ gridColumn: '2', gridRow: '2' }}
`;
