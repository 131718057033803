import { Field, Formik } from 'formik';
import React, { Component } from 'react';
import { useState } from 'react';
import Avatar from 'react-avatar-edit';
import { connect, useSelector } from 'react-redux';

import { closeDefaultModal, modalFormChanged } from 'core/redux/ui/actions';
import { EMPTY_STRING } from 'shared/constants/api';
import colors from 'shared/constants/colors';
import fileSizes from 'shared/constants/fileSizes';
import {
  FIELD_SOLUTION_ENGINEER,
  ORGANIZATION_ADMIN,
  SYSTEM_ADMIN,
  userRoleOptionsArray,
  userRoleOptions as userRoles,
} from 'shared/constants/users';
import Button, { ActionButtonText, TextButton } from 'shared/styles/components/Button';
import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import FormWrapper, {
  AvatarSourceContainer,
  ChangePasswordFormRow,
  CheckboxTextSpan,
  EmailOptionDiv,
  FieldError,
  Form,
  FormActions,
  FormAsterisk,
  FormBody,
  FormCol,
  FormFoot,
  FormGridRow,
  FormHead,
  FormIcon,
  FormLabelAnimated,
  FormLegend,
  FormMessage,
  FormRow,
  FormTitle,
  NonTextFieldContainer,
  ReadOnlyFormDisplayDiv,
} from 'shared/styles/components/Form';
import { TruncatedText } from 'shared/styles/components/TruncatedText';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import AnimatedField from 'shared/ui/fields/AnimatedField';
import MaskedField from 'shared/ui/fields/MaskedField';
import SelectField from 'shared/ui/fields/SelectField';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { handleSelectBlur, handleSelectChange } from 'shared/utilities/form';
import { replaceEmptyString } from 'shared/utilities/general';
import { validateUser } from 'shared/utilities/validators';

const CreateUserForm = ({
  user,
  userRole,
  editable,
  userSelfEdit,
  cancelHandler,
  headline,
  confirmText,
  cancelText,
  modalFormMessage,
  fseUserOptionAvailable,
  confirmHandler,
  organizationId,
  editUser,
}) => {
  console.log('UserForm props', organizationId);
  const [preview, setPreview] = useState(user && user.picture ? user.picture : EMPTY_STRING);
  const [changePasswordVisible, setChangePasswordVisible] = useState(false);

  const handleRemoveImage = (innerHandler, field) => e => {
    setPreview(EMPTY_STRING);
    innerHandler(field, EMPTY_STRING);
  };

  const handleChangePassword = () => e => {
    setChangePasswordVisible(true);
  };

  const setGlobalFormDirty = innerHandler => e => {
    console.log('setGlobalFormDirty');
    //this.props.modalFormChanged(e);
    innerHandler(e);
  };

  const onSubmit = async (values, formikActions) => {
    values.username = values.username.trim();

    const success = await confirmHandler({
      ...values,
      phoneNumber: (values.phoneNumber && `+${values.phoneNumber.replace(/\D/g, '')}`) || '',
      organizationId,
      picture: preview,
    });

    formikActions.setSubmitting(false);
  };
  const onCrop = preview => {
    setPreview(preview);
  };

  const onClose = () => {
    setPreview(EMPTY_STRING);
  };

  const onBeforeFileLoad = elem => {
    //reject files over 6 mb
    if (elem && elem.target.files[0].size > fileSizes.userPhotoMax) {
      alert('The selected file is too large. Please try another.');
      elem.target.value = '';
    } else if (elem && elem.target.files[0].size < fileSizes.globalMin) {
      alert('The selected file is corrupt or invalid. Please try another.');
      elem.target.value = '';
    }
  };

  const userRoleOptions = userRoleOptionsArray
    .filter(
      userRoleOption =>
        userRoleOption.id <=
        userRoleOptionsArray.find(roleOption => roleOption.value === userRole).id,
    )
    .filter(userRoleOption =>
      userRoleOption.value === userRoles[FIELD_SOLUTION_ENGINEER].value ||
      userRoleOption.value === userRoles[SYSTEM_ADMIN].value
        ? fseUserOptionAvailable
        : true,
    );

  const currentUser = useSelector(state => state.user);
  return (
    <FormWrapper>
      <Formik
        initialValues={
          (user && {
            picture: user.picture,
            username: user.username,
            givenName: user.givenName,
            familyName: user.familyName,
            email: user.email,
            phoneNumber: user.phoneNumber,
            userTitle: user.userTitle,
            userRole: user.userRole,
            active: user.active,
            twoFactorAuthMethod: user.twoFactorAuthMethod,
            resetUser: user.resetUser,
            optOutOfEmails: user.optOutOfEmails,
          }) || {
            picture: '',
            username: '',
            givenName: '',
            familyName: '',
            email: '',
            phoneNumber: '1',
            userTitle: '',
            userRole: '',
            active: '',
            oldPassword: '',
            newPassword: '',
            confirmNewPassword: '',
            twoFactorAuthMethod: '',
            resetUser: false,
            optOutOfEmails: false,
          }
        }
        validate={validateUser}
        onSubmit={onSubmit}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {({
          values,
          errors,
          touched,
          dirty,
          handleChange: formikHandleChange,
          handleBlur,
          submitForm,
          setFieldValue,
          setTouched,
          isSubmitting,
        }) => {
          const handleChange = dirty ? formikHandleChange : setGlobalFormDirty(formikHandleChange);
          return (
            <Form onSubmit={submitForm}>
              <FormHead>
                <FormIcon>
                  {editUser ? (
                    <IconSvgComponent svgFileName="edit-pencil-blue" alt="Edit" />
                  ) : (
                    <IconSvgComponent svgFileName="plus" alt="Create" />
                  )}
                </FormIcon>

                <FormTitle>{headline}</FormTitle>
              </FormHead>

              <FormBody>
                <FormGridRow>
                  <FormCol>
                    <FormRow>
                      {user && replaceEmptyString(values.picture) ? (
                        <div>
                          <div
                            style={{
                              ...styles.userImagePreviewContainer,
                              backgroundImage: `url(${user.picture})`,
                            }}
                          />
                          <Button
                            onClick={handleRemoveImage(setFieldValue, 'picture')}
                            default
                            center
                          >
                            REMOVE IMAGE
                          </Button>
                        </div>
                      ) : (
                        <AvatarSourceContainer>
                          <Avatar
                            imageWidth={300}
                            width={300}
                            height={300}
                            borderStyle={styles.avatarBorder}
                            label={'UPLOAD PROFILE PHOTO'}
                            labelStyle={styles.avatarLabel}
                            onCrop={onCrop}
                            onClose={onClose}
                            onBeforeFileLoad={onBeforeFileLoad}
                          />
                        </AvatarSourceContainer>
                      )}
                    </FormRow>
                  </FormCol>

                  <FormCol>
                    {user && user.username ? (
                      <ReadOnlyFormDisplayDiv>
                        <FormLabelAnimated animated>Username</FormLabelAnimated>
                        <TruncatedText title={values.username}>{values.username}</TruncatedText>
                      </ReadOnlyFormDisplayDiv>
                    ) : (
                      <AnimatedField
                        name="username"
                        placeholder="Username"
                        value={values.username}
                        disabled={false}
                        touched={touched.username}
                        validationError={errors.username}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        required
                      />
                    )}
                    <AnimatedField
                      name="givenName"
                      placeholder="First Name"
                      value={values.givenName}
                      disabled={!editable && user && user.firstName}
                      touched={touched.givenName}
                      validationError={errors.givenName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      required
                    />

                    <AnimatedField
                      name="familyName"
                      placeholder="Last Name"
                      value={values.familyName}
                      disabled={!editable && user && user.familyName}
                      touched={touched.familyName}
                      validationError={errors.familyName}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      required
                    />
                    <div
                      title={
                        editUser &&
                        currentUser.user.userRole !== userRoles[ORGANIZATION_ADMIN].value &&
                        'This field cannot be edited'
                      }
                    >
                      <AnimatedField
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        touched={touched.email}
                        validationError={errors.email}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        disabled={
                          editUser &&
                          currentUser.user.userRole !== userRoles[ORGANIZATION_ADMIN].value
                        }
                        required
                      />
                    </div>

                    <MaskedField
                      name="phoneNumber"
                      placeholder="Mobile Phone Number"
                      value={values.phoneNumber}
                      disabled={!editable && user && user.phoneNumber}
                      touched={touched.phoneNumber}
                      validationError={errors.phoneNumber}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                      required
                      mask="+9 (999) 999-9999"
                    />

                    <AnimatedField
                      name="userTitle"
                      placeholder="Title"
                      value={values.userTitle}
                      disabled={!editable && user && user.userTitle}
                      touched={touched.userTitle}
                      validationError={errors.userTitle}
                      handleChange={handleChange}
                      handleBlur={handleBlur}
                    />
                    {user && user.userRole ? (
                      <ReadOnlyFormDisplayDiv>
                        <FormLabelAnimated animated>Role</FormLabelAnimated>
                        <TruncatedText title={user.role}>{user.role}</TruncatedText>
                      </ReadOnlyFormDisplayDiv>
                    ) : (
                      <SelectField
                        name="userRole"
                        placeholder="Role"
                        defaultValue={values.userRole}
                        disabled={user && user.userRole}
                        onChange={handleSelectChange(setFieldValue, 'userRole')}
                        onBlur={handleSelectBlur(setTouched, 'userRole', touched)}
                        validationError={errors.userRole}
                        isClearable={false}
                        isSearchable={false}
                        options={userRoleOptions}
                        field="true"
                        required
                      />
                    )}
                    {editUser && (
                      <EmailOptionDiv>
                        <Checkbox singleCheck>
                          <CheckboxInner
                            isChecked={values.optOutOfEmails}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                            onClick={() => setFieldValue('optOutOfEmails', !values.optOutOfEmails)}
                          />
                        </Checkbox>
                        <CheckboxTextSpan>Opt out of emails</CheckboxTextSpan>
                      </EmailOptionDiv>
                    )}
                    {user ? (
                      <NonTextFieldContainer>
                        <>
                          <Field
                            name={'resetUser'}
                            render={({ field, form }) => {
                              return (
                                <React.Fragment>
                                  <input
                                    type="checkbox"
                                    id={'resetUser'}
                                    checked={field.value}
                                    {...field}
                                  />
                                  <CheckboxTextSpan>
                                    Reset password and two-factor option
                                  </CheckboxTextSpan>
                                </React.Fragment>
                              );
                            }}
                          />
                        </>
                      </NonTextFieldContainer>
                    ) : null}
                    {userSelfEdit && (
                      <React.Fragment>
                        {!changePasswordVisible && (
                          <TextButton type="button" onClick={handleChangePassword}>
                            <ActionButtonText>CHANGE PASSWORD</ActionButtonText>
                          </TextButton>
                        )}
                        {changePasswordVisible && (
                          <NonTextFieldContainer>
                            <ChangePasswordFormRow>
                              <AnimatedField
                                name="oldPassword"
                                value={values.oldPassword}
                                touched={touched.oldPassword}
                                validationError={errors.oldPassword}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="Current Password"
                                type="password"
                                id="oldPassword"
                                required
                              />
                            </ChangePasswordFormRow>
                            <ChangePasswordFormRow>
                              <AnimatedField
                                name="newPassword"
                                value={values.newPassword}
                                touched={touched.newPassword}
                                validationError={errors.newPassword}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="New Password"
                                type="password"
                                id="newPassword"
                                required
                              />
                            </ChangePasswordFormRow>
                            <ChangePasswordFormRow>
                              <AnimatedField
                                name="confirmNewPassword"
                                value={values.confirmNewPassword}
                                touched={touched.confirmNewPassword}
                                validationError={errors.confirmNewPassword}
                                handleChange={handleChange}
                                handleBlur={handleBlur}
                                placeholder="Confirm New Password"
                                type="password"
                                id="confirmNewPassword"
                                required
                              />
                            </ChangePasswordFormRow>
                          </NonTextFieldContainer>
                        )}
                      </React.Fragment>
                    )}
                    <FieldError>{touched.userRole && errors.userRole}</FieldError>
                  </FormCol>
                </FormGridRow>
                <FormMessage message={modalFormMessage}>{modalFormMessage}</FormMessage>
              </FormBody>

              <FormFoot>
                <FormLegend>
                  <FormAsterisk>*</FormAsterisk> &mdash; required fields
                </FormLegend>

                <FormActions>
                  {cancelHandler ? (
                    <Button onClick={cancelHandler} withRightSpacer mediumAlt default type="button">
                      {cancelText}
                    </Button>
                  ) : null}
                  <ButtonWithLoader
                    isLoading={isSubmitting}
                    confirmText={confirmText}
                    loadingStyleProp={'submittingWithSpinnerModal'}
                    notLoadingStyleProp={'mediumAlt'}
                    clickHandler={submitForm}
                  />
                </FormActions>
              </FormFoot>
            </Form>
          );
        }}
      </Formik>
    </FormWrapper>
  );
};

export default connect(
  state => ({
    modalFormMessage: state.ui.modalFormMessage,
    userRole: state.user.user.userRole,
  }),
  {
    closeDefaultModal,
    modalFormChanged,
  },
)(CreateUserForm);

const styles = {
  avatarLabel: {
    color: colors.haze,
    fontSize: '14px',
    fontWeight: '800',
    letterSpacing: '0.59px',
    cursor: 'pointer',
  },
  avatarBorder: {
    border: '1px solid #979797',
    textAlign: 'center',
    backgroundColor: colors.fog,
    height: '300px',
  },
  userImagePreviewContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: 120,
    height: 120,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 20,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
  },
};
