import { Link, useLocation } from 'react-router-dom';
import WcpTable from 'app/shared/components/controls/WcpTable/WcpTable';
import ManageAlertsActionMenu from 'app/features/alerts/ui/components/AlertsSummary/menus/ManageAlertsActionMenu';
import { TruncatedTextWithWrap } from 'shared/styles/components/TruncatedText';

const ManageAlertsSummaryTable = ({ alerts, handleUpdateListingCount }) => {
  console.log(
    '===========================================\nRendering ManageAlertsSummaryTable\n=============================================',
  );
  const getAlertNameClasses = alert => {
    return `vehicleNameAndIcon ${
      alert.alertDetails.alertInfo.isActive && alert.alertDetails.alertInfo.testModeEnabled !== true
        ? ''
        : 'deactivated'
    }`;
  };
  const getAlertNameTooltip = alert => {
    return `Alert Name: ${alert.alertDetails.decorated.summaryTable.alertName} ${
      alert.alertDetails.alertInfo.testModeEnabled ? ' (TEST MODE) ' : ''
    }
    ${alert.alertDetails.alertInfo.isActive ? '' : '(Deactivated)'}`;
  };
  let alertsTableData = alerts?.map(alert => {
    return {
      alertId: alert.alertId,
      alertName: alert.alertDetails.decorated.summaryTable.alertName,
      condition: alert.alertDetails.decorated.summaryTable.conditionColumn,
      testModeEnabled: alert.alertDetails.alertInfo.testModeEnabled,
      isActive: alert.alertDetails.alertInfo.isActive,
      alertNameTooltip: getAlertNameTooltip(alert),
      alertNameClasses: getAlertNameClasses(alert),
      alertValue: alert.alertDetails.decorated.summaryTable.valueColumn,
      targetVehicleOrGroupName: alert.alertDetails.decorated.targetVehicleOrGroupName,
      recipient: alert.alertDetails.decorated.summaryTable.recipientColumn,
      alertMethod: alert.alertDetails.decorated.summaryTable.methodColumn,
      multiLevel: alert.alertNotifications.length > 1,
      // used for action menu, see comment on action menu below
      rawAlert: alert,
    };
  });
  const { pathname } = useLocation();

  const columns = [
    {
      title: 'Alert Name',
      displayProperty: 'alertName',
      className: 'name',
      width: '20%',
      render({ rowData, cellJsx }) {
        return (
          //CANNOT USE ES6 string interpolation here, it breaks the bolded search results
          <span className={rowData.alertNameClasses} title={rowData.alertNameTooltip}>
            <TruncatedTextWithWrap>
              <Link
                to={{
                  pathname: `/editalert/${encodeURIComponent(rowData.alertId)}`,
                  state: pathname,
                }}
                style={{ textDecoration: 'none' }}
              >
                {cellJsx}
                {rowData.testModeEnabled ? ' (TEST MODE) ' : ''}{' '}
                {rowData.isActive ? '' : '(Deactivated)'}
              </Link>
            </TruncatedTextWithWrap>
          </span>
        );
      },
    },
    {
      title: 'Condition',
      displayProperty: 'condition',
    },
    {
      title: 'Value',
      displayProperty: 'alertValue',
    },

    {
      title: 'Vehicle/Group',
      displayProperty: 'targetVehicleOrGroupName',
      width: '10%',
      render({ cellValue, cellJsx }) {
        return (
          <span title={`Vehicle/Group: ${cellValue} `}>
            <TruncatedTextWithWrap>{cellJsx}</TruncatedTextWithWrap>
          </span>
        );
      },
    },

    {
      title: 'Recipients',
      displayProperty: 'recipient',
      render({ rowData, cellJsx }) {
        return (
          //CANNOT USE ES6 string interpolation here, it breaks the bolded search results
          <span
            title={
              rowData.multiLevel
                ? 'Recipients: (Multi-level) -' + rowData.recipient
                : 'Recipients: ' + rowData.recipient
            }
          >
            <TruncatedTextWithWrap>
              {rowData.multiLevel ? '(Multi-level)' : ''} {cellJsx}
            </TruncatedTextWithWrap>
          </span>
        );
      },
    },

    {
      title: 'Method',
      displayProperty: 'alertMethod',
    },

    {
      title: '',
      className: 'action',
      searchEnabled: false,
      render({ rowData }) {
        // passing in the original alert tree here bc this is also used in the cards and other checks currently and I dont want to refactor at this time, if at all
        return <ManageAlertsActionMenu alert={rowData.rawAlert} />;
      },
    },
  ];

  return (
    <WcpTable
      columns={columns}
      tableSourceData={alertsTableData}
      emptyText="No alerts are currently associated with this organization"
      setListingCount={handleUpdateListingCount}
    />
  );
};

export default ManageAlertsSummaryTable;
