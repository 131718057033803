/**
 * @ External Dependencies
 */
import React, { Component } from 'react';
import InputMask from 'react-input-mask';

/**
 * @ Styled Components
 */
import {
  FormControls,
  FormLabelAnimated,
  FormFieldAnimated,
  FormAsterisk,
  FieldError,
} from 'shared/styles/components/Form';

/**
 * Class for field.
 *
 * @class      Field (name)
 */
class MaskedField extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isFocused: this.props.value ? true : false,
    };

    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange = e => {
    if (typeof this.props.handleChange === 'function') {
      this.props.handleChange(e);
    }
  };

  handleFieldFocus = () => {
    this.setState({
      isFocused: true,
    });
  };

  handleFieldBlur = e => {
    typeof this.props.handleBlur === 'function' && this.props.handleBlur(e);
    if (e.target.value === '') {
      this.setState({
        isFocused: false,
      });
    } else {
      this.setState({
        isFocused: true,
      });
    }
  };

  render() {
    const {
      id,
      name,
      placeholder,
      required,
      type,
      innerRef,
      mask,
      value: propsValue,
      validationError,
      touched,
      containerStyles,
      inputStyles,
      labelOffset,
      disabled,
      forceAnimated,
      smallerTextMobile,
      beforeMaskedValueChangeHandler,
    } = this.props;

    const { value: stateValue, isFocused } = this.state;
    return (
      <FormControls style={containerStyles}>
        <FormLabelAnimated animated={isFocused || forceAnimated} offset={labelOffset} htmlFor={id}>
          {placeholder}

          {required ? <FormAsterisk>*</FormAsterisk> : null}
        </FormLabelAnimated>

        <InputMask
          mask={mask}
          onFocus={this.handleFieldFocus}
          onBlur={this.handleFieldBlur}
          onChange={this.handleInputChange}
          value={propsValue || stateValue}
          disabled={disabled}
          beforeMaskedValueChange={beforeMaskedValueChangeHandler}
        >
          {inputProps => {
            return (
              <FormFieldAnimated
                disableUnderline={disabled}
                innerRef={innerRef}
                type={type ? type : 'text'}
                id={id}
                name={name}
                required={required}
                invalid={touched && validationError}
                style={inputStyles}
                smallerTextMobile={smallerTextMobile}
              />
            );
          }}
        </InputMask>
        <FieldError>{touched && validationError}</FieldError>
      </FormControls>
    );
  }
}

export default MaskedField;
