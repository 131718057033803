/**
 * @ External Dependencies
 */
import { createAction } from 'redux-actions';

/**
 * @ Action creators
 */
export const openSearch = createAction('OPEN_SEARCH');
export const closeSearch = createAction('CLOSE_SEARCH');
export const getFilteredData = createAction('GET_FILTERED_DATA');
export const setTerm = createAction('SET_TERM');
