import { getValueOrFallbackIfUndefined } from 'app/shared/utilities/reducerHelpers/getValueOrFallbackIfUndefined';
import { validateAndExtractPayload } from 'app/shared/utilities/reducerHelpers/validateAndExtractPayload';

// updateUnitOfMeasureAction  →  updateUnitOfMeasureReducer
export const updateUnitOfMeasureReducer = (state, payload) => {
  const { unitOfMeasure } = validateAndExtractPayload({ payload, mustInclude: ['unitOfMeasure'] });

  let newUnitOfMeasure = getValueOrFallbackIfUndefined(
    unitOfMeasure,
    state.alert.alertDetails.alertInfo.unitOfMeasure,
  );

  return {
    ...state,
    alert: {
      ...state.alert,
      alertDetails: {
        ...state.alert.alertDetails,
        alertInfo: {
          ...state.alert.alertDetails.alertInfo,
          unitOfMeasure: newUnitOfMeasure,
        },
      },
    },
  };
};
