import React from 'react';
import { GroupTagDiv } from 'shared/styles/components/GroupTags';
import { getAssociatedGroups } from 'shared/utilities/vehicle';

export default function VehicleGroupTags({ vehicle, groups }) {
  // get associated groups
  if (groups) {
    vehicle.groups = getAssociatedGroups({ vehicle, groups });
  }

  return (
    <div>
      {vehicle &&
        vehicle.groups &&
        vehicle.groups.map(group => (
          <GroupTagDiv key={group.groupName || group.group_name}>
            {group.groupName || group.group_name}
          </GroupTagDiv>
        ))}
    </div>
  );
}
