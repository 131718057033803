import { ActionButtonWithIcon } from 'shared/styles/components/Button';
import { createExportDownload } from 'shared/utilities/csvExport';

const MaintenanceLogsExport = ({ maintenanceLogs, vehicleName }) => {
  const prepareDataForExport = () => {
    let maintenanceLogsForExport = maintenanceLogs.map(m => ({
      Completed_Date: m.formattedTime,
      Odometer: m.odometerDisplay,
      Engine_Hours: m.engineHoursDisplay,
      Interval: m.intervalDisplay,
      Maintenance_Type: m.maintenanceTypeDisplay,
      Notes: m.notesDisplay,
      Marked_Completed_By: m.markedCompletedByDisplay,
    }));

    let csvFileName = `wcp_maintenance_logs_for_${vehicleName}`;
    createExportDownload(maintenanceLogsForExport, csvFileName);
  };

  return (
    <ActionButtonWithIcon onClick={() => prepareDataForExport()} export>
      Export to .csv
    </ActionButtonWithIcon>
  );
};

export default MaintenanceLogsExport;
