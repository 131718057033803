import { useEffect, useState } from 'react';

// GENERIC
const useConnectFormikWithProvider = ({ providerValues, providerToFormikMapper }) => {
  const newFieldValues = providerToFormikMapper(providerValues);

  // this gets the formik field values from the provider state
  const [fieldValues, setFieldValues] = useState(newFieldValues);

  useEffect(() => {
    // map provider state to formik field values
    setFieldValues(newFieldValues);
  }, [providerValues]);

  return {
    fieldValues,
  };
};

export default useConnectFormikWithProvider;
