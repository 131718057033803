import React from 'react';
import { ActionButtonWithIcon } from 'shared/styles/components/Button';
import { createExportDownload, getCsvFileName } from 'shared/utilities/csvExport';
import { ExportWrapper } from 'shared/styles/components/Analytics';
import { ExportDiv } from 'shared/styles/components/PageList';

const ExportCsvButton = ({ exportData, fileNamePrefix }) => {
  return (
    <ExportWrapper>
      <ActionButtonWithIcon
        onClick={() => {
          const fileName = getCsvFileName({ fileNamePrefix });
          createExportDownload(exportData, fileName);
        }}
        export
      >
        Export to .csv
      </ActionButtonWithIcon>
    </ExportWrapper>
  );
};

export default ExportCsvButton;
