import { useEffect, useState } from 'react';
import Media from 'react-media';
import { useSelector } from 'react-redux';
import { Navigate, useNavigate, useParams } from 'react-router-dom';

import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import { useFetchVehiclesQuery } from 'features/fleet/vehicles/hooks/useFetchVehiclesQuery';
import FirmwareUpdateTable from 'features/ota/firmware/components/byDevice/FirmwareUpdateTableByDevice';
import FirmwareUpdateSummaryItem from 'features/ota/firmware/components/byDevice/FirmwareUpdateSummaryItem';
import useFetchFirmwareQuery from 'features/ota/firmware/hooks/useFetchFirmwareQuery';
import useUpdateFirmwareMutation from 'features/ota/firmware/hooks/useUpdateFirmwareMutation';
import { mapAndSortFirmwareUpdates } from 'features/ota/firmware/utilities/mapAndSortFirmwareUpdates';
import OtaUpdateCard from 'features/ota/shared/mobile/OtaUpdateCard';
import ListingCount from 'shared/components/ListingCount';
import { permissionData } from 'shared/constants/users';
import { Cards } from 'shared/styles/components/MobileCard';
import { OtaSummaryItemWrapperDiv, UpdateAllWrapperDiv } from 'shared/styles/components/OtaUpdates';
import PageListWrapper, {
  PageListHead,
  TableCheckboxFilterWrapper,
} from 'shared/styles/components/PageList';
import { SectionBody, SectionInner, SectionTitle } from 'shared/styles/components/Section';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { sortAscendingAlpha } from 'shared/utilities/general';
import { sizes } from 'shared/utilities/media';
import {
  getOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal,
  setOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal,
} from 'shared/utilities/localStore';
import OnlyShowPendingUpdatesCheckbox from 'features/ota/firmware/components/controls/OnlyShowPendingUpdatesCheckbox';

const FirmwareUpdateDetailsPage = () => {
  const { productId, updateChannel, updateVersion } = useParams();
  const navigate = useNavigate();

  // redux
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);

  // react query
  const [fetchFirmwareQuery, firmwareUpdates] = useFetchFirmwareQuery({
    key: 'firmwaredetails',
    autoRun: true,
    enableRefetchInterval: true,
    cachingEnabled: false,
  });

  const [mappedFirmwareUpdates, setMappedFirmwareUpdates] = useState(null);

  useEffect(() => {
    setMappedFirmwareUpdates(firmwareUpdates && mapAndSortFirmwareUpdates(firmwareUpdates));
  }, [firmwareUpdates]);

  const [fetchVehiclesQuery, vehicles] = useFetchVehiclesQuery({
    enableRefetchInterval: true,
    cachingEnabled: false,
    apiFlags: {
      addons: false,
      cell: true,
      device: true,
      gps: true,
      meta: true,
      maintenance: false,
      obd: false,
    },
  });

  const updateFirmwareMutation = useUpdateFirmwareMutation();

  // local

  let onlyShowPendingUpdatesPreference = getOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal();

  const [vehicleStatuses, setVehicleStatuses] = useState(null);
  const [firmwareUpdatePostLoading, setFirmwareUpdatePostLoading] = useState(null);
  const [manualFirmwareFetchLoading, setManualFirmwareFetchLoading] = useState(false);
  const [updates, setUpdates] = useState([]);
  const [filteredDeviceUpdates, setFilteredDeviceUpdates] = useState([]);
  const [updateAllLoading, setUpdateAllLoading] = useState(null);
  const [listingCount, setListingCount] = useState(filteredDeviceUpdates?.length);

  const [onlyShowPendingUpdates, setOnlyShowPendingUpdates] = useState(
    onlyShowPendingUpdatesPreference ? onlyShowPendingUpdatesPreference : false,
  );

  useEffect(() => {
    if (!_.isEmpty(mappedFirmwareUpdates)) {
      let updatesGroupedByProductAndVersion = mappedFirmwareUpdates.reduce((obj, u) => {
        // create key from productName and productId
        let key = u.productId;

        obj[key] = obj[key] || {};
        obj[key][u.version] = obj[key][u.version] || [];
        obj[key][u.version].push(u);

        return obj;
      }, {});

      if (!updatesGroupedByProductAndVersion[productId][updateVersion]) {
        navigate('/updates');
      } else {
        let updates = updatesGroupedByProductAndVersion[productId][updateVersion].filter(
          u => u.channel === updateChannel,
        );

        setUpdates(updates?.sort((v1, v2) => sortAscendingAlpha(v1.label, v2.label)));
        setManualFirmwareFetchLoading(false);
        setUpdateAllLoading(false);
      }
    }
  }, [mappedFirmwareUpdates, fetchFirmwareQuery.isFetching]);

  const firstDevice = updates && updates[0];
  useEffect(() => {
    setManualFirmwareFetchLoading(true);

    fetchFirmwareQuery.refetch();
  }, [productId, updateChannel, updateVersion]);

  useEffect(() => {
    if (onlyShowPendingUpdates) {
      if (updates) {
        setFilteredDeviceUpdates(
          updates?.filter(
            u => u.firmwareStatus !== 'UP_TO_DATE' && u.firmwareStatus !== 'EXTERNAL_UPDATE',
          ),
        );
      }
    } else {
      setFilteredDeviceUpdates(updates);
    }
  }, [onlyShowPendingUpdates, updates]);

  useEffect(() => {
    if (vehicles)
      setVehicleStatuses(
        vehicles.reduce(
          (o, v) => ({
            ...o,
            [v.vehicle_id]: v.gps
              ? {
                  isActive: v.meta.online,
                  isResponding: v.gps.fr_mode_enabled === 1,
                }
              : {
                  isActive: false,
                  isResponding: false,
                },
          }),
          {},
        ),
      );
  }, [vehicles]);

  // render helpers
  let queriesLoading =
    fetchFirmwareQuery.isLoading || fetchVehiclesQuery.isLoading || manualFirmwareFetchLoading;

  let hasUpdates = updates?.length > 0;

  // render
  return queriesLoading ? (
    <LoadingOverlay />
  ) : (
    !!vehicleStatuses &&
      (!hasUpdates ? (
        <Navigate to="/updates" />
      ) : (
        <PageListWrapper>
          <PageListHead>
            <SectionTitle size="27">{`${firstDevice?.productName} ${updateChannel
              .charAt(0)
              .toUpperCase() + updateChannel.slice(1).toLowerCase()} Update`}</SectionTitle>
            <UpdateAllWrapperDiv>
              <PermissionProtectedElement requiredPermissions={[permissionData.editotafirmware]}>
                <ButtonWithLoader
                  loadingStyleProp={'submittingWithSpinner'}
                  notLoadingStyleProp={'updates'}
                  disabled={
                    updates?.filter(u => u.firmwareStatus === 'READY_TO_UPDATE').length === 0 ||
                    !updates?.some(
                      u =>
                        vehicleStatuses[u.vehicleId].isActive ||
                        vehicleStatuses[u.vehicleId].isResponding,
                    )
                  }
                  confirmText={'Update All Devices'}
                  isLoading={updateAllLoading}
                  clickHandler={() => {
                    setUpdateAllLoading(true);
                    updateFirmwareMutation.mutate({
                      productId,
                      updateChannel,
                      updateVersion,
                      devicesToUpdate: filteredDeviceUpdates.filter(u =>
                        u.firmwareStatus?.includes(
                          'READY_TO_UPDATE',
                          'ERROR_BOOTLOADING',
                          'ERROR_DOWNLOADING',
                        ),
                      ),
                      organizationId: filteredOrganizationId,
                    });
                  }}
                />
              </PermissionProtectedElement>
            </UpdateAllWrapperDiv>
          </PageListHead>
          <SectionInner>
            <OtaSummaryItemWrapperDiv firmware>
              <FirmwareUpdateSummaryItem
                showTotals={false}
                deviceUpdates={updates}
                releaseDate={firstDevice.releaseDate}
                releaseNotes={firstDevice.releaseNotes}
                channel={firstDevice.channel}
                version={firstDevice.version}
              />
            </OtaSummaryItemWrapperDiv>
          </SectionInner>
          <ListingCount
            listingCount={listingCount}
            includeTotalText={false}
            totalCount={updates.length}
            itemTypeName={'Device'}
          />
          <TableCheckboxFilterWrapper>
            <OnlyShowPendingUpdatesCheckbox
              checked={onlyShowPendingUpdates}
              handleChecked={() => {
                setOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal(!onlyShowPendingUpdates),
                  setOnlyShowPendingUpdates(!onlyShowPendingUpdates);
              }}
            />
          </TableCheckboxFilterWrapper>
          <SectionBody>
            <Media
              queries={{
                tablet: { maxWidth: sizes.tablet },
                mobile: { maxWidth: sizes.mobile },
              }}
            >
              {matches =>
                matches.tablet ? (
                  <div>
                    <Cards ota>
                      {filteredDeviceUpdates.map(u => (
                        <OtaUpdateCard
                          update={u}
                          vehicleStatuses={vehicleStatuses}
                          updatePostLoading={firmwareUpdatePostLoading}
                          handleUpdateFirmware={async u => {
                            setFirmwareUpdatePostLoading(u.deviceId);
                            updateFirmwareMutation.mutate({
                              productId,
                              updateChannel,
                              updateVersion,
                              devicesToUpdate: [u],
                              organizationId: filteredOrganizationId,
                            });
                          }}
                        />
                      ))}
                    </Cards>
                  </div>
                ) : (
                  <FirmwareUpdateTable
                    deviceUpdates={filteredDeviceUpdates}
                    vehicleStatuses={vehicleStatuses}
                    updatePostLoading={firmwareUpdatePostLoading}
                    handleUpdateListingCount={listingCount => setListingCount(listingCount)}
                    handleUpdateFirmware={async device => {
                      setFirmwareUpdatePostLoading(device.deviceId);
                      updateFirmwareMutation.mutate({
                        productId,
                        updateChannel,
                        updateVersion,
                        devicesToUpdate: [device],
                        organizationId: filteredOrganizationId,
                      });
                    }}
                  />
                )
              }
            </Media>
          </SectionBody>
        </PageListWrapper>
      ))
  );
};

export default FirmwareUpdateDetailsPage;
