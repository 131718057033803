import React from 'react';
import { Link } from 'react-router-dom';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';

const EditGroupMenuItem = ({ groupName }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn>
        <Link to={`${encodeURIComponent(groupName)}`} style={styles.groupEditButton}>
          Edit
        </Link>
      </SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default EditGroupMenuItem;

const styles = {
  groupEditButton: {
    textDecoration: 'none',
    display: 'block',
  },
};
