import { useState } from 'react';
import { DebounceInput } from 'react-debounce-input';
import { connect } from 'react-redux';

import { associateFSEsToOrganization } from 'core/redux/organizations/actions';
import colors from 'shared/constants/colors';
import SelectFSETable from 'shared/modals/SelectFSETable';
import CancelButton from 'shared/styles/components/Button';
import { ActionsContainer, CloseButton, Container, Title } from 'shared/styles/components/Modal';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

export default connect(null, dispatch => ({
  associateFSEsToOrganization: ({ FSEs, organizationId }) =>
    dispatch(associateFSEsToOrganization({ FSEs, organizationId })),
}))(
  ({
    handleRequestClose,
    FSEs,
    organizationId,
    cancelText,
    cancelHandler,
    onSuccess,
    associateFSEsToOrganization,
  }) => {
    const defaultSearchValue = '';
    const [searchValue, setSearchValue] = useState('');
    const [selectedFSEs, setSelectedFSEs] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const handleSearchFocus = event => {
      setSearchValue(searchValue === defaultSearchValue ? '' : searchValue);
    };
    const handleSearchChange = event => {
      setSearchValue(event.target.value);
    };
    const handleSearchBlur = event => {
      if (event.target.value === defaultSearchValue) {
        setSearchValue(defaultSearchValue);
      }
    };

    return (
      <Container style={styles.container} maxWidth={700}>
        <CloseButton onClick={handleRequestClose}>
          <IconSvgComponent svgFileName={'x'} alt="Close" />
        </CloseButton>
        <div style={styles.header}>
          <IconSvgComponent
            width={20}
            svgFileName={'plus'}
            alt="Create"
            beforeInjection={svg =>
              svg
                .querySelectorAll('use')
                .forEach(path => path.setAttribute('fill', colors.midnight))
            }
            svgStyle={styles.icon}
          />
          <Title style={styles.title}>Add Field Solution Engineer</Title>
        </div>
        <div>
          <div style={styles.searchContainer}>
            <IconSvgComponent style={styles.searchIcon} svgFileName={'search'} />

            <DebounceInput
              type="text"
              value={searchValue}
              onFocus={handleSearchFocus}
              onBlur={handleSearchBlur}
              onChange={handleSearchChange}
              style={styles.search}
            />
          </div>
          <SelectFSETable
            setSelected={setSelectedFSEs}
            FSEs={FSEs.filter(FSE => `${FSE.givenName} ${FSE.familyName}`.includes(searchValue))}
          />
          <ActionsContainer style={styles.actionsContainer}>
            {cancelText && cancelHandler ? (
              <CancelButton onClick={cancelHandler} withRightSpacer mediumAlt default>
                {cancelText}
              </CancelButton>
            ) : null}
            <ButtonWithLoader
              isLoading={isSubmitting}
              confirmText={'Confirm'}
              loadingStyleProp={'submittingWithSpinnerModal'}
              notLoadingStyleProp={'formConfirm'}
              clickHandler={async () => {
                setIsSubmitting(true);
                const success = await associateFSEsToOrganization({
                  FSEs: selectedFSEs,
                  organizationId,
                });
                setIsSubmitting(false);
                if (success) {
                  onSuccess();
                }
              }}
            />
          </ActionsContainer>
        </div>
      </Container>
    );
  },
);

const styles = {
  container: {
    height: 'auto',
    maxHeight: 'none',
  },
  header: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
  },
  icon: {
    marginRight: '10px',
  },
  title: {
    paddingLeft: 10,
  },
  searchContainer: {
    marginBottom: 20,
  },
  search: {
    display: 'block',
    width: '100%',
    height: '35px',
    padding: '0 38px',
    background: 'white',
    borderRadius: '5px',
    border: `1px solid ${colors.haze}`,
    fontSize: '12px',
    fontWeight: 600,
    opacity: '0.43',
    color: 'rgba(33, 31, 80, 0.43)',
  },
  searchIcon: {
    left: '13px',
    transform: 'translateY(150%) translateX(100%)',
  },
  actionsContainer: {
    marginTop: 34,
  },
};
