import React, { useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';

import { LivemapApi } from 'features/livemap/components/LivemapContextProvider';
import LivemapSidebarVehicleFilter from 'features/livemap/components/sidebar/LivemapSidebarVehicleFilter';
import LivemapSidebarVehicleFilterResultsList from 'features/livemap/components/sidebar/LivemapSidebarVehicleFilterResultsList';
import Searchbox from 'shared/components/Searchbox';
import device from 'shared/constants/sizing';
import { vehicleTypeViewData } from 'shared/constants/vehicle';
import { ActionButtonText, TextButton } from 'shared/styles/components/Button';
import {
  SwitchCheckbox,
  SwitchCheckboxLabel,
  SwitchTextDiv,
} from 'shared/styles/components/Checkbox';
import {
  MapSidebarSearchBoxWrapperDiv,
  ResultsCountLabel,
  ResultsCountWrapperDiv,
  ShowHideButtonDiv,
  SidebarAside,
  SidebarResultsWrapperDiv,
  SidebarShowToggle,
  VehicleNameToggleWrapperDiv,
} from 'shared/styles/components/SharedMapStyledComponents';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import {
  getEnableVehicleMapNamesInLocal,
  setDisplayVehicleNamesInLocal,
} from 'shared/utilities/localStore';

const LivemapSidebarContainer = ({ groups }) => {
  const {
    actions: { setLivemapSidebarStates, setHiddenVehicleIds, setLayerVisibilityOptions },
    state: { filteredVehicles, vehicleMapNamesVisible, hiddenVehicleIds, livemapMainSidebarOpen },
  } = useContext(LivemapApi);

  const [vehicleSearchText, setVehicleSearchText] = useState('');

  const filteredVehiclesBySearch = filteredVehicles.filter(
    v =>
      v.meta.label.toLowerCase().includes(vehicleSearchText.toLowerCase()) ||
      v.meta.alias?.toLowerCase().includes(vehicleSearchText.toLowerCase()) ||
      vehicleTypeViewData[v.meta.vehicle_type].title
        .toLowerCase()
        .includes(vehicleSearchText.toLowerCase()),
  );

  const handleVehicleNameToggle = () => {
    setDisplayVehicleNamesInLocal(!vehicleMapNamesVisible);
    setLayerVisibilityOptions({ vehicleNamesVisible: !vehicleMapNamesVisible });
    let mobile =
      !window.matchMedia(device.large).matches && !window.matchMedia(device.medium).matches;
    mobile &&
      setLivemapSidebarStates({
        livemapMainSidebarOpen: false,
      });
  };

  return (
    <SidebarAside expanded={livemapMainSidebarOpen}>
      {
        <Scrollbars
          style={{
            width: livemapMainSidebarOpen ? '100%' : 0,
            height: '100%',
            zIndex: 1,
            backgroundColor: 'white',
            transition: 'all 0.3s',
            transitionDelay: livemapMainSidebarOpen ? '-.05s' : '.05s',
          }}
        >
          <VehicleNameToggleWrapperDiv>
            <SwitchCheckbox
              id="vehicleNameDisplay"
              checked={vehicleMapNamesVisible}
              onChange={handleVehicleNameToggle}
            />
            <SwitchCheckboxLabel
              htmlFor="vehicleNameDisplay"
              title={`Toggle Vehicle Name Visibility On/Off`}
            >
              <SwitchTextDiv>Display Vehicle Names on Live Map</SwitchTextDiv>
            </SwitchCheckboxLabel>
          </VehicleNameToggleWrapperDiv>
          <LivemapSidebarVehicleFilter groups={groups} />

          <ResultsCountWrapperDiv>
            <ResultsCountLabel>
              Displaying
              {' ' + filteredVehiclesBySearch.length}
              {` Vehicle${filteredVehiclesBySearch.length !== 1 ? 's' : ''}`}
            </ResultsCountLabel>
            <ShowHideButtonDiv>
              <TextButton
                onClick={() =>
                  setHiddenVehicleIds([
                    ...(hiddenVehicleIds.length > 0
                      ? hiddenVehicleIds.filter(
                          hvid => !filteredVehiclesBySearch.map(fv => fv.vehicle_id).includes(hvid),
                        )
                      : []),
                  ])
                }
              >
                <ActionButtonText>SHOW ALL</ActionButtonText>
              </TextButton>
              <span> | </span>
              <TextButton
                onClick={() =>
                  setHiddenVehicleIds([
                    ...hiddenVehicleIds,
                    ...filteredVehiclesBySearch.map(v => v.vehicle_id),
                  ])
                }
              >
                <ActionButtonText>HIDE ALL</ActionButtonText>
              </TextButton>
            </ShowHideButtonDiv>
          </ResultsCountWrapperDiv>

          <MapSidebarSearchBoxWrapperDiv>
            <Searchbox setSearchText={setVehicleSearchText} searchText={vehicleSearchText} />
          </MapSidebarSearchBoxWrapperDiv>
          <SidebarResultsWrapperDiv>
            <LivemapSidebarVehicleFilterResultsList
              vehicles={filteredVehiclesBySearch}
              hiddenVehicleIds={hiddenVehicleIds}
              vehicleSearchText={vehicleSearchText}
            />
          </SidebarResultsWrapperDiv>
        </Scrollbars>
      }
      <SidebarShowToggle
        isOpen={livemapMainSidebarOpen}
        onClick={() =>
          setLivemapSidebarStates({
            livemapMainSidebarOpen: !livemapMainSidebarOpen,
          })
        }
      >
        {livemapMainSidebarOpen ? null : (
          <IconSvgComponent
            svgFileName="showLiveMapFilter"
            title="Show map filter"
            alt="Show map filter"
          />
        )}
      </SidebarShowToggle>
    </SidebarAside>
  );
};
export default LivemapSidebarContainer;
