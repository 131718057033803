import { useDispatch, useSelector } from 'react-redux';

import { hideNewReleaseNotification } from 'core/redux/ui/actions';
import ModalPortal from 'shared/modals/ModalPortal';
import ConfirmationModal from 'shared/modals/ConfirmationModal';
import { TextButton } from 'shared/styles/components/Button';
import { TextLink } from 'shared/styles/components/Link';
import { ReleaseNotesText } from 'shared/styles/components/Modal';

const NewReleaseNotifier = () => {
  const dispatch = useDispatch();

  const newReleaseNotificationVisible = useSelector(
    state => state.ui.newReleaseNotificationVisible,
  );
  const releaseVersion = useSelector(state => state.ui.releaseVersion?.releaseVersion);
  const path = document.URL;

  //comment in the following line to test locally and comment out the line below it
  //const hide = path.includes('hideNewVersionWindow=true');

  //hide if using url param or if running locally
  const hide = path.includes('hideNewVersionWindow=true') || path.includes('localhost');

  if (hide && newReleaseNotificationVisible === true) {
    dispatch(hideNewReleaseNotification());
  }

  return (
    <>
      {newReleaseNotificationVisible && releaseVersion && (
        <ModalPortal onRequestClose={() => dispatch(hideNewReleaseNotification())}>
          <ConfirmationModal
            simpleOk
            smallHeight
            bodyText={
              <>
                <TextButton releaseNotes type="button">
                  <TextLink
                    to={`https://releasenotes.cloud.whelen.com`}
                    title={`https://releasenotes.cloud.whelen.com`}
                  >
                    View the release notes
                  </TextLink>
                </TextButton>
                <ReleaseNotesText> to learn what’s new</ReleaseNotesText>
              </>
            }
            cancelHandler={() => dispatch(hideNewReleaseNotification())}
            confirmHandler={() => {
              dispatch(hideNewReleaseNotification());
            }}
            confirmText={'OK'}
            title={`Welcome to WCP Version ${releaseVersion}`}
          />
        </ModalPortal>
      )}
    </>
  );
};

export default NewReleaseNotifier;
