import { postGeofencesData } from 'core/api/geofences';
import { activeTimeOptions, timeZoneOptions } from 'features/geofences/constants/dropdownOptions';
import { validateGeofenceFields } from 'features/geofences/utilities/validateGeofences';
import { getExpirationTime } from 'shared/utilities/time';

export const createGeofence = async ({
  geofenceCardData,
  geofenceMapData,
  setGeofenceValidationMessage,
  dispatchNotification,
  currentOrganizationId,
}) => {
  let isValid = validateGeofenceFields({ geofenceCardData, setGeofenceValidationMessage });
  if (isValid) {
    try {
      let response = await postGeofencesData({
        organizationId: currentOrganizationId,
        geofence: {
          points: geofenceMapData.points,
          label: geofenceCardData.label,
          category: geofenceCardData.category,

          active_time_type: geofenceCardData.activeTimeType,
          // Range only

          active_range:
            geofenceCardData.activeTimeType === activeTimeOptions[2].value
              ? geofenceCardData.activeRange
              : null,
          timezone:
            geofenceCardData.activeTimeType === activeTimeOptions[1].value ||
            geofenceCardData.activeTimeType === activeTimeOptions[2].value
              ? geofenceCardData.timeZone
              : null,

          //ExpiresAt only
          expiration_time:
            geofenceCardData.activeTimeType === activeTimeOptions[1].value
              ? getExpirationTime({
                  expiresAtDate: geofenceCardData.expiresAtDate,
                  expiresAtTime: geofenceCardData.expiresAtTime,
                  timeZone: timeZoneOptions.find(to => to.value === geofenceCardData.timeZone)
                    .value,
                })
              : null,
          perform_config_update: geofenceCardData.performConfigUpdate,
          perform_firmware_update: geofenceCardData.performFirmwareUpdate,
          daylight_savings: geofenceCardData.dst,
        },
      });

      if (response) {
        dispatchNotification.geofenceCreateSuccess({
          label: geofenceCardData.label,
        });
        return true;
      } else return false;
    } catch (err) {
      dispatchNotification.geofenceCreateError({ err });
    }
  }
};
