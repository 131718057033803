import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { selectMapBounds } from 'core/redux/vehicles/selectors';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import {
  MapControlsContainer,
  MapFocusControlsDiv,
  MapLayersControlsDiv,
  MapZoomControlsDiv,
} from 'shared/styles/components/SharedMapStyledComponents';
import { getLastMapLocationInLocal } from 'shared/utilities/localStore';
import { whelenGpsCoordinates } from 'shared/constants/map';

const determineCoordinates = () => {
  const orgBounds = getLastMapLocationInLocal();
  const bounds = orgBounds ? orgBounds : whelenGpsCoordinates;
  return bounds;
};

const MapControls = ({ map, showSatelliteLayerToggle, showFocusToggle, toggleSatelliteView }) => {
  return (
    <MapControlsContainer>
      <MapLayersControlsDiv showSatelliteLayerToggle={showSatelliteLayerToggle}>
        <div onClick={() => toggleSatelliteView()}>
          <IconSvgComponent
            svgFileName={'map-layers'}
            alt="Toggle Streets/Satellite Layers"
            title="Toggle Streets/Satellite Layers"
          />
        </div>
      </MapLayersControlsDiv>
      <MapFocusControlsDiv showFocusToggle={showFocusToggle}>
        <div
          onClick={() => {
            map.cameraForBounds(determineCoordinates(), { padding: 50 });
            map.fitBounds(determineCoordinates(), { padding: 50, maxZoom: 15 });
          }}
        >
          <IconSvgComponent svgFileName={'map-focus'} alt="Focus" title="Focus" />
        </div>
      </MapFocusControlsDiv>

      <MapZoomControlsDiv>
        <div onClick={() => map.zoomIn()}>
          <IconSvgComponent svgFileName={'zoom-in'} alt="Zoom in" />
        </div>
        <div onClick={() => map.zoomOut()}>
          <IconSvgComponent svgFileName={'zoom-out'} alt="Zoom out" />
        </div>
      </MapZoomControlsDiv>
    </MapControlsContainer>
  );
};

export default connect(
  state => ({
    filteredVehicleMapBounds: selectMapBounds(state),
  }),
  null,
)(MapControls);
