/*
 * @ External Dependencies
 */
import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { connect } from 'react-redux';

/*
 * @ Internal Dependencies
 */
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import UploadIcon from 'shared/ui/icons/UploadIcon';
import colors from 'shared/constants/colors';

import { uploadConfiguration } from 'core/redux/configurations/actions';
import { decorateConfigurationObject } from 'core/api/configuration';

const onDrop = (setUploadState, uploadConfiguration, onUploadComplete) => async acceptedFiles => {
  setUploadState('active');
  const uploadComplete = await uploadConfiguration(acceptedFiles);
  setUploadState('idle');
  onUploadComplete(!!uploadComplete, decorateConfigurationObject(uploadComplete));
};

export default connect(
  state => ({ filteredOrganizationId: state.user.filteredOrganizationId }),
  dispatch => ({
    uploadConfiguration: file => dispatch(uploadConfiguration(file)),
  }),
)(({ onUploadComplete, uploadConfiguration, filteredOrganizationId }) => {
  const [uploadState, setUploadState] = useState('idle');
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple: false,
    onDrop: onDrop(setUploadState, uploadConfiguration, onUploadComplete),
  });
  return (
    <div {...getRootProps({ style: styles.dropzone })}>
      {uploadState === 'active' ? (
        <LoadingOverlay withLogo={false} externalStyles={styles.loader} />
      ) : null}
      <UploadIcon />
      <h4 style={styles.uploadCta}>Drag and drop file here to upload.</h4>
      <h5 style={styles.instructions}>File must be of .wln type</h5>
      <div style={styles.inputContainer}>
        <input
          {...getInputProps({
            style: styles.fileInput,
            id: 'fileInput',
          })}
        />
        <label for={'fileInput'} style={styles.fileLabel} onClick={e => e.stopPropagation()}>
          Or Select a File to Upload
        </label>
      </div>
    </div>
  );
});

const styles = {
  fileInput: {
    width: '.1px',
    height: '.1px',
    opacity: 0,
    overflow: 'hidden',
    position: 'absolute',
    zIndex: -1,
  },
  fileLabel: {
    padding: '10px 40px',
    margin: '0 auto',
    color: colors.midnight,
    fontSize: '12px',
    fontWeight: 800,
    letterSpacing: 0.27,
    textTransform: 'uppercase',
    borderRadius: '20.5px',
    borderColor: colors.midnight,
    borderStyle: 'solid',
    borderWidth: '2px',
    display: 'inline-block',
    textAlign: 'center',
  },
  loader: {
    overlay: {
      left: 0,
    },
  },
  dropzone: {
    marginBottom: '16px',
  },
  dropzoneImageSmall: {},
  uploadCta: {
    color: colors.midnight,
    marginBottom: '8px',
    fontWeight: 800,
    letterSpacing: 0.27,
    fontSize: '20px',
    textAlign: 'center',
  },
  instructions: {
    marginBottom: '14px',
    fontWeight: 600,
    fontSize: '14px',
    textAlign: 'center',
    color: colors.haze,
  },
  inputContainer: {
    textAlign: 'center',
  },
};
