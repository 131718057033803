import React from 'react';
import {
  DescriptionTextArea,
  FieldError,
  FormSingleColumnGridRow,
  NotesDiv,
} from 'shared/styles/components/Form';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const VehicleNotes = ({ formikProps: { values, touched, errors, handleChange, handleBlur } }) => {
  return (
    <>
      <NotesDiv
        vehicleEdit
        title="This field should not be used for personal identifiable information."
      >
        Notes
        <IconSvgComponent svgFileName="info" />
      </NotesDiv>
      <FormSingleColumnGridRow>
        <DescriptionTextArea
          name="notes"
          vehicleNotes
          value={values.notes}
          touched={touched.notes}
          validationError={errors.notes}
          onChange={handleChange}
          onBlur={handleBlur}
          rows={10}
          cols={5}
        />
        <FieldError>{errors.notes}</FieldError>
      </FormSingleColumnGridRow>
    </>
  );
};

export default VehicleNotes;
