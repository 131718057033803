import {
  mapRecipientDataToFrontend,
  recipientGroupMapping,
} from 'app/features/recipientGroups/utilities/recipientGroupMapping';
import { apiCall } from 'core/api/API';
import { sortAscendingAlpha } from 'shared/utilities/general';

export const getRecipientGroupsApi = async ({ organizationId }) => {
  const apiResponse = await apiCall({
    method: 'get',
    path: `/user_group`,
    queryStringParameters: {
      organization_id: `${organizationId}`,
    },
  });

  let recipientGroups = apiResponse.data.message.map(recipient =>
    mapRecipientDataToFrontend(recipient, recipientGroupMapping),
  );
  recipientGroups = recipientGroups?.sort((r1, r2) =>
    sortAscendingAlpha(r1.recipientGroupName, r2.recipientGroupName),
  );

  return recipientGroups;
};
