export const findAlertNotificationSelector = (state, alertNotificationGuid) => {
  const alertNotification = state.alert.alertNotifications.find(
    n => n.alertNotificationGuid === alertNotificationGuid,
  );

  if (!alertNotification) {
    throw new Error(
      `Alert Notification with alertNotificationGuid ${alertNotificationGuid} not found`,
    );
  }

  return alertNotification;
};
