import { useEffect, useState } from 'react';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import {
  CustomAlertRowWrapper,
  CustomAlertCheckWrapper,
  RecipientFullName,
} from 'shared/styles/components/ManageAlerts';
import { determineCustomAlertMethod } from 'app/shared/utilities/determineCustomAlertMethod';
import { alertMethodConstants } from 'app/features/alerts/data/enums/alertMethodConstants';
import useAlertFormsProviderContext from 'app/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';

const CustomAlertMethodOption = ({
  currentRecipientGroupMember,
  updatedGroupMemberOptions,
  setUpdatedGroupMemberOptions,
}) => {
  let { recipientUserFullName, recipientUserName } = currentRecipientGroupMember;

  let updatedMember = updatedGroupMemberOptions.find(
    member => member.recipientUserName === recipientUserName,
  );

  updatedGroupMemberOptions = updatedGroupMemberOptions.filter(
    member => member.recipientUserName !== recipientUserName,
  );

  console.log('updatedMember', updatedMember);

  let { sms, email } = updatedMember;

  let handleCheckboxesChanged = ({ sms, email }) => {
    let recipientUserAlertMethod = determineCustomAlertMethod(sms, email);
    console.log('handleCheckboxesChanged', sms, email, recipientUserAlertMethod);
    console.log('existing updatedGroupMemberOptions', updatedGroupMemberOptions);
    let newMembers = [
      ...updatedGroupMemberOptions,
      {
        recipientUserName,
        sms,
        email,
        recipientUserAlertMethod,
      },
    ];

    console.log('newMembers', newMembers);
    setUpdatedGroupMemberOptions(newMembers);
    console.log('done');
  };

  return (
    <CustomAlertRowWrapper>
      <RecipientFullName title={recipientUserFullName}>{recipientUserFullName}</RecipientFullName>
      <CustomAlertCheckWrapper>
        <CheckboxInner
          title="Text Alert"
          id="textCheckbox"
          customAlerts
          isChecked={sms}
          onClick={() => {
            console.log('sms', sms);
            handleCheckboxesChanged({
              sms: !sms,
              email: email,
            });
          }}
        />
      </CustomAlertCheckWrapper>
      <CustomAlertCheckWrapper>
        <CheckboxInner
          title="Email Alert"
          id="emailCheckbox"
          customAlerts
          isChecked={email}
          onClick={() => {
            console.log('email', email);

            handleCheckboxesChanged({
              sms: sms,
              email: !email,
            });
          }}
        />
      </CustomAlertCheckWrapper>
    </CustomAlertRowWrapper>
  );
};

export default CustomAlertMethodOption;
