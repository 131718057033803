import { useState, useEffect } from 'react';

export default ({ fetchAPIData, dependencies }) => {
  const [dataLoaded, setDataLoaded] = useState(null);
  const fetchData = async () => {
    setDataLoaded(false);
    const response = await fetchAPIData();
    setDataLoaded(response);
  };

  useEffect(() => {
    fetchData();
  }, [].concat(dependencies));

  return dataLoaded;
};
