import { userRoleOptions as userRoles, FIELD_SOLUTION_ENGINEER } from 'shared/constants/users';

export const selectUserRole = user => (user ? user.userRole : null);

export const getPermissions = user => {
  if (user?.user?.userRole !== userRoles[FIELD_SOLUTION_ENGINEER].value) {
    return user?.user?.permissions;
  } else {
    if (user?.user?.organizationId === user?.filteredOrganizationId) {
      return user?.user?.permissions;
    } else if (user?.orgPermissions?.orgId === user?.filteredOrganizationId) {
      return user?.orgPermissions?.permissions;
    } else {
      return [];
    }
  }
};

export const checkForUserPermission = (user, requiredPermission) => {
  if (user?.user?.userRole !== userRoles[FIELD_SOLUTION_ENGINEER].value) {
    return user?.user?.permissions?.some(p => p === requiredPermission);
  } else {
    if (user?.user?.organizationId === user?.filteredOrganizationId) {
      return user?.user?.permissions?.some(p => p === requiredPermission);
    } else if (user?.orgPermissions?.orgId === user?.filteredOrganizationId) {
      return user?.orgPermissions?.permissions?.some(p => p === requiredPermission);
    } else {
      return false;
    }
  }
};
