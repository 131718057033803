import React from 'react';

import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

import colors from 'shared/constants/colors';
import xIcon from 'assets/svg/x-icon.svg';

export default function({ width, style }) {
  return (
    <div
      style={{
        ...styles.imageContainer,
        ...(width && {
          width: `${width}px`,
          display: 'inline-block',
          margin: '0 10px 0 0',
        }),
      }}
    >
      <IconSvgComponent style={styles.icon} svgFileName={'x-icon'} />
      <div
        style={{
          ...styles.circle,
          ...(width && { borderWidth: `${width * 0.1}px` }),
          ...(width && { paddingBottom: `calc(100% - ${width * 0.2}px)` }),
        }}
      />
    </div>
  );
}
const styles = {
  imageContainer: {
    position: 'relative',
    width: '17%',
    margin: '0 auto 14px',
  },
  circle: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '0px',
    margin: '0 auto',
    paddingBottom: 'calc(100% - 12px)',
    borderRadius: '50%',
    borderColor: colors.midnight,
    borderWidth: '6px',
    borderStyle: 'solid',
  },
  icon: {
    position: 'absolute',
    width: '43.4%',
    top: '35%',
    left: 0,
    right: 0,
    margin: 'auto',
  },
};
