import { useField, useFormikContext } from 'formik';
import { useEffect } from 'react';

const useMakeFormikField = ({
  fieldName,
  fieldValue,
  triggerValidation = false,
  touched = false,
}) => {
  if (!fieldName) throw new Error('useMakeFormikField: fieldName is required');

  const [field, meta, { setValue, setTouched }] = useField(fieldName);

  useEffect(() => {
    setValue(fieldValue, triggerValidation);
    setTouched(touched, triggerValidation);
  }, [fieldValue, triggerValidation, touched]);

  return { field, meta, validationResult: { errorMessage: meta.error } };
};

export default useMakeFormikField;
