import React, { useState } from 'react';
import { connect } from 'react-redux';

import AnalyticsHeatmapContainer from 'features/analytics/heatmap/components/AnalyticsHeatmapContainer';
import useAPIData from 'shared/hooks/useAPIData';
import { fetchVehicles } from 'core/redux/vehicles/actions';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';

const AnalyticsHeatmapPage = ({ filteredOrganizationId, fetchVehicles }) => {
  const vehiclesLoaded = useAPIData({
    fetchAPIData: () => fetchVehicles(),
    dependencies: [filteredOrganizationId],
  });

  return vehiclesLoaded ? (
    <AnalyticsHeatmapContainer organizationId={filteredOrganizationId} />
  ) : (
    <LoadingOverlay />
  );
};

export default connect(
  state => ({
    filteredOrganizationId: state.user.filteredOrganizationId,
  }),
  dispatch => ({
    fetchVehicles: () => dispatch(fetchVehicles()),
  }),
)(AnalyticsHeatmapPage);
