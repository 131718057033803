import { initializeWcpLogger } from 'app/core/error/utilities/initializeWcpLogger';
import useWcpLogger from 'app/core/error/utilities/useWcpLogger';
import RouterSetup from 'app/core/routes/RouteSetup';
import { getLoggedInStatus } from 'core/redux/user/actions';
import AlertBarNotifier from 'core/widgets/notifiers/AlertBarNotifier';
import NewReleaseNotifier from 'core/widgets/notifiers/NewReleaseNotifier';
import SessionTimeoutNotifier from 'core/widgets/notifiers/SessionTimeoutNotifier';
import SidebarAlertsNotifier from 'core/widgets/notifiers/SidebarAlertsNotifier';
import ToastNotifier from 'core/widgets/notifiers/ToastNotifier';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import SoloModal from 'shared/modals/SoloModal';
import Wrapper from 'shared/styles/components/Wrapper';
import theme from 'shared/styles/global/theme';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import { loginIsFresh } from 'shared/utilities/localStore';
import { ThemeProvider } from 'styled-components';

import { useLDClient } from 'launchdarkly-react-client-sdk';

// console.log('***Loading WCP...***');
initializeWcpLogger();

const App = () => {
  const ldClient = useLDClient();

  const dispatch = useDispatch();
  const {
    alertsVisible,
    subscriptionExpiryAlertBar,
    subscriptionExpiryAlertBarDismissed,
    checkingSession,
    filteredOrganizationId,
  } = useSelector(state => ({
    filteredOrganizationId: state.user.filteredOrganizationId,
    alertsVisible: state.ui.alertsVisible,
    subscriptionExpiryAlertBar: state.ui.subscriptionExpiryAlertBar,
    checkingSession: state.user.checkingSession,
    subscriptionExpiryAlertBarDismissed:
      (state.cached.subscriptionExpiryAlertBarDismissed &&
        state.cached.subscriptionExpiryAlertBarDismissed) ||
      (state.ui.subscriptionExpiryAlertBar && state.ui.subscriptionExpiryAlertBar.dismissed),
  }));
  useWcpLogger();
  // todo: fix this from rendering App multiple times
  useEffect(() => {
    dispatch(getLoggedInStatus());
  }, []);

  const isLoggedIn = loginIsFresh();

  const shouldDisplaySubscriptionExpiryAlertBar =
    isLoggedIn && subscriptionExpiryAlertBar !== null && !subscriptionExpiryAlertBarDismissed;

  useEffect(() => {
    if (filteredOrganizationId)
      ldClient.identify({
        kind: 'organization',
        key: filteredOrganizationId,
      });
  }, [filteredOrganizationId]);

  return !checkingSession ? (
    <ThemeProvider theme={theme}>
      <Wrapper>
        {shouldDisplaySubscriptionExpiryAlertBar && (
          <AlertBarNotifier alertBarData={subscriptionExpiryAlertBar} />
        )}

        <RouterSetup />
        <SidebarAlertsNotifier expanded={alertsVisible} />
        <ToastNotifier />
        <NewReleaseNotifier />
        <SessionTimeoutNotifier />
        <SoloModal />
      </Wrapper>
    </ThemeProvider>
  ) : (
    <LoadingOverlay externalStyles={{ overlay: { left: '0px' } }} />
  );
};

export default App;
