import { Cards } from 'shared/styles/components/MobileCard';
import ConfigurationLibraryCard from 'app/features/configurations/mobile/ConfigurationLibraryCard';
import ConfigurationLibraryActionMenu from 'app/features/configurations/menus/ConfigurationLibraryActionMenu';

const ConfigurationLibraryCardContainer = ({ configurationList }) => {
  const getActionMenu = configData => {
    return <ConfigurationLibraryActionMenu configData={configData} />;
  };
  return (
    <div>
      <Cards>
        {configurationList.map(c => (
          <ConfigurationLibraryCard
            configuration={c}
            key={c.configId}
            actionMenuComponent={() => getActionMenu(c)}
          />
        ))}
      </Cards>
    </div>
  );
};

export default ConfigurationLibraryCardContainer;
