import { useEffect, useState } from 'react';

const useAllExpanded = ({ filteredVehicleCount }) => {
  const [collapsedIndividualItems, setCollapsedIndividualItems] = useState(0);

  const [allExpanded, setAllExpanded] = useState(true);

  useEffect(() => {
    if (filteredVehicleCount === collapsedIndividualItems && filteredVehicleCount !== 0) {
      setAllExpanded(false);
    } else if (collapsedIndividualItems === 0) {
      setAllExpanded(true);
    }
  }, [collapsedIndividualItems]);

  return {
    allExpanded,
    setAllExpanded,
    collapsedIndividualItems,
    setCollapsedIndividualItems,
  };
};

export default useAllExpanded;
