import { Formik } from 'formik';

import Button from 'shared/styles/components/Button';
import FormWrapper, {
  Form,
  FormHeadWithSmallerMargin,
  FormIcon,
  FormMessage,
  FormTitle,
} from 'shared/styles/components/Form';
import { ActionsContainer, ModalContent } from 'shared/styles/components/Modal';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import CustomModal from 'shared/ui/modals/CustomModal';

const GenericFormModal = ({
  icon,
  children,
  title,
  initialValues,
  submitHandler,
  validator,
  handleCloseModal,
  errorMessage,
  submitButtonText,
  submitButtonDisabled,
  customWidth,
  validateOnBlur,
  validateOnChange,
}) => {
  return (
    <CustomModal
      customWidth={customWidth}
      title={
        <>
          <FormIcon>{icon}</FormIcon>
          <FormTitle>{title}</FormTitle>
        </>
      }
      handleCloseModal={handleCloseModal}
    >
      <FormWrapper>
        <Formik
          initialValues={initialValues}
          onSubmit={submitHandler}
          validate={validator}
          validateOnChange={validateOnChange}
          validateOnBlur={validateOnBlur}
        >
          {formikProps => (
            <Form>
              {errorMessage && (
                <FormHeadWithSmallerMargin>
                  <FormMessage message={errorMessage}>{errorMessage}</FormMessage>
                </FormHeadWithSmallerMargin>
              )}
              <ModalContent>{children(formikProps)}</ModalContent>
              <ActionsContainer>
                <Button onClick={handleCloseModal} withRightSpacer default type="button">
                  Cancel
                </Button>
                <ButtonWithLoader
                  isLoading={formikProps.isSubmitting}
                  confirmText={submitButtonText}
                  loadingStyleProp={'submittingWithSpinnerModal'}
                  notLoadingStyleProp={'mediumAlt'}
                  clickHandler={formikProps.submitForm}
                  disabled={submitButtonDisabled || Object.entries(formikProps.errors).length > 0}
                />
              </ActionsContainer>
            </Form>
          )}
        </Formik>
      </FormWrapper>
    </CustomModal>
  );
};

export default GenericFormModal;
