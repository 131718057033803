import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';

export const recipientGroupDeleteSuccess = () =>
  addNotification({
    notification: createNotification(LEVELS.SUCCESS, 'Successfully Deleted Recipient Group'),
  });

export const recipientGroupDeleteError = () =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Deleting Recipient Group'),
  });

export const recipientGroupCreateSuccess = () =>
  addNotification({
    notification: createNotification(LEVELS.SUCCESS, 'Successfully Created Recipient Group'),
  });

export const recipientGroupCreateError = err =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Creating Recipient Group', err),
  });

export const recipientGroupEditSuccess = () =>
  addNotification({
    notification: createNotification(LEVELS.SUCCESS, 'Successfully Editted Recipient Group'),
  });

export const recipientGroupEditError = () =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Editing Recipient Group'),
  });
