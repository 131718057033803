import { formatISOTimestampToShortDate } from 'shared/utilities/time';

export const mapVehiclePlaybackProviderStateToFormik = ({
  selectedVehicleId,
  startDate,
  startTime,
  endDate,
  endTime,
  selectedGranularity,
}) => {
  const formikFieldValues = {
    startDateField: formatISOTimestampToShortDate(startDate),
    startTimeField: startTime,
    endDateField: formatISOTimestampToShortDate(endDate),
    endTimeField: endTime,
    selectedVehicleId: selectedVehicleId || null,
    selectedGranularity: selectedGranularity || 5,
  };

  return formikFieldValues;
};
