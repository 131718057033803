import { SimpleDropdownBtn, SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';

const DeleteMaintenanceEventMenuItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Delete Maintenance Event</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default DeleteMaintenanceEventMenuItem;
