import { maintenanceTypeConstants } from 'app/features/maintenance/data/maintenanceTypeConstants';
import DropdownOptions from 'app/shared/utilities/dropdown/DropdownOptions';

// dropdown data
export const maintenanceTypeDropdownData = [
  { value: maintenanceTypeConstants.BRAKES, label: 'Brakes' },
  { value: maintenanceTypeConstants.OIL, label: 'Oil Change' },
  { value: maintenanceTypeConstants.TIRES, label: 'Tires' },
  { value: maintenanceTypeConstants.TUNE_UP, label: 'Tune Up' },
  { value: maintenanceTypeConstants.DIAGNOSTIC, label: 'Check Engine' },
  { value: maintenanceTypeConstants.AERIAL_SERVICE, label: 'Aerial Service' },
  { value: maintenanceTypeConstants.AERIAL_INSPECTION, label: 'Aerial Inspection' },
  { value: maintenanceTypeConstants.ALIGNMENT_SERVICE, label: 'Alignment Service' },
  { value: maintenanceTypeConstants.GENERATOR_SERVICING, label: 'Generator Servicing' },
  { value: maintenanceTypeConstants.PM_A, label: 'PM A' },
  { value: maintenanceTypeConstants.PM_B, label: 'PM B' },
  { value: maintenanceTypeConstants.PM_C, label: 'PM C' },
  { value: maintenanceTypeConstants.PM_D, label: 'PM D' },
  { value: maintenanceTypeConstants.PUMP_RECERTIFICATION, label: 'Pump Re-Certification' },
  { value: maintenanceTypeConstants.PUMP_SERVICE, label: 'Pump Service' },
  { value: maintenanceTypeConstants.RADAR_CERTIFICATION, label: 'Radar Certification' },
  { value: maintenanceTypeConstants.RADIO_MAINTENANCE, label: 'Radio Maintenance' },
  { value: maintenanceTypeConstants.STATE_VEHICLE_INSPECTION, label: 'State Vehicle Inspection' },
  { value: maintenanceTypeConstants.TAG_RENEWAL, label: 'Tag Renewal' },
  { value: maintenanceTypeConstants.TRANSMISSION_SERVICE, label: 'Transmission Service' },
];

// getters
export const getMaintenanceTypeDropdownObject = ({ value, label }) => {
  return value
    ? maintenanceTypeDropdownData.find(i => i.value === value)
    : label
    ? maintenanceTypeDropdownData.find(i => i.label === label)
    : null;
};

export const getMaintenanceTypeValueFromLabel = label => {
  return maintenanceTypeDropdownData.find(i => i.label === label)?.value;
};

export const getMaintenanceTypeLabelFromValue = value => {
  return maintenanceTypeDropdownData.find(i => i.value === value)?.label;
};

export const maintenanceTypeDropdownOptions = new DropdownOptions(maintenanceTypeDropdownData);
