import React, { useState } from 'react';
import { vehicleLockStatusViewData, vehicleTypeViewData } from 'shared/constants/vehicle';
import {
  CardLabel,
  CardValue,
  CardRow,
  CardName,
  CardType,
  ShowCollapseButton,
  CardContainer,
  ButtonWrapper,
  CardOuterWrapper,
  VehicleIcon,
  CardIconWrapper,
  CardCollapsedInfo,
  ActionMenuBtnWrapper,
  CardExpandedInfo,
  CardSimNotActive,
  CardBottomWrapper,
} from 'shared/styles/components/MobileCard';
import { getFormattedDuid } from 'shared/utilities/vehicle';
import { displayVehicleIcon } from 'features/fleet/vehicles/helpers/utilities';
import { SimBanner } from 'features/fleet/vehicles/components/forms/custom/SimBanner';

export default function VehicleCard({ vehicle, actionMenuComponent }) {
  const [expanded, setExpanded] = useState(false);

  const handleToggleShowCollapse = () => {
    setExpanded(!expanded);
  };

  // temp mapping fix
  vehicle.vehicleId = vehicle.vehicle_id;
  vehicle.vehicleName = vehicle.meta.label;

  return (
    <CardOuterWrapper>
      <CardContainer vehicleExpanded={expanded}>
        <ActionMenuBtnWrapper>{actionMenuComponent(vehicle)}</ActionMenuBtnWrapper>
        <CardIconWrapper>
          <VehicleIcon
            title={vehicle.meta.out_of_service ? 'This vehicle is out of service' : 'Vehicle'}
            svgFileName={
              vehicle.meta.out_of_service
                ? 'out-of-service-small'
                : displayVehicleIcon(vehicle.meta.vehicle_type)
            }
          />
          <CardCollapsedInfo>
            <CardRow header>
              <CardName title={vehicle.meta.label}>{vehicle.meta.label}</CardName>
            </CardRow>
            <CardRow header>
              <CardType>{vehicleTypeViewData[vehicle.meta.vehicle_type].title}</CardType>
            </CardRow>
          </CardCollapsedInfo>
        </CardIconWrapper>
        <CardExpandedInfo expanded={expanded}>
          <CardRow>
            <CardLabel>VSG Registration ID:</CardLabel>
            <CardValue>{getFormattedDuid(vehicle)}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Vehicle Year:</CardLabel>
            <CardValue>{vehicle.meta.mfg_year}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Vehicle Make:</CardLabel>
            <CardValue>{vehicle.meta.make}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Vehicle Model:</CardLabel>
            <CardValue>{vehicle.meta.model}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>License Plate:</CardLabel>
            <CardValue>{vehicle.meta.license_plate}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>VIN:</CardLabel>
            <CardValue>{vehicle.meta.vin}</CardValue>
          </CardRow>
          <CardRow>
            <CardLabel>Security Lock: </CardLabel>
            <CardValue>{vehicle.meta.formatted_lock_status}</CardValue>
          </CardRow>
          {vehicle.meta.gtt_enabled && (
            <CardRow>
              <CardLabel>Miovision Integration:</CardLabel>
              <CardValue>{vehicle.meta.gtt_enabled ? 'Enabled' : 'Not Enabled'}</CardValue>
            </CardRow>
          )}
          {vehicle.meta.haas_enabled && (
            <CardRow>
              <CardLabel>HAAS Integration:</CardLabel>
              <CardValue>
                {vehicle.meta.haas_enabled ? 'Enabled' : 'Pending'}
                {vehicle.meta.haas_public_alerting &&
                vehicle.meta.haas_public_alerting !== 'ALWAYS_ON'
                  ? ' w/ Alerting'
                  : ''}
              </CardValue>
            </CardRow>
          )}
        </CardExpandedInfo>
      </CardContainer>
      <CardBottomWrapper>
        {vehicle.cell?.state !== 'active' ? (
          <CardSimNotActive>
            <SimBanner vehicle={vehicle} simMessage={true} />
          </CardSimNotActive>
        ) : null}

        <ButtonWrapper>
          <ShowCollapseButton onClick={(e) => handleToggleShowCollapse(e)}>
            {expanded ? 'COLLAPSE' : 'SEE DETAILS'}
          </ShowCollapseButton>
        </ButtonWrapper>
      </CardBottomWrapper>
    </CardOuterWrapper>
  );
}
