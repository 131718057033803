// TODO: refactor as a general utility for all 207 responses
export const process207 = ({ status, response, vehicleName, dispatchNotification }) => {
  // should always be 207 in this case
  if (status !== 207) throw new Error('Unexpected response status code');
  else {
    // get status code for each message in the response
    const statusCodes = response.message.map(ir => ir.statusCode);

    const allSuccess = statusCodes.every(code => code === 200);
    const allFailure = statusCodes.every(code => code !== 200);

    if (allSuccess) {
      dispatchNotification.vehicleEditSuccess({ vehicleName });
    } else if (allFailure) {
      dispatchNotification.vehicleEditError({
        vehicleName,
        err: response.message.map(m => m.error || m.message).join('\n'),
      });
    } else {
      dispatchNotification.vehicleEditDeviceWarning({
        message: response.message.map(m => m.error || m.message).join('\n'),
      });
    }
  }
};
