import styled from 'styled-components';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

export const iconStyles = `position: absolute;
right: 0px;
top: -12px;
width: 40px;
height: 40px;
padding: 10px;
cursor: pointer;
background: transparent;
border: none;

@media screen and (max-width: 1024px) {
  top: -3px;
}
`;

export const vehicleEditVinLookupIconStyle = `position: absolute;
width: 40px;
height: 40px;
padding: 10px;
cursor: pointer;
background: transparent;
border: none;
margin-left:-30px;
margin-top:-8px;
`;

export const SpinnerContainer = styled.div`
  ${iconStyles}
`;

export const VehicleEditSpinnerContainer = styled.div`
  ${vehicleEditVinLookupIconStyle}
`;

export const LockUnlockButton = styled(IconSvgComponent)`
  margin-right: 20px;
  cursor: pointer;

  @media screen and (max-width: 1024px) {
    margin-right: 15px;
  }
`;

export const SimIcon = styled(IconSvgComponent)`
  margin-right: 10px;
`;

export const ClearFilterIcon = styled(IconSvgComponent)`
  margin-right: 10px;
  cursor: pointer;

  ${props =>
    props.mapFilters &&
    `
    display: inline-flex;
    margin-left: 5px;
  `}
`;
