/*
 * External dependencies
 */
import { handleActions, combineActions } from 'redux-actions';

/*
 * Actions
 */
import {
  receiveConfigurations,
  receiveConfigurationUpdates,
  requestUpdateConfiguration,
  rejectUpdateConfiguration,
  rejectDeleteConfiguration,
  requestDeleteConfiguration,
  requestUpdateVehiclesWithConfiguration,
  receiveUpdateVehiclesWithConfiguration,
} from './actions';

const defaultState = {
  configurations: [],
  configurationUpdates: [],
  loading: {
    updateDeviceRequest: null,
  },
};

const configurations = handleActions(
  {
    [requestUpdateVehiclesWithConfiguration]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        updateDeviceRequest: true,
      },
    }),
    [receiveUpdateVehiclesWithConfiguration]: (state, action) => ({
      ...state,
      loading: {
        ...state.loading,
        updateDeviceRequest: false,
      },
    }),
    [receiveConfigurations]: (state, action) => ({
      ...state,
      configurations: action.payload.map(configuration => ({
        ...configuration,
        loadingStatus: false,
        deviceCount:
          configuration.supportedDevices?.length !== null &&
          configuration.supportedDevices?.length !== undefined
            ? configuration.supportedDevices?.length
            : 0,
      })),
    }),
    [requestDeleteConfiguration]: (state, { payload: configId }) => {
      return {
        ...state,
        configurations: state.configurations.map(configuration => ({
          ...configuration,
          loadingStatus: configuration.loadingStatus || configuration.configId === configId,
        })),
      };
    },
    [receiveConfigurationUpdates]: (state, { payload }) => {
      return {
        ...state,
        configurationUpdates: payload,
      };
    },
    [requestUpdateConfiguration]: (state, { payload: configId }) => {
      return {
        ...state,
        configurations: state.configurations.map(configuration => {
          configuration.loadingStatus =
            configuration.loadingStatus || configuration.configId === configId;
          return configuration;
        }),
      };
    },
    [requestDeleteConfiguration]: (state, { payload: configId }) => {
      return {
        ...state,
        configurations: state.configurations.map(configuration => {
          configuration.loadingStatus =
            configuration.loadingStatus || configuration.configId === configId;
          return configuration;
        }),
      };
    },
    [combineActions(rejectUpdateConfiguration, rejectDeleteConfiguration)]: (
      state,
      { payload: { configId } },
    ) => {
      return {
        ...state,
        configurations: state.configurations.map(configuration => {
          configuration.loadingStatus =
            configuration.loadingStatus && configuration.configId !== configId;
          return configuration;
        }),
      };
    },
  },
  defaultState,
);

export default configurations;
