import { useParams } from 'react-router-dom';

import PageListWrapper, { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import AlertFormsProvider from 'app/features/alerts/services/providers/AlertFormsProvider/AlertFormsProvider';
import EditAlertForm from 'app/features/alerts/ui/components/AlertForms/EditAlertForm/EditAlertForm';

const EditAlertPage = () => {
  const { alertId } = useParams();

  return (
    <PageListWrapper>
      <PageListHead stackable>
        <PageListTitle>Edit Alert</PageListTitle>
      </PageListHead>
      <AlertFormsProvider alertId={alertId}>
        <EditAlertForm />
      </AlertFormsProvider>
    </PageListWrapper>
  );
};

export default EditAlertPage;
