// updateAlertNotificationDetailsAction  →  updateAlertNotificationDetailsReducer
export const updateAlertNotificationDetailsReducer = (state, payload) => {
  const { notificationIndex, delayMinutes, cooldownMinutes, isLocationIncluded } = payload;

  const alert = state.alert;
  if (delayMinutes !== undefined) {
    if (notificationIndex !== undefined) {
      alert.alertNotifications[notificationIndex].delayMinutes = delayMinutes;
    }
  }

  if (cooldownMinutes !== undefined) {
    if (notificationIndex !== undefined) {
      alert.alertNotifications[notificationIndex].cooldownMinutes = cooldownMinutes;
    }
  }
  if (isLocationIncluded !== undefined) {
    if (notificationIndex !== undefined) {
      alert.alertNotifications[notificationIndex].isLocationIncluded = isLocationIncluded;
    }
  }

  return {
    ...state,
    alert,
  };
};
