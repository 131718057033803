import { useSelector } from 'react-redux';

import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { useMutation, useQueryClient } from '@tanstack/react-query';

// const createMutationKey = (apiFn, params) => [
//   apiFn.name,
//   ...Object.entries(params).map(([key, value]) => `${key}:${value}`),
// ];

const useGenericMutation = ({ apiFn, onSuccessCallback, onErrorCallback, invalidateQueries }) => {
  const queryClient = useQueryClient();

  const organizationId = useSelector(state => state.user.filteredOrganizationId);

  const mutationResult = useMutation({
    mutationFn: params => apiFn({ organizationId, ...params }),
    onSuccess: onSuccessCallback
      ? response => {
          try {
            if (invalidateQueries) {
              queryClient.invalidateQueries(invalidateQueries.map(q => q.queryKey[0]));
            }
            onSuccessCallback(response);
          } catch (err) {
            // catch error in success callback
            logWcpError('Error in Success Callback', err);
          }
        }
      : () => {
          // default log success
        },
    onError: onErrorCallback
      ? err => {
          try {
            onErrorCallback(err);
          } catch (error) {
            // catch error in error callback
            logWcpError(error);
          }
        }
      : () => {
          logWcpError(err);
        },
  });

  return {
    ...mutationResult,
  };
};

export default useGenericMutation;
