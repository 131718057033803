import React from 'react';
import { BlueButton } from 'shared/styles/components/Button';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const AddRecipientButton = ({ handleAddRecipient }) => {
  return (
    <BlueButton addRecipient onClick={handleAddRecipient}>
      <IconSvgComponent svgFileName="plus-white" alt="Add Another Recpient" />
      Add Another Recipient
    </BlueButton>
  );
};

export default AddRecipientButton;
