import styled from 'styled-components';
import colors from 'shared/constants/colors';
import closeX from 'assets/svg/x.svg';
import device from 'shared/constants/sizing';

export const VehicleDetailCardContainer = styled.div`
  position: absolute;
  width: 330px;
  left: 18px;
  top: 130px;
  background-color: white;
  z-index: 1;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.1);

  @media ${device.large} {
    top: 75px;
  }

  ${props =>
    props.playbackCard &&
    `
    left: auto;
    right: 20px;
    top: 110px;
    @media ${device.large} {
      top: 110px;
    }
  `};
`;

export const ModalSection = styled.div`
  padding: 15px 10px 20px 10px;
  letter-spacing: 0.5px;

  ${props =>
    props.withBorder &&
    `
    border-bottom: 1px solid ${colors.haze};
  `};
  ${props =>
    props.playbackCard &&
    `
    padding: 15px 20px 0px;
  `};

  ${props =>
    props.topSection &&
    `
    padding: 0px 0px 0px 20px;
  `};

  ${props =>
    props.main &&
    `
    padding: 10px 5px 10px 10px;
  `};
`;

export const SectionLabel = styled.div`
  font-size: 10px;
  text-transform: uppercase;
  color: ${colors.haze};

  ${props =>
    props.maintenance &&
    `
    margin-top: -5px;
    font-size: 10px;
    font-weight:700;
    color: ${colors.midnight};
  `};
`;

export const DetailColumnsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const DetailColumn = styled.div`
  flex: 1 1 50%;
  margin-top: 0px;
  margin-right: 5px;

  ${props =>
    props.maintenance &&
    `
    margin-right: 0px;
  `};
`;

// fields
export const VehicleLabel = styled.div`
  color: ${colors.midnight};
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 6px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 220px;
`;

export const VehicleType = styled.span`
  display: block;
  color: ${colors.haze};
  text-transform: uppercase;
  font-size: 12px;
  line-height: 1;
  margin-bottom: 4px;
`;

export const VehicleStatus = styled.span`
  display: block;
  font-size: 12px;
`;

export const VehicleTime = styled.div`
  font-size: 10px;
  text-align: left;
  margin-bottom: 5px;
  margin-top: 10px;
  width: 100%;
`;
export const TimeLabel = styled.span`
  color: ${colors.haze};
`;
export const TimeValue = styled.span`
  color: ${colors.midnight};
  margin-top: 3px;
`;

export const VehicleDetailLabel = styled.span`
  color: ${colors.haze};
  font-size: 10px;
`;

export const VehicleDetailValue = styled.span`
  color: ${colors.midnight};
  font-size: 10px;
`;

export const ActionMenuBtnWrapper = styled.div`
  width: 30px;
  margin-right: -45px;
  margin-top: -20px;
  float: right;
  @media all and (display-mode: fullscreen) {
    display: none;
  }
`;

export const VehicleNameAndIconDiv = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const VehicleNameAndIconInnerDiv = styled.div`
  margin-left: 12px;
`;

export const VehicleIcon = styled.img`
  width: 28px;
`;

export const CloseButton = styled.div`
  width: 12px;
  height: 12px;
  float: right;
  margin-top: 7px;
  margin-right: 7px;
  position: relative;
  cursor: pointer;
  background-image: url(${closeX});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
`;

export const AddonItem = styled.span`
  color: ${colors.haze};
  font-size: 10px;
  margin-top: 5px;

  ${props =>
    props.alert &&
    `
    font-weight:800;
    color: ${colors.cherry};
  `};
`;

export const MapVehicleCardWrapper = styled.div`
  max-height: 375px;
  overflow-y: scroll;
  overflow-x: hidden;
  @media ${device.large} {
    max-height: calc(100vh - 250px);
    overflow-y: auto;
  }
`;

export const IconWrapper = styled.span`
  margin-right: 3px;
  margin-left: -3px;
`;

export const NoClickWrapper = styled.div`
  cursor: default;
`;

export const MaintenanceStatusWrapper = styled.div`
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: -3px;
`;
