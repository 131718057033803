import React from 'react';

import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import useLockVehicleMutation, {
  LOCK_OPERATION,
} from 'features/fleet/vehicles/hooks/useLockVehicleMutation';
import SimpleConfirmationModal from 'shared/ui/modals/SimpleConfirmationModal';

const ToggleLockVehicleModal = ({
  modalData: { show, vehiclesOrVehicle, lockOperation },
  handleCloseModal,
}) => {
  const lockVehicleMutation = useLockVehicleMutation({
    vehiclesOrVehicle,
    lockOperation,
  });
  const getModalText = (vehiclesOrVehicle) => {
    if (lockOperation === LOCK_OPERATION.LOCK) {
      if (Array.isArray(vehiclesOrVehicle)) {
        return `Enabling the security lock will prevent all vehicles' CenCom Core devices from being
        updated via the device’s USB port. Configuration and firmware updates for CenCom Core will
        only be available through OTA.`;
      } else {
        return `Enabling the security lock will prevent all CenCom Core devices in this vehicle from
        receiving configuration and firmware updates via the device’s USB port. Configuration and
        firmware updates for CenCom Core will only be available through OTA.`;
      }
    } else {
      if (Array.isArray(vehiclesOrVehicle)) {
        return `Disabling the security lock will allow all vehicles’ CenCom Core devices to receive
        configuration and firmware updates via the device’s USB port and OTA.`;
      } else {
        return `Disabling the security lock will allow all CenCom Core devices in this vehicle to receive
        configuration and firmware updates via the device’s USB port and OTA.`;
      }
    }
  };

  return (
    <>
      {show && (
        <SimpleConfirmationModal
          customWidth={650}
          handleCloseModal={handleCloseModal}
          isLoading={lockVehicleMutation.isPending}
          icon={lockOperation === LOCK_OPERATION.LOCK ? `config-lock` : `config-unlock`}
          // iconDimensions={{ height: 23, width: 25 }}
          // alt="Lock"
          submitHandler={async () => {
            try {
              let response = await lockVehicleMutation.mutateAsync({
                lockOperation: lockOperation,
                vehiclesOrVehicle,
              });
              return response;
            } catch (err) {
              logWcpError(err);
            } finally {
              handleCloseModal();
            }
          }}
          bodyText={getModalText(vehiclesOrVehicle)}
          title={`${
            lockOperation === LOCK_OPERATION.LOCK ? `Lock` : `Unlock`
          } Configuration Updates ${Array.isArray(vehiclesOrVehicle) ? ' for All Vehicles' : ''}`}
          confirmText={`${lockOperation === LOCK_OPERATION.LOCK ? `Lock` : `Unlock`} Vehicle${
            Array.isArray(vehiclesOrVehicle) ? 's' : ''
          }`}
          cancelText="Cancel"
        />
      )}
    </>
  );
};

export default ToggleLockVehicleModal;
