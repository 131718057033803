import Button from 'shared/styles/components/Button';
import {
  ActionsContainer,
  BoldedItem,
  CloseButton,
  ModalText,
  Title,
} from 'shared/styles/components/Modal';

const DeactivateUserModal = ({
  confirmPrompt,
  confirmText,
  cancelText,
  confirmHandler, // user => dispatch(deactivateUser(user)),
  cancelHandler,
  userModalOrganizationId,
  userModalUsername,
  boldedItem,
  secondaryText,
}) => {
  return (
    <div>
      <CloseButton onClick={cancelHandler} />
      <Title>{confirmText}</Title>
      <ModalText>
        {confirmPrompt} <BoldedItem>{boldedItem}</BoldedItem>
      </ModalText>
      <ModalText>{secondaryText}</ModalText>
      <ActionsContainer>
        <Button onClick={cancelHandler} default>
          {cancelText}
        </Button>
        <Button
          mediumAlt
          style={styles.confirm}
          onClick={() => {
            confirmHandler({ userModalOrganizationId, userModalUsername });
            cancelHandler();
          }}
        >
          {confirmText}
        </Button>
      </ActionsContainer>
    </div>
  );
};

const styles = {
  confirm: {
    marginLeft: '10px',
  },
};

export default DeactivateUserModal;
