import { whitelistPath, organizationPath } from 'shared/constants/api';
import { apiCall } from 'core/api/API';

export const getWhitelists = async ({ organizationId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: whitelistPath(organizationId),
    method: 'get',
  });
};

export const getWhitelist = async ({ organizationId, productId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${whitelistPath(organizationId)}/${productId}`,
    method: 'get',
  });
};

export const addChannelToWhitelist = async ({ organizationId, productId, channel }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${whitelistPath(organizationId)}/${productId}/channel/${channel}`,
    method: 'post',
    headers: {
      'content-type': 'application/json',
    },
  });
};

export const removeChannelFromWhitelist = async ({ organizationId, productId, channel }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${whitelistPath(organizationId)}/${productId}/channel/${channel}`,
    method: 'del',
  });
};

export const deleteWhitelist = async ({ organizationId, productId }) => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `${whitelistPath(organizationId)}/${productId}`,
    method: 'del',
  });
};

export const getProductIds = async () => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/firmware/supported_product_ids`,
    method: 'get',
  });
};

export const getChannels = async productIds => {
  return await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/firmware/channel`,
    method: 'get',
    queryStringParameters: {
      product_ids: `${productIds.join(',')}`,
    },
  });
};
