import { alertTypeDropdownOptions } from 'app/features/alerts/data/ui/dropdown/alertTypeDropdownOptions';
import { getCooldownPeriodLabelFromValue } from 'app/features/alerts/data/ui/dropdown/cooldownPeriodDropdownData';

export const mapAlertsDataToCsv = ({ alerts }) => {
  const alertsForExport = alerts.map(a => ({
    Alert_Name: a.alertDetails.alertInfo.alertName,
    Condition: alertTypeDropdownOptions.getLabelByValue(a.alertDetails.alertInfo.alertType),

    Value: a.alertDetails.decorated.summaryTable.valueColumn,
    Vehicle_Or_Group: a.alertDetails.decorated.targetVehicleOrGroupName,
    Recipient: `${a.alertNotifications?.length > 1 ? '(Multi-level)' : ''} ${
      a.alertDetails.decorated.summaryTable.recipientColumn
    }`,
    Method: a.alertDetails.decorated.summaryTable.methodColumn,
    Cooldown_Period: getCooldownPeriodLabelFromValue(a.cooldownMinutes),
    Delay_: getCooldownPeriodLabelFromValue(a.delayMinutes),
    Is_Active: a.alertDetails.alertInfo.isActive ? 'Active' : 'Deactivated',
  }));
  return alertsForExport;
};
