import { Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { useQueryClient } from '@tanstack/react-query';
import ReminderDaysField from 'app/features/maintenance/components/MaintenanceSummary/fields/ReminderDaysField';
import ReminderEngineHoursField from 'app/features/maintenance/components/MaintenanceSummary/fields/ReminderEngineHoursField';
import ReminderMileageField from 'app/features/maintenance/components/MaintenanceSummary/fields/ReminderMileageField';
import { updateOrganizationAPI } from 'core/api/organizations';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { editMaintenanceRemindersSuccess } from 'features/people/organizations/helpers/notifications';
import { useFetchOrganizationQuery } from 'features/people/organizations/hooks/useFetchOrganizationQuery';
import Button from 'shared/styles/components/Button';
import {
  Form,
  FormActions,
  FormAsterisk,
  FormBody,
  FormFoot,
  FormLegend,
  FormTitle,
} from 'shared/styles/components/Form';
import { ManageRemindersFieldsWrapperDiv } from 'shared/styles/components/Maintenance';
import { ActionsContainer, ModalText } from 'shared/styles/components/Modal';
import { HaasIntegrationWrapperDiv } from 'shared/styles/components/VehicleEdit';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import CustomModal from 'shared/ui/modals/CustomModal';
import { validateMaintenanceReminders } from 'shared/utilities/validators';
import ReminderOilLifeField from 'app/features/maintenance/components/MaintenanceSummary/fields/ReminderOilLifeField';
import {
  addDisplayCommas,
  stripCommas,
  stripTrailingPercentage,
} from 'app/features/maintenance/utilities/formatting';
import MaintenanceSummaryContextProvider from 'app/features/maintenance/providers/MaintenanceSummaryContextProvider/MaintenanceSummaryContextProvider';

export const ManageRemindersModal = ({ modalData: { show }, handleCloseModal }) => {
  const [fetchOrganizationQuery, organization] = useFetchOrganizationQuery({
    enableRefetchInterval: false,
  });

  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const confirmHandler = async (values, formikActions) => {
    organization.reminderMileage = +stripCommas(values.reminderMileage);
    organization.reminderEngineHours = +stripCommas(values.reminderEngineHours);
    organization.reminderDays = +values.reminderDays;
    organization.reminderOilLife = +stripTrailingPercentage(values.reminderOilLife);
    try {
      let success = false;
      success = await updateOrganizationAPI(organization);
      if (success) {
        dispatch(editMaintenanceRemindersSuccess());
        queryClient.invalidateQueries('fetchScheduledMaintenanceEventsQuery');
      }
    } catch (err) {
      logWcpError(err);
    }

    formikActions.setSubmitting(false);
    handleCloseModal();
  };
  return (
    <MaintenanceSummaryContextProvider>
      {({ state: { allVehicles } }) => {
        const atLeastOneVehicleWithEnahcedTelematics = allVehicles?.some(v => {
          return v?.meta.enhanced_telematics_enabled === true;
        });
        return (
          show && (
            <CustomModal
              customWidth={650}
              title={
                <>
                  <FormTitle>{'Manage Reminders'}</FormTitle>
                </>
              }
              handleCloseModal={handleCloseModal}
            >
              <>
                <ModalText>
                  <HaasIntegrationWrapperDiv>
                    By setting the reminder threshold below, vehicles will be added to the
                    maintenance queue marked as “Due in Soon” once they pass the specified threshold
                    prior to reaching the actual maintenance interval.
                  </HaasIntegrationWrapperDiv>
                </ModalText>
                <Formik
                  initialValues={{
                    ...organization,
                    reminderMileage: organization.reminderMileage
                      ? addDisplayCommas(organization.reminderMileage)
                      : '',
                    reminderEngineHours: organization.reminderEngineHours
                      ? addDisplayCommas(organization.reminderEngineHours)
                      : '',
                    reminderDays: organization.reminderDays ? organization.reminderDays : '',
                    reminderOilLife: organization.reminderOilLife
                      ? organization.reminderOilLife
                      : '',
                  }}
                  validate={validateMaintenanceReminders}
                  validateOnChange={true}
                  validateOnBlur={false}
                  onSubmit={(values, formikActions) => confirmHandler(values, formikActions)}
                >
                  {formikProps => {
                    return (
                      <Form onSubmit={formikProps.submitForm}>
                        <FormBody>
                          <ManageRemindersFieldsWrapperDiv
                            enhancedTelematics={atLeastOneVehicleWithEnahcedTelematics}
                          >
                            <ReminderMileageField formikProps={formikProps} />
                            <ReminderEngineHoursField formikProps={formikProps} />
                            <ReminderDaysField formikProps={formikProps} />
                            {atLeastOneVehicleWithEnahcedTelematics && (
                              <ReminderOilLifeField formikProps={formikProps} />
                            )}
                          </ManageRemindersFieldsWrapperDiv>
                        </FormBody>
                        <FormFoot>
                          <FormLegend>
                            <FormAsterisk>*</FormAsterisk> required fields
                          </FormLegend>
                          <FormActions>
                            <ActionsContainer>
                              <Button
                                onClick={async () => {
                                  handleCloseModal();
                                }}
                                withRightSpacer
                                default
                                type="button"
                              >
                                Cancel
                              </Button>
                              <ButtonWithLoader
                                isLoading={formikProps.isSubmitting}
                                confirmText={'Submit'}
                                loadingStyleProp={'submittingWithSpinnerModal'}
                                notLoadingStyleProp={'mediumAlt'}
                                clickHandler={formikProps.submitForm}
                              />
                            </ActionsContainer>
                          </FormActions>
                        </FormFoot>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            </CustomModal>
          )
        );
      }}
    </MaintenanceSummaryContextProvider>
  );
};

export default ManageRemindersModal;
