import { sortAscendingAlpha } from 'shared/utilities/general';

class DropdownOptions {
  constructor(options) {
    this.options = options || [];
  }

  // sorted by default unless customSort is true, then we maintain passed in order
  getAllOptions({ includeReadonly = false } = { includeReadonly: false }) {
    if (includeReadonly) {
      return this.options;
    } else {
      return this.options.filter(option => !option.readonly);
    }
  }

  getValueByLabel({ label, includeReadonly = true }) {
    return this.getAllOptions({ includeReadonly }).find(item => item.label === label)?.value;
  }

  getOptionByLabel({ label, includeReadonly = true }) {
    return this.getAllOptions({ includeReadonly }).find(item => item.label === label);
  }

  getLabelByValue({ value, includeReadonly = true }) {
    return this.getAllOptions({ includeReadonly }).find(item => item.value === value)?.label;
  }

  getOptionByValue({ value, includeReadonly = true }) {
    return this.getAllOptions({ includeReadonly }).find(item => item.value === value);
  }

  getAllOptionsSortedAndFiltered({ sortFn = x => x, filterFn = x => x, includeReadonly = false }) {
    return this.getAllOptions({ includeReadonly })
      .filter(filterFn)
      .sort(sortFn);
  }

  getAllLabels() {
    return this.getAllOptions({ includeReadonly: false })
      .map(item => item.label)
      .sort(sortAscendingAlpha);
  }

  getAllValues() {
    return this.getAllOptions({ includeReadonly: false })
      .map(item => item.value)
      .sort(sortAscendingAlpha);
  }
}

export default DropdownOptions;
