import { deviceProductIds } from 'shared/constants/device';
import {
  vehicleLockStatusViewData,
  vehicleOnlineStatusViewData,
  vehicleRespondingStatusViewData,
  vehicleTypeViewData,
} from 'shared/constants/vehicle';
import StyledReactSelect from 'shared/styles/components/SelectField';
import {
  DeviceCell,
  deviceOuterCellStyles,
  supportedDevicesHeaderCellStyles,
  SelectWrapper,
} from 'shared/styles/components/Table';
import { getGuardedObjectProp, sortAscendingAlpha } from 'shared/utilities/general';

export const getFormattedDuid = (vehicle) => {
  let regEx = /(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/;
  let stringTemplate = '$1-$2-$3-$4-$5';

  return (
    (vehicle.meta?.duid ?? vehicle.device?.uid)?.replace(regEx, stringTemplate) ??
    'No Device Attached'
  );
};

export const decorateVehicle = (vehicle) => {
  // get formatted id
  vehicle.meta.formatted_device_id = getFormattedDuid(vehicle);

  // get formatted vehicle type
  if (vehicleTypeViewData[vehicle.meta.vehicle_type])
    vehicle.meta.formatted_vehicle_type = vehicleTypeViewData[vehicle.meta.vehicle_type].title;
  else vehicle.meta.formatted_vehicle_type = 'Unknown';

  // get locked status
  const lockedStatus = vehicle?.device?.CenComCoreAccess;
  vehicle.meta.lock_status = lockedStatus;
  if (lockedStatus) {
    vehicle.meta.formatted_lock_status = vehicleLockStatusViewData[lockedStatus]?.title;
  } else {
    // no VSG object so there is nowhere for the backend to send a value of NA
    vehicle.meta.lock_status = vehicleLockStatusViewData.NA.id;
    vehicle.meta.formatted_lock_status = vehicleLockStatusViewData.NA.title;
  }

  // get vehicle online and responding statuses from gps
  return {
    ...vehicle,
    onlineStatus: vehicle.meta.online
      ? vehicleOnlineStatusViewData.ACTIVE.id
      : vehicleOnlineStatusViewData.INACTIVE.id,
    respondingStatus:
      vehicle.gps?.fr_mode_enabled === 1
        ? vehicleRespondingStatusViewData.RESPONDING.id
        : vehicleRespondingStatusViewData.NOT_RESPONDING.id,
  };
};

// get heading from gps and convert from cardinal direction to degrees
export const getHeading = (heading) => {
  const directions = ['N', 'NE', 'E', 'SE', 'S', 'SW', 'W', 'NW'];
  const index = Math.floor(((heading + 22.5) % 360) / 45);
  return directions[index];
};

export const sortAscendingAlphaLabel = (vehicle1, vehicle2) => {
  const labelOne = vehicle1.meta.label.toLowerCase();
  const labelTwo = vehicle2.meta.label.toLowerCase();
  return sortAscendingAlpha(labelOne, labelTwo);
};

export const sortAscendingAlphaAliasAndLabel = (vehicle1, vehicle2) => {
  const labelOne = vehicle1.meta.alias
    ? `${vehicle1.meta.alias.toLowerCase()} ${vehicle1.meta.label.toLowerCase()}`
    : vehicle1.meta.label.toLowerCase();
  const labelTwo = vehicle2.meta.alias
    ? `${vehicle2.meta.alias.toLowerCase()} ${vehicle2.meta.label.toLowerCase()}`
    : vehicle2.meta.label.toLowerCase();
  return sortAscendingAlpha(labelOne, labelTwo);
};

export const mergeVehicles = ({ lastVehicles, incomingVehicle }) => {
  return [...lastVehicles]
    .map((lastVehicle) => {
      return incomingVehicle.vehicle_id === lastVehicle.vehicle_id ? incomingVehicle : lastVehicle;
    })
    .sort(sortAscendingAlphaLabel);
};

export const recalculateMinYear = ({ minYear, vehicles }) => {
  return Array.isArray(vehicles)
    ? vehicles.reduce(
        (minYear, vehicle) => (vehicle.mfg_year < minYear ? vehicle.mfg_year : minYear),
        new Date().getFullYear(),
      )
    : minYear;
};

export const sortAscendingAlphaOptions = (option1, option2) =>
  sortAscendingAlpha(option1.label, option2.label);

export const uidColumn = (paddingLeft, width) => ({
  title: 'UUID',
  dataIndex: 'uid',
  className: 'uid',
  key: 'uid',
  onHeaderCell: (row) => ({
    style: {
      ...supportedDevicesHeaderCellStyles,
      paddingLeft: `${paddingLeft}px`,
    },
  }),
  onCell: () => ({
    style: deviceOuterCellStyles,
  }),
  ...(width ? { width } : {}),
  render: (value) => (
    <DeviceCell nameCell tableHorizontalPadding={paddingLeft}>
      {value}
    </DeviceCell>
  ),
});

export const getCurrentVersionColumn = (paddingLeft) => ({
  title: 'CURRENT FIRMWARE VERSION',
  dataIndex: 'firmware',
  className: 'version',
  key: 'version',
  width: '200px',
  onHeaderCell: (row) => ({
    style: {
      ...supportedDevicesHeaderCellStyles,
      ...(paddingLeft ? { paddingLeft: `${paddingLeft}px` } : {}),
    },
  }),
  render: (value, row) =>
    value && (
      <DeviceCell nameCell firmwareVersion tableHorizontalPadding={paddingLeft}>
        {'v. ' + value?.current?.version}
      </DeviceCell>
    ),
});

export const firmwareChannelColumn = (
  otaFirmwareEditPermissions,
  firmwareChannels,
  generateHandleSelectChange,
  width,
  paddingLeft,
  paddingRight,
) => {
  return {
    title: 'Firmware Channel',
    dataIndex: 'firmware',
    className: 'firmware',
    key: 'firmwareChannel',
    onHeaderCell: (row) => ({
      style: {
        ...supportedDevicesHeaderCellStyles,
        ...(paddingLeft ? { paddingLeft: `${paddingLeft}px` } : {}),
      },
    }),
    onCell: () => ({
      style: {
        ...deviceOuterCellStyles,
        ...(paddingLeft ? { paddingLeft: `${paddingLeft}px` } : {}),
        ...(paddingRight ? { paddingRight: `${paddingRight}px` } : {}),
      },
    }),
    ...(width ? { width } : {}),
    render: (firmware, device) => {
      const targetChannel = firmware?.target?.channel?.toUpperCase();
      const currentChannel = firmware?.current?.channel?.toUpperCase();

      const deviceChannelObjects = firmwareChannels?.filter(
        (channelsDevice) => channelsDevice.product_id === device.product_id,
      );

      let options = [];

      if (!deviceChannelObjects) {
        let defaultOption = {
          label: targetChannel || currentChannel,
          value: targetChannel || currentChannel,
        };

        options.push(defaultOption);
      } else {
        options = deviceChannelObjects.map((dc) => ({
          label: `${dc.channel} (${dc.version})`,
          value: dc.channel,
        }));

        options = options.sort(sortAscendingAlphaOptions);
      }

      const selectedValue = options
        ? options.find((option) => option.value === (targetChannel || currentChannel))
        : null;

      return (
        <SelectWrapper>
          <StyledReactSelect
            options={otaFirmwareEditPermissions ? options : [selectedValue]}
            onChange={
              generateHandleSelectChange && generateHandleSelectChange(device.uid, 'firmware')
            }
            placeholder="Not set"
            isDisabled={!otaFirmwareEditPermissions}
            value={selectedValue}
            closeMenuOnSelect={true}
            isClearable={false}
            isSearchable={false}
          />
        </SelectWrapper>
      );
    },
  };
};

export const configurationColumn = (
  otaEditPermissions,
  configurations,
  generateHandleSelectChange,
  width,
  paddingRight,
) => ({
  title: 'Configuration Channel',
  dataIndex: 'config',
  className: 'configuration',
  key: 'configuration',
  onHeaderCell: (row) => ({
    style: supportedDevicesHeaderCellStyles,
  }),
  onCell: () => ({
    style: {
      ...deviceOuterCellStyles,
      paddingRight: `${paddingRight}px`,
    },
  }),
  ...(width ? { width } : {}),
  render: (config, device) => {
    if (configurations.length === 0) {
      return <>Non WCP Config</>;
    } else if (config) {
      // set default value for when a config that is coming directly from Command with the id of 00000000000000000000000000000000
      let selectedValue = {
        value: '00000000-0000-0000-0000-000000000000',
        label: 'Non WCP Config',
      };
      configurations.forEach((c) => {
        const { targetId, currentId, target_id, current_id } = config || {};
        const guardedTargetId = targetId || target_id;
        const guardedCurrentId = currentId || current_id;
        if (guardedTargetId ? guardedTargetId === c.configId : guardedCurrentId === c.configId) {
          selectedValue = {
            value: c.configId,
            label: c.configName,
          };
        }
      });
      const filteredOptions = otaEditPermissions
        ? configurations.length > 0
          ? configurations
              .filter((c) => c.acmProductId && c.acmProductId === device.product_id)
              .filter((configuration) => configuration.archived !== 'true')
              .map((configuration) => ({
                value: configuration.configId,
                label: configuration.configName,
              }))
              .sort(sortAscendingAlphaOptions)
          : []
        : [selectedValue];

      return (
        <SelectWrapper>
          <StyledReactSelect
            options={filteredOptions}
            onChange={
              generateHandleSelectChange && generateHandleSelectChange(device.uid, 'configuration')
            }
            placeholder={'Not Set'}
            value={selectedValue}
            isDisabled={!otaEditPermissions}
            closeMenuOnSelect={true}
            isClearable={false}
            isSearchable={false}
          />
        </SelectWrapper>
      );
    }
  },
});

export const deviceNameColumn = (paddingLeft, width) => ({
  title: 'Device Name',
  dataIndex: 'product_name',
  className: 'deviceName',
  key: 'deviceName',
  onHeaderCell: (row) => ({
    style: {
      ...supportedDevicesHeaderCellStyles,
      paddingLeft: `${paddingLeft}px`,
    },
  }),
  onCell: () => ({
    style: deviceOuterCellStyles,
  }),
  ...(width ? { width } : {}),
  render: (value, row) => (
    <DeviceCell nameCell tableHorizontalPadding={paddingLeft}>
      {value}
    </DeviceCell>
  ),
});

const getFirmwareChannelChanged = ({ remoteValue, formValue }) => {
  const firmwareChannelChanged =
    getGuardedObjectProp('target.channel', remoteValue) !==
    getGuardedObjectProp('target.channel', formValue);
  return firmwareChannelChanged;
};

const getFirmwareChannelsChanged = ({ device, deviceFormValue }) => {
  const firmwareChannelsChanged =
    Object.prototype.toString.call(deviceFormValue) === '[object Array]'
      ? deviceFormValue
          .map((innerDevice) =>
            Object.entries(innerDevice).reduce(
              firmwareChannelChangeReducer(
                device.find((innerRemoteDevice) => innerDevice.uid === innerRemoteDevice.uid),
              ),
              false,
            ),
          )
          .reduce((changed, firmwareDidChange) => (changed ? changed : firmwareDidChange), false)
      : Object.prototype.toString.call(deviceFormValue) === '[object Object]'
        ? Object.entries(deviceFormValue).reduce(firmwareChannelChangeReducer(device), false)
        : device === deviceFormValue;
  return firmwareChannelsChanged;
};

const firmwareChannelChangeReducer =
  (device) =>
  (changed, [key, value]) => {
    const nextChanged = changed
      ? changed
      : key === 'firmware'
        ? getFirmwareChannelChanged({
            remoteValue: device[key],
            formValue: value,
          })
        : key === 'devices'
          ? getFirmwareChannelsChanged({
              device: device[key],
              deviceFormValue: value,
            })
          : key === 'CenComCore'
            ? getFirmwareChannelsChanged({
                device: device[key],
                deviceFormValue: value,
              })
            : changed;

    return nextChanged;
  };

const addProductionOption = (options) => {
  if (!options.includes({ label: 'PRODUCTION', value: 'PRODUCTION' }))
    options.push({ label: 'PRODUCTION', value: 'PRODUCTION' });
};

export const getVsgChannelOptions = (firmwareChannels) => {
  let vsg = firmwareChannels.filter((wl) => wl.product_id === deviceProductIds.VSG);
  return vsg?.map((dc) => ({ label: `${dc.channel} (${dc.version})`, value: dc.channel })) || [];
};

export const getVsgChannelSelectedValue = (vsgChannelOptions, vsg) => {
  return vsgChannelOptions.find(
    (o) =>
      o.value ===
      (vsg?.firmware?.target
        ? vsg?.firmware?.target
        : vsg?.firmware?.current
      )?.channel?.toUpperCase(),
  );
};

export const getCccConfigSelectedValue = (cccConfigurationChannelOptions, ccc) => {
  return cccConfigurationChannelOptions.find(
    (o) => o.value === (ccc?.config?.target_id ? ccc?.config?.target_id : ccc?.config?.current_id),
  );
};

export const getCccConfigOptions = (configurations, ccc) => {
  let options = configurations
    .filter((c) => c.acmProductId && c.acmProductId === ccc?.product_id)
    .filter((configuration) => configuration.archived !== 'true')
    .map((channel) => ({
      label: channel.configName,
      value: channel.configId,
    }));

  addProductionOption(options);

  return options;
};

export const getCccFirmwareChannelSelectedValue = (cccFirmwareChannelOptions, ccc) => {
  return cccFirmwareChannelOptions?.find(
    (o) =>
      o.value ===
      (ccc?.firmware?.target
        ? ccc?.firmware?.target
        : ccc?.firmware?.current
      )?.channel?.toUpperCase(),
  );
};

export const getCccFirmwareChannelOptions = (firmwareChannels) => {
  let ccc = firmwareChannels?.filter((wl) => wl.product_id === deviceProductIds.CENCOMCORE);

  const cccFirmwareChannelOptions =
    ccc.map((dc) => ({
      label: `${dc.channel} (${dc.version})`,
      value: dc.channel,
    })) || [];

  addProductionOption(cccFirmwareChannelOptions);
  return cccFirmwareChannelOptions;
};

export const getVehicleIcon = (onlineStatus, respondingStatus) => {
  let svgIconFileName = 'car-outline-offline';
  if (onlineStatus) {
    onlineStatus === vehicleOnlineStatusViewData.ACTIVE.id
      ? (svgIconFileName = 'car-outline-online')
      : (svgIconFileName = 'car-outline-offline');
    respondingStatus === vehicleRespondingStatusViewData.RESPONDING.id &&
      onlineStatus === vehicleOnlineStatusViewData.ACTIVE.id &&
      (svgIconFileName = 'car-outline-responding');
  }
  return svgIconFileName;
};

export const getYearOptions = (vehicles) => {
  const minYear = vehicles.reduce(
    (minYear, vehicle) => (vehicle.meta.mfg_year < minYear ? vehicle.meta.mfg_year : minYear),
    new Date().getFullYear(),
  );

  const yearOptions = [];
  for (let year = new Date().getFullYear() + 1; year > minYear - 1; year = year - 1) {
    yearOptions.push({ value: year, label: `${year}` });
  }
  return yearOptions;
};

export const getAssociatedGroups = ({ vehicle, groups }) => {
  return groups.filter((group) => group.vehicle_id.indexOf(vehicle.vehicle_id) > -1);
};

export const getObjectsThatMatchId = ({ objects, id }) => {
  return vehicles.map((v) => v.vehicleId)?.includes(id);
};

export const getModelOptions = (vehicles) => {
  return vehicles
    ?.reduce((models, vehicle) => {
      if (!models.includes(vehicle.meta.model)) {
        models.push(vehicle.meta.model);
      }
      return models;
    }, [])
    .reduce((options, model) => {
      if (!options.find((option) => option.value === model)) {
        return options.concat([
          {
            value: model,
            label: model,
          },
        ]);
      }
      return options;
    }, [])
    .sort(sortAscendingAlpha);
};

export const getMakeOptions = (vehicles) =>
  vehicles
    .reduce((makes, vehicle) => {
      if (!makes.includes(vehicle.meta.make)) {
        makes.push(vehicle.meta.make);
      }
      return makes;
    }, [])
    .map((make) => ({
      value: make,
      label: `${make.slice(0, 1)}${make.slice(1)}`,
    }));
