import styled from 'styled-components';
import device from 'shared/constants/sizing';

export const SectionDivider = styled.div`
  border: 0px;
  border-bottom: 1px solid;
  border-image: linear-gradient(to right, rgba(138, 144, 156, 0.5), rgba(0, 0, 0, 0));
  border-image-slice: 1;
  margin-top: 10px;
  margin-bottom: 20px;

  ${props =>
    props.mobileOnly &&
    `
        display: block;
	    margin-bottom:45px;
        @media ${device.medium} {
            display: none;
        }
	`}
`;
