import { useContext } from 'react';

import { VehiclePlaybackContext } from 'app/features/playback/state/provider/VehiclePlaybackProvider';

const useVehiclePlaybackProviderContext = () => {
  const vehiclePlaybackContext = useContext(VehiclePlaybackContext);

  if (!vehiclePlaybackContext) {
    throw 'useVehiclePlaybackProviderContext must be used within a VehiclePlaybackContext';
  }

  return vehiclePlaybackContext;
};

export default useVehiclePlaybackProviderContext;
