/**
 * @ External Dependencies
 */
import React from 'react';
import PropTypes from 'prop-types';
import { ReactSVG } from 'react-svg';

/**
 * @ Internal Dependencies
 */
import { JSToCSS } from 'shared/utilities/css';

const IconSvgComponent = ({ svgFileName, svgStyle, ...props }) => {
  return (
    <ReactSVG
      src={require(`assets/svg/${svgFileName}.svg`)}
      className="svg-icon"
      {...(svgStyle && {
        beforeInjection: svg => {
          svg.setAttribute('style', JSToCSS(svgStyle));
        },
      })}
      {...props}
    />
  );
};

/**
 * Define the interface of the component.
 *
 * @type {Object}
 */
IconSvgComponent.propTypes = {
  svgFileName: PropTypes.string.isRequired,
};

export default IconSvgComponent;
