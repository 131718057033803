import AlertsExportButton from 'app/features/alerts/ui/components/AlertsSummary/buttons/AlertsExportButton';
import ManageAlertsSummaryCardContainer from 'app/features/alerts/ui/components/AlertsSummary/mobile/ManageAlertsSummaryCardContainer';
import ManageAlertsSummaryTable from 'app/features/alerts/ui/components/AlertsSummary/tables/ManageAlertsSummaryTable';
import AlertsSummaryProvider from 'app/features/alerts/services/providers/AlertsSummaryProvider/AlertsSummaryProvider';
import useAlertsSummaryProviderContext from 'app/features/alerts/services/providers/AlertsSummaryProvider/hooks/useAlertsSummaryProviderContext';
import ListingCount from 'shared/components/ListingCount';
import MediaWrapper from 'shared/components/MediaWrapper';
import { ExportDiv, ExportWrapper } from 'shared/styles/components/PageList';

const AlertsSummary = () => {
  const {
    state: { alerts, listingCount },
    actions: { updateAlertsListingCountAction },
  } = useAlertsSummaryProviderContext();

  // derived state
  const alertsCount = alerts?.length;
  return (
    <>
      {alertsCount > 0 && (
        <ExportWrapper>
          <ExportDiv>
            <AlertsExportButton alerts={alerts} />
          </ExportDiv>
        </ExportWrapper>
      )}
      {alerts && (
        <>
          <ListingCount
            listingCount={listingCount}
            totalCount={alertsCount}
            itemTypeName={'Alert'}
          />

          <MediaWrapper
            mobileComponent={<ManageAlertsSummaryCardContainer alerts={alerts} />}
            nonMobileComponent={
              <ManageAlertsSummaryTable
                alerts={alerts}
                handleUpdateListingCount={listingCount =>
                  updateAlertsListingCountAction({ listingCount })
                }
              />
            }
          />
        </>
      )}
    </>
  );
};

export default AlertsSummary;
