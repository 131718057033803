import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import { TableCheckboxFilterWrapper } from 'shared/styles/components/PageList';
import { TableFilterSpan } from 'shared/styles/components/Table';

const OnlyShowPendingUpdatesCheckbox = ({ checked, handleChecked }) => {
  return (
    <>
      <Checkbox singleCheck>
        <CheckboxInner isChecked={checked} onClick={handleChecked} />
      </Checkbox>
      <TableFilterSpan>Only show pending updates</TableFilterSpan>
    </>
  );
};

export default OnlyShowPendingUpdatesCheckbox;
