import { createAction } from 'redux-actions';

import {
  getOrganizations,
  createOrganizationAPI,
  decorateOrganizationObject,
  undecorateOrganizationObject,
  updateOrganizationAPI,
  getAssociatedFSEData as getAssociatedFSEDataAPI,
  associateFSEsToOrganization as associateFSEsToOrganizationAPI,
  disassociateFSEFromOrganization as disassociateFSEFromOrganizationAPI,
  fetchOrganizationFSEs as fetchOrganizationFSEsAPI,
  decorateFSE,
} from 'core/api/organizations';
import { closeGlobalModal } from 'core/redux/ui/actions';
import { addNotification } from 'core/redux/ui/actions';
import { setSubscriptionExpiryAlertBar } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import {
  organizationIsNearExpiration,
  getDaysUntilExpiration,
} from 'shared/utilities/organizations';
import { getSubscriptionExpiryAlertMessage } from 'shared/constants/alerts';
import { getDebouncedThunk } from 'shared/utilities/debounce';

export const requestOrganizations = createAction('REQUEST_ORGANIZATIONS');
export const receiveOrganizations = createAction('RECEIVE_ORGANIZATIONS');
export const rejectOrganizations = createAction('REJECT_ORGANIZATIONS');
export const requestCreateOrganization = createAction('REQUEST_CREATE_ORGANIZATION');
export const successCreateOrganization = createAction('SUCCESS_CREATE_ORGANIZATION');
export const rejectCreateOrganization = createAction('REJECT_CREATE_ORGANIZATION');
export const requestDeleteOrganization = createAction('REQUEST_DELETE_ORGANIZATION');
export const successDeleteOrganization = createAction('SUCCESS_DELETE_ORGANIZATION');
export const rejectDeleteOrganization = createAction('REJECT_DELETE_ORGANIZATION');
export const successToggleOrganizationActiveState = createAction(
  'SUCCESS_TOGGLE_ORGANIZATION_ACTIVE_STATE',
);
export const rejectToggleOrganizationActiveState = createAction(
  'REJECT_TOGGLE_ORGANIZATION_ACTIVE_STATE',
);
export const requestDeactivateOrganization = createAction('REQUEST_DEACTIVATE_ORGANIZATION');
export const successDeactivateOrganization = createAction('SUCCESS_DEACTIVATE_ORGANIZATION');
export const rejectDeactivateOrganization = createAction('REJECT_DEACTIVATE_ORGANIZATION');
export const requestActivateOrganization = createAction('REQUEST_ACTIVATE_ORGANIZATION');
export const successActivateOrganization = createAction('SUCCESS_ACTIVATE_ORGANIZATION');
export const rejectActivateOrganization = createAction('REJECT_ACTIVATE_ORGANIZATION');
export const requestUpdateOrganization = createAction('REQUEST_UPDATE_ORGANIZATION');
export const successUpdateOrganization = createAction('SUCCESS_UPDATE_ORGANIZATION');
export const rejectUpdateOrganization = createAction('REJECT_UPDATE_ORGANIZATION');
export const requestRenameOrganization = createAction('REQUEST_RENAME_ORGANIZATION');
export const successRenameOrganization = createAction('SUCCESS_RENAME_ORGANIZATION');
export const rejectRenameOrganization = createAction('REJECT_RENAME_ORGANIZATION');
export const requestAssociateFSEtoOrganization = createAction('REQUEST_ASSOCIATE_FSE_ORGANIZATION');
export const successAssociateFSEtoOrganization = createAction('SUCCESS_ASSOCIATE_FSE_ORGANIZATION');
export const rejectAssociateFSEtoOrganization = createAction('REJECT_ASSOCIATE_FSE_ORGANIZATION');
export const requestAssociateFSEData = createAction('REQUEST_ASSOCIATED_FSE_DATA');
export const successAssociateFSEData = createAction('SUCCESS_ASSOCIATED_FSE_DATA');
export const rejectAssociateFSEData = createAction('REJECT_ASSOCIATED_FSE_DATA');
export const requestDisassociateFSEFromOrganization = createAction(
  'REQUEST_DISASSOCIATE_FSE_ORGANIZATION',
);
export const successDisassociateFSEFromOrganization = createAction(
  'SUCCESS_DISASSOCIATE_FSE_ORGANIZATION',
);
export const rejectDisassociateFSEFromOrganization = createAction(
  'REJECT_DISASSOCIATE_FSE_ORGANIZATION',
);
export const requestOrganizationFSEs = createAction('REQUEST_ORGANIZATION_FSES');
export const receiveOrganizationFSEs = createAction('SUCCESS_ORGANIZATION_FSES');
export const rejectOrganizationFSEs = createAction('REJECT_ORGANIZATION_FSES');

export const fetchOrganizations = ({ organizationIds } = {}) => {
  return async (dispatch, getState) => {
    dispatch(requestOrganizations());
    try {
      const userState = getState().user;
      const cached = getState().cached;
      const { response } = await getOrganizations({ organizationIds });
      dispatch(
        receiveOrganizations(
          response.message.map(organization => decorateOrganizationObject(organization)),
        ),
      );
      const filteredOrganization = decorateOrganizationObject(
        response.message.find(
          organization => organization.organization_id === userState.filteredOrganizationId,
        ),
      );
      if (organizationIsNearExpiration(filteredOrganization)) {
        dispatch(
          setSubscriptionExpiryAlertBar({
            ...getSubscriptionExpiryAlertMessage(filteredOrganization),
            ...{
              dismissed:
                cached.subscriptionExpiryAlertBarDismissed !== null
                  ? cached.subscriptionExpiryAlertBarDismissed
                  : false,
            },
            ...{ daysUntilExpiration: getDaysUntilExpiration(filteredOrganization) },
          }),
        );
      }
      return true;
    } catch (e) {
      console.log(e);
      dispatch(rejectOrganizations(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Getting Organizations', e),
        }),
      );
      return false;
    }
  };
};

export const debouncedFetchOrganizations = getDebouncedThunk(fetchOrganizations, 1000);

export const createOrganization = organization => {
  return async dispatch => {
    dispatch(requestCreateOrganization());

    try {
      const { response } = await createOrganizationAPI(organization);
      dispatch(successCreateOrganization(response.message));
      dispatch(fetchOrganizations());
      dispatch(closeGlobalModal());
      if (response && response.message) {
        dispatch(successCreateOrganization(response.message));
        dispatch(fetchOrganizations());
        dispatch(closeGlobalModal());
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.SUCCESS, 'Successfully Created Organization'),
          }),
        );
        return true;
      }
    } catch (e) {
      console.log(e);
      dispatch(rejectCreateOrganization({ errorMessage: e }));
      return false;
    }
  };
};

export const deactivateOrganization = organization => {
  return async dispatch => {
    dispatch(requestDeactivateOrganization(organization.organizationId));

    try {
      const { response } = await updateOrganizationAPI({
        ...organization,
        active: false,
      });
      if (response.message) {
        dispatch(successDeactivateOrganization());
        dispatch(fetchOrganizations());
        dispatch(closeGlobalModal());
        return true;
      }
    } catch (e) {
      console.log('error deactivating organization:', e);
      dispatch(
        rejectDeactivateOrganization({
          errorMessage: e,
          organizationId: organization.organizationId,
        }),
      );
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Deactivating Organization', e),
        }),
      );
      return false;
    }
  };
};

export const activateOrganization = organization => {
  return async dispatch => {
    dispatch(requestActivateOrganization(organization.organizationId));

    try {
      const { response } = await updateOrganizationAPI({ ...organization, active: true });
      dispatch(successActivateOrganization());
      dispatch(fetchOrganizations());
      dispatch(closeGlobalModal());
    } catch (e) {
      console.log('error activating organization:', e);
      dispatch(
        rejectActivateOrganization({
          errorMessage: e,
          organizationId: organization.organizationId,
        }),
      );
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Activating Organization', e),
        }),
      );
    }
  };
};

export const editOrganization = organization => {
  return async dispatch => {
    dispatch(requestUpdateOrganization(organization.organizationId));

    try {
      const { response } = await updateOrganizationAPI(organization);
      dispatch(successUpdateOrganization(response.message));
      dispatch(fetchOrganizations());
      dispatch(fetchOrganizationFSEs(organization.organizationId));
      dispatch(closeGlobalModal());
      return response;
    } catch (e) {
      console.log(e);
      dispatch(
        rejectUpdateOrganization({
          errorMessage: e,
          organizationId: organization.organizationId,
        }),
      );
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error editing organization details', e),
        }),
      );
    }
  };
};

export const toggleOrganizationActiveState = organization => {
  return async dispatch => {
    try {
      const { response } = await updateOrganizationAPI({
        ...organization,
        active: organization.active ? false : true,
      });
      dispatch(successToggleOrganizationActiveState(response.message));
      dispatch(fetchOrganizations());
    } catch (e) {
      console.log(e);
      dispatch(rejectToggleOrganizationActiveState(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Toggling Organization Status', e),
        }),
      );
    }
  };
};

export const renameOrganization = ({ organization, newOrganizationName }) => {
  return async dispatch => {
    dispatch(requestRenameOrganization());
    try {
      const response = await dispatch(
        editOrganization({ ...organization, organizationName: newOrganizationName }),
      );
      dispatch(successRenameOrganization(response.message));
      dispatch(fetchOrganizations());
      return response;
    } catch (e) {
      console.log(e);
      dispatch(rejectRenameOrganization(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Renaming Organization', e),
        }),
      );
      return { error: e };
    }
  };
};

export const getAssociatedFSEData = ({ organizationId, username }) => {
  return async dispatch => {
    dispatch(requestAssociateFSEData());
    try {
      const { response } = await getAssociatedFSEDataAPI({
        organizationId,
        username,
      });

      let fseData = response.message.associated_fse[0];

      if (fseData) {
        dispatch(
          successAssociateFSEData({ orgId: organizationId, permissions: fseData.permissions }),
        );
        return true;
      } else {
        dispatch(successAssociateFSEData({}));
      }
      return false;
    } catch (e) {
      console.log(e);
      dispatch(rejectAssociateFSEData());

      return false;
    }
  };
};

export const associateFSEsToOrganization = ({ FSEs, organizationId }) => {
  return async dispatch => {
    dispatch(requestAssociateFSEtoOrganization());
    try {
      const { response } = await associateFSEsToOrganizationAPI({
        FSEs,
        organizationId,
      });
      if (response.message) {
        dispatch(successAssociateFSEtoOrganization());
        dispatch(fetchOrganizationFSEs(organizationId));
        return true;
      }
      return false;
    } catch (e) {
      console.log(e);
      dispatch(rejectAssociateFSEtoOrganization(e));
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            'Error associating Field Solution Engineer with Organization',
            e,
          ),
        }),
      );
      return false;
    }
  };
};

export const disassociateFSEFromOrganization = ({ username, organizationId }) => {
  return async dispatch => {
    dispatch(requestDisassociateFSEFromOrganization());
    try {
      const { response } = await disassociateFSEFromOrganizationAPI({
        username,
        organizationId,
      });
      dispatch(successDisassociateFSEFromOrganization());
      dispatch(fetchOrganizationFSEs(organizationId));
      return true;
    } catch (e) {
      console.log(e);
      dispatch(rejectDisassociateFSEFromOrganization(e));
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            'Error disassociating Field Solution Engineer with Organization',
            e,
          ),
        }),
      );
      return { error: e };
    }
  };
};

export const fetchOrganizationFSEs = organizationId => {
  console.log('fetching organization FSEs');
  return async (dispatch, getState) => {
    dispatch(requestOrganizationFSEs());
    try {
      const userState = getState().user;
      const guardedOrganizationId = organizationId ? organizationId : userState.user.organizationId;
      const { response } = await fetchOrganizationFSEsAPI(guardedOrganizationId);
      if (response && response.message) {
        dispatch(
          receiveOrganizationFSEs({
            organizationId: guardedOrganizationId,
            associated_fse: response.message.associated_fse.map(FSE => decorateFSE(FSE)),
          }),
        );
      }
      return true;
    } catch (e) {
      console.log(e);
      dispatch(rejectOrganizationFSEs());
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            'Error getting Field Solution Engineers for Organization',
            e,
          ),
        }),
      );
      return { error: e };
    }
  };
};
