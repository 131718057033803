import React from 'react';
import { useState, useEffect } from 'react';
import IsFetchingWrapper from 'shared/components/IsFetchingWrapper';
import { BlueButton } from 'shared/styles/components/Button';
import CustomAlertsModal from 'app/features/alerts/ui/components/AlertForms/shared/modals/CustomAlertsModal';
import useAlertFormsProviderContext from 'app/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import { useQueryClient } from '@tanstack/react-query';

const CustomAlertsButton = ({ recipientRow }) => {
  let recipientGroup = recipientRow.recipientGroup;

  const [customAlertsModalData, setCustomAlertsModalData] = useState({
    show: false,
    recipientRow,
  });

  useEffect(() => {
    setCustomAlertsModalData(prevState => ({ ...prevState, recipientRow }));
  }, [recipientGroup]);

  // brute force solution for spinner
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  let queryKey =
    queryCache
      .getAll()
      .map(cache => cache.queryKey)
      .filter(key => key[0] === 'getRecipientGroupByIdApi')
      .find(key => key[1].split(':')[1] === recipientGroup?.recipientGroupId) || [];

  return (
    <IsFetchingWrapper inline useWholeKeys queryKeys={[queryKey]}>
      <>
        <BlueButton
          type="button"
          onClick={() => setCustomAlertsModalData(prevState => ({ ...prevState, show: true }))}
        >
          Custom Alerts
        </BlueButton>
        <CustomAlertsModal
          modalData={customAlertsModalData}
          handleCloseModal={() =>
            setCustomAlertsModalData(prevState => ({ ...prevState, show: false }))
          }
        />
      </>
    </IsFetchingWrapper>
  );
};

export default CustomAlertsButton;
