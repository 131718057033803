export const FLEET_ANALYST = 'FLEET_ANALYST';
export const ORGANIZATION_ADMIN = 'ORGANIZATION_ADMIN';
export const SYSTEM_ADMIN = 'SYSTEM_ADMIN';
export const FIELD_SOLUTION_ENGINEER = 'FIELD_SOLUTION_ENGINEER';
export const MAP_ANALYST = 'MAP_ANALYST';

export const userRoleOptions = {
  [MAP_ANALYST]: {
    id: 0,
    title: 'Map Analyst',
    label: 'Map Analyst',
    value: 'Map Analyst',
    isChecked: false,
  },
  [FLEET_ANALYST]: {
    id: 1,
    title: 'Fleet Analyst',
    label: 'Fleet Analyst',
    value: 'Normal User',
    isChecked: false,
  },
  [ORGANIZATION_ADMIN]: {
    id: 2,
    title: 'Organization Admin',
    label: 'Organization Admin',
    value: 'Organization Admin',
    isChecked: false,
  },
  [FIELD_SOLUTION_ENGINEER]: {
    id: 3,
    title: 'Field Solution Engineer',
    label: 'Field Solution Engineer',
    value: 'FSE',
    isChecked: false,
  },
  [SYSTEM_ADMIN]: {
    id: 4,
    title: 'System Admin',
    label: 'System Admin',
    value: 'System Admin',
    isChecked: false,
  },
};

export const userRoleValues = Object.values(userRoleOptions)
  .sort((a, b) => a.id - b.id)
  .map(option => option.value);

export const userRoleOptionsArray = Object.values(userRoleOptions).sort((a, b) => a.id - b.id);

export const variableScopeAllowedUserRoles = [
  userRoleOptions[SYSTEM_ADMIN].value,
  userRoleOptions[FIELD_SOLUTION_ENGINEER].value,
];

export const allAdminUserRoles = [
  userRoleOptions[ORGANIZATION_ADMIN].value,
  userRoleOptions[SYSTEM_ADMIN].value,
  userRoleOptions[FIELD_SOLUTION_ENGINEER].value,
];

export const organizationScopedUserRoles = [
  userRoleOptions[ORGANIZATION_ADMIN].value,
  userRoleOptions[FIELD_SOLUTION_ENGINEER].value,
];

export const permissionData = {
  viewmap: 'LiveMap',
  viewfleet: 'ViewFleet',
  editfleet: 'EditFleet',
  viewotaconfig: 'ViewOTAConfig',
  editotaconfig: 'EditOTAConfig',
  viewotafirmware: 'ViewOTAFirmware',
  editotafirmware: 'EditOTAFirmware',
  viewanalytics: 'ViewAnalytics',
  viewVehiclePlayback: 'ViewVehiclePlayback',
  editgeofence: 'EditGeofence',
  editAlerts: 'EditAlerts',
};

// MFA types
export const mfaTypes = {
  SMS: 'SMS_MFA',
  AUTHENTICATION_APP: 'SOFTWARE_TOKEN_MFA',
};
