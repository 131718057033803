export const FIRMWARE_REFETCH_INTERVAL = 5000;

export const firmwareStatuses = {
  UP_TO_DATE: {
    id: 1,
    displayName: 'Up to Date',
    status: 'UP_TO_DATE',
  },
  BOOTLOADING: {
    id: 2,
    displayName: 'Bootloading',
    status: 'BOOTLOADING',
  },
  ERROR_BOOTLOADING: {
    id: 3,
    displayName: 'Error Bootloading',
    status: 'ERROR_BOOTLOADING',
  },
  DOWNLOAD_SCHEDULED: {
    id: 4,
    displayName: 'Download Scheduled',
    status: 'DOWNLOAD_SCHEDULED',
  },
  DOWNLOAD_STARTED: {
    id: 5,
    displayName: 'Download Started',
    status: 'DOWNLOAD_STARTED',
  },
  ERROR_DOWNLOADING: {
    id: 6,
    displayName: 'Downloading',
    status: 'ERROR_DOWNLOADING',
  },
  READY_TO_UPDATE: {
    id: 7,
    displayName: 'Ready to Update',
    status: 'READY_TO_UPDATE',
  },
  UPDATE_AUTHORIZED: {
    id: 8,
    displayName: 'Update Authorized',
    status: 'UPDATE_AUTHORIZED',
  },
  NOT_AUTHORIZED: {
    id: 9,
    displayName: 'Not Authorized',
    status: 'NOT_AUTHORIZED',
  },
  EXTERNAL_UPDATE: {
    id: 10,
    displayName: 'Update Complete (Via Command)',
    status: 'EXTERNAL_UPDATE',
  },
};
