import React from 'react';
import {
  CardLabel,
  CardValue,
  CardRow,
  CardName,
  CardContainer,
  CardOuterWrapper,
  VehicleIcon,
  CardIconWrapper,
  ActionMenuBtnWrapper,
} from 'shared/styles/components/MobileCard';

export default function AnalyticsDashboardCard({ vehicle, actionMenuComponent }) {
  return (
    <CardOuterWrapper>
      <CardContainer analyticsResults>
        <ActionMenuBtnWrapper>{actionMenuComponent(vehicle)}</ActionMenuBtnWrapper>
        <CardIconWrapper>
          <VehicleIcon analyticsResults svgFileName={'car-outline'} alt="Vehicle" />
          <CardRow>
            <CardName title={vehicle.vehicle_name}>{vehicle.vehicle_name}</CardName>
          </CardRow>
        </CardIconWrapper>
        <CardRow>
          <CardLabel>Hours Online:</CardLabel>
          <CardValue>{vehicle.hours_online}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Hours Responding:</CardLabel>
          <CardValue>{vehicle.hours_responding}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Hours Offline:</CardLabel>
          <CardValue>{vehicle.hours_offline}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Responding Events:</CardLabel>
          <CardValue>{vehicle.responding_count}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Top Responding Speed:</CardLabel>
          <CardValue>{vehicle.top_responding_speed}</CardValue>
        </CardRow>
      </CardContainer>
    </CardOuterWrapper>
  );
}
