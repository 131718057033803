import React, { useState } from 'react';
import { Formik } from 'formik';

import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

/**
 * Styled Components
 */
import { Container, Title, CloseButton, ActionsContainer } from 'shared/styles/components/Modal';

/**
 * @ Constants
 */

import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import Button from 'shared/styles/components/Button';
import {
  ChangePasswordFormRow,
  NonTextFieldContainer,
  FieldError,
} from 'shared/styles/components/Form';
import { validateUserPassword } from 'shared/utilities/validators';

import AnimatedField from 'shared/ui/fields/AnimatedField';

export default ({ actions, handleRequestClose }) => {
  const { updateUserPassword } = actions;

  return (
    <Container autoHeight>
      <CloseButton onClick={handleRequestClose}>
        <IconSvgComponent svgFileName={'x'} alt="Close" />
      </CloseButton>
      <Title style={styles.title}>
        <IconSvgComponent style={styles.editIcon} svgFileName={'edit-pencil-blue'} alt="Edit" />
        Change Password
      </Title>
      <Formik
        validateOnChange={true}
        validateOnBlur={false}
        initialValues={{
          oldPassword: '',
          newPassword: '',
          confirmNewPassword: '',
        }}
        validate={validateUserPassword}
        onSubmit={async (values, actions) => {
          let success;
          success = await updateUserPassword({
            oldPassword: values.oldPassword,
            newPassword: values.newPassword,
          });
          actions.setSubmitting(false);
          if (success) {
            handleRequestClose();
          }
        }}
      >
        {({ values, errors, touched, isSubmitting, handleChange, handleBlur, submitForm }) => (
          <form style={styles.formBody} onSubmit={submitForm}>
            <NonTextFieldContainer>
              <ChangePasswordFormRow>
                <AnimatedField
                  name="oldPassword"
                  value={values.oldPassword}
                  touched={touched.oldPassword}
                  validationError={errors.oldPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="Current Password"
                  type="password"
                  id="oldPassword"
                  required
                />
              </ChangePasswordFormRow>
              <ChangePasswordFormRow>
                <AnimatedField
                  name="newPassword"
                  value={values.newPassword}
                  touched={touched.newPassword}
                  validationError={errors.newPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="New Password"
                  type="password"
                  id="newPassword"
                  required
                />
              </ChangePasswordFormRow>
              <ChangePasswordFormRow>
                <AnimatedField
                  name="confirmNewPassword"
                  value={values.confirmNewPassword}
                  touched={touched.confirmNewPassword}
                  validationError={errors.confirmNewPassword}
                  handleChange={handleChange}
                  handleBlur={handleBlur}
                  placeholder="Confirm New Password"
                  type="password"
                  id="confirmNewPassword"
                  required
                />
              </ChangePasswordFormRow>
            </NonTextFieldContainer>
            <ActionsContainer>
              <Button onClick={handleRequestClose} withRightSpacer default type="button">
                Cancel
              </Button>
              <ButtonWithLoader
                isLoading={isSubmitting}
                confirmText={'Save'}
                loadingStyleProp={'submittingWithSpinner'}
                notLoadingStyleProp={'mediumAlt'}
                clickHandler={submitForm}
              />
            </ActionsContainer>
          </form>
        )}
      </Formik>
    </Container>
  );
};

const styles = {
  title: {
    marginBottom: '42px',
  },
  editIcon: {
    marginRight: '10px',
  },
  formBody: {
    padding: '0px 20px 0px 20px',
  },
};
