import { getOrganizationUsersApi } from 'app/features/users/api/get/getOrganizationUsersApi';
import useGenericQuery from 'app/shared/hooks/reactQuery/useGenericQuery';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';

const useFetchOrganizationUsersQuery = () => {
  const [queryResult, queryKey] = useGenericQuery({
    queryKeyPrefix: 'getOrganizationUsersApi',
    apiFn: getOrganizationUsersApi,
    onErrorCallback: err => {
      logWcpError(err);
    },
  });

  return [queryResult.data, queryKey];
};

export default useFetchOrganizationUsersQuery;
