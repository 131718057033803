// https://stackoverflow.com/a/47819165
export const JSToCSS = JS => {
  let cssString = '';
  for (let objectKey in JS) {
    cssString +=
      objectKey.replace(/([A-Z])/g, g => `-${g[0].toLowerCase()}`) + ': ' + JS[objectKey] + ';\n';
  }

  return cssString;
};
