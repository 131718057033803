import React from 'react';
import {
  CardLabel,
  CardValue,
  CardRow,
  CardName,
  CardContainer,
  CardOuterWrapper,
} from 'shared/styles/components/MobileCard';
import { formatISOTimestampToLongDateWithTime } from 'shared/utilities/time';

export default function UserActivityLogCard({ log }) {
  return (
    <CardOuterWrapper>
      <CardContainer userLogs>
        <CardRow>
          <CardName>{log.action}</CardName>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Timestamp:</CardLabel>
          <CardValue>{log.formattedTime}</CardValue>
        </CardRow>

        <CardRow potentialLongValue>
          <CardLabel>Name:</CardLabel>
          <CardValue>{log.name}</CardValue>
        </CardRow>

        <CardRow>
          <CardLabel>Details:</CardLabel>
          <CardValue>{log.details}</CardValue>
        </CardRow>
      </CardContainer>
    </CardOuterWrapper>
  );
}
