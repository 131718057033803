import { Formik } from 'formik';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useFlags } from 'launchdarkly-react-client-sdk';

import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { createHelpRequest } from 'core/redux/help/actions';
import FileInputButton from 'features/help/components/FileInputButton';
import fileSizes from 'shared/constants/fileSizes';
import FormWrapper, {
  AttachFileLabel,
  DescriptionTextArea,
  FieldError,
  FileLabelContainer,
  Form,
  FormActions,
  FormAsterisk,
  FormBody,
  FormFoot,
  FormHead,
  FormLabelAnimated,
  FormLegend,
  FormSingleColumnGridRow,
  FormTitle,
} from 'shared/styles/components/Form';
import PageListWrapper from 'shared/styles/components/PageList';
import StyledReactSelect from 'shared/styles/components/SelectField';
import { TruncatedText } from 'shared/styles/components/TruncatedText';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import { handleSelectBlur, handleSelectChange } from 'shared/utilities/form';
import { validateHelp } from 'shared/utilities/validators';

const HelpPage = () => {
  const { helpRequiredFieldsTextChangeV1 } = useFlags();

  const navigate = useNavigate();

  // state hooks
  const [attachedFiles, setAttachedFiles] = useState(null);

  // redux hooks
  const userRole = useSelector(state => (user => (user ? user.userRole : null))(state.user.user));
  const dispatch = useDispatch();

  // handlers
  const submitHandler = async (values, formikActions) => {
    try {
      await dispatch(
        createHelpRequest({
          ticket: values.type,
          description: values.description,
          attachments: attachedFiles,
        }),
      );
      formikActions.setSubmitting(false);
      navigate('/livemap');
    } catch (err) {
      logWcpError(err);
    }
  };

  const onBeforeFileLoad = e => {
    //reject total file size over 6 mb
    if (e) {
      let totalSize = 0;
      for (let i = 0; i < e.target.files.length; i++) {
        const item = e.target.files[i];
        totalSize = totalSize + item.size;
      }
      if (totalSize > fileSizes.helpMax) {
        alert('The selected file(s) exceeds the limit of 4mb.');
        e.target.value = '';
      } else if (totalSize < fileSizes.globalMin) {
        alert('The selected file is corrupt or invalid. Please try another.');
        e.target.value = '';
      }
      setAttachedFiles(e.target.files);
    }
  };

  // render
  return (
    <PageListWrapper style={{ width: '100%' }}>
      <FormWrapper>
        <Formik
          initialValues={{
            type: 'Help',
            description: '',
          }}
          validate={validateHelp}
          onSubmit={submitHandler}
        >
          {({
            values,
            errors,
            touched,
            submitForm,
            setTouched,
            setFieldValue,
            handleChange,
            handleBlur,
            isSubmitting,
          }) => {
            return (
              <Form onSubmit={submitForm} style={{ float: 'left' }}>
                <FormHead>
                  <FormTitle>Help</FormTitle>
                </FormHead>

                <FormBody>
                  <FormLabelAnimated animated>
                    Ticket Type<FormAsterisk>*</FormAsterisk>
                  </FormLabelAnimated>
                  <FormSingleColumnGridRow help>
                    <StyledReactSelect
                      name="type"
                      defaultValue={{
                        label: 'Help',
                        value: 'Help',
                      }}
                      disabled={false}
                      onChange={handleSelectChange(setFieldValue, 'type')}
                      onBlur={handleSelectBlur(setTouched, 'type', touched)}
                      isClearable={false}
                      isSearchable={false}
                      options={[
                        { label: 'Help', value: 'Help' },
                        { label: 'Bug', value: 'Bug' },
                        { label: 'Security Issue', value: 'Security Issue' },
                      ]}
                      field="true"
                      required
                    />

                    <FieldError>{touched.type && errors.type}</FieldError>
                  </FormSingleColumnGridRow>

                  <FormLabelAnimated>
                    Description of issue<FormAsterisk> *</FormAsterisk>
                  </FormLabelAnimated>
                  <FormSingleColumnGridRow>
                    <DescriptionTextArea
                      name="description"
                      value={values.description}
                      touched={touched.description}
                      validationError={errors.description}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      required
                      rows={10}
                      cols={5}
                    />
                    <FieldError>{touched.description && errors.description}</FieldError>
                  </FormSingleColumnGridRow>
                  <AttachFileLabel>Associated image or config file</AttachFileLabel>
                  <FormSingleColumnGridRow>
                    <div>
                      <FileInputButton
                        labelText={'Attach file'}
                        alignment={'left'}
                        onChange={e => {
                          onBeforeFileLoad(e);
                        }}
                      />

                      <FileLabelContainer>
                        <TruncatedText
                          attachedFileList
                          title={`Attached file(s): ${
                            attachedFiles
                              ? Array.from(attachedFiles)
                                  .map(f => f.name)
                                  .join(', ')
                              : 'none'
                          } `}
                          style={{ width: '320px' }}
                        >
                          {`Attached file(s): ${
                            attachedFiles
                              ? Array.from(attachedFiles)
                                  .map(f => f.name)
                                  .join(', ')
                              : 'none'
                          } `}
                        </TruncatedText>
                      </FileLabelContainer>
                    </div>
                  </FormSingleColumnGridRow>
                </FormBody>
                <FormFoot>
                  <FormLegend>
                    <FormAsterisk>*</FormAsterisk> &mdash; required field
                    {`${helpRequiredFieldsTextChangeV1 ? 's' : '(s)'}`}
                  </FormLegend>

                  <FormActions>
                    <ButtonWithLoader
                      isLoading={isSubmitting}
                      confirmText={'Submit'}
                      loadingStyleProp={'submittingWithSpinnerModal'}
                      notLoadingStyleProp={'mediumAlt'}
                      clickHandler={submitForm}
                    />
                  </FormActions>
                </FormFoot>
              </Form>
            );
          }}
        </Formik>
      </FormWrapper>
    </PageListWrapper>
  );
};
export default HelpPage;
