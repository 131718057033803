/**
 * @ External Dependecies
 */
import styled from 'styled-components';
//When using this component always set the title to the value so that if truncation occurs, the full detail can be viewed in the tooltip
export const TruncatedText = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  ${props =>
    props.userName &&
    `
    width : 200px;
  `};

  ${props =>
    props.groupHeader &&
    `
    white-space: break-spaces;
    word-break: break-all;
		display: -webkit-box;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
  `};
  ${props =>
    props.attachedFileList &&
    `
    white-space: break-spaces;
    word-break: break-all;
		display: -webkit-box;
		-webkit-line-clamp: 3;
		-webkit-box-orient: vertical;
  `};

  ${props =>
    props.checkEngine &&
    `
   margin-top:5px
  `};
`;

//For use when wraping is desired, but truncation is needed with a long single word
export const TruncatedTextWithWrap = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  word-break: break-all;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;

  ${props =>
    props.orgTable &&
    `
    max-width : 200px;
  `};
`;
