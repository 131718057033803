import React from 'react';
import Button from 'shared/styles/components/Button';
import { ModalText, ActionsContainer, Title, CloseButton } from 'shared/styles/components/Modal';

export default ({
  resourceId,
  confirmPrompt,
  confirmText,
  cancelText,
  confirmHandler,
  cancelHandler,
  handleRequestClose,
}) => {
  return (
    <div>
      <CloseButton onClick={handleRequestClose} />
      <Title>{confirmText}</Title>
      <ModalText>{confirmPrompt}</ModalText>
      <ActionsContainer>
        <Button onClick={cancelHandler} default>
          {cancelText}
        </Button>
        <Button
          mediumAlt
          style={styles.confirm}
          onClick={() => {
            confirmHandler(resourceId);
            cancelHandler();
          }}
        >
          {confirmText}
        </Button>
      </ActionsContainer>
    </div>
  );
};

const styles = {
  confirm: {
    marginLeft: '10px',
  },
};
