import { vehiclePlaybackDateValidator } from 'app/features/playback/state/formik/validation/vehiclePlaybackDateValidator';
import { vehiclePlaybackEndDateValidator } from 'app/features/playback/state/formik/validation/vehiclePlaybackEndDateValidator';
import {
  analyticsLaunchDateValidator,
  dateRegex,
  dateRegexError,
  endDateBeforeStartDateValidator,
  regexValidator,
  requiredValidator,
} from 'shared/utilities/validators';

export const vehiclePlaybackDateRangeValidators = {
  startDate: requiredValidator(
    vehiclePlaybackDateValidator(regexValidator(dateRegex, dateRegexError('start date'))),
  ),
  endDate: requiredValidator(
    vehiclePlaybackDateValidator(regexValidator(dateRegex, dateRegexError('end date'))),
  ),
  endDateBeforeStartDate: endDateBeforeStartDateValidator,
  endDateWithinOneDayOfStartDate: vehiclePlaybackEndDateValidator,
  startDateAfterAnalyticsLaunch: analyticsLaunchDateValidator,
};
