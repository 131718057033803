import { vehicleAddonDictionary } from 'features/fleet/vehicles/constants/vehicleAddonDictionary';
import { vsgInputOptions } from 'features/fleet/vehicles/constants/vsgInputOptions';
import { RemoveButton } from 'shared/styles/components/Button';
import { FormAsterisk, FormLabelAnimated } from 'shared/styles/components/Form';
import StyledReactSelect from 'shared/styles/components/SelectField';
import {
  AddonFeatureDiv,
  FeatureTypeDiv,
  VsgInputSelectionDiv,
  AlertSelectionDiv,
  AlertIcon,
  AlertLevelOuterWrapperDiv,
  MobileRemoveLineItemButtonWrapper,
} from 'shared/styles/components/VehicleAddons';
import AnimatedField from 'shared/ui/fields/AnimatedField';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { vehicleAddonAlertLevels } from 'features/fleet/vehicles/constants/vehicleAddonAlertLevels';
import { TextButton } from 'shared/styles/components/Button';

const SimpleAddon = ({ addon, removeAddon, handleSelectVsgInput, handleSelectAlertLevel }) => {
  const detemineAlertLevelIcon = addon => {
    switch (addon.alert_level) {
      case 'Low':
        return <AlertIcon Low alt="Low Alert Level" title="Low Alert Level" />;
      case 'Medium':
        return <AlertIcon Medium alt="Medium Alert Level" title="Medium Alert Level" />;
      case 'High':
        return <AlertIcon High alt="High Alert Level" title="High Alert Level" />;
      default:
        return <AlertIcon />;
    }
  };
  return (
    <>
      <AddonFeatureDiv>
        <FeatureTypeDiv>
          <AnimatedField
            smallerTextMobile={true}
            placeholder="Feature Type"
            name="addonFeatureName"
            value={vehicleAddonDictionary[addon.type]?.displayName}
            disabled={true}
          />
        </FeatureTypeDiv>
        <VsgInputSelectionDiv>
          <FormLabelAnimated animated>
            VSG Input<FormAsterisk>*</FormAsterisk>
          </FormLabelAnimated>
          <StyledReactSelect
            name="vsgInput"
            placeholder={'Select an Input'}
            isDisabled={false}
            isClearable={false}
            isSearchable={false}
            options={vsgInputOptions}
            defaultValue={{
              label:
                addon.assigned_inputs?.length > 0 &&
                vsgInputOptions.find(o => o.value === addon.assigned_inputs[0]).label,
            }}
            onChange={e => handleSelectVsgInput(addon.addon_id, e.value)}
          />
        </VsgInputSelectionDiv>
        <AlertLevelOuterWrapperDiv>
          <AlertSelectionDiv>
            <FormLabelAnimated animated>
              Alert Level<FormAsterisk>*</FormAsterisk>
            </FormLabelAnimated>
            <StyledReactSelect
              name="alertLevelOption"
              placeholder={'Select an Alert Level'}
              disabled={false}
              isClearable={false}
              isSearchable={false}
              options={vehicleAddonAlertLevels}
              defaultValue={{
                label: vehicleAddonAlertLevels.find(a => a.label === addon.alert_level).label,
              }}
              onChange={e =>
                handleSelectAlertLevel(addon.addon_id, vehicleAddonAlertLevels[e.value].label)
              }
            />
          </AlertSelectionDiv>
          {detemineAlertLevelIcon(addon)}
          <RemoveButton type="button" lineItem title="Remove">
            <IconSvgComponent
              svgFileName={'remove-feature'}
              alt="Remove"
              onClick={() => removeAddon(addon.addon_id)}
            />
          </RemoveButton>
        </AlertLevelOuterWrapperDiv>
        <MobileRemoveLineItemButtonWrapper>
          <TextButton
            type="button"
            alt="Remove Addon"
            largeFont
            onClick={() => removeAddon(addon.addon_id)}
          >
            Remove Addon
          </TextButton>
        </MobileRemoveLineItemButtonWrapper>
      </AddonFeatureDiv>
    </>
  );
};

export default SimpleAddon;
