import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { SimMessage } from 'shared/styles/components/Form';

export const SimBanner = ({ cellState, cellDuid, duid, simMessage }) => {
  if (!cellState) {
    return (
      <>
        <IconSvgComponent
          svgFileName="sim-unknown"
          alt="Unable to determine cellular plan information at this moment"
          title={'Unable to determine cellular plan information at this moment'}
        />
        {simMessage && (
          <SimMessage>Unable to determine cellular plan information at this moment</SimMessage>
        )}
      </>
    );
  }

  switch (cellState) {
    case 'active':
      return <span></span>;
    case 'unknown':
      return (
        <>
          <IconSvgComponent
            svgFileName="sim-unknown"
            alt="Unable to determine cellular plan information at this moment"
            title={'Unable to determine cellular plan information at this moment for ' + cellDuid}
          />
          {simMessage && (
            <SimMessage>Unable to determine cellular plan information at this moment</SimMessage>
          )}
        </>
      );
    case 'deactive':
      return (
        <>
          <IconSvgComponent
            svgFileName="sim-warning"
            alt="Device cellular plan is currently not active"
            title={'Device cellular plan is currently not active for ' + duid}
          />
          {simMessage && <SimMessage>Device cellular plan is currently not active</SimMessage>}
        </>
      );
    case 'pre-active':
      return (
        <>
          <IconSvgComponent
            svgFileName="sim-warning"
            alt="Device cellular plan is currently not active"
            title={'Device cellular plan is currently not active for ' + duid}
          />
          {simMessage && <SimMessage>Device cellular plan is currently not active</SimMessage>}
        </>
      );
    case 'pending deactivation':
      return (
        <>
          <IconSvgComponent
            svgFileName="sim-warning"
            alt="Device cellular plan is currently not active"
            title={'Device cellular plan is currently not active for ' + duid}
          />
          {simMessage && <SimMessage>Device cellular plan is currently not active</SimMessage>}
        </>
      );
    case 'device not found':
      return (
        <>
          <IconSvgComponent
            svgFileName="sim-warning"
            alt="Device cellular information was not found"
            title={'Device cellular information was not found for ' + duid}
          />
          {simMessage && <SimMessage>Device cellular information was not found</SimMessage>}
        </>
      );
    case 'pending activation':
      return (
        <>
          <IconSvgComponent
            svgFileName="sim-pending"
            alt="Device cellular plan pending activation. Device plan should be active soon but in rare cases this can take up to 24 hours"
            title={
              'Device cellular plan pending activation. Device plan should be active soon but in rare cases this can take up to 24 hours. VSG ID: ' +
              cellDuid
            }
          />
          {simMessage && (
            <SimMessage>
              Device cellular plan pending activation. Device plan should be active soon but in rare
              cases this can take up to 24 hours
            </SimMessage>
          )}
        </>
      );
    case 'id not found':
      return (
        <>
          <IconSvgComponent
            svgFileName="sim-warning"
            alt="VSG Registration ID appears to be incorrect"
            title={'VSG Registration ID appears to be incorrect for ' + duid}
          />
          {simMessage && <SimMessage>VSG Registration ID appears to be incorrect</SimMessage>}
        </>
      );

    default:
      return <span></span>;
  }
};
