import { Link } from 'react-router-dom';

import PermissionProtectedElement from 'core/components/authorization/PermissionProtectedElement';
import { permissionData } from 'shared/constants/users';
import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import { UnsupportedIconWrapper } from 'shared/styles/components/Maintenance';
import { TableCellCheckboxWrapper } from 'shared/styles/components/Table';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const ScheduleMaintenanceVehicleNameColumn = ({
  vehicle,
  selectableVehicles,
  selectedVehicles,
  setSelectedVehicles,
}) => {
  return (
    <span className="vehicleNameAndIcon" title={'Vehicle Name: ' + vehicle.vehicleName}>
      {selectableVehicles.map((v) => v.vehicleId)?.includes(vehicle.vehicleId) ? (
        <TableCellCheckboxWrapper>
          <Checkbox singleCheck>
            <CheckboxInner
              isChecked={selectedVehicles?.map((sv) => sv.vehicleId)?.includes(vehicle.vehicleId)}
              onClick={() => {
                selectedVehicles.map((sv) => sv.vehicleId)?.includes(vehicle.vehicleId)
                  ? setSelectedVehicles({
                      selectedVehicles: [
                        ...selectedVehicles.filter((sv) => sv.vehicleId !== vehicle.vehicleId),
                      ],
                    })
                  : setSelectedVehicles({ selectedVehicles: [...selectedVehicles, vehicle] });
              }}
            />
          </Checkbox>
        </TableCellCheckboxWrapper>
      ) : (
        <UnsupportedIconWrapper>
          <IconSvgComponent
            svgFileName="unsupported-checkbox"
            title="This vehicle does not support the selected interval type"
            alt="This vehicle does not support the selected interval type"
          />
        </UnsupportedIconWrapper>
      )}
      <PermissionProtectedElement
        requiredPermissions={[permissionData.editfleet]}
        alternativeComponent={<>{vehicle.vehicleName}</>}
      >
        <Link
          to={{
            pathname: `/vehicle/${encodeURIComponent(vehicle.vehicleId)}`,
          }}
          style={{ textDecoration: 'none' }}
        >
          {vehicle.vehicleName}
        </Link>
      </PermissionProtectedElement>
    </span>
  );
};

export default ScheduleMaintenanceVehicleNameColumn;
