import { useIsFetching } from '@tanstack/react-query';
import React from 'react';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';
import SmallLoadingSpinner from 'shared/ui/spinners/SmallLoadingSpinner';

const IsFetchingWrapper = ({
  children,
  queryKeys,
  inline,
  stillLoadingOverride = false,
  useWholeKeys = false,
}) => {
  // hack - filter out invalid queryKeys
  queryKeys = queryKeys.filter(Boolean);

  // ignore when queryKeys is null or empty
  if (queryKeys.length === 0) return null;

  // queryKeys is an array of arrays, where the first item in each array is the queryKey prefix we care about
  // throw exception if it is null or filled with undefined values
  // if (!queryKeys || queryKeys.some(k => !k || k.length === 0)) {
  //   throw new Error(
  //     `Query keys must be an array of arrays with at least one item in each array. Was given: ${JSON.stringify(
  //       queryKeys,
  //     )}`,
  //   );
  // }
  let queryFilters = {
    predicate: ({ queryKey }) => {
      // does any of the items in queryKey match those in queryKeyWords?
      let keys;

      if (useWholeKeys) {
        queryKey = queryKey.join('');
        keys = queryKeys.map(k => k.join(''));
      } else {
        keys = queryKeys.map(k => k[0]);
      }

      let filtered = keys.some(qk => queryKey.includes(qk));

      return filtered;
    },
  };
  // useIsFetching takes a predicate function that returns true if the queryKey matches the queryKeyPrefix
  const numFetching = useIsFetching(queryFilters);

  // if any of the queries are fetching, show the loading overlay
  const isFetching = numFetching > 0;
  const loadingSpinner = inline ? <SmallLoadingSpinner /> : <LoadingOverlay />;

  return isFetching || stillLoadingOverride ? loadingSpinner : <>{children}</>;
};

export default IsFetchingWrapper;
