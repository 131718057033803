import { useEffect, useState } from 'react';
import Media from 'react-media';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import { getUserActivityLogs } from 'core/api/users';
import UserActivityLogCard from 'features/people/shared/mobile/UserActivityLogCard';
import DateRangeSubmitForm from 'shared/components/DateRangeSubmitForm';
import { SORT_ORDER } from 'shared/constants/table';
import { NoDataDiv } from 'shared/styles/components/Analytics';
import { Cards } from 'shared/styles/components/MobileCard';
import PageListWrapper, {
  DateWrapper,
  ExportDiv,
  ExportWrapper,
  PageListHead,
  PageListTitle,
} from 'shared/styles/components/PageList';
import WcpTable from 'app/shared/components/controls/WcpTable/WcpTable';
import { sortDescendingAlpha } from 'shared/utilities/general';
import { sizes } from 'shared/utilities/media';
import {
  formatISOTimestampToShortDate,
  getAMonthAgo,
  getLongDateFromUnixTimestamp,
  getNow,
} from 'shared/utilities/time';
import { validateLogsRange } from 'shared/utilities/validators';
import UserActivityLogsExportButton from 'features/people/organizations/buttons/UserActivityLogsExportButton';

const UserActivityLogsPage = () => {
  const { userName } = useParams();
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const [userlogs, setUserlogs] = useState([]);
  const [filteredLogsData, setFilteredLogsData] = useState(userlogs);
  const [listingCount, setListingCount] = useState(userlogs?.length);
  const [serverError, setServerError] = useState(false);
  const defaultStartDate = formatISOTimestampToShortDate(getAMonthAgo());
  const defaultEndDate = formatISOTimestampToShortDate(getNow());

  useEffect(() => {
    if (userlogs) {
      setListingCount(userlogs.length ? userlogs.length : 0);
      userlogs.forEach((log) => {
        log.formattedTime = getLongDateFromUnixTimestamp(log.timestamp);
      });
      setFilteredLogsData(
        userlogs.sort((l1, l2) => sortDescendingAlpha(l1.timestamp, l2.timestamp)),
      );
    }
  }, [userlogs]);

  const onSubmit = async ({ startDate, endDate }) => {
    try {
      let data = await getUserActivityLogs({
        userName: userName,
        organizationId: filteredOrganizationId,
        startDate: startDate,
        endDate: endDate,
      });
      setUserlogs(data.response.message);
      setServerError(false);
    } catch (err) {
      logWcpError(err);
      setServerError(true);
    }
  };

  const columns = [
    {
      title: 'Timestamp',
      displayProperty: 'formattedTime',
      sortProperty: 'timestamp',
    },
    {
      displayProperty: 'action',
      width: '25%',
    },
    {
      title: 'Name',
      displayProperty: 'name',
      width: '25%',
    },
    {
      title: 'Details',
      displayProperty: 'details',
      width: '25%',
    },
  ];
  return (
    <>
      <PageListWrapper>
        <PageListHead className="head-wrapper">
          <PageListTitle>
            {filteredLogsData.length > 0
              ? `Activity Logs for ${userName}`
              : `Please choose a date range`}
          </PageListTitle>
          <div className="lastseen">
            {filteredLogsData.length > 0 && filteredLogsData[0].formattedTime
              ? `Last seen ${filteredLogsData[0].formattedTime}`
              : ''}
          </div>
          <DateWrapper>
            <DateRangeSubmitForm
              defaultStartDate={defaultStartDate}
              defaultEndDate={defaultEndDate}
              submitHandler={onSubmit}
              validationFunction={validateLogsRange}
              confirmText="Generate"
            />
          </DateWrapper>
          {serverError && (
            <NoDataDiv>Server error: No message was returned from the server</NoDataDiv>
          )}
        </PageListHead>
        {filteredLogsData.length > 0 && (
          <ExportWrapper>
            <ExportDiv>
              <UserActivityLogsExportButton
                userActivityLogs={filteredLogsData}
                userName={userName}
              />
            </ExportDiv>
          </ExportWrapper>
        )}
        {filteredLogsData && (
          <>
            <h5>
              {listingCount === filteredLogsData?.length
                ? `Total of ${listingCount} Log${listingCount !== 1 ? 's' : ''}`
                : `${listingCount} of ${filteredLogsData?.length} Log${
                    filteredLogsData?.length !== 1 ? 's' : ''
                  }`}
            </h5>

            <Media
              queries={{
                tablet: { maxWidth: sizes.tablet },
                mobile: { maxWidth: sizes.mobile },
              }}
            >
              {(matches) =>
                matches.tablet ? (
                  <div>
                    <Cards>
                      {filteredLogsData.map((log) => (
                        <UserActivityLogCard log={log} key={log.timestamp + log.action} />
                      ))}
                    </Cards>
                  </div>
                ) : (
                  <>
                    <WcpTable
                      tableClassName="table-simple"
                      columns={columns}
                      tableSourceData={filteredLogsData}
                      rowKey={(record, i) => i}
                      defaultSortOrder={SORT_ORDER.DESC}
                      setListingCount={setListingCount}
                      emptyText="No activity logs are currently associated with this user"
                    />
                  </>
                )
              }
            </Media>
          </>
        )}
      </PageListWrapper>
    </>
  );
};

export default UserActivityLogsPage;
