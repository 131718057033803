import React from 'react';

import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import LoadFailure from 'app/core/error/components/LoadFailure';
import { datadogRum } from '@datadog/browser-rum';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    logWcpError(error, info);

    const renderingError = new Error(error.message);
    renderingError.name = `ReactRenderingError`;
    renderingError.stack = info.componentStack;
    renderingError.cause = error;

    datadogRum.addError(renderingError);
  }

  render() {
    if (this.state.hasError) {
      return <LoadFailure />;
    }

    return this.props.children;
  }
}
