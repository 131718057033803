import { SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';
import ActionMenu from 'shared/ui/table/ActionMenu';
import ViewVehiclePlayback from 'shared/ui/menus/ViewVehiclePlayback';
import { alertTypeConstants } from 'app/features/alerts/data/enums/alertTypeConstants';
import {
  alertTypeDropdownOptions,
  getAlertTypeValueFromLabel,
} from 'app/features/alerts/data/ui/dropdown/alertTypeDropdownOptions';
import { subractMinutesFromTimestamp } from 'shared/utilities/time';
import { useSelector } from 'react-redux';
import { permissionData } from 'shared/constants/users';

const ManageAlertsLogsActionMenu = ({ log }) => {
  const user = useSelector(state => state.user);
  const isViewPlaybackAllowed = user.user.permissions.includes(permissionData.viewVehiclePlayback);
  const notValidAlertType =
    log.alertType !== alertTypeConstants.SPEED_EXCEEDS &&
    log.alertType !== alertTypeConstants.NR_SPEED_EXCEEDS &&
    log.alertType !== alertTypeConstants.ADDON_ACTIVE;

  return (
    <>
      <ActionMenu>
        <SimpleDropdownItem
          style={notValidAlertType || !isViewPlaybackAllowed ? { cursor: 'not-allowed' } : null}
        >
          <ViewVehiclePlayback
            vehicleId={log.vehicleId}
            timestamp={subractMinutesFromTimestamp({ startTime: log.timestamp, change: 5 })}
            //duration should always be 10 minutes, sent in seconds
            duration={600}
            offlineEvent={false}
            overTwentyFourHours={false}
            notValidAlertType={notValidAlertType}
            isViewPlaybackAllowed={isViewPlaybackAllowed}
          />
        </SimpleDropdownItem>
      </ActionMenu>
    </>
  );
};

export default ManageAlertsLogsActionMenu;
