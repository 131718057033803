const colors = {
  fog: '#f9f9f9',
  haze: '#8a909c',
  concrete: '#e5e5e5',
  cloud: '#f7f7f7',
  cherry: '#d82237',
  tangerine: '#1c1c1c',
  lightTangerine: '#e46473',
  blueberry: '#0e6196',
  cotton: '#ffffff',
  midnight: '#1c1c1c',
  sky: '#424242',
  blackberry: '#001f28',
  charcoal: '#424242',
  grass: '#4ca311',
  charcoal: '#4c4c4c',
};

export default colors;
