import useVehiclePlaybackProviderContext from 'app/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import {
  DateRangeValidationDiv,
  FormAsterisk,
  FormLabelAnimated,
} from 'shared/styles/components/Form';
import { PlaybackDateDiv } from 'shared/styles/components/VehiclePlayback';
import StyledDayPickerInput from 'shared/ui/StyledDayPickerInput';
import {
  formatISOTimestampToShortDate,
  getLongDateInIsoFormat,
  getShortDateFromDatePicker,
} from 'shared/utilities/time';
import { useFormikContext } from 'formik';
import useMakeFormikField from 'app/shared/components/controls/WcpForm/hooks/useMakeFormikField';

const VehiclePlaybackFormEndDateField = () => {
  const {
    actions: { setPlaybackRequestFormAction },
    state: {
      playbackRequestForm: { readOnly, endDate },
    },
  } = useVehiclePlaybackProviderContext();

  const { validationResult } = useMakeFormikField({
    fieldName: 'endDateField',
    fieldValue: endDate,
    triggerValidation: true,
  });

  return (
    <PlaybackDateDiv>
      <FormLabelAnimated animated>
        End Date (MM/DD/YYYY)<FormAsterisk>*</FormAsterisk>
      </FormLabelAnimated>
      <StyledDayPickerInput
        placeholder={formatISOTimestampToShortDate(endDate)}
        name="endDateField"
        value={formatISOTimestampToShortDate(endDate)}
        onDayChange={date => {
          if (date) {
            setPlaybackRequestFormAction({ endDate: getLongDateInIsoFormat(date) });
          }
        }}
        disabled={readOnly}
      />

      <DateRangeValidationDiv>{validationResult.errorMessage}</DateRangeValidationDiv>
    </PlaybackDateDiv>
  );
};

export default VehiclePlaybackFormEndDateField;
