import { useEffect, useState } from 'react';

const useGetFirmwareCounts = ({ updates }) => {
  // total updates for a vehicle
  const [totalCount, setTotalCount] = useState(0);

  // all the completed updates for a vehicle
  const [completedCount, setCompletedCount] = useState(0);

  // if all updates are complete
  const [allComplete, setAllComplete] = useState(false);

  useEffect(() => {
    if (!updates) {
      return false;
    }

    let totalCount = updates.length;
    let completedCount = updates.filter(u => u.firmwareStatus === 'UP_TO_DATE').length;

    setTotalCount(totalCount);
    setCompletedCount(completedCount);
    setAllComplete(completedCount === totalCount);
  }, [updates]);

  return {
    completedCount,
    totalCount,
    allComplete,
  };
};

export default useGetFirmwareCounts;
