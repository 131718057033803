import { useNavigate } from 'react-router-dom/dist';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import {
  recipientGroupEditSuccess,
  recipientGroupEditError,
} from 'app/features/recipientGroups/utilities/notifications';
import { putRecipientGroupApi } from 'app/features/recipientGroups/api/put/putRecipientGroupApi';
import { useDispatch } from 'react-redux';
import useGenericMutation from 'app/shared/hooks/reactQuery/useGenericMutation';

const useUpdateRecipientGroupMutation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const mutation = useGenericMutation({
    apiFn: putRecipientGroupApi,
    onSuccessCallback: () => {
      dispatch(recipientGroupEditSuccess());
      navigate('/managerecipientgroups');
    },
    onErrorCallback: err => {
      dispatch(recipientGroupEditError());
      logWcpError(err);
      navigate('/managerecipientgroups');
    },
  });

  return mutation;
};

export default useUpdateRecipientGroupMutation;
