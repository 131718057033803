import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const UploadButton = styled.div`
  display: flex;
  align-items: center;
  font-size: 12px;
  padding: 10px 15px 10px 18px;
`;
export const WhitelistLink = styled(Link)`
  text-decoration: none;
`;
