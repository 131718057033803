import React from 'react';
import AnimatedField from 'shared/ui/fields/AnimatedField';

const VehicleNameField = ({
  formikProps: { values, touched, errors, handleChange, handleBlur },
}) => {
  return (
    <AnimatedField
      name="label"
      placeholder="Vehicle Name"
      value={values.label}
      touched={touched.label}
      validationError={errors.label}
      handleChange={handleChange}
      handleBlur={handleBlur}
      required
    />
  );
};

export default VehicleNameField;
