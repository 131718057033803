export const mapAndSortFirmwareUpdates = firmwareUpdates => {
  return firmwareUpdates
    .map(p => ({
      productId: p.product_id,
      version: p.version,
      channel: p.channel,
      productLine: p.product_line,
      deviceId: p.device_id,
      versionCurrent: p.version_current,
      versionInitial: p.version_initial,
      currentChannel: p.current_channel,
      error: p.error,
      firmwareStatus: p.firmware_status,
      model: p.model,
      label: p.label,
      vehicleId: p.vehicle_id,
      vin: p.vin,
      vehicleType: p.vehicle_type,
      mfgYear: p.mfg_year,
      organizationId: p.organization_id,
      licensePlate: p.license_plate,
      make: p.make,
      connectivityDeviceId: p.connectivity_device_id,
      productType: p.product_type,
      productName: p.product_name,
      releaseNotes: p.release_notes,
      releaseDate: p.release_date,
      time: p.time,
      hasNeverStartedOta: p.has_never_started_ota,
    }))
    .sort((a, b) => a.productName.localeCompare(b.productName));
};
