/**
 * @ External Dependecies
 */
import styled from 'styled-components';

/**
 * @ Internal Dependecies
 */
import colors from 'shared/constants/colors';
import theme from 'shared/styles/global/theme';
import { media } from 'shared/utilities/media';
import device from 'shared/constants/sizing';

import caretDownWhiteIcon from 'assets/images/caret-down-white@2x.png';

export const SharedMapDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  flex: 1 1 100%;
  max-height: calc(100vh - ${theme.header_height});
  touch-action: none;
`;

/* HEADER */

export const HeaderDiv = styled.div`
  height: ${theme.liveMapBar_height};
  box-shadow: 0 7px 11px rgba(0, 0, 0, 0.09);
  background-image: linear-gradient(to right, ${colors.midnight} 0%, ${colors.charcoal} 100%);
  position: -webkit-sticky;
  position: sticky;

  li + li {
    border-left: 1px solid rgba(230, 235, 245, 0.2);
  }
`;

export const MapHeaderItemsDiv = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export const MapHeaderItem = styled.div`
  width: 25%;
  flex-grow: 1;
  flex-shrink: 1;
  font-size: 13px;
  font-weight: 600;
  color: #fff;

  .dropdown {
    width: 100%;

    .dropdown__trigger {
      position: relative;
      display: flex;
      width: 100%;
      justify-content: flex-start;
      align-items: center;
      height: ${theme.liveMapBar_height};
      padding: 0 35px 0 20px;

      &:after {
        content: '';
        position: absolute;
        right: 18px;
        top: 50%;
        transform: translateY(-50%);
        width: 10px;
        height: 7px;
        background-image: url(${caretDownWhiteIcon});
        background-repeat: no-repeat;
        background-position: center center;
        background-size: cover;
        transition: transform 0.3s;
      }
    }
  }

  .dropdown.dropdown--active .dropdown__trigger {
    &:after {
      transform: translateY(-50%) rotate(180deg);
    }
  }

  ${props =>
    props.textCentered &&
    `
		display: flex;
		justify-content: flex-start;
		align-items: center;
	`}
`;

export const HeaderText = styled.span`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: ${theme.liveMapBar_height};
  padding: 0 20px;
  color: #fff;
`;

/* SIDEBAR  */

export const SidebarAside = styled.aside`
  position: absolute;
  z-index: 20;
  flex: 1 0 375px;
  width: ${theme.liveMapSidebar_width_collapsed};
  height: 100%;
  transition: all 0.3s;
  height: calc(100% - ${theme.liveMapBar_height});
  visibility: hidden;
  white-space: nowrap;

  ${props =>
    props.expanded === true &&
    `
    visibility: visible;
    width: ${theme.liveMapSidebar_width_expanded};
    box-shadow: 0 2px 42px rgba(0, 0, 0, 0.11);
  `}
  ${media.mobile`
  left: 0px;
    `}
`;

export const LivemapRightSidebarAside = styled.aside`
  position: absolute;
  z-index: 10;
  flex: 1 0 375px;
  width: 0px;
  height: 100%;
  transition: all 0.15s;
  height: calc(100% - ${theme.liveMapBar_height});
  visibility: hidden;
  white-space: nowrap;
  right: 0px;

  ${props =>
    props.expanded === true &&
    `
    right: 0px;
    visibility: visible;
    transition: all 0.3s;
    width: ${theme.liveMapSidebar_width_expanded};
    box-shadow: 0 2px 42px rgba(0, 0, 0, 0.11);
  `}

${props =>
  props.addons === true &&
  `
    top: 110px;
    height: calc(100% - ${theme.liveMapAddons_height_offset});
  `}
  ${props =>
    props.fullScreen === true &&
    `
    height: calc(50% - 60px);
  `}
  ${props =>
    props.adjustedHeight === true &&
    `
    top: calc(50vh);
    height: calc(50% );
  `}
`;

export const LiveMapSidebarHead = styled.div`
  padding: 5px 17px 1px 20px;
`;

export const SidebarAndContentDiv = styled.div`
  display: flex;
  flex-grow: 1;
  flex-shrink: 1;
`;

/* MAP */

export const MapContentDiv = styled.div`
  display: flex;
  max-width: 100%;
  flex: 1 1 100%;

  .map-container {
    flex: 1 1 100%;
    height: 100%;
  }
`;
export const LivemapContentInnerDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const MapboxDiv = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const SidebarContainer = styled.div``;

const SidebarToggle = styled.button`
  position: absolute;
  height: 45px;
  margin-top: 5px;
  border: none;
  top: 5px;
  padding: 2px;
  background-color: ${colors.cotton};

  &:hover {
    cursor: pointer;
  }

  .svg-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
  }
`;

export const SidebarShowToggle = styled(SidebarToggle)`
  right: 0px;
  width: 80px;
  border-top-right-radius: 25px;
  border-bottom-right-radius: 25px;
  transition: all 0.15s;
  visibility: visible;

  &:hover {
    background: ${colors.fog};
  }

  ${props =>
    props.sidebarIsExpanded &&
    `
    z-index: 0;
    background: ${colors.cotton};
  `}

  ${props =>
    props.rightSideSidebar &&
    `
    border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  `}
`;

export const SidebarHideToggle = styled(SidebarToggle)`
  right: 3px;
  width: 35px;
`;

export const MapControlsContainer = styled.div`
  display: none;

  @media ${device.medium} {
    display: block;
  }
`;

export const MapFocusControlsDiv = styled.div`
  display: none;
  position: relative;
  bottom: 20px;
  right: 5px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  ${props =>
    props.showFocusToggle &&
    `
   display: block;
  `}
//should be able to remove this prop down the line when vehiclePlayback controls are refactored
${props =>
  props.liveMap &&
  `
    bottom:10px;
  `}
`;
export const MapLayersControlsDiv = styled.div`
  display: none;
  position: relative;
  bottom: 30px;
  right: 5px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  ${props =>
    props.showSatelliteLayerToggle &&
    `
   display: block;
  `}
  ${props =>
    props.liveMap &&
    `
    bottom:10px;
  `}
`;

export const MapPerformanceModeDiv = styled.div`
  position: absolute;
  top: 0px;
  right: 5px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
`;

export const MapZoomControlsDiv = styled.div`
  position: relative;
  bottom: 10px;
  right: 5px;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
  display: none;

  @media ${device.medium} {
    display: block;
  }
`;

export const FullScreenMapToggle = styled.div`
  margin-right: 20px;
  cursor: pointer;

  ${props =>
    props.exitButtonIsVisible &&
    `
    display: none;
  `}
`;

export const RespondingDot = styled.div`
  &:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-left: 20px;
    margin-right: 5px;
    background: ${colors.cherry};
    border: 1px solid #ffffff;
    ${props =>
      props.logs &&
      `
    margin-left: 0px;
  `}
  }
  ${props =>
    props.logsCard &&
    `
    margin-left:5px;
  `}
`;

export const MapFilterCheckboxes = styled.div`
  display: grid;
  grid-gap: 20px;
  margin-top: 20px;

  grid-template-areas:
    'MapCheckboxCol'
    'MapCheckboxCol';

  @media ${device.large} {
    grid-template-areas: 'MapCheckboxCol MapCheckboxCol';
    grid-template-columns: 49% 49%;
    grid-gap: 2%;
  }
`;

export const MapCheckboxCol = styled.div`
  max-width: 100%;
  min-width: 100px;
  flex: 1 100%;
  flex: wrap;
`;

export const ToggleWrapper = styled.div`
  display: none;

  @media ${device.large} {
    display: block;
  }
`;

export const ResultsCountLabel = styled.h5`
  padding: 20px 28px 1px 20px;
  letter-spacing: -0.8px;
  font-size: 16px;

  @media ${device.large} {
    font-size: 18px;
    letter-spacing: -0.3px;
  }
`;

export const MapSidebarSearchBoxWrapperDiv = styled.div`
  padding-left: 20px;
  margin-bottom: 10px;

  ${props =>
    props.geofences &&
    `
    padding-left: 0px;
  `}
  ${props =>
    props.addons &&
    `
    padding-left: 0px;
    width:130px;
  `}
`;

export const ResultsCountWrapperDiv = styled.div``;
export const ShowHideButtonDiv = styled.div`
  float: right;
  margin-top: -45px;
  margin-right: 28px;
`;

export const FilterLabel = styled.h5`
  margin: 10px 0px;
`;

export const HeatmapFilterDiv = styled.div`
  margin-top: 40px;
`;

export const HideLiveMapDiv = styled.div`
  margin: 100px auto;
  text-align: center;
`;

export const VehiclePlaybackNoMapDiv = styled.div`
  display: none;

  @media ${device.large} {
    display: block;
  }
  margin: 50px auto;
  text-align: center;
`;

export const VehiclePlaybackNoMapInnerDiv = styled.div`
  margin-left: ${theme.liveMapSidebar_width_expanded};
`;

export const SidebarResultsWrapperDiv = styled.div`
  padding: 0px 0px 100px 0px;
  @media ${device.large} {
    padding: 0px;
  }
`;

export const LiveMapRightSidebarWrapperDiv = styled.div`
  padding: 15px 20px 100px 20px;
`;

export const TimestampWrapperDiv = styled.div`
  display: none;

  // show for both larger screen and tablets
  @media ${device.large} {
    display: block;
  }
  @media ${device.medium} {
    display: block;
  }
`;

export const VehicleNameToggleWrapperDiv = styled.div`
  margin-left: 10px;
  margin-top: 13px;
`;
