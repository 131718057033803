import { maintenanceStatusConstants } from 'app/features/maintenance/data/maintenanceStatusConstants';
import { maintenanceIntervalDropdownData } from 'app/features/maintenance/data/maintenanceIntervalDropdownData';
import { maintenanceStatusDropdownData } from 'app/features/maintenance/data/maintenanceStatusDropdownData';
import { maintenanceTypeDropdownData } from 'app/features/maintenance/data/maintenanceTypeDropdownData';
import Badge from 'shared/styles/components/Badge';
import { SuppressedWrapper } from 'shared/styles/components/Maintenance';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { formatISOTimestampToShortDate } from 'shared/utilities/time';
import { UpToDate } from 'shared/styles/components/Maintenance';
import { maintenanceIntervalConstants } from 'app/features/maintenance/data/maintenanceIntervalConstants';
import { maintenanceTypeConstants } from 'app/features/maintenance/data/maintenanceTypeConstants';

export const addDisplayCommas = valueToConvert => {
  return valueToConvert?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const stripCommas = value => {
  return value?.toString().replaceAll(',', '');
};

export const stripTrailingPercentage = value => {
  return value?.toString().replaceAll('%', '');
};

export const determineBoldDisplay = value =>
  value === maintenanceStatusConstants.DUE_IN_SOON ||
  value === maintenanceStatusConstants.DUE_FOR_WORK ||
  value === maintenanceStatusConstants.CHECK_ENGINE;

export const determineStatusDisplay = event => {
  const displayValue = maintenanceStatusDropdownData.find(m => m.value === event.status)?.label;
  if (
    event.status === maintenanceStatusConstants.DUE_IN_SOON ||
    event.status === maintenanceStatusConstants.DUE_FOR_WORK
  ) {
    return (
      <Badge maintenanceDue title={`Staus: ${displayValue}`}>
        {displayValue}
      </Badge>
    );
  } else if (event.status === maintenanceStatusConstants.CHECK_ENGINE) {
    return event.isSuppressed ? (
      <SuppressedWrapper title="Alerts have been suppressed">
        <IconSvgComponent svgFileName="suppressed-alerts" />
        <Badge
          checkEngineSuppressed
          title={`Staus: ${displayValue} (Alerts Suppressed) \nError Code: ${event.diagnosticCode}: ${event.diagnosticCodeDescription}`}
        >
          {displayValue}
        </Badge>
      </SuppressedWrapper>
    ) : (
      <Badge
        checkEngine
        title={`Error Code: ${event.diagnosticCode}: ${event.diagnosticCodeDescription}`}
      >
        {displayValue}
      </Badge>
    );
  } else if (event.status === maintenanceStatusConstants.UP_TO_DATE) {
    return (
      <>
        <IconSvgComponent
          svgFileName={'check-success'}
          title={`Staus: ${displayValue} Up to Date`}
        />
        <UpToDate>Up to Date</UpToDate>
      </>
    );
  }
};

/**
 * Returns the display value for the interval type dropdown.
 * @param {Object} maintenanceLog - Maintenance log object
 * @returns {String} - Display value for the interval type dropdown
 */
export const getIntervalDisplay = maintenanceLog => {
  let display;
  if (maintenanceLog.interval_unit === maintenanceIntervalConstants.TOTAL_MILEAGE) {
    display = `${maintenanceLog.total_mileage && maintenanceLog.total_mileage} ${
      maintenanceIntervalDropdownData.find(i => i.value === maintenanceLog.interval_unit)
        ?.unitSuffix
    }`;
  } else if (maintenanceLog.interval_unit !== maintenanceIntervalConstants.DATE) {
    maintenanceLog.interval_unit !== 'NONE'
      ? (display = `${maintenanceLog.interval}  ${
          maintenanceIntervalDropdownData.find(i => i.value === maintenanceLog.interval_unit)
            ?.unitSuffix
        }`)
      : (display = 'N/A');
  } else if (maintenanceLog.interval_unit === maintenanceIntervalConstants.DATE) {
    display = `${maintenanceLog.due_date &&
      formatISOTimestampToShortDate(maintenanceLog.due_date)}`;
  }
  return display;
};

/**
 * Returns the display value for the maintenance type dropdown.
 * @param {String} type - Maintenance type
 * @returns {String} - Display value for the maintenance type dropdown
 */
export const getMaintenanceTypeDisplay = type => {
  let display;
  type !== maintenanceTypeConstants.DIAGNOSTIC
    ? (display = maintenanceTypeDropdownData.find(i => i.value === type)?.label)
    : (display = 'Check Engine');
  return display;
};

/**
 * Returns the display value for the notes field.
 * @param {Object} maintenanceLog - Maintenance log object
 * @returns {String} - Display value for the notes field
 */
export const getNotesContent = maintenanceLog => {
  let message =
    maintenanceLog.diagnostic_code !== null
      ? `Error code: ${maintenanceLog.diagnostic_code} ${maintenanceLog.diagnostic_code_description}`
      : maintenanceLog.notes;
  return message ? message : 'N/A';
};

export const getObdDisplayValue = value => {
  let displayValue;
  value ? (displayValue = addDisplayCommas(value)) : (displayValue = 'N/A');
  return displayValue;
};

export const getLastIn = value => {
  let displayValue;

  value
    ? (displayValue = formatISOTimestampToShortDate(vehicle.obd.last_obd_message_time))
    : (displayValue = 'N/A');
  return displayValue;
};
