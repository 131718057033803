import React, { useState } from 'react';
import { SimpleDropdownItem, SimpleDropdownBtn } from 'shared/styles/components/DropdownMenu';

export default ({ title, onClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onClick}>{title}</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};
