export const mapGeofenceActivityLogsToCsv = ({ geofenceLogsData }) => {
  return geofenceLogsData.map(l => {
    return {
      Timestamp: l.formattedTime,
      Vehicle_Name: l.vehicle_label,
      Action: `${l.action} Geofence`,
      Geofence_Status: l.while_active ? 'Active' : 'Not Active',
    };
  });
};
