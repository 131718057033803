import React, { useState, useEffect } from 'react';
import Media from 'react-media';
import { sizes } from 'shared/utilities/media';

/**
 * @ Components
 */
import ConfigurationSummaryModalContent from 'shared/modals/ConfigurationSummaryModalContent';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import SortableTableWithoutPagination from 'shared/ui/table/SortableTableWithoutPagination';

/**
 * Styled Components
 */
import { Container, Title, CloseButton } from 'shared/styles/components/Modal';
import {
  supportedDevicesHeaderCellStyles,
  DeviceCell,
  SupportedDevicesTable,
} from 'shared/styles/components/Table';

/**
 * @ Utilities
 */
import { formatISOTimestampToLongDateWithTime } from 'shared/utilities/time';
import {
  UploadDateDiv,
  SupportedDeviceName,
  TableCount,
  TableTitle,
  SupportedDeviceWrapperDiv,
} from 'shared/styles/components/OtaUpdates';

/**
 * @ Constants
 */
import colors from 'shared/constants/colors';
import { getDevices } from 'core/api/devices';

const tableHorizontalPadding = 47;
const columns = [
  {
    title: 'Product Name',
    dataIndex: 'productName',
    key: 'productName',
    onHeaderCell: () => ({
      style: {
        ...supportedDevicesHeaderCellStyles,
        paddingLeft: `${tableHorizontalPadding}px`,
      },
    }),
    width: '340px',
    render: value => (
      <DeviceCell nameCell tableHorizontalPadding={tableHorizontalPadding} maxWidth={340}>
        {value}
      </DeviceCell>
    ),
  },
  {
    title: 'Product ID',
    dataIndex: 'productId',
    key: 'productId',
    width: 170,
    onHeaderCell: () => ({ style: supportedDevicesHeaderCellStyles }),
    render: value => <DeviceCell>{value}</DeviceCell>,
  },
  {
    title: 'Supported Installations',
    dataIndex: 'supportedInstallations',
    key: 'supportedInstallations',
    onHeaderCell: () => ({ style: supportedDevicesHeaderCellStyles }),
    render: value => <DeviceCell>{value}</DeviceCell>,
  },
];

export default ({ data: configDetails, onSuccess, handleRequestClose }) => {
  const [supportedDevices, setSupportedDevices] = useState([]);

  useEffect(() => {
    getSupportedDevices();
  }, [configDetails]);
  const getSupportedDevices = () => {
    setSupportedDevices(configDetails.supportedDevices);
  };
  return (
    <Container autoHeight>
      <CloseButton onClick={handleRequestClose}>
        <IconSvgComponent svgFileName={'x'} alt="Close" title="Close" />
      </CloseButton>
      <Title style={styles.title}>
        <IconSvgComponent style={styles.editIcon} svgFileName={'edit-pencil-blue'} alt="Edit" />
        Edit Configuration
      </Title>
      <ConfigurationSummaryModalContent configDetails={configDetails} onSuccess={onSuccess}>
        <UploadDateDiv>
          Uploaded on:{' '}
          {configDetails.uploadedTime !== 'UNKNOWN'
            ? formatISOTimestampToLongDateWithTime(configDetails.uploadedTime)
            : configDetails.uploadedTime}
        </UploadDateDiv>
        <TableTitle>
          Supported Products
          <TableCount>{configDetails.supportedDevices.length}</TableCount>
        </TableTitle>

        <Media
          queries={{
            tablet: { maxWidth: sizes.tablet },
            mobile: { maxWidth: sizes.mobile },
          }}
        >
          {matches =>
            matches.tablet ? (
              <SupportedDeviceWrapperDiv>
                {supportedDevices.map(supportedDevice => (
                  <div>
                    <SupportedDeviceName>{supportedDevice.productName}</SupportedDeviceName>
                    Supported devices: {supportedDevice.supportedInstallations}
                  </div>
                ))}
              </SupportedDeviceWrapperDiv>
            ) : (
              <SortableTableWithoutPagination
                style={{
                  marginBottom: '40px',
                  overflow: 'visible',
                  marginLeft: '47px',
                  marginRight: '47px',
                  ...styles.supportedDevicesTable,
                }}
                scroll={{ y: 300 }}
                columns={columns}
                data={supportedDevices}
                rowKey={record => record.productId + record.installationId}
                onRow={(row, index) => ({
                  style: {
                    maxHeight: '50px',
                    backgroundColor: index % 2 === 0 ? colors.fog : colors.concrete,
                  },
                })}
                components={{
                  table: SupportedDevicesTable,
                }}
              />
            )
          }
        </Media>
      </ConfigurationSummaryModalContent>
    </Container>
  );
};

const styles = {
  title: {
    marginBottom: '42px',
  },
  editIcon: {
    marginRight: '10px',
  },
  supportedDevicesTable: {
    marginLeft: `-${tableHorizontalPadding}px`,
    marginRight: `-${tableHorizontalPadding}px`,
  },
};
