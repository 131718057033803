import MaintenanceItemContainer from 'app/features/maintenance/components/MaintenanceBuilder/containers/MaintenanceItemContainer';
import MaintenanceTypeDropdown from 'app/features/maintenance/components/MaintenanceBuilder/dropdowns/MaintenanceTypeDropdown';
import { getMaintenanceTypeValueFromLabel } from 'app/features/maintenance/data/maintenanceTypeDropdownData';
import MaintenanceBuilderContextProvider from 'app/features/maintenance/providers/MaintenanceBuilderContextProvider/MaintenanceBuilderContextProvider';
import { MaintenanceTypeControlDiv } from 'shared/styles/components/Maintenance';

const MaintenanceBuilder = ({
  handleSelectedIntervalTypes,
  handleSetMaintenanceItems,
  initialState,
  setScheduleMaintenanceIsValid,
  handleMaintenanceItemsDirty,
  handleMaintenanceItemsValid,
  vehicle,
  orgUsesEngineHours,
  orgUsesEnhancedTelematics,
}) => {
  return (
    <MaintenanceBuilderContextProvider
      initialState={initialState}
      onStateChanged={({ selectedIntervalTypes, maintenanceItems, rowValidStates }) => {
        // if rowValidStates is empty or undefined return
        if (!rowValidStates || rowValidStates.length === 0) return false;

        const isValid = rowValidStates.every(row => row.isValid);
        const isDirty = rowValidStates.some(row => row.isDirty);

        // pass selected state changes up to the parent
        // if handleSelectedIntervalTypes is not defined, don't call it
        if (handleMaintenanceItemsDirty) handleMaintenanceItemsDirty({ isDirty });
        if (handleMaintenanceItemsValid) handleMaintenanceItemsValid({ isValid });
        if (handleSelectedIntervalTypes) handleSelectedIntervalTypes({ selectedIntervalTypes });
        if (handleSetMaintenanceItems) handleSetMaintenanceItems({ maintenanceItems });
        return false;
      }}
    >
      {(
        {
          addMaintenanceItemAction,
          updateMaintenanceItemAction,
          removeMaintenanceItemAction,
          isMaintenanceItemValidAction,
        },
        { maintenanceItems },
      ) => {
        return (
          <MaintenanceTypeControlDiv>
            <MaintenanceTypeDropdown
              handleSelect={selectedItem => {
                const maintenanceItem = {
                  maintenanceType: selectedItem,
                };
                // this just gets called once
                addMaintenanceItemAction({ maintenanceItem });
              }}
            />

            <div>
              {maintenanceItems?.map(item => (
                <MaintenanceItemContainer
                  vehicle={vehicle}
                  orgUsesEngineHours={orgUsesEngineHours}
                  orgUsesEnhancedTelematics={orgUsesEnhancedTelematics}
                  key={item.id}
                  maintenanceItem={item}
                  handleIsMaintenceBuilderValid={({ isValid }) => {
                    if (setScheduleMaintenanceIsValid) setScheduleMaintenanceIsValid({ isValid });
                    isMaintenanceItemValidAction({ id: item.id, isValid });
                  }}
                  handleUpdateMaintenanceItem={({ updatedMaintenanceItem }) => {
                    updateMaintenanceItemAction({ maintenanceItem: updatedMaintenanceItem });
                  }}
                  handleRemoveMaintenanceItem={id => removeMaintenanceItemAction({ id })}
                />
              ))}
            </div>
          </MaintenanceTypeControlDiv>
        );
      }}
    </MaintenanceBuilderContextProvider>
  );
};

export default MaintenanceBuilder;
