import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import VehicleEditFormContainer from 'features/fleet/vehicles/components/forms/VehicleEditFormContainer';
import { useFetchSingleVehicleQuery } from 'features/fleet/vehicles/hooks/useFetchSingleVehicleQuery';
import LoadingWrapper from 'shared/components/LoadingWrapper';
import PageListWrapper from 'shared/styles/components/PageList';

const VehicleEditPage = () => {
  const { vehicleId } = useParams();
  const navigate = useNavigate();
  const userState = useSelector(state => state.user);

  const fetchSingleVehicleQuery = useFetchSingleVehicleQuery({
    vehicleId,
    enableRefetchInterval: false,
    apiFlags: {
      addons: true,
      cell: true,
      device: true,
      gps: true,
      meta: true,
      maintenance: true,
      obd: true,
    },
    mappingEnabled: true,
  });

  const vehicle = fetchSingleVehicleQuery.data;
  // HELPERS
  const navigateBackToVehicleList = () => {
    navigate('/vehicles');
  };
  return (
    <PageListWrapper>
      <LoadingWrapper isLoading={fetchSingleVehicleQuery.isFetching || !vehicle}>
        <VehicleEditFormContainer
          handleRedirect={navigateBackToVehicleList}
          vehicle={vehicle}
          userState={userState}
        />
      </LoadingWrapper>
    </PageListWrapper>
  );
};

export default VehicleEditPage;
