export default (action, delay) => {
  let timeout;
  const debounceAction = args => {
    action(args);
    clearTimeout(timeout);
    timeout = '';
  };
  return [
    args => {
      if (timeout) {
        clearTimeout(timeout);
        timeout = '';
      }
      timeout = setTimeout(debounceAction, delay);
    },
    () => clearTimeout(timeout),
  ];
};

export const getDebouncedThunk = (action, delay) => {
  let timeout;
  let thunkResolve;
  let thunkPromise = new Promise(resolve => {
    thunkResolve = resolve;
  });

  return args => dispatch => {
    const localTimeout = setTimeout(() => {
      if (localTimeout !== timeout) {
        return thunkPromise;
      } else {
        thunkResolve(dispatch(action(args)));
      }
    }, delay);
    timeout = localTimeout;
    return thunkPromise;
  };
};
