import { addHoursToDateTime, getShortDateInIsoFormat, getUtc } from 'shared/utilities/time';

export const convertDateField = (dateField, timeField) => {
  return getUtc(
    addHoursToDateTime({
      dateTime: getShortDateInIsoFormat(dateField),
      hours: timeField,
    }),
  );
};
