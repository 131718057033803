import { useQuery } from '@tanstack/react-query';
import { useContext, useEffect, useState } from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { connect, useSelector } from 'react-redux';

import { getOrganization } from 'core/api/organizations';
import AddonActivityCategorySection from 'features/livemap/components/addons/AddonActivityCategorySection.js';
import { LivemapApi } from 'features/livemap/components/LivemapContextProvider';
import { organizationFetchError } from 'features/livemap/helpers/notifications';
import Searchbox from 'shared/components/Searchbox';
import { vehicleAddonAlertLevels } from 'features/fleet/vehicles/constants/vehicleAddonAlertLevels';
import { vehicleAddonDictionary } from 'features/fleet/vehicles/constants/vehicleAddonDictionary';
import ModalPortal from 'shared/modals/ModalPortal';
import {
  AddonFiltersWrapperDiv,
  LivemapAddonsCheckboxFilterWrapper,
} from 'shared/styles/components/Addons';
import {
  AllAddonsButtonWrapperDiv,
  AllAddonsModalContainerDiv,
} from 'shared/styles/components/Addons';
import { TextButton } from 'shared/styles/components/Button';
import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import { CloseButton, Container, Title } from 'shared/styles/components/Modal';
import {
  FilterLabel,
  LivemapRightSidebarAside,
  LiveMapRightSidebarWrapperDiv,
  MapSidebarSearchBoxWrapperDiv,
  SidebarHideToggle,
  SidebarShowToggle,
} from 'shared/styles/components/SharedMapStyledComponents';
import { CheckboxTextSpan } from 'shared/styles/components/VehicleAddons';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const containsHighPriorityAddon = (addons) => {
  return addons.some((a) => a.alert_level === vehicleAddonAlertLevels[3].label);
};

const getAddonsGroupedByTypeEntries = (addons) => {
  // group by type
  const addonsGroupedByType = addons?.reduce(
    (result, item) => ({
      ...result,
      ...{ [item.type]: result[item.type] ? [...result[item.type], ...[item]] : [item] },
    }),
    {},
  );

  return Object.entries(addonsGroupedByType)
    .sort((a, b) => {
      return a[0].localeCompare(b[0]);
    })
    .sort((a, b) => {
      if (containsHighPriorityAddon(a[1]) && !containsHighPriorityAddon(b[1])) {
        return -1;
      } else if (!containsHighPriorityAddon(a[1]) && containsHighPriorityAddon(b[1])) {
        return 1;
      } else {
        return 0;
      }
    });
};

const LivemapAddonsSidebarContainer = () => {
  const {
    actions: { setLivemapSidebarStates },
    state: { allVehicles, isFullScreenMode, livemapAddonsSidebarOpen },
  } = useContext(LivemapApi);

  const [searchText, setSearchText] = useState('');
  const [sidebarAddons, setSidebarAddons] = useState([]);
  const [modalAddons, setModalAddons] = useState([]);
  const [filterOfflineVehiclesCheckboxIsChecked, setFilterOfflineVehiclesCheckboxIsChecked] =
    useState(false);
  const [viewAllAddonsModalOpen, setViewAllAddonsModalOpen] = useState(false);
  const [organizationName, setOrganizationName] = useState('');

  // TODO react-query
  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const organizationQuery = useQuery({
    queryKey: ['fetchOrganization', filteredOrganizationId],
    queryFn: () => getOrganization(filteredOrganizationId),

    enabled: true,
    select: ({ response: { message: organizations } }) => {
      return organizations;
    },
    onError: (err) => dispatch(organizationFetchError({ err })),
  });

  useEffect(() => {
    if (organizationQuery.data) {
      setOrganizationName(organizationQuery.data[0]?.organization_name);
    }
  }, [organizationQuery.data]);

  /// TODO this causes circular dependency issues when trying to serialize
  useEffect(() => {
    if (!allVehicles) return;
    // append vehicle object to each addon
    allVehicles.forEach((vehicle) => {
      vehicle.addons.forEach((addon) => {
        addon.vehicle = vehicle;
      });
    });

    // flatten
    const allAddons = allVehicles.reduce((result, v) => [...result, ...v.addons], []);

    let filteredActiveAddons = allAddons.filter(
      (a) =>
        a.vehicle?.meta?.label.toLowerCase().includes(searchText.toLowerCase()) ||
        vehicleAddonDictionary[a.type]?.displayName
          .toLowerCase()
          .includes(searchText.toLowerCase()),
    );

    if (filterOfflineVehiclesCheckboxIsChecked)
      filteredActiveAddons = filteredActiveAddons.filter((a) => a.vehicle.meta.online);

    //filtering out non active addons for sidebar display
    const addonsGroupedByTypeEntriesForSidebar = getAddonsGroupedByTypeEntries(
      filteredActiveAddons.filter((a) => a.is_active),
    );

    setSidebarAddons(addonsGroupedByTypeEntriesForSidebar);

    // but we still want the full list for the addon overview modal
    const addonsGroupedByTypeEntriesForModal = getAddonsGroupedByTypeEntries(allAddons);
    setModalAddons(addonsGroupedByTypeEntriesForModal);
  }, [allVehicles, searchText, filterOfflineVehiclesCheckboxIsChecked]);

  return (
    <LivemapRightSidebarAside
      addons
      expanded={livemapAddonsSidebarOpen}
      fullScreen={isFullScreenMode}
      adjustedHeight={isFullScreenMode}
    >
      {
        //we need two levels of the scrollbar element here because of the way the transitions render from right to left
        //this is because there is content inside the outerscroll that is not scrollable
        //any other container type causes brief horizontal scrollbars to appear during the transition
        <Scrollbars
          style={{
            width: livemapAddonsSidebarOpen ? '100%' : 0,
            height: '100%',
            backgroundColor: 'white',
            transition: livemapAddonsSidebarOpen ? null : 'all 1.15s',
            transitionDelay: '.5s',
          }}
        >
          <Scrollbars
            style={{
              height: 'calc(100% - 50px)',
              zIndex: 1,
            }}
          >
            <LiveMapRightSidebarWrapperDiv addons>
              <FilterLabel>Active Vehicle Add-Ons</FilterLabel>
              {livemapAddonsSidebarOpen && (
                <SidebarHideToggle
                  onClick={() =>
                    setLivemapSidebarStates({
                      livemapAddonsSidebarOpen: false,
                    })
                  }
                >
                  <IconSvgComponent
                    svgFileName="hide-right-side-sidebar"
                    title="Hide add-on activity"
                    alt="Hide add-on activity"
                  />
                </SidebarHideToggle>
              )}
              <AddonFiltersWrapperDiv>
                <MapSidebarSearchBoxWrapperDiv addons>
                  <Searchbox
                    smallSearch={true}
                    setSearchText={setSearchText}
                    searchText={searchText}
                  />
                </MapSidebarSearchBoxWrapperDiv>
                <LivemapAddonsCheckboxFilterWrapper>
                  <Checkbox singleCheck>
                    <CheckboxInner
                      isChecked={filterOfflineVehiclesCheckboxIsChecked}
                      onClick={() =>
                        setFilterOfflineVehiclesCheckboxIsChecked(
                          !filterOfflineVehiclesCheckboxIsChecked,
                        )
                      }
                    />
                  </Checkbox>
                  <CheckboxTextSpan addonsFilter>Hide offline vehicles</CheckboxTextSpan>
                </LivemapAddonsCheckboxFilterWrapper>
              </AddonFiltersWrapperDiv>
              {sidebarAddons?.length > 0 ? (
                sidebarAddons.map((addOnByTypeEntry) => (
                  <AddonActivityCategorySection
                    addonType={addOnByTypeEntry[0]}
                    showAlert={
                      /* show alert badge on addon header if any addon alert_level is set to high alert */
                      containsHighPriorityAddon(addOnByTypeEntry[1])
                    }
                    showTime={true}
                    addons={addOnByTypeEntry[1]}
                  ></AddonActivityCategorySection>
                ))
              ) : (
                <>No activity</>
              )}

              {viewAllAddonsModalOpen && (
                <ModalPortal
                  onRequestClose={() => {
                    setViewAllAddonsModalOpen(false);
                  }}
                >
                  <Container maxWidth={650} maxHeight={550}>
                    <CloseButton onClick={() => setViewAllAddonsModalOpen(false)}>
                      <IconSvgComponent svgFileName={'x'} alt="Close" title="Close" />
                    </CloseButton>
                    <Title>Vehicle Add-Ons used in {organizationName}</Title>
                    <AllAddonsModalContainerDiv>
                      <Scrollbars
                        style={{
                          width: '100%',
                          height: '100%',
                          backgroundColor: 'white',
                        }}
                      >
                        {modalAddons?.length > 0 ? (
                          modalAddons.map((addOnByTypeEntry) => (
                            <AddonActivityCategorySection
                              addonType={addOnByTypeEntry[0]}
                              showAlert={false}
                              showTime={false}
                              addons={addOnByTypeEntry[1]}
                              setViewAllAddonsModalOpen={setViewAllAddonsModalOpen}
                              viewAllAddonsModalOpen={viewAllAddonsModalOpen}
                            ></AddonActivityCategorySection>
                          ))
                        ) : (
                          <>No Addons</>
                        )}
                      </Scrollbars>
                    </AllAddonsModalContainerDiv>
                  </Container>
                </ModalPortal>
              )}
            </LiveMapRightSidebarWrapperDiv>
          </Scrollbars>
          {!isFullScreenMode && (
            <AllAddonsButtonWrapperDiv isOpen={livemapAddonsSidebarOpen}>
              <TextButton type="button" largeFont onClick={() => setViewAllAddonsModalOpen(true)}>
                View All Vehicle Add-ons used
              </TextButton>
            </AllAddonsButtonWrapperDiv>
          )}
        </Scrollbars>
      }
      {livemapAddonsSidebarOpen ? null : (
        <SidebarShowToggle
          rightSideSidebar
          isOpen={livemapAddonsSidebarOpen}
          onClick={() =>
            setLivemapSidebarStates({
              livemapAddonsSidebarOpen: !livemapAddonsSidebarOpen,
            })
          }
        >
          <IconSvgComponent
            svgFileName="show-addons-activity-sidebar"
            title="Show add-on activity"
            alt="Show add-on activity"
          />
        </SidebarShowToggle>
      )}
    </LivemapRightSidebarAside>
  );
};
export default LivemapAddonsSidebarContainer;
