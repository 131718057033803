import { createAction } from 'redux-actions';
import {
  savePermissions as savePermissionsAPI,
  restorePermissions as restorePermissionsAPI,
} from 'core/api/organizations';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { addNotification } from 'core/redux/ui/actions';
export const requestEditPermissions = createAction('REQUEST_EDIT_PERMISSIONSS');
export const successEditPermissions = createAction('RECEIVE_EDIT_PERMISSIONS');
export const rejectEditPermissions = createAction('REJECT_EDIT_PERMISSIONS');

export const savePermissions = ({ organizationId, permissions }) => {
  return async dispatch => {
    dispatch(requestEditPermissions());
    try {
      let { response } = await savePermissionsAPI({
        organizationId,
        permissions,
      });
      if (response.message) {
        console.log(response.message);
        dispatch(successEditPermissions());
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.SUCCESS, response.message),
          }),
        );
      }
    } catch (e) {
      console.log(e);
      dispatch(rejectEditPermissions());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, e),
        }),
      );
    }
  };
};

export const restorePermissions = ({ organizationId }) => {
  return async dispatch => {
    try {
      let { response } = await restorePermissionsAPI({
        organizationId,
      });
      if (response.message) {
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.SUCCESS, response.message),
          }),
        );
      }
    } catch (e) {
      console.log(e);
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, e),
        }),
      );
    }
  };
};
