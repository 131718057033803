import { useQueryClient } from '@tanstack/react-query';
import { deleteConfiguration } from 'core/api/configuration';
import useGenericMutation from 'app/shared/hooks/reactQuery/useGenericMutation';
export const useDeleteConfigMutation = () => {
  const queryClient = useQueryClient();

  const mutation = useGenericMutation({
    apiFn: deleteConfiguration,
    onSuccessCallback: () => {},

    onErrorCallback: err => {},
  });

  return mutation;
};
