export const playbackSpeedOptions = [
  {
    label: '.25x',
    value: 0.25,
  },
  {
    label: '.5x',
    value: 0.5,
  },
  {
    label: 'Normal',
    value: 1,
  },
  {
    label: '2x',
    value: 2,
  },
  {
    label: '10x',
    value: 10,
  },
  {
    label: '20x',
    value: 20,
  },
  {
    label: '40x',
    value: 40,
  },
];
