export const logWcpError = (error, errorInfo) => {
  // TODO - error logging
  console.log('WCP ERROR:', error, errorInfo || '');
};
export const wcpDebugProviderLog = (title, msg) => {
  if (!DEBUG_PROVIDER_LOG) {
    return;
  }
  console.groupCollapsed(title);
  Object.entries(msg).forEach(([key, value]) => {
    console.info(`${key}:`, value);
  });
  console.groupEnd();
};

// TODO migrate these options
const DEBUG_PROVIDER_LOG = true;
