import {
  AlertCount,
  AlertIcon,
  AlertItem,
  AlertText,
  AlertTextWrapper,
  MaintenanceAlertWrapper,
  NeedAttentionText,
} from 'shared/styles/components/Maintenance';

const MaintenanceAlertBox = ({
  totalVehicles,
  checkEngine,
  dueForWork,
  dueInSoon,
  handleClickNeedsAttention,
}) => {
  return (
    <MaintenanceAlertWrapper onClick={handleClickNeedsAttention}>
      <AlertIcon />
      <div>
        <NeedAttentionText>{totalVehicles} Vehicles need attention</NeedAttentionText>
        <AlertTextWrapper>
          <AlertText>
            <AlertItem>
              <AlertCount>{checkEngine}</AlertCount> Check Engine
            </AlertItem>
            <AlertItem>
              <AlertCount>{dueForWork}</AlertCount> Due for Work
            </AlertItem>
            <AlertItem>
              <AlertCount>{dueInSoon}</AlertCount> Due in Soon
            </AlertItem>
          </AlertText>
        </AlertTextWrapper>
      </div>
    </MaintenanceAlertWrapper>
  );
};

export default MaintenanceAlertBox;
