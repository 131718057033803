import useVehiclePlaybackProviderContext from 'app/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import useMakeFormikField from 'app/shared/components/controls/WcpForm/hooks/useMakeFormikField';
import { useFormikContext } from 'formik';
import FilteredDropdown from 'app/shared/components/controls/FilteredDropdown/FilteredDropdown';

import { sortAscendingAlphaLabel } from 'shared/utilities/vehicle';
import DropdownOptions from 'app/shared/utilities/dropdown/DropdownOptions';

const VehiclePlaybackFormVehicleDropdown = () => {
  const { actions, state } = useVehiclePlaybackProviderContext();

  const {
    allVehicles,
    playbackRequestForm: { readOnly, selectedVehicleId },
  } = state;
  const { setPlaybackRequestFormAction } = actions;

  const formik = useFormikContext();

  useMakeFormikField({
    fieldName: 'selectedVehicleId',
    fieldValue: selectedVehicleId,
    touched: true,
    triggerValidation: true,
  });

  const defaultSelectedVehicleLabel = allVehicles?.find(v => v.vehicle_id === selectedVehicleId)
    ?.meta.label;

  const vehicleOptions =
    allVehicles
      ?.sort(sortAscendingAlphaLabel)
      .map(v => ({ label: v.meta.label, value: v.meta.label })) || [];
  const vehicleDropdownOptions = new DropdownOptions(vehicleOptions);

  const getSelectedVehicleId = label => {
    return allVehicles.find(v => v.meta.label === label).vehicle_id;
  };
  return (
    <>
      <FilteredDropdown
        fieldLabel={'Vehicle'}
        placeholderLabel="Select a Vehicle..."
        styleOptions={{
          disabled: readOnly,
        }}
        setup={{
          dropdownOptions: vehicleDropdownOptions,
          preselectedValue: defaultSelectedVehicleLabel,
          handleSelect: selectedOption => {
            setPlaybackRequestFormAction({
              selectedVehicleId: selectedOption.value
                ? getSelectedVehicleId(selectedOption.value)
                : null,
            });
          },
        }}
      />
    </>
  );
};

export default VehiclePlaybackFormVehicleDropdown;
