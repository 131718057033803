import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { createGroup } from 'core/api/groups';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';

export const useCreateGroupMutation = () => {
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);

  const queryClient = useQueryClient();
  const createGroupMutation = useMutation({
    mutationFn: ({ groupName }) =>
      createGroup({
        organizationId: filteredOrganizationId,
        groupName,
      }),

    onSuccess: () => {
      queryClient.invalidateQueries(['fetchGroupsQuery']);
    },
    onError: err => {
      logWcpError(err);
    },
  });

  return createGroupMutation;
};
