import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';

export const maintenancePostError = ({ err }) =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Creating Scheduled Maintenance', err),
  });
export const maintenancePostSuccess = () =>
  addNotification({
    notification: createNotification(LEVELS.SUCCESS, 'Successfully Scheduled Maintenance'),
  });
export const maintenancePostPartialSuccess = ({ msg }) =>
  addNotification({
    notification: createNotification(
      LEVELS.WARNING,
      'Successfully Scheduled Some Maintenance Items' + msg,
    ),
  });
export const maintenanceLogsFetchError = ({ err }) =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Getting Maintenance Logs', err),
  });

export const maintenanceDeleteSuccess = () =>
  addNotification({
    notification: createNotification(LEVELS.SUCCESS, 'Successfully Deleted Maintenance Event'),
  });
export const maintenanceDeleteError = () =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Deleting Maintenance Event'),
  });

export const suppressAlertsSuccess = () =>
  addNotification({
    notification: createNotification(
      LEVELS.SUCCESS,
      'Successfully Suppressed Alerts ',
      'You will not longer recieve alerts for this event',
    ),
  });
export const suppressAlertsError = () =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Suppressing Maintenance Event'),
  });

export const markMaintenanceCompletedSuccess = vehicleName =>
  addNotification({
    notification: createNotification(
      LEVELS.SUCCESS,
      'Maintenance Successfully Completed ',
      `Marked Completed for ${vehicleName}`,
    ),
  });
export const markMaintenanceCompletedError = () =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Completing Maintenance Event'),
  });
