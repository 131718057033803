import { useQuery } from '@tanstack/react-query';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { checkLogin } from 'shared/utilities/user';
import { checkForUserPermission } from 'core/redux/user/selectors';
import { permissionData } from 'shared/constants/users';

import { apiCall } from 'core/api/API';
import {
  mapMaintenanceDataToFrontend,
  mapMaintenanceTotalMilageData,
} from 'app/features/maintenance/utilities/mapping';

const getMaintenanceApi = async ({ organizationId }) => {
  const { body } = await apiCall({
    path: `/maintenance`,
    method: 'get',
    queryStringParameters: {
      organization_id: `${organizationId}`,
    },
  });

  return body;
};

export const useFetchScheduledMaintenanceEventsQuery = ({ key = '', autoRun = true } = {}) => {
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const user = useSelector(state => state.user);
  const [hasPermissions, setHasPermissions] = useState(false);

  useEffect(() => {
    let allowed = checkForUserPermission(user, permissionData.editfleet);
    setHasPermissions(allowed);
  }, []);

  const scheduledMaintenanceItemsQuery = useQuery({
    queryKey: ['fetchScheduledMaintenanceEventsQuery' + key],
    queryFn: () => getMaintenanceApi({ organizationId: filteredOrganizationId }),
    enabled: autoRun && checkLogin() && !!hasPermissions,
    onError: err => {
      logWcpError(err);
    },
    select: body => {
      return body.message.events
        ?.map(item => mapMaintenanceDataToFrontend(item))
        .map(e => mapMaintenanceTotalMilageData({ maintenanceEvent: e }));
    },
  });

  return scheduledMaintenanceItemsQuery;
};
