import { OccuredAtDiv } from 'shared/styles/components/Maintenance';
import AnimatedField from 'shared/ui/fields/AnimatedField';

const OccurredAtField = ({ createdDate }) => {
  return (
    <OccuredAtDiv>
      <AnimatedField
        smallerTextMobile
        disabled={true}
        placeholder="Occured at"
        name="occuredAt"
        value={createdDate}
      />
    </OccuredAtDiv>
  );
};

export default OccurredAtField;
