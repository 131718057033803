import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { toggleSidebarAlerts, toggleGlobalOverlay } from 'core/redux/ui/actions';
import { submitLogout } from 'core/redux/user/actions';
import ProfileWidget, {
  ProfileWidgetHolder,
  ProfileWidgetAvatar,
  ProfileWidgetNotification,
  ProfileWidgetCount,
  ProfileMenuAccount,
  ProfileWidgetGivenName,
} from 'shared/styles/components/ProfileWidget';
import DropdownMenu, {
  DropdownMenuTrigger,
  DropdownMenuContent,
  SimpleDropdownItem,
  SimpleDropdownBtn,
  DropdownLink,
} from 'shared/styles/components/DropdownMenu';
import { toggleEditUserModal } from 'core/redux/ui/actions';
import GlobalOverlay from 'shared/styles/components/GlobalOverlay';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import OrganizationFilter from 'core/widgets/organizationFilter/OrganizationFilter';
import { TruncatedText } from 'shared/styles/components/TruncatedText';

const ProfileWidgetComponent = () => {
  const dispatch = useDispatch();

  const userImage = useSelector(state => state.user.user.picture);
  const username = useSelector(state => state.user.user.username);
  const userGivenName = useSelector(state => state.user.user.givenName);
  const firmwareAlertCount = useSelector(state => state.ui.firmwareAlertCount);

  const [organizationFilterDropdownActive, setOrganizationFilterDropdownActive] = useState(false);
  const [profileDropdownActive, setProfileDropdownActive] = useState(false);

  const toggleProfileDropdown = () => {
    setProfileDropdownActive(!profileDropdownActive);
    dispatch(toggleGlobalOverlay());
  };

  const toggleOrganizationFilterDropdown = () => {
    setOrganizationFilterDropdownActive(!organizationFilterDropdownActive);
    dispatch(toggleGlobalOverlay());
  };

  return (
    <ProfileWidget>
      <ProfileWidgetHolder organizationName>
        <OrganizationFilter
          isDropdownActive={organizationFilterDropdownActive}
          toggleDropdown={toggleOrganizationFilterDropdown}
        />
        <GlobalOverlay
          onClick={toggleOrganizationFilterDropdown}
          visible={organizationFilterDropdownActive}
        />
      </ProfileWidgetHolder>

      <ProfileWidgetHolder borderleft>
        <ProfileWidgetGivenName title={userGivenName}>{userGivenName}</ProfileWidgetGivenName>
        <DropdownMenu active={profileDropdownActive}>
          <DropdownMenuTrigger onClick={toggleProfileDropdown}>
            <ProfileWidgetAvatar
              style={{
                backgroundImage: `url(${userImage})`,
              }}
            >
              {userImage == 'undefined' && userGivenName
                ? userGivenName.slice(0, 1).toUpperCase()
                : null}
            </ProfileWidgetAvatar>

            <DropdownMenuContent $profileMenu>
              <ProfileMenuAccount>
                <TruncatedText title={username}>{username}</TruncatedText>
              </ProfileMenuAccount>
              <SimpleDropdownItem>
                <TruncatedText>
                  <DropdownLink to="/user">Edit Profile</DropdownLink>
                </TruncatedText>
              </SimpleDropdownItem>
              <SimpleDropdownItem>
                <SimpleDropdownBtn onClick={() => dispatch(submitLogout({ isTimedOut: false }))}>
                  Log Out
                </SimpleDropdownBtn>
              </SimpleDropdownItem>
            </DropdownMenuContent>
          </DropdownMenuTrigger>
        </DropdownMenu>

        <GlobalOverlay onClick={toggleProfileDropdown} theprofile={profileDropdownActive} />
      </ProfileWidgetHolder>

      <ProfileWidgetHolder borderleft notificationCount>
        <ProfileWidgetNotification
          onClick={() => {
            dispatch(toggleSidebarAlerts());
          }}
        >
          {firmwareAlertCount > 0 && <ProfileWidgetCount>{firmwareAlertCount}</ProfileWidgetCount>}

          <IconSvgComponent svgFileName="bell" alt="Alert" />
        </ProfileWidgetNotification>
      </ProfileWidgetHolder>
    </ProfileWidget>
  );
};

export default ProfileWidgetComponent;
