import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';

import { PAGINATION_OPTIONS } from 'shared/constants/pagination';
import StyledReactSelect from 'shared/styles/components/SelectField';
import { PaginationSelectWrapper } from 'shared/styles/components/Table';

const PaginationControls = ({
  totalPageCount,
  currentPageIndex,
  currentPageSize,
  displayedData,
  handlePageChange,
  handlePageSizeChange,
}) => {
  return (
    <div className="pagination">
      <div className="page-size-dd">
        <div className="show-label">show</div>
        <PaginationSelectWrapper>
          <StyledReactSelect
            value={{ label: `${currentPageSize}`, value: currentPageSize }}
            isClearable={false}
            isSearchable={false}
            options={PAGINATION_OPTIONS}
            onChange={handlePageSizeChange}
          />
        </PaginationSelectWrapper>
      </div>
      <ReactPaginate
        previousLabel={'previous'}
        nextLabel={'next'}
        breakLabel={'...'}
        breakClassName={'break-me'}
        pageCount={totalPageCount}
        marginPagesDisplayed={2}
        pageRangeDisplayed={2}
        onPageChange={handlePageChange}
        containerClassName={'pagination'}
        activeClassName={'active'}
        forcePage={currentPageIndex}
        nextClassName={!displayedData ? 'disabled next-page' : 'next-page'}
      />
    </div>
  );
};

export default PaginationControls;
