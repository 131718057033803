export const MAP_VEHICLES_SOURCE_NAME = 'vehicles_source_id';
export const MAP_MAIN_VEHICLES_LAYER_NAME = 'main_vehicles_layer_id';
export const MAP_RESPONDING_VEHICLES_LAYER_NAME = 'responding_vehicles_layer_id';
export const ALERT_BADGES = 'alert_badges';
export const VEHICLE_NAMES = 'vehicle_names';

export const MapData = {
  source: {
    id: MAP_VEHICLES_SOURCE_NAME,
    data: {
      type: 'geojson',
      buffer: 5,
      data: {
        type: 'FeatureCollection',
        features: [],
      },
    },
  },
  layers: {
    mainVehicles: {
      id: MAP_MAIN_VEHICLES_LAYER_NAME,
      source: MAP_VEHICLES_SOURCE_NAME,
      type: 'symbol',
      layout: {
        'icon-image': {
          property: 'icon_type',
          type: 'identity',
        },

        'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.3, 19, 0.9],

        'icon-rotate': {
          property: 'icon_rotate',
          type: 'identity',
        },
        'icon-ignore-placement': true,
        'icon-allow-overlap': true,
      },
      paint: {
        'icon-opacity': {
          property: 'vehicle_opacity',
          type: 'identity',
        },
        'icon-halo-color': 'rgba(0, 0, 0, 0)',
        'icon-halo-width': 20,
      },
    },
    respondingVehicles: {
      id: MAP_RESPONDING_VEHICLES_LAYER_NAME,
      source: MAP_VEHICLES_SOURCE_NAME,
      type: 'circle',
      paint: {
        'circle-radius': ['interpolate', ['linear'], ['zoom'], 10, 12, 19, 45],
        'circle-color': {
          property: 'halo_color',
          type: 'identity',
        },
        'circle-opacity': {
          property: 'circle_opacity',
          type: 'identity',
        },
      },
    },

    labels: {
      id: VEHICLE_NAMES,
      source: MAP_VEHICLES_SOURCE_NAME,
      type: 'symbol',
      layout: {
        'text-field': {
          property: 'name',
          type: 'identity',
        },
        'text-transform': 'uppercase',
        'text-size': 14,
        // the stops are zoom points where wer can adjust the offset
        'text-offset': {
          stops: [
            [1, [0, 0]],
            [5, [0, -0.2]],
            [10, [0, -0.5]],
            [15, [0, -1.5]],
            [19, [0, -2]],
          ],
        },
        'text-font': ['Arial Unicode MS Regular'],
        'text-allow-overlap': {
          stops: [
            [1, false],
            [5, false],
            [10, false],
            [15, false],
            [17, true],
            [19, true],
          ],
        },
        'text-max-width': 20,
        'text-variable-anchor': {
          stops: [[1, ['bottom', 'top', 'left', 'right']]],
          stops: [[15, ['bottom', 'top', 'left', 'right']]],
          stops: [[19, ['bottom', 'top', 'left', 'right']]],
        },
      },
      paint: {
        'text-color': '#1c1c1c',
        'text-halo-color': '#d3d3d3',
        'text-halo-width': 20,
      },
    },
    alerts: {
      id: ALERT_BADGES,
      source: MAP_VEHICLES_SOURCE_NAME,
      type: 'symbol',
      layout: {
        'icon-image': {
          property: 'alert_level',
          type: 'identity',
        },
        'icon-allow-overlap': true,
        'icon-size': ['interpolate', ['linear'], ['zoom'], 10, 0.3, 19, 0.9],
        'icon-anchor': 'bottom-left',
        'icon-offset': [20, -20],
      },
    },
  },
};
