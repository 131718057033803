import store from 'core/redux/store';
import { showSessionTimeoutNotification } from 'core/redux/ui/actions';
import { submitLogout } from 'core/redux/user/actions';
import { authKey, filteredOrganizationId, localStore } from 'shared/constants/localStore';

let logoutTimeout;
let logoutTimeoutWarning;

const setAuthExpirationTimeout = expiresIn => {
  logoutTimeout = setTimeout(() => {
    const currentTime = new Date().getTime();
    let authStateExpiresAt = JSON.parse(localStore.getItem(authKey))?.ExpiresAt;
    if (currentTime >= authStateExpiresAt) store.dispatch(submitLogout({ isTimedOut: true }));
  }, expiresIn);
  logoutTimeoutWarning = setTimeout(() => {
    const currentTime = new Date().getTime();
    if (currentTime >= expiresIn - 300000) store.dispatch(showSessionTimeoutNotification());
  }, expiresIn - 300000);
};

export const updateAuthStore = ({
  AccessToken,
  ExpiresIn,
  ExpiresAt,
  TokenType,
  RefreshToken,
  IdToken,
}) => {
  if (localStore) {
    const authState = JSON.parse(localStore.getItem(authKey));
    let nextExpiresAt = null;
    const currentTime = new Date().getTime();
    if (ExpiresAt) {
      nextExpiresAt = ExpiresAt;
      clearTimeout(logoutTimeout);
      clearTimeout(logoutTimeoutWarning);
      if (ExpiresAt > currentTime) {
        setAuthExpirationTimeout(ExpiresAt - currentTime);
      }
    }
    if (!ExpiresAt && AccessToken && ExpiresIn) {
      nextExpiresAt = new Date().getTime() + ExpiresIn * 1000;
      clearTimeout(logoutTimeout);
      clearTimeout(logoutTimeoutWarning);
      setAuthExpirationTimeout(ExpiresIn * 1000);
    }
    if (!AccessToken) {
      clearTimeout(logoutTimeoutWarning);
      clearTimeout(logoutTimeout);
    }
    localStore.setItem(
      authKey,
      JSON.stringify({
        ...authState,
        AccessToken,
        ExpiresIn,
        ExpiresAt: nextExpiresAt,
        TokenType,
        RefreshToken: RefreshToken || getRefreshToken(),
        IdToken,
      }),
    );
  }
};

const getAuthState = () => JSON.parse(localStore.getItem(authKey));
const getFilteredOrganizationId = () => localStore.getItem(filteredOrganizationId);

export const clearAuthStore = () => {
  updateAuthStore({
    AccessToken: null,
    IdToken: null,
    ExpiresIn: null,
    ExpiresAt: null,
  });
};

export const getAccessToken = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { AccessToken } = authState;
      return AccessToken;
    }
    return '';
  }
};

export const getRefreshToken = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { RefreshToken } = authState;
      return RefreshToken;
    }
    return '';
  }
};

export const getIdToken = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { IdToken } = authState;
      return IdToken;
    }
    return '';
  }
};

export const getUserInfo = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { IdToken } = authState;
      const tokenParts = IdToken.split('.');
      return JSON.parse(atob(tokenParts[1]));
    }
  }
};

export const getUsername = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState && authState.IdToken) {
      const { 'cognito:username': username } = JSON.parse(atob(authState.IdToken.split('.')[1]));
      return username;
    }
  }
};
export const getExpiryTime = () => {
  if (localStore) {
    const authState = JSON.parse(localStore.getItem(authKey)) || {};
    if (authState) {
      const { ExpiresAt } = authState;
      return ExpiresAt;
    }
    return 0;
  }
};

export const loginIsFresh = () => {
  if (localStore) {
    const authState = getAuthState();
    if (authState) {
      const { AccessToken, IdToken, ExpiresAt } = authState;
      return AccessToken !== null && IdToken !== null && ExpiresAt > new Date().getTime() / 1000;
    }
    return false;
  }
};

export const getFilteredOrgIdInLocal = () => {
  if (localStore) {
    return getFilteredOrganizationId();
  }
};

export const setFilteredOrgIdInLocal = OrgId => {
  if (localStore) {
    if (OrgId === null) {
      localStore.removeItem(filteredOrganizationId);
    } else {
      localStore.setItem(filteredOrganizationId, OrgId);
    }
    setSubscriptionExpiryAlertBarDismissedInLocalStorage(null);
  }
};

export const getSubscriptionExpiryAlertBarDismissedInLocalStorage = () => {
  if (localStore) {
    return JSON.parse(localStore.getItem('subscriptionExpiryAlertBannerDismissed'));
  }
};

export const setSubscriptionExpiryAlertBarDismissedInLocalStorage = val => {
  if (localStore) {
    localStore.setItem('subscriptionExpiryAlertBannerDismissed', JSON.stringify(val));
  }
};
export const getLastMapLocationInLocal = () => {
  if (localStore) {
    return JSON.parse(localStore.getItem('lastMapLocation'));
  }
};

export const setLastMapLocationInLocal = val => {
  if (localStore) {
    localStore.setItem('lastMapLocation', JSON.stringify(val));
  }
};

export const getHeatmapLocationInLocal = () => {
  if (localStore) {
    return JSON.parse(localStore.getItem('heatmapLocation'));
  }
};

export const setHeatmapLocationInLocal = val => {
  if (localStore) {
    localStore.setItem('heatmapLocation', JSON.stringify(val));
  }
};

export const getHideGeofencesDrawHintInLocal = () => {
  if (localStore) {
    return JSON.parse(localStore.getItem('hideGeofencesDrawHint'));
  }
};

export const setHideGeofencesDrawHintInLocal = val => {
  if (localStore) {
    localStore.setItem('hideGeofencesDrawHint', JSON.stringify(val));
  }
};

export const getEnableVehicleMapNamesInLocal = () => {
  if (localStore) {
    return JSON.parse(localStore.getItem('displayVehicleNamesInLocal'));
  }
};

export const setDisplayVehicleNamesInLocal = val => {
  if (localStore) {
    localStore.setItem('displayVehicleNamesInLocal', JSON.stringify(val));
  }
};

export const getDisplayOtaUpdatesPreferenceInLocal = () => {
  if (localStore) {
    return JSON.parse(localStore.getItem('displayOtaUpdatesPreferenceInLocal'));
  }
};

export const setDisplayOtaUpdatesPreferenceInLocal = val => {
  if (localStore) {
    localStore.setItem('displayOtaUpdatesPreferenceInLocal', JSON.stringify(val));
  }
};

export const getPerformanceModePreferenceInLocal = () => {
  if (localStore) {
    return JSON.parse(localStore.getItem('performanceModePreferenceInLocal'));
  }
};

export const setPerformanceModePreferenceInLocal = val => {
  if (localStore) {
    localStore.setItem('performanceModePreferenceInLocal', JSON.stringify(val));
  }
};

export const getOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal = () => {
  if (localStore) {
    return JSON.parse(localStore.getItem('onlyShowPendingOtaUpdatesByDevicePreferenceInLocal'));
  }
};

export const setOnlyShowPendingOtaUpdatesByDevicePreferenceInLocal = val => {
  if (localStore) {
    localStore.setItem('onlyShowPendingOtaUpdatesByDevicePreferenceInLocal', JSON.stringify(val));
  }
};
