import { useContext, useEffect, useRef, useState } from 'react';

import { LivemapApi } from 'features/livemap/components/LivemapContextProvider';
import MapboxComponent from 'features/livemap/components/map/MapboxComponent';
import { MapData } from 'features/livemap/helpers/mapData';
import useFlyToCoordinates from 'features/livemap/hooks/useFlyToCoordinates';
import useFollowVehicle from 'features/livemap/hooks/useFollowVehicle';
import useLivemapControls from 'features/livemap/hooks/useLivemapControls';
import { useVehicleAnimations } from 'features/livemap/hooks/useVehicleAnimations';
import { setHeatmapLocationInLocal } from 'shared/utilities/localStore';

import 'mapbox-gl/dist/mapbox-gl.css';

const LivemapComponent = () => {
  const {
    state: {
      visibleMapVehicles,
      vehicleMapNamesVisible,
      flyToCoordinates,
      selectedVehicle,
      isMapboxLoaded,
      isPerformanceMode,
      focusedZoomLevel,
    },
  } = useContext(LivemapApi);

  const mapboxComponentRef = useRef(null);

  const [mapboxApi, setMapboxApi] = useState(null);

  // animations
  const [animatedVehicles] = useVehicleAnimations({
    visibleMapVehicles,
    isPerformanceMode,
  });

  const renderVehicles = vehicles => {
    mapboxComponentRef.current.renderVehicles(vehicles);
  };

  // on mapbox load
  useEffect(() => {
    // set mapbox api handle
    setMapboxApi(mapboxComponentRef.current.getMapboxApiHandle());

    // render right after style load to make sure vehicles display
    renderVehicles(visibleMapVehicles);
  }, [isMapboxLoaded]);

  // animation
  useEffect(() => {
    renderVehicles(animatedVehicles);
  }, [animatedVehicles]);

  useEffect(() => {
    if (mapboxApi) {
      const bounds = mapboxApi.getBounds();
      setHeatmapLocationInLocal([
        [bounds.getWest(), bounds.getSouth()],
        [bounds.getEast(), bounds.getNorth()],
      ]);
    }
  }, []);

  useEffect(() => {
    if (!mapboxApi) return;
    vehicleMapNamesVisible
      ? mapboxApi.setLayoutProperty(MapData.layers.labels.id, 'visibility', 'visible')
      : mapboxApi.setLayoutProperty(MapData.layers.labels.id, 'visibility', 'none');
  }, [vehicleMapNamesVisible, isMapboxLoaded]);

  // set up map controls
  useLivemapControls({
    mapboxApi,
  });

  // focus the vehicle on every new fetch
  useFollowVehicle({
    mapboxApi,
    selectedVehicle,
    visibleMapVehicles,
    animatedVehicles,
  });

  // fly to coordinates
  useFlyToCoordinates({
    mapboxApi,
    flyToCoordinates,
    focusedZoomLevel,
  });

  // render
  return <MapboxComponent ref={mapboxComponentRef} />;
};

export default LivemapComponent;
