import { apiCall, getAuthToken } from 'core/api/API';
import { mapToFrontend } from 'app/shared/utilities/mapping/mapper';
import { vehiclePlaybackMapping } from 'app/features/playback/services/mapping/vehiclePlaybackMapping';

export const getVehiclePlaybackDataApi = async ({
  organizationId,
  selectedVehicleId,
  startDate,
  endDate,
  selectedGranularity,
}) => {
  // TODO mapper
  const apiResponse = await apiCall({
    path: `/playback`,
    method: 'get',
    headers: {
      Authorization: getAuthToken(),
    },

    queryStringParameters: {
      organization_id: organizationId,
      vehicle_id: selectedVehicleId,
      start_date: startDate,
      end_date: endDate,
      resolution: selectedGranularity,
    },
  });

  let playback = mapToFrontend(apiResponse?.body?.message, vehiclePlaybackMapping);

  return playback;
};
