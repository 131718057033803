import styled from 'styled-components';
import colors from 'shared/constants/colors';
import device from 'shared/constants/sizing';

export const RadioButtonContainer = styled.div`
  margin-bottom: 50px;

  @media ${device.large} {
    margin-bottom: 25px;
  }
  ${props =>
    props.heatmap &&
    `
    margin-bottom:10px;
    @media ${device.large} {
    margin-bottom: 5px;
  }

  `}
`;

export const RadioButton = styled.input`
  margin-right: 15px;

  &[type='radio'] {
    position: absolute;
    top: auto;
    overflow: hidden;
    clip: rect(1px, 1px, 1px, 1px);
    width: 1px;
    height: 1px;
    white-space: nowrap;
    cursor: default;
  }

  &[type='radio'] + label {
    display: block;
    padding: 3px;
    padding-left: 25px;
    text-align: left;
  }

  &[type='radio']:checked,
  &[type='radio']:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  &[type='radio']:checked + label,
  &[type='radio']:not(:checked) + label {
    position: relative;
    cursor: pointer;
    display: inline-block;
  }

  &[type='radio']:checked + label:before,
  &[type='radio']:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    top: 8px;
    width: 18px;
    height: 18px;
    border: 2px solid ${colors.midnight};
    border-radius: 100%;
    background: #fff;

    @media ${device.large} {
      top: 3px;
    }
  }

  &[type='radio']:checked + label:after,
  &[type='radio']:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: ${colors.midnight};
    position: absolute;
    top: 12px;
    left: 4px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;

    @media ${device.large} {
      top: 7px;
    }
  }

  &[type='radio']:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
  }

  &[type='radio']:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
  }
`;

export const RadioButtonLabel = styled.label`
  font-weight: 600;
  font-size: 24px;
  font-weight: 400;
  vertical-align: top;
  color: ${colors.haze};

  @media ${device.large} {
    font-size: 14px;
  }
`;
