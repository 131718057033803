import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import NavigationItem from 'app/core/navigation/components/NavigationItem';
import useNavigationData from 'app/core/navigation/hooks/useNavigationData';
import {
  HelpLink,
  NavButtonIcon,
  NavigationNav,
  StyledNavigationUl,
  StyledNavLink,
  NavButtonText,
} from 'app/shared/styles/NavigationStyles';
import { selectNavigationItemKeyFromPathname } from 'app/core/navigation/utilities/navigation';
import {
  closeSidebar as closeSidebarAction,
  openSidebar as openSidebarAction,
} from 'core/redux/sidebar/actions';
import colors from 'shared/constants/colors';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { getOrganization } from 'core/redux/organizations/selectors';

const NavigationItemsContainer = ({ sidebarIsCollapsed }) => {
  const { pathname } = useLocation();
  const navigationItems = useNavigationData();

  const dispatch = useDispatch();
  const organizationId = useSelector(state => state.user.user.organizationId);

  const [activatedItemKey, setActivatedItemKey] = useState(null);
  const [selectedItemKey, setSelectedItemKey] = useState(null);

  const filteredOrganization = useSelector(state =>
    getOrganization(state, state.user.filteredOrganizationId),
  );

  useEffect(() => {
    const selectedKey = selectNavigationItemKeyFromPathname(navigationItems, pathname);

    setSelectedItemKey(selectedKey);
  });

  useEffect(() => {
    if (sidebarIsCollapsed) {
      setActivatedItemKey(null);
    }
  }, [sidebarIsCollapsed]);

  const handleNavigationItemClick = ({ itemKey, link }) => {
    if (sidebarIsCollapsed) {
      dispatch(openSidebarAction());
    }
    setActivatedItemKey(itemKey);
    if (link) {
      dispatch(closeSidebarAction());
    }
  };

  return (
    <NavigationNav sidebarIsCollapsed={sidebarIsCollapsed}>
      <StyledNavigationUl>
        {Object.entries(navigationItems).map(([itemKey, item]) => (
          <NavigationItem
            key={itemKey}
            itemKey={itemKey}
            item={item}
            sidebarIsCollapsed={sidebarIsCollapsed}
            activatedItemKey={activatedItemKey}
            selectedItemKey={selectedItemKey}
            handleNavigationItemClick={handleNavigationItemClick}
          />
        ))}
      </StyledNavigationUl>
      <HelpLink>
        <StyledNavLink
          to={'/help'}
          onClick={() => {
            setSelectedItemKey('HELP');
            setActivatedItemKey('HELP');
            dispatch(closeSidebarAction());
          }}
        >
          <NavButtonIcon>
            <IconSvgComponent
              svgFileName={'help'}
              style={{
                marginLeft: -5,
              }}
              beforeInjection={svg => {
                svg
                  .querySelectorAll('path')
                  .forEach(path => path.setAttribute('fill', colors.haze));
              }}
            />
          </NavButtonIcon>
          <NavButtonText>Help</NavButtonText>
        </StyledNavLink>
      </HelpLink>
    </NavigationNav>
  );
};

export default NavigationItemsContainer;
