export const displayVehicleIcon = vehicleType => {
  switch (vehicleType) {
    case 'AMBULANCE':
      return 'car4';
    case 'TOW':
      return 'car2';
    case 'DOT':
      return 'car1';
    case 'FIRE':
      return 'car5';
    case 'POLICE':
      return 'car3';
    default:
      return 'car3';
  }
};
