/**
 * @ External Dependencies
 */
import { handleActions } from 'redux-actions';

/**
 * @ Actions
 */
import { closeSearch, openSearch, getFilteredData, setTerm } from './actions';

/**
 * @ Reducer
 */
const defaultState = {
  visible: false,
  search_term: '',
  categories: [],
  data: [],
  filteredData: [],
};

/**
 * Actions Handler
 *
 * @type       {Function}
 */
const search = handleActions(
  {
    [openSearch]: state => ({
      ...state,
      visible: true,
    }),

    [closeSearch]: state => ({
      ...state,
      visible: false,
    }),

    [getFilteredData]: (state, { payload }) => ({
      ...state,
      filteredData: payload,
    }),

    [setTerm]: (state, { payload }) => ({
      ...state,
      search_term: payload,
    }),
  },
  defaultState,
);

export default search;
