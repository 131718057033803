import { apiCall } from 'core/api/API';
import { mapAlertLogDataToFrontend } from 'app/features/alerts/services/mapping/definitions/alertLogsMapping';
import { sortDescendingAlpha } from 'shared/utilities/general';

export const getAlertLogsApi = async ({ organizationId, startDate, endDate, alertId }) => {
  const apiResponse = await apiCall({
    endpoint: 'cloudfrontAPI',
    path: `/alerts/${alertId}/logs`,
    method: 'get',
    queryStringParameters: {
      organization_id: organizationId,
      start_date: startDate,
      end_date: endDate,
    },
  });

  let alertLogs = apiResponse.data.message.logs;

  alertLogs = alertLogs?.map(alertLog => mapAlertLogDataToFrontend(alertLog));
  alertLogs = alertLogs.sort((al1, al2) => sortDescendingAlpha(al1.timestamp, al2.timestamp));

  return alertLogs;
};
