import React from 'react';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';

const ResetAllGroupVehiclesMenuItem = ({ onMenuItemClick }) => {
  return (
    <SimpleDropdownItem>
      <SimpleDropdownBtn onClick={onMenuItemClick}>Reset All Group Vehicles</SimpleDropdownBtn>
    </SimpleDropdownItem>
  );
};

export default ResetAllGroupVehiclesMenuItem;
