import { RemoveButton } from 'shared/styles/components/Button';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const RemoveMaintenanceItemContainerButton = ({ handleRemoveMaintenanceItem }) => {
  return (
    <RemoveButton type="button" lineItem title="Remove">
      <IconSvgComponent
        svgFileName={'remove-feature'}
        alt="Remove"
        onClick={handleRemoveMaintenanceItem}
      />
    </RemoveButton>
  );
};

export default RemoveMaintenanceItemContainerButton;
