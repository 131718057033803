import { createAction } from 'redux-actions';
import { getUserActivityLogs, getUserAPI, decorateUserObject } from 'core/api/users';
import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';

export const requestActivityUser = createAction('REQUEST_ACTIVITY_USER');
export const receiveActivityUser = createAction('RECEIVE_ACTIVITY_USER');
export const rejectActivityUser = createAction('REJECT_ACTIVITY_USER');
export const requestUserActivityLogs = createAction('REQUEST_USER_ACTIVITY_LOGS');
export const receiveUserActivityLogs = createAction('RECEIVE_USER_ACTIVITY_LOGS');
export const rejectUserActivityLogs = createAction('REJECT_USER_ACTIVITY_LOGS');

export const fetchUserActivityLogs = ({ username, organizationId }) => {
  return async dispatch => {
    dispatch(requestUserActivityLogs());
    try {
      const { response } = await getUserActivityLogs({ username, organizationId });

      if (response && response.message) {
        dispatch(receiveUserActivityLogs(response.message));
      }
      return true;
    } catch (e) {
      console.log(e);
      dispatch(rejectUserActivityLogs(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Getting User Activity Logs', e),
        }),
      );
      return false;
    }
  };
};
export const fetchActivityUser = ({ username }) => {
  return async dispatch => {
    dispatch(requestActivityUser());
    try {
      const { response } = await getUserAPI({ username });

      if (response && response.message) {
        const decoratedUserObject = decorateUserObject({ user: response.message });
        dispatch(receiveActivityUser(decoratedUserObject));
      }
      return true;
    } catch (e) {
      console.log(e);
      dispatch(rejectActivityUser(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Getting User Activity Logs', e),
        }),
      );
      return false;
    }
  };
};
