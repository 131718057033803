import { getAssociatedVehicles } from 'app/shared/utilities/getAssociatedVehicles';

export const setScheduleMaintenanceFiltersReducer = (state, payload) => {
  let { selectedGroup, selectedModel } = payload;

  selectedGroup = selectedGroup !== undefined ? selectedGroup : state.selectedGroup;
  selectedModel = selectedModel !== undefined ? selectedModel : state.selectedModel;

  let filteredVehicles = state.allVehicles || [];
  // filter vehicles by selected group
  if (selectedGroup) {
    filteredVehicles = getAssociatedVehicles({ group: selectedGroup, vehicles: filteredVehicles });
  } else if (selectedGroup === null) {
    filteredVehicles = filteredVehicles;
  }

  if (selectedModel) {
    filteredVehicles = filteredVehicles.filter(vehicle => {
      return vehicle.meta.model === selectedModel;
    });
  } else if (selectedModel === null) {
    filteredVehicles = filteredVehicles;
  }

  let listingCount = filteredVehicles.length;

  return {
    ...state,
    selectedGroup,
    selectedModel,
    filteredVehicles,
    listingCount,
  };
};
