export const organizationIsNearExpiration = organization => {
  return new Date(organization.endDate) - new Date() < 1000 * 60 * 60 * 24 * 30;
};

export const getDaysUntilExpiration = organization => {
  return Math.ceil((new Date(organization.endDate) - new Date()) / (1000 * 60 * 60 * 24));
};

export const formatDateFromDynamoIsoString = date => {
  return date.substring(5, 7) + '/' + date.substring(8, 10) + '/' + date.substring(0, 4);
};

export const formatDateValueFromDynamoIsoString = date => {
  const datevalue = date.substring(0, 4) + date.substring(5, 7) + date.substring(8, 10);
  return parseInt(datevalue);
};

export const formatDateToDynamoIsoString = date => {
  return new Date(date).toISOString().substring(0, 10);
};
