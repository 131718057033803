import React from 'react';

import Button from 'shared/styles/components/Button';

import colors from 'shared/constants/colors';

export default ({ notificationHeadline, notificationText, dismissButtonText, dismissHandler }) => {
  return (
    <div>
      <h4 style={styles.notificationHeadline}>{notificationHeadline}</h4>
      <div style={styles.notificationText}>{notificationText}</div>
      <div style={styles.dismissContainer}>
        <Button mediumAlt style={styles.dismiss} onClick={dismissHandler}>
          {dismissButtonText}
        </Button>
      </div>
    </div>
  );
};

const styles = {
  notificationHeadline: {
    marginBottom: '30px',
  },
  notificationText: {
    marginBottom: '20px',
    color: colors.haze,
  },
  dismissContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
