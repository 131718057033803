import useVehiclePlaybackProviderContext from 'app/features/playback/state/provider/hooks/useVehiclePlaybackProviderContext';
import { useFormikContext } from 'formik';
import { hourOptions } from 'shared/constants/hourOptions';
import { FormLabelAnimated } from 'shared/styles/components/Form';
import StyledReactSelect from 'shared/styles/components/SelectField';
import { HourDiv } from 'shared/styles/components/VehiclePlayback';
import { getCurrentHour, getToday } from 'shared/utilities/time';

const currentHour = getCurrentHour();
const today = getToday();

const VehiclePlaybackFormEndTimeDropdown = () => {
  const { actions, state } = useVehiclePlaybackProviderContext();

  const {
    playbackRequestForm: { readOnly, endDate, endTime },
  } = state;

  const getFilteredEndHourOptions = () => {
    if (endDate === today) {
      return hourOptions.filter(o => o.value <= currentHour);
    } else {
      return hourOptions;
    }
  };

  return (
    <>
      <FormLabelAnimated animated>Time</FormLabelAnimated>
      <HourDiv>
        <StyledReactSelect
          mediumTextMobile
          name="endTimeField"
          //need this key to dynamically generate so that the seect if forced to re-render with the updated state
          key={`endTimeSelect${endTime}`}
          placeholder={readOnly ? 'N/A' : 'Time'}
          defaultValue={{ label: hourOptions.find(h => h.value === endTime)?.label }}
          onChange={e => actions.setPlaybackRequestFormAction({ endTime: e.value })}
          isDisabled={readOnly}
          isClearable={false}
          isSearchable={false}
          options={getFilteredEndHourOptions()}
        />
      </HourDiv>
    </>
  );
};

export default VehiclePlaybackFormEndTimeDropdown;
