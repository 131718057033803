import { isEqual } from 'lodash';

export const setModalConfirmationMemoized = () =>
  (() => {
    let touchedMemo = {};
    return (callback, touched) => {
      const itsEqual = isEqual(touched, touchedMemo);
      if (!isEqual(touched, touchedMemo)) {
        touchedMemo = touched;
        callback(touched);
      }
    };
  })();
