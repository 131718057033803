import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { duplicateGroup } from 'core/api/groups';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';

export const useDuplicateGroupMutation = () => {
  const queryClient = useQueryClient();
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);

  const duplicateGroupMutation = useMutation({
    mutationFn: ({ groupName, vehicleIds }) =>
      duplicateGroup({
        organizationId: filteredOrganizationId,
        groupName,
        vehicleIds: vehicleIds,
      }),

    onSuccess: () => {
      queryClient.invalidateQueries(['fetchGroupsQuery']);
    },
    onError: err => {
      logWcpError(err);
    },
  });

  return duplicateGroupMutation;
};
