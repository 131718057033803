import MapZoomControls from 'features/livemap/components/mapControls/MapZoomControls';
import useMapboxCustomControl from 'shared/hooks/useMapboxCustomControl';

const useMapZoomControls = ({ mapboxApi, isMapboxLoaded }) => {
  useMapboxCustomControl({
    renderComponent: <MapZoomControls mapboxApi={mapboxApi} />,
    mapboxApi,
    isMapboxLoaded,
    position: 'bottom-right',
  });
};

export default useMapZoomControls;
