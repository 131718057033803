export const baseApiUrlProd = 'https://api.cloud.whelen.com/app';
export const baseApiUrlDev = 'https://api.firstresponder.ai/dev';
export const baseApiUrlTest = 'https://api.firstresponder.ai/test';
export const baseApiUrlStaging = 'https://api.firstresponder.ai/staging';
export const baseApiUrlApp = 'https://api.firstresponder.ai/';
export const baseApiUrlFirmwareRepo = 'https://firmwarerepo.whelen.com';

export const groupPath = '/group';
export const organizationPath = '/organization';
export const whitelistPath = organizationId => `/organization/${organizationId}/whitelist_channels`;
export const associateFSEstoOrganizationPath = organizationId =>
  `${organizationPath}/${organizationId}/associated_fse`;
export const disassociateFSEFromOrganizationPath = ({ organizationId, username }) =>
  `${organizationPath}/${organizationId}/associated_fse/${username}`;
export const organizationFSEsPath = organizationId =>
  `${organizationPath}/${organizationId}/associated_fse`;
export const userPath = '/user';
export const userActivationPath = username => `${userPath}/${username}/activate`;
export const userDeactivationPath = username => `${userPath}/${username}/deactivate`;
export const userPasswordUpdatePath = '/userresetpwd';

export const getFirmwareChannelsPath = '/firmware/channel';
export const configurationPath = '/config/lib';
export const configurationDownloadPath = configurationId =>
  `/config/lib/${configurationId}/download`;
export const configurationUploadPath = '/config/lib/upload';
export const configurationAuthorizePath = '/config/update';

export const resetPasswordPath = '/userresetpwd';
export const getForgottenPasswordCodePath = username => `/forgotpwd/${username}`;
export const getForgottenUsernamePath = '/forgetusername';
export const firstSigninResetPath = '/forceresetpassword';
export const auditPath = '/audit';

export const EMPTY_STRING = 'EMPTY_STRING';
