import { useContext } from 'react';

import { LivemapApi } from 'features/livemap/components/LivemapContextProvider';
import useMapFocusButton from 'features/livemap/hooks/useMapFocusButton';
import useMapStyleToggleButton from 'features/livemap/hooks/useMapStyleToggleButton';
import useMapZoomControls from 'features/livemap/hooks/useMapZoomControls';
import usePerformanceModeButton from 'features/livemap/hooks/usePerformanceModeButton';

const useLivemapControls = ({ mapboxApi }) => {
  const {
    actions: { setPerformanceMode },
    state: { visibleMapVehicles, isMapboxLoaded, isPerformanceMode },
  } = useContext(LivemapApi);

  useMapZoomControls({
    mapboxApi,
    isMapboxLoaded,
  });

  useMapFocusButton({
    mapboxApi,
    isMapboxLoaded,
    vehicles: visibleMapVehicles,
  });

  useMapStyleToggleButton({
    mapboxApi,
    isMapboxLoaded,
  });

  usePerformanceModeButton({
    mapboxApi,
    isMapboxLoaded,
    isPerformanceMode,
    handlePerformanceModeChange: isPerformanceMode => {
      setPerformanceMode(isPerformanceMode);
    },
  });
};

export default useLivemapControls;
