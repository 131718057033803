import { createAction } from 'redux-actions';
import { sendHelpRequest } from 'core/api/help';

import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';

export const requestCreateHelpRequest = createAction('REQUEST_CREATE_HELP_REQUEST');
export const successCreateHelpRequest = createAction('SUCCESS_CREATE_HELP_REQUEST');
export const rejectCreateHelpRequest = createAction('REJECT_CREATE_HELP_REQUEST');

export const createHelpRequest = ({ ticket, description, attachments }) => {
  return async dispatch => {
    dispatch(requestCreateHelpRequest());

    try {
      const { response } = await sendHelpRequest({
        ticket,
        description,
        attachments,
      });
      dispatch(successCreateHelpRequest());
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.SUCCESS,
            'Help Request',
            'Your help request has been successfully submitted',
          ),
        }),
      );

      return { response };
    } catch (e) {
      console.log('error creating help request', e);
      dispatch(rejectCreateHelpRequest());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error creating help request', e),
        }),
      );
    }
  };
};
