import { useQuery } from '@tanstack/react-query';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

const createGenericQueryKeyFactory = (apiFn, queryKeyPrefix) => params => ({
  queryKey: [queryKeyPrefix, ...Object.entries(params).map(([key, value]) => `${key}:${value}`)],
  apiFnCallback: () => apiFn(params),
});

const useGenericQuery = ({
  queryKeyPrefix,
  apiFn,
  params,
  onSuccessCallback = () => {},
  autoRun = true,
}) => {
  const queryFactory = createGenericQueryKeyFactory(apiFn, queryKeyPrefix);

  const organizationId = useSelector(state => state.user.filteredOrganizationId);

  const { queryKey, apiFnCallback } = queryFactory({ ...params, organizationId });

  const queryResult = useQuery({
    queryKey,
    queryFn: apiFnCallback,
    enabled: autoRun,
    onError: err => {
      logWcpError(err);
    },
  });

  useEffect(() => {
    // onSuccess
    if (onSuccessCallback) {
      try {
        if (queryResult.data) {
          onSuccessCallback(queryResult.data);
        }
      } catch (err) {
        logWcpError(err);
      }
    }
  }, [queryResult.data]);

  return [queryResult, queryKey];
};

export default useGenericQuery;
