import { maintenanceStatusConstants } from 'app/features/maintenance/data/maintenanceStatusConstants';

/**
 * Parses the value from the due_in string
 * @param {String} dueIn - Due in string
 * @returns {Number} - Due in value
 */
export const parseDueInValue = (dueIn) => {
  return dueIn ? parseInt(dueIn.split(' ')[0]) : undefined;
};

/**
 * Returns a map of maintenance events grouped by vehicle and maintenance type
 * @param {Array} maintenanceEvents - Array of maintenance events
 * @returns {Map} - Map of maintenance events grouped by vehicle and maintenance type
 */
export const groupMaintenanceEventsByVehicle = (maintenanceEvents) => {
  if (maintenanceEvents) {
    // Group the maintenance events by vehicle and maintenance type
    return maintenanceEvents.reduce((groupedByVehicle, ev) => {
      const { vehicleId, maintenanceType } = ev;

      // Create a new vehicle group if it doesn't exist
      if (!groupedByVehicle.has(vehicleId)) {
        groupedByVehicle.set(vehicleId, new Map());
      }

      // Create a new maintenance type group if it doesn't exist
      const vehicleGroup = groupedByVehicle.get(vehicleId);

      if (!vehicleGroup.has(maintenanceType.value)) {
        vehicleGroup.set(maintenanceType.value, []);
      }

      const { dueIn, dueInUnit, dueInValue } = ev;

      // Add the event to the maintenance type group, attaching the dueIn
      vehicleGroup.get(maintenanceType.value).push({ ...ev, dueIn, dueInUnit, dueInValue });
      // Return the grouped maintenance events
      return groupedByVehicle;
    }, new Map());
  } else return new Map();
};

/**
 * Returns a map of maintenance events grouped by vehicle and maintenance status
 * @param {Array} maintenanceEvents - Array of maintenance events
 * @returns {Map} - Map of maintenance events grouped by vehicle and maintenance status
 */
export const groupMaintenanceEventsByMaintenanceStatus = (maintenanceEvents) => {
  return (
    // Group the maintenance events by vehicle and maintenance status
    maintenanceEvents?.reduce((groupedByStatus, me) => {
      // Create a new status group if it doesn't exist
      const statusGroup = groupedByStatus.get(me.status) || [];

      // Add the event to the status group
      groupedByStatus.set(me.status, [...statusGroup, me]);

      // Return the grouped maintenance events
      return groupedByStatus;
    }, new Map()) || new Map()
  );
};

/**
 * Returns an array of the next due maintenance events from a grouped maintenance events map
 * @param {Map} groupedMaintenanceEventsMap - Map of maintenance events grouped by vehicle and maintenance type
 * @returns {Array} - Array of the next due maintenance events
 */
const getNextDueInFromGroupedEvents = (groupedMaintenanceEventsMap) => {
  // Get the next due maintenance event for each maintenance type group
  return Array.from(
    groupedMaintenanceEventsMap.values(),
    (vehicleGroup) =>
      // Sort the maintenance type groups by dueIn and return the first event
      Array.from(vehicleGroup.values(), (typeGroup) => {
        let nextDue = typeGroup.sort((a, b) => a.dueInValue - b.dueInValue)[0];
        return nextDue;
      }),
    // Flatten the array of arrays
  ).flat();
};

/**
 * Returns an array of the next due maintenance events from a filtered maintenance events array
 * @param {Array} filteredMaintenanceEvents - Array of maintenance events
 * @returns {Array} - Array of the next due maintenance events
 */
export const filterOnlyNextScheduledEvents = (maintenanceEvents) => {
  const groupedMaintenanceEvents = groupMaintenanceEventsByVehicle(maintenanceEvents);
  let next = getNextDueInFromGroupedEvents(groupedMaintenanceEvents);
  return next;
};

export const getVehiclesWithMaintenanceEventOfStatus = (maintenanceEventsByStatus, status) => {
  let result = maintenanceEventsByStatus.get(status)?.map((event) => event.vehicleId);
  return result ? result : [];
};

/**
 * Returns an object with the total counts for each maintenance status
 * @param {Array} maintenanceEvents - Array of maintenance events
 * @returns {Object} - Object with the total counts for each maintenance status
 */
export const calculateTotalCounts = (maintenanceEvents) => {
  if (!maintenanceEvents)
    return {
      listingCount: 0,
      totalVehiclesNeedingAttentionCount: 0,
      totalCheckEngineCount: 0,
      totalDueForWorkCount: 0,
      totalDueInSoonCount: 0,
    };

  let maintenanceEventsByStatus = groupMaintenanceEventsByMaintenanceStatus(maintenanceEvents);

  let vehiclesWithCheckEngineEvents = getVehiclesWithMaintenanceEventOfStatus(
    maintenanceEventsByStatus,
    maintenanceStatusConstants.CHECK_ENGINE,
  );
  let vehiclesWithDueForWorkEvents = getVehiclesWithMaintenanceEventOfStatus(
    maintenanceEventsByStatus,
    maintenanceStatusConstants.DUE_FOR_WORK,
  );
  let vehiclesWithDueInSoonEvents = getVehiclesWithMaintenanceEventOfStatus(
    maintenanceEventsByStatus,
    maintenanceStatusConstants.DUE_IN_SOON,
  );

  // get unique vehicles for each group
  let uniqueVehiclesWithCheckEngineEvents = new Set(vehiclesWithCheckEngineEvents);
  let uniqueVehiclesWithDueForWorkEvents = new Set(vehiclesWithDueForWorkEvents);
  let uniqueVehiclesWithDueInSoonEvents = new Set(vehiclesWithDueInSoonEvents);

  // get unique vehicles total
  let totalUniqueVehiclesNeedingAttention = new Set([
    ...vehiclesWithCheckEngineEvents,
    ...vehiclesWithDueForWorkEvents,
    ...vehiclesWithDueInSoonEvents,
  ]);

  let totalVehiclesNeedingAttentionCount = totalUniqueVehiclesNeedingAttention.size;
  let totalCheckEngineCount = uniqueVehiclesWithCheckEngineEvents.size;
  let totalDueForWorkCount = uniqueVehiclesWithDueForWorkEvents.size;
  let totalDueInSoonCount = uniqueVehiclesWithDueInSoonEvents.size;
  let listingCount = maintenanceEvents.length;

  return {
    totalVehiclesNeedingAttentionCount,
    totalCheckEngineCount,
    totalDueForWorkCount,
    totalDueInSoonCount,
    listingCount,
  };
};
