/**
 * @ External Dependecies
 */
import styled from 'styled-components';

/**
 * @ Constants
 */
import {
  vehicleTypeViewData,
  vehicleOnlineStatusViewData,
  vehicleRespondingStatusViewData,
} from 'shared/constants/vehicle';
import colors from 'shared/constants/colors';

const Result = styled.div`
  padding: 10px 28px 10px 23px;
  border-bottom: 1px solid rgba(138, 144, 156, 0.15);
`;

export const ResultHead = styled.div`
  margin-bottom: 16px;
`;

export const ShowHideDiv = styled.div`
  float: right;
  margin-top: -20px;
`;

export const HideIconDiv = styled.div`
  margin-top: -2px;
  margin-left: 5px;
`;

export const VehicleNameDiv = styled.div`
  display: -webkit-box;
`;

export const ResultBody = styled.div``;

export const ResultType = styled.span`
  display: block;
  position: relative;
  margin-bottom: 16px;
  font-size: 10px;
  font-weight: 700;
  letter-spacing: 0.35px;

  &:before {
    content: '';
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
  }

  ${props => props.statusStyle}
`;

export const ResultSubTitle = styled.h6`
  font-size: 14px;
  font-weight: 400;
  color: #8a909c;
`;

export const VehicleTypeText = styled.p`
  margin-bottom: 0;
  font-size: 11px;
  font-weight: 600;
  line-height: 19px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  color: #8a909c;
`;

export const VehicleNameText = styled.h6`
  margin-bottom: 2px !important;
  font-size: 14px;
  font-weight: 600;
  color: #14101d;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 295px;
`;

export default Result;
