import { getDaysUntilExpiration } from 'shared/utilities/organizations';

export const getFirmwareUpdateAlertMessage = incompleteUpdates => {
  const { product_name, product_type, product_id, channel, version } =
    incompleteUpdates.length > 0 ? incompleteUpdates[0] : {};
  if (incompleteUpdates.length === 0) {
    return {};
  }
  return incompleteUpdates.length > 1
    ? {
        message: `You have an update available for ${
          product_name ? product_name : product_type
        } and ${incompleteUpdates.length - 1} other product${
          incompleteUpdates.length - 1 == 1 ? '' : 's'
        }.`,
        id: 0,
        link: {
          text: 'Update Now',
          to: '/updates',
        },
      }
    : {
        message: `You have an update available for ${product_name ? product_name : product_type}.`,
        id: 1,
        link: {
          text: 'Update Now',
          to: `/updates/${product_id}/${channel}/${version}`,
        },
      };
};

export const getFirmwareUpdateAlerts = incompleteUpdates => {
  return incompleteUpdates.map(update => {
    const { channel, product_name, product_type, version, product_id } = update;
    return {
      id: `${product_id}-${channel}-${version}`,
      message: `${product_name ? product_name : product_type} Update`,
      version,
      link: `/updates/${product_id}/${channel}/${version}`,
    };
  });
};

export const getSubscriptionExpiryAlertMessage = filteredOrganization => {
  const daysLeft = getDaysUntilExpiration(filteredOrganization);
  return {
    message:
      daysLeft > 0
        ? `Your subscription expires in ${daysLeft} days. Please contact your Field Solution Engineer.`
        : `Your subscription has expired. Please contact your Field Solution Engineer.`,
    id: 2,
  };
};
