import { useSelector } from 'react-redux';
import getNavigationMenuData from 'app/core/navigation/data/navigationMenuData';
import { getOrganization } from 'core/redux/organizations/selectors';

const useNavigationData = () => {
  const userOrgId = useSelector(state => state.user.user.organizationId);
  const filteredOrganizationId = useSelector(
    state => getOrganization(state, state.user.filteredOrganizationId)?.organizationId,
  );
  return getNavigationMenuData({
    userOrgId,
    filteredOrganizationId,
  });
};

export default useNavigationData;
