import { handleActions, combineActions } from 'redux-actions';

import {
  requestVehicles,
  receiveVehicles,
  rejectVehicles,
  requestVehiclesMeta,
  receiveVehiclesMeta,
  rejectVehiclesMeta,
  receiveVehicle,
  requestLockVehicle,
  requestUnlockVehicle,
  rejectLockVehicle,
  rejectUnlockVehicle,
  requestEditVehicle,
  rejectEditVehicle,
  successEditVehicle,
} from './actions';

const defaultState = {
  vehicles: {},
  mapVehicles: [],
  devices: [],
  makes: [],
  models: [],
  minYear: new Date().getFullYear(),
  unassociatedDevices: [],
  loadingVehicles: false,
  requestingDevices: false,
};

const vehicles = handleActions(
  {
    [combineActions(requestLockVehicle, requestUnlockVehicle, requestEditVehicle)]: (
      state,
      { payload: vehicleId },
    ) => {
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          [vehicleId]: {
            ...state.vehicles[vehicleId],
            loading_status: true,
          },
        },
      };
    },

    [combineActions(requestVehicles, requestVehiclesMeta)]: state => ({
      ...state,
      loadingVehicles: true,
    }),

    [combineActions(receiveVehicles, receiveVehiclesMeta)]: (state, { payload }) => {
      return {
        ...state,
        loadingVehicles: false,
        mapVehicles: payload.filter(vehicle => vehicle.gps),
        vehicles: {
          ...payload.reduce((vehicles, vehicle) => {
            return {
              ...vehicles,
              [vehicle.vehicle_id]: vehicle,
            };
          }, {}),
        },
        minYear: Array.isArray(payload)
          ? payload.reduce(
              (minYear, vehicle) =>
                vehicle.meta.mfg_year < minYear ? vehicle.meta.mfg_year : minYear,
              new Date().getFullYear(),
            )
          : state.minYear,
        makes: Array.isArray(payload)
          ? payload.reduce((makes, vehicle) => {
              if (!makes.includes(vehicle.meta.make)) {
                makes.push(vehicle.meta.make);
              }
              return makes;
            }, [])
          : state.makes,
        models: Array.isArray(payload)
          ? payload.reduce((models, vehicle) => {
              if (!models.includes(vehicle.meta.model)) {
                models.push(vehicle.meta.model);
              }
              return models;
            }, [])
          : state.models,
      };
    },

    [combineActions(rejectVehicles, rejectVehiclesMeta)]: state => ({
      ...state,
      loadingVehicles: false,
    }),

    /* Single Vehicle */

    [receiveVehicle]: (state, { payload }) => {
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          [payload.vehicle_id]: payload,
        },
      };
    },

    [combineActions(
      rejectLockVehicle,
      rejectUnlockVehicle,
      rejectEditVehicle,
      successEditVehicle,
    )]: (state, { payload: { vehicleId } }) => {
      return {
        ...state,
        vehicles: {
          ...state.vehicles,
          [vehicleId]: {
            ...state.vehicles[vehicleId],
            loading_status: false,
          },
        },
      };
    },
  },
  defaultState,
);

export default vehicles;
