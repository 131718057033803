import React from 'react';

import whelenLogo from 'assets/images/whelenlogo@2x.png';
import wcpLogo from 'assets/images/wcpLogo@2x.png';

import {
  Wrapper,
  StyledFormWrapper,
  LoginWrapper,
  StyledHead,
  StyledBody,
  StyledTitle,
  StyledIconWrapper,
  StyledFoot,
  StyledButton,
  WhelenLogo,
  WCPLogo,
  PreLogoText,
} from 'shared/styles/components/LoginSignup';

import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';

const LoggedOutForm = ({
  formTitle,
  formMessage,
  formRows,
  submitHandler,
  submitText,
  children,
  isSubmitting,
}) => (
  <Wrapper>
    <LoginWrapper>
      <StyledFormWrapper>
        <StyledHead>
          <StyledIconWrapper>
            <WCPLogo src={wcpLogo} alt="Whelen Cloud Platform®" />
          </StyledIconWrapper>

          <StyledTitle>{formTitle}</StyledTitle>

          {formMessage && (
            <div
              style={{
                fontWeight: '300',
                color: '#745d23',
                fontSize: '12px',
                padding: '10px 25px',
                marginTop: '20px',
                marginBottom: '-45px',
                backgroundColor: '#fbecc3',
              }}
            >
              {formMessage}
            </div>
          )}
        </StyledHead>

        <StyledBody>
          <form>{children}</form>
        </StyledBody>

        <StyledFoot>
          <ButtonWithLoader
            isLoading={isSubmitting}
            confirmText={submitText}
            loadingStyleProp={'loginWithSpinner'}
            notLoadingStyleProp={'loginButton'}
            clickHandler={submitHandler}
          />
          <PreLogoText>POWERED BY</PreLogoText>
          <WhelenLogo src={whelenLogo} alt="Whelen" />
        </StyledFoot>
      </StyledFormWrapper>
    </LoginWrapper>
  </Wrapper>
);

export default LoggedOutForm;
