import React from 'react';

const ListingCount = ({ listingCount, totalCount, itemTypeName, includeTotalText = true }) => {
  return (
    <h5>
      {listingCount === totalCount
        ? `${includeTotalText ? 'Total of ' : ''} ${listingCount} ${itemTypeName}${
            listingCount !== 1 ? 's' : ''
          }`
        : `${listingCount} of ${totalCount} ${itemTypeName}${totalCount !== 1 ? 's' : ''}`}
    </h5>
  );
};

export default ListingCount;
