import { maintenanceIntervalConstants } from 'app/features/maintenance/data/maintenanceIntervalConstants';

export const setScheduleMaintenanceIntervalTypesReducer = (state, payload) => {
  let { selectedIntervalTypes } = payload;
  selectedIntervalTypes =
    selectedIntervalTypes !== undefined ? selectedIntervalTypes : state.selectedIntervalTypes;

  let selectableVehicles = state.filteredVehicles || [];

  const displayOdometer = selectedIntervalTypes?.some(
    type =>
      type === maintenanceIntervalConstants.MILEAGE ||
      type === maintenanceIntervalConstants.TOTAL_MILEAGE,
  );

  const displayEngineHours = selectedIntervalTypes?.some(
    type => type === maintenanceIntervalConstants.ENGINE_HOURS,
  );

  const displayOilLife = selectedIntervalTypes?.some(
    type => type === maintenanceIntervalConstants.OIL_LIFE,
  );

  if (displayOdometer) {
    selectableVehicles = selectableVehicles.filter(v => v.obd?.odometer);
  }

  if (displayEngineHours) {
    selectableVehicles = selectableVehicles.filter(v => v.obd?.engine_hours);
  }

  if (displayOilLife) {
    selectableVehicles = selectableVehicles.filter(
      v => v.meta?.enhanced_telematics_enabled === true,
    );
  }
  return {
    ...state,
    selectedIntervalTypes,
    selectableVehicles,
  };
};
