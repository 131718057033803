import { Formik } from 'formik';
import { useRef, useState } from 'react';
import Avatar from 'react-avatar-edit';
import { connect } from 'react-redux';

import {
  requestSetSelectedMfaMethod,
  requestUpdateUserPassword,
  setTwoFactorMethod,
  updateUserPassword,
  verifySmsMfa,
  verifySoftwareTokenMfa,
} from 'core/redux/user/actions';
import { editUser } from 'core/redux/users/actions';
import { EMPTY_STRING } from 'shared/constants/api';
import colors from 'shared/constants/colors';
import fileSizes from 'shared/constants/fileSizes';
import {
  mfaTypes,
  userRoleOptionsArray,
  userRoleOptions as userRoles,
} from 'shared/constants/users';
import Change2faModal from 'shared/modals/Change2faModal';
import ChangePasswordModal from 'shared/modals/ChangePasswordModal';
import ModalPortal from 'shared/modals/ModalPortal';
import Button, { ActionButtonText, TextButton } from 'shared/styles/components/Button';
import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import { EmailOptionDiv } from 'shared/styles/components/Form';
import { CheckboxTextSpan } from 'shared/styles/components/Form';
import {
  AvatarSourceContainer,
  FieldError,
  Form,
  FormActions,
  FormAsterisk,
  FormBody,
  FormCol,
  FormFoot,
  FormGridRow,
  FormHead,
  FormLabelAnimated,
  FormLegend,
  FormReadOnlyValue,
  FormRow,
  FormTitle,
  NonTextFieldContainer,
  ReadOnlyFormDisplayDiv,
} from 'shared/styles/components/Form';
import { UserSelectWrapper } from 'shared/styles/components/Form';
import PageListWrapper from 'shared/styles/components/PageList';
import StyledReactSelect from 'shared/styles/components/SelectField';
import { TruncatedText, TruncatedTextWithWrap } from 'shared/styles/components/TruncatedText';
import theme from 'shared/styles/global/theme';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import AnimatedField from 'shared/ui/fields/AnimatedField';
import MaskedField from 'shared/ui/fields/MaskedField';
import { handleSelectBlur, handleSelectChange } from 'shared/utilities/form';
import { validateUser } from 'shared/utilities/validators';

const UserDetailPage = ({
  user,
  fseUserOptionAvailable,
  saveEditUser,

  // actions
  setTwoFactorMethod,
  requestSetSelectedMfaMethod,
  loginWithMfa,
  verifySoftwareTokenMfa,
  verifySmsMfa,
  requestUpdateUserPassword,
  updateUserPassword,

  // data
  selectedMfaMethod,
  redirectToVerifyMfa,
  username,
  session,
  mfaType,
  mfaDestination,
  authenticationAppSecretCode,
  smsVerificationCode,
}) => {
  const formikRef = useRef(null);
  const [preview, setPreview] = useState(user && user.picture ? user.picture : EMPTY_STRING);
  const [isCropping, setIsCropping] = useState(false);
  const [change2faModalActive, setChange2faModalActive] = useState(false);
  const [changePasswordModalActive, setChangePasswordModalActive] = useState(false);

  const handleRemoveImage = (innerHandler, field) => e => {
    setPreview(EMPTY_STRING);
    innerHandler(field, EMPTY_STRING);
  };

  const onCrop = preview => {
    setIsCropping(true);
    setPreview(preview);
  };

  const onClose = () => {
    setPreview(EMPTY_STRING);
  };

  const onBeforeFileLoad = elem => {
    //reject files over 6 mb
    if (elem && elem.target.files[0].size > fileSizes.userPhotoMax) {
      alert('The selected file is too large. Please try another.');
      elem.target.value = '';
    } else if (elem && elem.target.files[0].size < fileSizes.globalMin) {
      alert('The selected file is corrupt or invalid. Please try another.');
      elem.target.value = '';
    }
  };

  const handleFormSubmit = async (values, actions) => {
    values.username = values.username.trim();
    await saveEditUser({
      ...values,
      phoneNumber: (values.phoneNumber && `+${values.phoneNumber.replace(/\D/g, '')}`) || '',
      picture: preview,
    });
    setIsCropping(false);
    actions.setSubmitting(false);
  };

  const userRoleOptions = userRoleOptionsArray
    .filter(
      userRoleOption =>
        userRoleOption.id <=
        userRoleOptionsArray.find(roleOption => roleOption.value === user.userRole).id,
    )
    .filter(userRoleOption =>
      userRoleOption.value === userRoles[userRoleOptionsArray.FIELD_SOLUTION_ENGINEER] ||
      userRoleOption.value === userRoles[userRoleOptionsArray.SYSTEM_ADMIN]
        ? fseUserOptionAvailable
        : true,
    );

  const twoFactorAuthMethodOptions = [
    {
      id: 1,
      title: 'SMS',
      label: 'Text / SMS Message',
      value: mfaTypes.SMS,
    },
    {
      id: 2,
      title: 'Authenticator App',
      label: 'Authenticator App',
      value: mfaTypes.AUTHENTICATION_APP,
    },
  ];

  return (
    <>
      <PageListWrapper style={{ width: '100%' }}>
        <Formik
          initialValues={
            (user && {
              picture: user.picture,
              username: user.username,
              givenName: user.givenName,
              familyName: user.familyName,
              email: user.email,
              phoneNumber: user.phoneNumber,
              userTitle: user.userTitle,
              userRole: user.userRole,
              twoFactorAuthMethod: user.twoFactorAuthMethod,
              active: user.active,
              organizationId: user.organizationId,
              optOutOfEmails: user.optOutOfEmails,
            }) || {
              picture: '',
              username: '',
              givenName: '',
              familyName: '',
              email: '',
              phoneNumber: '',
              userTitle: '',
              userRole: '',
              active: '',
              oldPassword: '',
              newPassword: '',
              confirmNewPassword: '',
              optOutOfEmails: false,
            }
          }
          innerRef={formikRef}
          validate={validateUser}
          onSubmit={handleFormSubmit}
        >
          {({
            values,
            errors,
            touched,
            dirty,
            submitForm,
            handleChange,
            handleBlur,
            setFieldValue,
            setTouched,
            isSubmitting,
          }) => {
            return (
              <Form style={{ float: 'left' }}>
                <FormHead>
                  <FormTitle>
                    <TruncatedTextWithWrap>{`${user.givenName} ${user.familyName} (${user.userRole})`}</TruncatedTextWithWrap>
                  </FormTitle>
                </FormHead>
                <FormBody>
                  <FormGridRow>
                    <FormCol>
                      <FormRow>
                        {!isCropping && preview !== EMPTY_STRING && preview !== 'undefined' ? (
                          <div style={styles.profileImageContainer}>
                            <div
                              style={{
                                ...styles.userImagePreviewContainer,
                                backgroundImage: `url(${preview})`,
                              }}
                            />
                            <Button
                              onClick={handleRemoveImage(setFieldValue, 'picture')}
                              default
                              center
                            >
                              REMOVE IMAGE
                            </Button>
                          </div>
                        ) : (
                          <AvatarSourceContainer>
                            <Avatar
                              imageWidth={300}
                              width={300}
                              height={300}
                              borderStyle={styles.avatarBorder}
                              label={'UPLOAD PROFILE PHOTO'}
                              labelStyle={styles.avatarLabel}
                              onCrop={onCrop}
                              onClose={onClose}
                              onBeforeFileLoad={onBeforeFileLoad}
                            />
                          </AvatarSourceContainer>
                        )}
                      </FormRow>
                    </FormCol>

                    <FormCol>
                      {user && user.username ? (
                        <ReadOnlyFormDisplayDiv>
                          <FormLabelAnimated animated>Username</FormLabelAnimated>
                          <TruncatedText title={values.username}>{values.username}</TruncatedText>
                        </ReadOnlyFormDisplayDiv>
                      ) : (
                        <AnimatedField
                          name="username"
                          placeholder="Username"
                          value={values.username}
                          disabled={false}
                          touched={touched.username}
                          validationError={errors.username}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          required
                        />
                      )}
                      <AnimatedField
                        name="givenName"
                        placeholder="First Name"
                        value={values.givenName}
                        disabled={false}
                        touched={touched.givenName}
                        validationError={errors.givenName}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        required
                      />
                      <AnimatedField
                        name="familyName"
                        placeholder="Last Name"
                        value={values.familyName}
                        disabled={false}
                        touched={touched.familyName}
                        validationError={errors.familyName}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        required
                      />
                      <AnimatedField
                        name="email"
                        placeholder="Email"
                        value={values.email}
                        disabled={false}
                        touched={touched.email}
                        validationError={errors.email}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        required
                      />
                      <MaskedField
                        name="phoneNumber"
                        placeholder="Mobile Phone Number"
                        value={values.phoneNumber}
                        disabled={false}
                        touched={touched.phoneNumber}
                        validationError={errors.phoneNumber}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                        required
                        mask="+9 (999) 999-9999"
                      />
                      <AnimatedField
                        name="userTitle"
                        placeholder="Title"
                        value={values.userTitle}
                        disabled={false}
                        touched={touched.userTitle}
                        validationError={errors.userTitle}
                        handleChange={handleChange}
                        handleBlur={handleBlur}
                      />
                      <UserSelectWrapper>
                        <StyledReactSelect
                          name="userRole"
                          placeholder="Select role"
                          defaultValue={{
                            label: userRoleOptions.find(u => u.value === values.userRole).label,
                          }}
                          isDisabled={user && user.username}
                          onChange={handleSelectChange(setFieldValue, 'userRole')}
                          onBlur={handleSelectBlur(setTouched, 'userRole', touched)}
                          validationError={errors.userRole}
                          isClearable={false}
                          isSearchable={false}
                          options={userRoleOptions}
                          required
                        />

                        <EmailOptionDiv userPage>
                          <Checkbox singleCheck>
                            <CheckboxInner
                              isChecked={values.optOutOfEmails}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                              onClick={() =>
                                setFieldValue('optOutOfEmails', !values.optOutOfEmails)
                              }
                            />
                          </Checkbox>
                          <CheckboxTextSpan>Opt out of emails</CheckboxTextSpan>
                        </EmailOptionDiv>
                      </UserSelectWrapper>

                      <NonTextFieldContainer>
                        <FormLabelAnimated animated>Current Two-Factor Method</FormLabelAnimated>
                        <FormReadOnlyValue>
                          {user.twoFactorAuthMethod === mfaTypes.SMS
                            ? 'Text / SMS Message'
                            : user.twoFactorAuthMethod === mfaTypes.AUTHENTICATION_APP
                            ? 'Authenticator App'
                            : 'Not Set'}
                        </FormReadOnlyValue>
                      </NonTextFieldContainer>
                      <TextButton
                        type="button"
                        onClick={() => {
                          requestSetSelectedMfaMethod();
                          setChange2faModalActive(true);
                        }}
                      >
                        <ActionButtonText>CHANGE TWO-FACTOR</ActionButtonText>
                      </TextButton>
                      <br />
                      <TextButton
                        type="button"
                        onClick={() => {
                          requestUpdateUserPassword();
                          setChangePasswordModalActive(true);
                        }}
                      >
                        <ActionButtonText>CHANGE PASSWORD</ActionButtonText>
                      </TextButton>
                      <FieldError>{touched.userRole && errors.userRole}</FieldError>
                    </FormCol>
                  </FormGridRow>
                </FormBody>

                <FormFoot>
                  <FormLegend>
                    <FormAsterisk>*</FormAsterisk> &mdash; required fields
                  </FormLegend>

                  <FormActions>
                    <ButtonWithLoader
                      isLoading={isSubmitting}
                      confirmText={'Save Changes'}
                      loadingStyleProp={'submittingWithSpinnerModal'}
                      notLoadingStyleProp={'mediumAlt'}
                      clickHandler={submitForm}
                    />
                  </FormActions>
                </FormFoot>
              </Form>
            );
          }}
        </Formik>
      </PageListWrapper>
      {change2faModalActive ? (
        <ModalPortal onRequestClose={() => setChange2faModalActive(false)}>
          <Change2faModal
            data={{
              selectedMfaMethod,
              redirectToVerifyMfa,
              username,
              session,
              mfaType,
              mfaDestination,
              authenticationAppSecretCode,
              smsVerificationCode,
            }}
            onSuccess={() => setChange2faModalActive(false)}
            actions={{
              setTwoFactorMethod,
              verifySoftwareTokenMfa,
              loginWithMfa,
              verifySmsMfa,
              requestSetSelectedMfaMethod,
            }}
          />
        </ModalPortal>
      ) : null}
      {changePasswordModalActive ? (
        <ModalPortal onRequestClose={() => setChangePasswordModalActive(false)}>
          <ChangePasswordModal
            onSuccess={() => setChangePasswordModalActive(false)}
            actions={{
              requestUpdateUserPassword,
              updateUserPassword,
            }}
          />
        </ModalPortal>
      ) : null}
    </>
  );
};

export default connect(
  state => ({
    user: state.user.user,
    username: state.user.user.username,
    session: state.user.session,
    mfaType: state.user.mfaType,
    mfaDestination: state.user.mfaDestination,
    authenticationAppSecretCode: state.user.authenticationAppSecretCode,
    smsVerificationCode: state.user.smsVerificationCode,
    redirectToVerifyMfa: state.user.redirectToVerifyMfa,
    selectedMfaMethod: state.user.selectedMfaMethod,
  }),

  dispatch => ({
    requestSetSelectedMfaMethod: async () => {
      return dispatch(await requestSetSelectedMfaMethod());
    },
    requestUpdateUserPassword: async () => {
      return dispatch(await requestUpdateUserPassword());
    },
    updateUserPassword: async ({ oldPassword, newPassword }) => {
      return dispatch(await updateUserPassword(oldPassword, newPassword));
    },
    setTwoFactorMethod: async ({ twoFactorMethod }) => {
      return dispatch(await setTwoFactorMethod({ twoFactorMethod }));
    },
    verifySoftwareTokenMfa: async ({ userMfaCode }) => {
      return dispatch(await verifySoftwareTokenMfa({ userMfaCode }));
    },
    verifySmsMfa: async ({ smsVerificationCode, userMfaCode, bypass = false }) => {
      return dispatch(await verifySmsMfa({ smsVerificationCode, userMfaCode, bypass }));
    },
    saveEditUser: async ({
      username,
      email,
      phoneNumber,
      givenName,
      familyName,
      userTitle,
      active,
      userRole,
      picture,
      organizationId,
      twoFactorAuthMethod,
      optOutOfEmails,
    }) =>
      dispatch(
        await editUser({
          username,
          email,
          phoneNumber,
          givenName,
          familyName,
          userTitle,
          active,
          userRole,
          picture,
          organizationId,
          twoFactorAuthMethod,
          optOutOfEmails,
        }),
      ),
  }),
)(UserDetailPage);

const styles = {
  avatarLabel: {
    color: colors.haze,
    fontSize: '14px',
    fontWeight: '800',
    letterSpacing: '0.59px',
    cursor: 'pointer',
  },
  avatarBorder: {
    border: '1px solid #979797',
    textAlign: 'center',
    backgroundColor: colors.fog,
    height: '300px',
  },
  userImagePreviewContainer: {
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '50%',
    width: 120,
    height: 120,
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 20,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center center',
    backgroundSize: 'contain',
  },
  profileImageContainer: {
    width: '300px',
    margin: '0 auto',
  },
  label: {
    fontFamily: theme.font,
    fontSize: '15px',
    fontWeight: '400',
    color: colors.haze,
    marginLeft: '10px',
  },
};
