/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import colors from 'shared/constants/colors';
import device from 'shared/constants/sizing';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import theme from 'shared/styles/global/theme';

export const CardOuterWrapper = styled.div`
  flex: 0 1 100%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  padding: 15px;
  margin-bottom: 40px;
  background: ${colors.cotton};
  height: fit-content;

  @media ${device.medium} {
    flex: 0 1 calc(50% - 1em);

    ${props =>
      props.singleColumn &&
      `
		flex: 0 1 100%;
  `}
  }
`;

export const CardContainer = styled.div`
  height: 70px;
  ${props =>
    props.vehicleExpanded &&
    `
		height:300px;
  `}
  ${props =>
    props.userExpanded &&
    `
		height:200px;
  `}
  ${props =>
    props.analyticsResults &&
    `
		height:150px;
  `}
  ${props =>
    props.otaExpanded &&
    `
		height:200px;
  `}
  ${props =>
    props.otaByVehicleExpanded &&
    `
		height:175px;
  `}
  ${props =>
    props.permissionsExpanded &&
    `
		height:300px;
  `}
  ${props =>
    props.configLibrary &&
    `
		height:120px;
  `}



${props =>
  props.logsResults &&
  `
		height:120px;
  `}

${props =>
  props.otaLogs &&
  `
		height:180px;
  `}
  ${props =>
    props.otaLogsNoAuthorizer &&
    `
		height:160px;
  `}

  ${props =>
    props.config &&
    `
		height:110px;
  `}

${props =>
  props.otaLogsConfigNoAuthorizer &&
  `
		height:85px;
  `}

${props =>
  props.geofenceLogs &&
  `
		height:85px;
  `}

${props =>
  props.userLogs &&
  `
		height:auto;
  `}
  ${props =>
    props.maintenanceEvent &&
    `
		height:200px;
  `}
  ${props =>
    props.recipientGroup &&
    `
		height:60px;
  `}
 ${props =>
   props.alertSummary &&
   `
		height:140px;
  `}
`;

export const Cards = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export const CardLabel = styled.label`
  font-size: 13px;
  font-weight: 700;
  color: ${colors.haze};
`;
export const CardValue = styled.label`
  font-size: 13px;
  font-weight: 400;
  color: ${colors.haze};
  margin-left: 10px;

  ${props =>
    props.horizontalStack &&
    `
		margin-right:10px;
  `}
  ${props =>
    props.tight &&
    `
    margin-left:5px;
  `}

${props =>
  props.red &&
  `
    color: ${colors.cherry};
    font-weight:700;
  `}
`;
export const CardName = styled.label`
  font-size: 15px;
  font-weight: 700;
  color: ${colors.midnight};
  overflow: hidden;
  word-break: break-all;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;

  ${props =>
    props.contactName &&
    `
		margin-bottom:10px;
  `}

  ${props =>
    props.withRespondingStatus &&
    `
		display:flex;
  `}
   ${props =>
     props.deactivated &&
     `
		color: ${colors.haze};
  `}
`;
export const CardStatus = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.midnight};
`;
export const CardType = styled.label`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.midnight};
`;
export const CardRow = styled.div`
  margin-bottom: 5px;

  ${props =>
    props.configName &&
    `
		overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 300px;
  `}

${props =>
  props.potentialLongValue &&
  `
		overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 310px;
  `}

${props =>
  props.userCardHeader &&
  `
		overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 150px;
  `}

  ${props =>
    props.header &&
    `
		margin-bottom:0;
  `}
   ${props =>
     props.maintenanceStatus &&
     `
     padding-top:3px;
		line-height:17px;
  `}
  ${props =>
    props.itemNamePotentialLongValue &&
    `
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      width: 250px;
    `}

  &:last-child {
    margin-bottom: 0;
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  flex: auto;
  margin: 10px 0;
`;

export const ActionMenuBtnWrapper = styled.div`
  float: right;
  width: 30px;
`;

export const ShowCollapseButton = styled.button`
  border: none;
  font-size: 15px;
  font-weight: 700;
  background: transparent;
  color: ${colors.midnight};
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    color: ${colors.charcoal};
    cursor: pointer;
  }
`;

export const CardIconWrapper = styled.div`
  display: flex;
`;

export const CardCollapsedInfo = styled.div``;

export const CardExpandedInfo = styled.div`
  display: none;
  ${props =>
    props.expanded &&
    `
  display: block;
`}
`;

export const VehicleIcon = styled(IconSvgComponent)`
  margin-right: 10px;
  height: 70px;

  ${props =>
    props.analyticsResults &&
    `
		height:30px;
  `}
`;

export const CardBottomWrapper = styled.div`
  display: flex;
`;
export const CardSimNotActive = styled.div`
  display: flex;
  justify-content: flex-start;
  font-family: ${theme.font};
  font-size: 16px;
  font-weight: 400;
  padding: 0;
  margin-top: 10px;
`;

export const CardCheckbox = styled.span`
  margin-right: 5px;
`;

export const CheckboxLabelSpan = styled.span`
  font-size: 14px;
  margin-left: 5px;
  margin-top: -2px;
  margin-right: 5px;
`;

export const PermissionListing = styled.div`
  display: grid;
  grid-gap: 5px;

  grid-template-areas: 'Col' 'Col';
  grid-template-columns: 175px 125px;
`;
