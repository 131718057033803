import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  maintenancePostError,
  maintenancePostSuccess,
  maintenancePostPartialSuccess,
} from 'app/features/maintenance/utilities/notifications';
import Button from 'shared/styles/components/Button';
import { AssignCancelDiv } from 'shared/styles/components/Form';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import useCreateMaintenanceEventsMutation from 'app/features/maintenance/hooks/mutations/useCreateMaintenanceEventsMutation';

const ScheduleMaintenanceSubmitControls = ({
  selectedVehicles,
  maintenanceItems,
  navigateBackToMaintenance,
  isValid,
}) => {
  const dispatch = useDispatch();
  const [serverError, setServerError] = useState(false);

  const submitDisabled = maintenanceItems?.length < 1 || selectedVehicles?.length < 1 || !isValid;

  const [submitting, setSubmitting] = useState(false);

  const filteredOrganizationId = useSelector((state) => state.user.filteredOrganizationId);
  const createMaintenanceEventsMutation = useCreateMaintenanceEventsMutation();

  const confirmHandler = async ({ maintenanceItems, selectedVehicles }) => {
    setSubmitting(true);
    try {
      // for each maintenance item, sent a postMaintenance call and await all of them to finish
      const responses = await Promise.all(
        maintenanceItems.map(async (item) => {
          await createMaintenanceEventsMutation.mutateAsync({
            vehicleIds: selectedVehicles.map((v) => v.vehicleId),
            maintenanceEvent: item,
            organizationId: filteredOrganizationId,
          });
        }),
      );

      setSubmitting(false);
      setServerError(false);
      navigateBackToMaintenance();
    } catch (err) {
      dispatch(maintenancePostError({ err }));
      logWcpError(err);
      setServerError(true);
      setSubmitting(false);
    }
  };
  return (
    <AssignCancelDiv>
      <Button type="button" onClick={navigateBackToMaintenance} withRightSpacer mediumAlt default>
        Cancel
      </Button>
      <ButtonWithLoader
        isLoading={submitting}
        confirmText={'Schedule'}
        loadingStyleProp={'submittingWithSpinnerModal'}
        notLoadingStyleProp={'mediumAlt'}
        clickHandler={() => confirmHandler({ maintenanceItems, selectedVehicles })}
        disabled={submitDisabled}
      />
    </AssignCancelDiv>
  );
};

export default ScheduleMaintenanceSubmitControls;
