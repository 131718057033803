import styled from 'styled-components';

export const CollapsibleDiv = styled.div`
  transition: max-height 0.3s;
  overflow: hidden;

  ${props =>
    props.maxHeight &&
    `
    max-height: ${props.maxHeight}px;
  `};

  ${props =>
    props.collapsed &&
    `
    max-height: 0px;
  `};

  ${props =>
    props.cards &&
    `
    padding:2px;
  `};
`;
