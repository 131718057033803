export const isMaintenanceItemValidReducer = (state, payload) => {
  const { id, isValid } = payload;

  const newRowValidState = { id, isValid, isDirty: true };

  // add if doesn't already exist, otherwise update
  const rowValidStates = state.rowValidStates.some(row => row.id === id)
    ? state.rowValidStates.map(row => (row.id === id ? newRowValidState : row))
    : [...state.rowValidStates, newRowValidState];

  return {
    ...state,
    rowValidStates,
    isValid: rowValidStates.every(row => row.isValid),
    isDirty: rowValidStates.some(row => row.isDirty),
  };
};
