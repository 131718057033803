import { FormikProvider } from 'formik';

import useEditAlertForm from 'app/features/alerts/services/formik/hooks/useEditAlertForm';
import useAlertFormsProviderContext from 'app/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';
import CancelButton from 'app/features/alerts/ui/components/AlertForms/shared/buttons/CancelButton';
import SubmitButton from 'app/features/alerts/ui/components/AlertForms/shared/buttons/SubmitButton';
import AlertDetailsContainer from 'app/features/alerts/ui/components/AlertForms/shared/containers/AlertDetailsContainer';
import AlertNotificationBuilder from 'app/features/alerts/ui/components/AlertNotificationBuilder/AlertNotificationBuilder';
import AddAdditionalNotificationButton from 'app/features/alerts/ui/components/AlertNotificationBuilder/buttons/AddAdditionalNotificationButton';
import { WcpForm } from 'app/shared/components/controls/WcpForm/WcpForm';
import { AssignCancelDiv, FieldError } from 'shared/styles/components/Form';
import {
  BottomOfFormValidationMessage,
  CreateAlertFormWrapperDiv,
} from 'shared/styles/components/ManageAlerts';

const EditAlertForm = () => {
  const {
    state: { alert },
  } = useAlertFormsProviderContext();

  const formik = useEditAlertForm();
  return (
    <FormikProvider value={formik}>
      <CreateAlertFormWrapperDiv>
        <WcpForm
          formik={formik}
          customOnSubmit={e => {
            formik.values.alert = alert;
            return formik.handleSubmit(e);
          }}
        >
          <AlertDetailsContainer />
          <AlertNotificationBuilder />
          <AddAdditionalNotificationButton />
          <BottomOfFormValidationMessage>
            {(formik.errors.alertRecipientField || formik.errors.alertMethodField) &&
            alert?.alertNotifications[0]?.alertRecipientRows.length > 0 ? (
              <FieldError>
                Please select all required recipients and associated alert methods
              </FieldError>
            ) : null}
          </BottomOfFormValidationMessage>
          <AssignCancelDiv withTopSpacing>
            <CancelButton />
            <SubmitButton title={'Save'} />
          </AssignCancelDiv>
        </WcpForm>
      </CreateAlertFormWrapperDiv>
    </FormikProvider>
  );
};

export default EditAlertForm;
