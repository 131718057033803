export const maintenanceTypeConstants = {
  BRAKES: 'BRAKES',
  OIL: 'OIL',
  TIRES: 'TIRES',
  TUNE_UP: 'TUNE_UP',
  DIAGNOSTIC: 'DIAGNOSTIC',
  PM_A: 'PM_A',
  PM_B: 'PM_B',
  PM_C: 'PM_C',
  PM_D: 'PM_D',
  TRANSMISSION_SERVICE: 'TRANSMISSION_SERVICE',
  ALIGNMENT_SERVICE: 'ALIGNMENT_SERVICE',
  STATE_VEHICLE_INSPECTION: 'STATE_VEHICLE_INSPECTION',
  RADIO_MAINTENANCE: 'RADIO_MAINTENANCE',
  RADAR_CERTIFICATION: 'RADAR_CERTIFICATION',
  TAG_RENEWAL: 'TAG_RENEWAL',
  AERIAL_SERVICE: 'AERIAL_SERVICE',
  AERIAL_INSPECTION: 'AERIAL_INSPECTION',
  PUMP_RECERTIFICATION: 'PUMP_RECERTIFICATION',
  PUMP_SERVICE: 'PUMP_SERVICE',
  GENERATOR_SERVICING: 'GENERATOR_SERVICING',
};
