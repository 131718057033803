export const getRecipientDisplayName = recipient => {
  return `${recipient.firstName} ${recipient.lastName}`;
};

export const getUserNameDisplayFromUserList = userList => {
  if (userList?.length > 0) {
    //need join to ensure that the array is treated the same between the display value and the title
    return userList?.map(getRecipientDisplayName).join(', ');
  } else {
    return 'No Recipient (Logs Only)';
  }
};
