import { alertTypeConstants } from 'app/features/alerts/data/enums/alertTypeConstants';
import { alertTypeDropdownOptions } from 'app/features/alerts/data/ui/dropdown/alertTypeDropdownOptions';
import {
  alertNotificationMapping,
  mapAlertRecipientRows,
} from 'app/features/alerts/services/mapping/definitions/alertNotificationMapping';
import { decorateFromTriggerValue } from 'app/features/alerts/services/mapping/helpers/decorateFromTriggerValue';
import { offlineTimeUnitConverterToBackend } from 'app/features/alerts/services/utilities/offlineTimeUnitConverterToBackend';
import { offlineTimeUnitConverterToFrontend } from 'app/features/alerts/services/utilities/offlineTimeUnitConverterToFrontend';
import { getAggregateAlertMethodFromRecipientList } from 'app/shared/utilities/getAggregateAlertMethodFromRecipientList';
import { mapToBackend, mapToFrontend } from 'app/shared/utilities/mapping/mapper';

export const alertsMapping = {
  alert_id: {
    required: true,
    frontendProperty: 'alertId',
  },
  alert_name: {
    required: true,
    frontendProperty: 'alertDetails.alertInfo.alertName',
  },
  alert_type: {
    required: true,
    frontendProperty: 'alertDetails.alertInfo.alertType',
  },
  trigger_value: {
    required: true,
    // change name to triggerValueObject because this is an object with variable properties rather than a single value
    frontendProperty: 'alertDetails.alertInfo.triggerValueObject',
  },
  // display value associated with trigger value such as "MPH" or "Hours"
  // not always sent
  ui_unit_preference: {
    frontendProperty: 'alertDetails.alertInfo.unitOfMeasure',
  },
  is_active: {
    frontendProperty: 'alertDetails.alertInfo.isActive',
  },

  // org
  organization_id: {
    frontendProperty: 'organizationId',
  },
  // vehicle groups
  vehicle_group_id: {
    frontendProperty: 'alertDetails.vehicleInfo.vehicleGroupId',
  },
  vehicle_group_name: {
    frontendProperty: 'alertDetails.vehicleInfo.vehicleGroupName',
  },
  // vehicles
  vehicle_id: {
    frontendProperty: 'alertDetails.vehicleInfo.vehicleId',
  },
  vehicle_name: {
    frontendProperty: 'alertDetails.vehicleInfo.vehicleName',
  },

  test_mode_enabled: {
    frontendProperty: 'alertDetails.alertInfo.testModeEnabled',
  },
  test_mode_mute_alerts: {
    frontendProperty: 'alertDetails.alertInfo.testModeMuteAlerts',
  },

  // additional notification levels
  additional_levels: {
    frontendProperty: 'alertNotifications',
    transformValueToFrontend: value => value.map(al => mapToFrontend(al, alertNotificationMapping)),
    transformValueToBackend: value => value.map(al => mapToBackend(al, alertNotificationMapping)),
  },

  decorateFrontend: alert => {
    // create a decorated object to hold additional properties
    alert.alertDetails.decorated = {};
    alert.alertDetails.decorated.summaryTable = {};

    // scrape primary notification properties and map to alertNotification object
    const primaryNotification = mapToFrontend(alert, alertNotificationMapping);

    // add primary notification to the front of alertNotifications
    alert.alertNotifications.unshift(primaryNotification);

    // takes various trigger values and populates decorated properties accordingly
    decorateFromTriggerValue(alert);

    // add prop to display vehicle or group name depending on which is present

    alert.alertDetails.decorated.targetVehicleOrGroupName =
      alert.alertDetails.vehicleInfo.vehicleGroupName ||
      alert.alertDetails.vehicleInfo.vehicleName ||
      'All Vehicles';

    // get the alert type display name
    alert.alertDetails.decorated.summaryTable.conditionColumn = alertTypeDropdownOptions.getLabelByValue(
      alert.alertDetails.alertInfo.alertType,
    );

    // add display name for table consumption so that it doesn't get transformed on search
    alert.alertDetails.decorated.summaryTable.alertName = alert.alertDetails.alertInfo.alertName;
    let uniqueRecipientsDisplayNames = new Set();

    // need to convert minutes from the backend to match the preferred unit of measure

    if (
      alert.alertDetails.alertInfo.alertType === alertTypeConstants.OFFLINE_TIME_EXCEEDS &&
      alert.alertDetails.alertInfo.triggerValueObject.value
    ) {
      alert.alertDetails.alertInfo.triggerValueObject.value = offlineTimeUnitConverterToFrontend(
        alert.alertDetails.alertInfo.unitOfMeasure,
        alert.alertDetails.alertInfo.triggerValueObject.value,
      );
    }

    alert.alertNotifications.forEach(notification => {
      if (notification.alertRecipientRows?.length > 0) {
        // put all recipients in a Set
        notification.alertRecipientRows.forEach(row => {
          if (row.recipientGroup) {
            row.recipientGroup.recipientGroupMembers.forEach(member => {
              uniqueRecipientsDisplayNames.add(member.recipientUserFullName);
            });
          } else {
            uniqueRecipientsDisplayNames.add(row.recipientUser.recipientUserFullName);
          }
        });
      }

      // get the pretty printed list of all recipients
      alert.alertDetails.decorated.summaryTable.recipientColumn =
        uniqueRecipientsDisplayNames.size > 0
          ? Array.from(uniqueRecipientsDisplayNames).join(', ')
          : 'No Recipients (Logs Only)';
    });

    //get all individual users or group users and find their combined alert methods for display on tables, cards, etc
    let allAlertRecipients = [];

    alert.alertNotifications.forEach(notification => {
      if (notification.alertRecipientRows?.length > 0) {
        notification.alertRecipientRows.forEach(row => {
          if (row.recipientGroup) {
            row.recipientGroup.recipientGroupMembers.forEach(member => {
              allAlertRecipients.push(member);
            });
          } else {
            allAlertRecipients.push(row.recipientUser);
          }
        });
      }
    });

    alert.alertDetails.decorated.summaryTable.methodColumn = getAggregateAlertMethodFromRecipientList(
      allAlertRecipients,
      true,
    );

    // manual clean up
    Object.keys(alert).forEach(key => {
      if (
        key !== 'alertNotifications' &&
        key !== 'alertDetails' &&
        key !== 'alertId' &&
        key !== 'organizationId'
      ) {
        // delete the key from mappedAlert
        delete alert[key];
      }
    });

    return alert;
  },

  postBackendTransform: alert => {
    // take the first element of additional_levels and map it to the alert object
    let primaryNotification = alert.additional_levels.shift();

    if (alert.alert_type === alertTypeConstants.OFFLINE_TIME_EXCEEDS && alert.trigger_value.value) {
      // need to send minutes to the backend always
      alert.trigger_value.value = offlineTimeUnitConverterToBackend(
        alert.ui_unit_preference,
        alert.trigger_value.value,
      );
    }

    // merge the primary notification with the alert object
    alert = { ...alert, ...primaryNotification };

    return alert;
  },
};
