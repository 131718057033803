import Amplify, { API } from 'aws-amplify';
import ReactGA from 'react-ga';

import { refreshToken } from 'core/api/login';
// redux store
import store from 'core/redux/store';
import getDebounced from 'shared/utilities/debounce';
import { clearAuthStore, getAccessToken } from 'shared/utilities/localStore';
import apiUrl from 'shared/utilities/url';

export const [debouncedRefreshToken, clearDebouncedRefreshToken] = getDebounced(() => {
  refreshToken({ accessToken: getAccessToken() });
}, 10000);

export const getAuthToken = () => {
  return getAccessToken();
};

Amplify.configure({
  API: {
    endpoints: [
      {
        name: 'cloudfrontAPI',
        endpoint: apiUrl,
        custom_header: async () => {
          return {
            Authorization: getAuthToken(),
          };
        },
      },
    ],
  },
});

const logCallToGooglAnalytics = (path, method) => {
  try {
    let cleanPath = path.replace(/\b[a-f\d-]{36}/, '{Id}');

    ReactGA.event({
      category: 'API',
      action: `${cleanPath} ${method}`,
    });
  } catch (error) {
    console.log('Analytics API call error');
    console.log(error);
  }
};

const unauthenticatedRoutes = ['/login', '/troubleloggingin', '/resetpwd'];

export const apiCall = async ({
  endpoint,
  path,
  method,
  queryStringParameters,
  body,
  headers,
  responseType,
}) => {
  logCallToGooglAnalytics(path, method);

  try {
    const reduxState = store.getState();

    // if filteredOrganizationId is set, and user is not in that organization, add targetOrganizationId to query string
    if (
      reduxState.user.filteredOrganizationId &&
      reduxState.user.user.organizationId !== reduxState.user.filteredOrganizationId &&
      !unauthenticatedRoutes.includes(path)
    ) {
      // add targetOrganizationId to query string
      const targetOrganizationId = reduxState.user.filteredOrganizationId;

      queryStringParameters = {
        ...queryStringParameters,
        targetOrganizationId,
      };
    }
    const apiResponse = await API[method](endpoint || 'cloudfrontAPI', path, {
      responseType,
      response: true,
      queryStringParameters,
      body,
      headers,
    });
    // TODO: store entire array in constants
    if (
      !['/logout', '/login', '/vehicles', '/refreshtoken'].includes(path) &&
      !path.includes('/forgotpwd') &&
      !path.includes('/forgetusername')
    ) {
      debouncedRefreshToken();
    }
    if (path === '/logout') {
      clearDebouncedRefreshToken();
    }

    return {
      response: apiResponse.data, // deprecated; use body instead
      data: apiResponse.data, // deprecated; use body instead
      body: apiResponse.data, // use this
      status: apiResponse.status,
      headers: apiResponse.headers,
    };
  } catch (e) {
    if (!e.response) {
      throw 'Error getting response from server, please check your network connection.';
    } else if (!e.response.status) {
      // if you see this error, backend is not sending response.status
      throw 'Something went wrong, but the server did not return a status.';
    }

    // special cases
    else if (e.response.status === 401) {
      clearAuthStore();

      // is there a message pushed from backend
      if (e.response?.data?.errorMessage) {
        throw e.response.data.errorMessage;
      }
      // api gateway default
      else if (e.message) {
        throw e.message;
      } else {
        return 'Something went wrong, and the server did not return an error message.'; // if you see this error, backend is not sending data.errorMessage
      }
    }
    // default case
    else {
      let serverError =
        e.response.data && e.response.data.errorMessage
          ? e.response.data.errorMessage
          : 'Something went wrong, and the server did not return an error message.'; // if you see this error, backend is not sending data.errorMessage

      throw serverError;
    }
  }
};

export const fetchAPICall = async ({ baseURI, resourcePath, method, headers, body }) => {
  let queryStringParameters = '';
  try {
    const reduxState = store.getState();
    let targetOrganizationId = null;
    if (
      reduxState.user.filteredOrganizationId &&
      reduxState.user.user.organizationId !== reduxState.user.filteredOrganizationId &&
      !unauthenticatedRoutes.includes(resourcePath)
    ) {
      targetOrganizationId = store.getState().user.filteredOrganizationId;
    }

    // include in query string if targetOrganizationId is set
    if (targetOrganizationId) {
      queryStringParameters =
        targetOrganizationId && '?' + new URLSearchParams({ targetOrganizationId });
    }

    const response = await fetch(`${baseURI}${resourcePath}${queryStringParameters}`, {
      mode: 'cors',
      method,
      headers,
      body,
    });
    const responseBody = await response.json();
    if (responseBody && responseBody.errorMessage) throw responseBody.errorMessage;

    if (responseBody && responseBody.message) return { response: responseBody };
    else {
      return responseBody;
    }
  } catch (e) {
    console.log(
      `Error using fetch to make a request to ${baseURI}${resourcePath}${queryStringParameters}`,
      e,
    );
    throw e;
  }
};

const triggerBrowserDownload = (url, filename) => {
  const link = document.createElement('a');
  link.href = url;
  link.download = filename;
  document.body.appendChild(link);
  link.click();
  link.parentNode.removeChild(link);
};

export const handleDownloadFromURL = ({ downloadServiceCall, resourceId, filename }) => {
  return async () => {
    const { response } = await downloadServiceCall(resourceId);
    triggerBrowserDownload(response, filename);
  };
};

export const parse207Response = ({ response, handleError, handlePartial, handleSuccess }) => {
  const { status, data } = response;
  // should always be 207 in this case
  if (status !== 207) throw new Error('Unexpected response status code');

  // get status code for each message in the response
  const statusCodes = data.message.map(ir => ir.statusCode);

  // determine if subresponses were all errors, all successes, or a mix
  const allErrors = !statusCodes.some(code => code === 200);
  const noErrors = statusCodes.every(code => code === 200);

  // determine which notification to dispatch
  if (allErrors) {
    handleError();
  } else if (noErrors) {
    handleSuccess();
  } else {
    handlePartial();
  }
};
