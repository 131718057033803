import AnimatedField from 'shared/ui/fields/AnimatedField';

const OilLifeField = ({ formikProps: { values, touched, errors, handleChange, handleBlur } }) => {
  const oilLifeWithPercentage = values.oilLife + '%';
  return (
    <div>
      <AnimatedField
        name="oilLife"
        placeholder="Oil Life"
        value={oilLifeWithPercentage}
        touched={touched.oilLife}
        handleChange={handleChange}
        handleBlur={handleBlur}
        disabled={true}
      />
    </div>
  );
};

export default OilLifeField;
