import { v4 as uuidv4 } from 'uuid';

// addAlertNotificationAction → addAlertNotificationReducer
export const addAlertNotificationReducer = (state, _) => {
  return {
    ...state,
    alert: {
      ...state.alert,
      alertNotifications: [
        ...state.alert.alertNotifications,
        {
          alertNotificationGuid: uuidv4(),
          alertRecipientRows: [
            {
              recipientRowGuid: uuidv4(),
              recipientRowAlertMethod: null,
            },
          ],
          cooldownMinutes: 1440,
          delayMinutes: 0,
          isLocationIncluded: true,
        },
      ],
    },
  };
};
