import { InvalidReducerPayloadError } from 'app/core/error/classes/InvalidReducerPayloadError';

export const assertReducerPayload = ({ payload, mustInclude }) => {
  const missingFields = mustInclude.filter(field => payload[field] === undefined);

  if (missingFields.length) {
    throw new InvalidReducerPayloadError(
      `Payload is missing required fields: ${missingFields.join(', ')}`,
    );
  }
};
