import { Fragment } from 'react';

import {
  FIELD_SOLUTION_ENGINEER,
  SYSTEM_ADMIN,
  userRoleOptions as userRoles,
} from 'shared/constants/users';
import { SimpleDropdownBtn, SimpleDropdownItem } from 'shared/styles/components/DropdownMenu';
import ViewActivityLogsMenuItem from 'shared/ui/menus/ViewActivityLogsMenuItem';
import ActionMenu from 'shared/ui/table/ActionMenu';

export default ({
  user,
  currentUserUsername,
  activeTab,
  organization,
  userRole,
  handleToggleEditModal,
  handleDeactivateUser,
  handleActivateUser,
  handleDisassociateFSE,
}) => (
  <>
    {activeTab === 'users' ? (
      <ActionMenu>
        <SimpleDropdownItem>
          <SimpleDropdownBtn
            onClick={handleToggleEditModal({
              username: user.username,
              organizationId: user.organizationId,
            })}
          >
            Edit
          </SimpleDropdownBtn>
        </SimpleDropdownItem>
        {userRole !== userRoles[FIELD_SOLUTION_ENGINEER].value ? (
          <SimpleDropdownItem>
            <ViewActivityLogsMenuItem username={user.username} />
          </SimpleDropdownItem>
        ) : null}
        {user.active === true ? (
          user.username !== currentUserUsername && (
            <SimpleDropdownItem>
              <SimpleDropdownBtn
                onClick={() => {
                  console.log('handleDeactivateUser click', user);
                  return handleDeactivateUser({
                    username: user.username,
                    organization,
                  });
                }}
              >
                Deactivate
              </SimpleDropdownBtn>
            </SimpleDropdownItem>
          )
        ) : (
          <SimpleDropdownItem>
            <SimpleDropdownBtn onClick={handleActivateUser({ user, organization })}>
              Activate
            </SimpleDropdownBtn>
          </SimpleDropdownItem>
        )}
      </ActionMenu>
    ) : (
      <>
        {userRole === userRoles[SYSTEM_ADMIN].value ? (
          <ActionMenu>
            <SimpleDropdownItem>
              <SimpleDropdownBtn
                onClick={handleDisassociateFSE({
                  username: user.username,
                  organizationId: organization.organizationId,
                })}
              >
                Remove
              </SimpleDropdownBtn>
            </SimpleDropdownItem>
          </ActionMenu>
        ) : (
          <ActionMenu>
            <SimpleDropdownItem>
              <ViewActivityLogsMenuItem username={user.username} />
            </SimpleDropdownItem>
          </ActionMenu>
        )}
      </>
    )}
  </>
);
