import { addNotification } from 'core/redux/ui/actions';
import { createNotification, LEVELS } from 'shared/utilities/notification';

export const vehicleFetchError = ({ err }) =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Getting Vehicles', err),
  });
export const vehicleResetSuccess = ({ message }) =>
  addNotification({
    notification: createNotification(LEVELS.SUCCESS, 'Successfully Reset Vehicle', message),
  });
export const vehicleResetError = ({ err }) =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Resetting Vehicle', err),
  });
export const vehicleCreateSuccess = ({ vehicleName }) =>
  addNotification({
    notification: createNotification(
      LEVELS.SUCCESS,
      'Vehicle Create Success',
      `Successfully created "${vehicleName}"`,
    ),
  });

export const lockError = ({ err }) =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error locking / unlocking', err),
  });

export const lockVehicleSuccess = ({ vehicleName }) =>
  addNotification({
    notification: createNotification(
      LEVELS.SUCCESS,
      'Vehicle Locked Successfully',
      `Successfully locked "${vehicleName}"`,
    ),
  });
export const unlockVehicleSuccess = ({ vehicleName }) =>
  addNotification({
    notification: createNotification(
      LEVELS.SUCCESS,
      'Vehicle Unlocked Successfully',
      `Successfully unlocked "${vehicleName}"`,
    ),
  });

export const lockVehicleError = ({ vehicleName }) =>
  addNotification({
    notification: createNotification(
      LEVELS.ERROR,
      'Error Locking Vehicle',
      `Failed to lock "${vehicleName}"`,
    ),
  });
export const unlockVehicleError = ({ vehicleName }) =>
  addNotification({
    notification: createNotification(
      LEVELS.ERROR,
      'Error Unloocking Vehicle',
      `Failed to unlock "${vehicleName}"`,
    ),
  });
export const lockAllVehiclesSuccess = () =>
  addNotification({
    notification: createNotification(
      LEVELS.SUCCESS,
      'Vehicles Locked Successfully',
      `Successfully locked all vehicles`,
    ),
  });
export const unlockAllVehiclesSuccess = () =>
  addNotification({
    notification: createNotification(
      LEVELS.SUCCESS,
      'Vehicles Unlocked Successfully',
      `Successfully unlocked all vehicles`,
    ),
  });
export const lockAllVehiclesError = err =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Locking All Vehicles'),
  });
export const unlockAllVehiclesError = err =>
  addNotification({
    notification: createNotification(LEVELS.ERROR, 'Error Unlocking All Vehicles'),
  });
export const lockAllVehiclesPartialError = err =>
  addNotification({
    notification: createNotification(
      LEVELS.WARNING,
      'Error Locking All Vehicles',
      `Some vehicles could not be locked`,
    ),
  });
export const unlockAllVehiclesPartialError = err =>
  addNotification({
    notification: createNotification(
      LEVELS.WARNING,
      'Error Unlocking All Vehicles',
      `Some vehicles could not be unlocked`,
    ),
  });
