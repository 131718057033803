import useMakeFormikField from 'app/shared/components/controls/WcpForm/hooks/useMakeFormikField';
import FilteredDropdown from 'shared/ui/dropdowns/FilteredDropdown';
import { DropdownWrapper } from 'shared/styles/components/ManageAlerts';
import useAlertFormsProviderContext from 'app/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';

const VehicleGroupDropdown = () => {
  const {
    state: {
      alert: {
        alertDetails: {
          vehicleInfo: { vehicleGroupId },
        },
      },
      vehicleGroups,
    },
    actions: { updateAlertDetailsAction },
  } = useAlertFormsProviderContext();

  useMakeFormikField({ fieldName: 'vehicleGroupId', fieldValue: vehicleGroupId });
  const defaultSelectedGroup = vehicleGroups?.find(g => g.group_id === vehicleGroupId)?.group_name;

  return (
    vehicleGroups && (
      <DropdownWrapper>
        <FilteredDropdown
          restrictedHeightResults
          dropdownOptions={vehicleGroups?.map(g => g.group_name)}
          defaultSelectedItem={defaultSelectedGroup}
          title={'Group'}
          placeholder={defaultSelectedGroup || 'All Groups'}
          onItemSelect={selected => {
            if (selected) {
              const vg = vehicleGroups?.find(g => g.group_name === selected);
              updateAlertDetailsAction({ vehicleGroupId: vg.group_id });
            } else {
              updateAlertDetailsAction({ vehicleGroupId: null });
            }
          }}
        />
      </DropdownWrapper>
    )
  );
};

export default VehicleGroupDropdown;
