import { useEffect, useState } from 'react';

import { getHeatmapData } from 'core/api/analytics';
import AnalyticsHeatmapHeader from 'features/analytics/heatmap/components/header/AnalyticsHeatmapHeader';
import AnalyticsHeatmapMapboxComponent from 'features/analytics/heatmap/components/map/AnalyticsHeatmapMapboxComponent';
import AnalyticsHeatmapSidebarContainer from 'features/analytics/heatmap/components/sidebar/AnalyticsHeatmapSidebarContainer';
import { activityFilterOptions } from 'features/analytics/heatmap/constants/AnalyticsHeatmapConstants';
import { AnalyticsHeatmapApi } from 'features/analytics/heatmap/helpers/AnalyticsHeatmapApiContext';
import {
  SharedMapDiv,
  SidebarAndContentDiv,
} from 'shared/styles/components/SharedMapStyledComponents';

const AnalyticsHeatmapContainer = ({ organizationId }) => {
  const [heatmapSidebarOpen, setHeatmapSidebarOpen] = useState(true);
  const [heatmapData, setHeatmapData] = useState([]);
  const [respondingHeatmapData, setRespondingHeatmapData] = useState([]);
  const [notRespondingHeatmapData, setNotRespondingHeatmapData] = useState([]);

  const [headerStartDate, setHeaderStartDate] = useState(null);
  const [headerEndDate, setHeaderEndDate] = useState(null);

  const [selectedActivityFilterOption, setSelectedActivityFilterOption] = useState(
    activityFilterOptions.ALL,
  );
  const [showRespondingLayers, setShowRespondingLayers] = useState(false);
  const [showNotRespondingLayers, setShowNotRespondingLayers] = useState(false);

  const submitHeatmapDataQuery = async ({ startDate, endDate }) => {
    // get heatmapData
    let data = await getHeatmapData({ startDate, endDate, organizationId });

    // set heatmap data
    setRespondingHeatmapData(data.response.message.responding);
    setNotRespondingHeatmapData(data.response.message.active);
    setHeatmapData(data.response.message.items);
  };

  useEffect(() => {
    if (selectedActivityFilterOption === activityFilterOptions.ALL) {
      setShowRespondingLayers(true);
      setShowNotRespondingLayers(true);
    } else if (selectedActivityFilterOption === activityFilterOptions.RESPONDING) {
      setShowRespondingLayers(true);
      setShowNotRespondingLayers(false);
    } else if (selectedActivityFilterOption === activityFilterOptions.NOT_RESPONDING) {
      setShowRespondingLayers(false);
      setShowNotRespondingLayers(true);
    }
  }, [selectedActivityFilterOption]);

  return (
    <AnalyticsHeatmapApi.Provider
      value={{
        setHeatmapSidebarOpen,
        setSelectedActivityFilterOption,
        selectedActivityFilterOption,
        submitHeatmapDataQuery,
        setHeaderStartDate,
        setHeaderEndDate,
      }}
    >
      <SharedMapDiv>
        <AnalyticsHeatmapHeader startDate={headerStartDate} endDate={headerEndDate} />
        <SidebarAndContentDiv>
          <AnalyticsHeatmapSidebarContainer
            heatmapData={heatmapData}
            isOpen={heatmapSidebarOpen}
            selectedActivityFilterOption={selectedActivityFilterOption}
          />
          <AnalyticsHeatmapMapboxComponent
            respondingHeatmapData={respondingHeatmapData}
            notRespondingHeatmapData={notRespondingHeatmapData}
            showRespondingLayers={showRespondingLayers}
            showNotRespondingLayers={showNotRespondingLayers}
          />
        </SidebarAndContentDiv>
      </SharedMapDiv>
    </AnalyticsHeatmapApi.Provider>
  );
};
export default AnalyticsHeatmapContainer;
