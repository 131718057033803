import { convertDateField } from 'app/features/playback/state/formik/utilities/convertDateField';

export const mapFormikToVehiclePlaybackRequestFormParams = ({
  selectedVehicleId,
  startDateField,
  startTimeField,
  endDateField,
  endTimeField,
  selectedGranularity,
}) => {
  const requestParams = {
    selectedVehicleId,
    startDate: convertDateField(startDateField, startTimeField),
    startTime: startTimeField,
    endDate: convertDateField(endDateField, endTimeField),
    endTime: endTimeField,
    selectedGranularity,
  };

  return requestParams;
};
