import MaintenanceBuilder from 'app/features/maintenance/components/MaintenanceBuilder/MaintenanceBuilder';
import ScheduleMaintenanceSelectAllCheckbox from 'app/features/maintenance/components/MaintenanceScheduler/checkboxes/ScheduleMaintenanceSelectAllCheckbox';
import ScheduleMaintenanceFilters from 'app/features/maintenance/components/MaintenanceScheduler/filters/ScheduleMaintenanceFilters';
import ScheduleMaintenanceVehicleCardContainer from 'app/features/maintenance/components/MaintenanceScheduler/mobile/ScheduleMaintenanceVehicleCardContainer';
import ScheduleMaintenanceSubmitControls from 'app/features/maintenance/components/MaintenanceScheduler/submit/ScheduleMaintenanceSubmitControls';
import ScheduleMaintenanceTable from 'app/features/maintenance/components/MaintenanceScheduler/table/ScheduleMaintenanceTable';
import ScheduleMaintenanceContextProvider from 'app/features/maintenance/providers/ScheduleMaintenanceContextProvider/ScheduleMaintenanceContextProvider';
import ListingCount from 'shared/components/ListingCount';
import MediaWrapper from 'shared/components/MediaWrapper';
import { UnsupportedTextWrapper } from 'shared/styles/components/Maintenance';
import ScheduledMaintenanceSelectedVehicleCount from './widgets/ScheduledMaintenanceSelectedVehicleCount';

const MaintenanceScheduler = ({ navigateBackToMaintenance }) => {
  return (
    <ScheduleMaintenanceContextProvider>
      {(
        {
          setSelectedVehiclesAction,
          setScheduleMaintenanceFiltersAction,
          setScheduleMaintenanceIntervalTypesAction,
          setMaintenanceItemsAction,
          setListingCountAction,
          setScheduleMaintenanceIsValidAction,
        },
        {
          maintenanceItems,
          filteredVehicles,
          selectableVehicles,
          selectedVehicles,
          listingCount,
          allGroups,
          allVehicles,
          isValid,
        },
      ) => {
        const orgUsesEngineHours = allVehicles?.some(v => {
          return v?.obd?.engine_hours !== null;
        });
        const orgUsesEnhancedTelematics = allVehicles?.some(v => {
          return v?.meta.enhanced_telematics_enabled !== false;
        });
        return (
          <>
            <MaintenanceBuilder
              orgUsesEngineHours={orgUsesEngineHours}
              orgUsesEnhancedTelematics={orgUsesEnhancedTelematics}
              setScheduleMaintenanceIsValid={setScheduleMaintenanceIsValidAction}
              handleSelectedIntervalTypes={({ selectedIntervalTypes }) =>
                setScheduleMaintenanceIntervalTypesAction({ selectedIntervalTypes })
              }
              handleSetMaintenanceItems={({ maintenanceItems }) =>
                setMaintenanceItemsAction({ maintenanceItems })
              }
            />
            <h5>Select Vehicles to Receive Maintenance</h5>
            <ScheduleMaintenanceFilters
              groups={allGroups}
              vehicles={allVehicles}
              setScheduleMaintenanceFilters={setScheduleMaintenanceFiltersAction}
            />
            <UnsupportedTextWrapper>
              Vehicles that do not support the selected interval type or have never been online will
              be unavailable for selection
            </UnsupportedTextWrapper>
            {allVehicles && (
              <>
                <ListingCount
                  listingCount={listingCount}
                  totalCount={filteredVehicles?.length}
                  itemTypeName={'Vehicle'}
                />
                <ScheduleMaintenanceSelectAllCheckbox
                  selectedVehicles={selectedVehicles}
                  selectableVehicles={selectableVehicles}
                  setSelectedVehicles={setSelectedVehiclesAction}
                />
                <MediaWrapper
                  mobileComponent={
                    <ScheduleMaintenanceVehicleCardContainer
                      vehicles={filteredVehicles}
                      selectableVehicles={selectableVehicles}
                      selectedVehicles={selectedVehicles}
                      setSelectedVehicles={setSelectedVehiclesAction}
                      handleUpdateListingCount={() =>
                        setListingCountAction({ listingCount: filteredVehicles?.length })
                      }
                    />
                  }
                  nonMobileComponent={
                    <ScheduleMaintenanceTable
                      vehicleRecords={filteredVehicles}
                      selectableVehicles={selectableVehicles}
                      selectedVehicles={selectedVehicles}
                      setSelectedVehicles={setSelectedVehiclesAction}
                      handleUpdateListingCount={listingCount =>
                        setListingCountAction({ listingCount })
                      }
                    />
                  }
                />
              </>
            )}
            <ScheduledMaintenanceSelectedVehicleCount selectedVehicles={selectedVehicles} />
            <ScheduleMaintenanceSubmitControls
              selectedVehicles={selectedVehicles}
              maintenanceItems={maintenanceItems}
              navigateBackToMaintenance={navigateBackToMaintenance}
              isValid={isValid}
            />
          </>
        );
      }}
    </ScheduleMaintenanceContextProvider>
  );
};

export default MaintenanceScheduler;
