import React from 'react';
import { VehicleLicenseWrapper } from 'shared/styles/components/Form';
import AnimatedField from 'shared/ui/fields/AnimatedField';

const VehicleLicensePlateField = ({
  formikProps: { values, touched, errors, handleChange, handleBlur },
}) => {
  return (
    <VehicleLicenseWrapper>
      <AnimatedField
        name="licensePlate"
        placeholder="License Plate"
        value={values.licensePlate}
        touched={touched.licensePlate}
        validationError={errors.licensePlate}
        handleChange={handleChange}
        handleBlur={handleBlur}
        required
      />
    </VehicleLicenseWrapper>
  );
};

export default VehicleLicensePlateField;
