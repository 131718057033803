import React, { useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { addNotification } from 'core/redux/ui/actions';
import { createNotification } from 'shared/utilities/notification';
import { LEVELS } from 'shared/utilities/notification';
import { resetGroupVehicles } from 'core/api/vehicles';
import ConfirmationModalWithInnerModalPortal from 'shared/ui/modals/ConfirmationModalWithInnerModalPortal';
import {
  resetGroupVehiclesError,
  resetGroupVehiclesSuccess,
  resetSingleSuccess,
} from 'features/fleet/groups/helpers/notifications';

const ResetGroupVehiclesModal = ({ modalData: { show, vehicleIds }, handleCloseModal }) => {
  const dispatch = useDispatch();

  const resetVehicles = async (vehicleIds) => {
    let vehicleToReset;
    let failedVehicles;
    let success;
    for (let i = 0; i < vehicleIds.length; i++) {
      vehicleToReset = vehicleIds[i];
      try {
        let response = await resetGroupVehicles(vehicleToReset);
        if (response) {
          success = true;
          dispatch(resetSingleSuccess({ vehicleToReset }));
        }
      } catch (err) {
        failedVehicles += vehicleToReset;
      }
    }
    if (failedVehicles) {
      dispatch(resetGroupVehiclesError({ failedVehicles }));
    } else {
      dispatch(resetGroupVehiclesSuccess());
    }
  };

  return (
    show && (
      <ConfirmationModalWithInnerModalPortal
        onCloseModal={handleCloseModal}
        onSuccess={handleCloseModal}
        onFailure={handleCloseModal}
        bodyText={`Do you really want to reset all vehicles in this group? Resetting the vehicle will not affect Maintenance, Alerts, Add-ons, Miovision, and HAAS settings. Please note pending Firmware and Configuration updates will be cleared and will need to be reapplied.`}
        secondaryBodyText={`This operation may take a while.`}
        title={`Reset All Group Vehicles`}
        handleRequestClose={handleCloseModal}
        submitHandler={async () => {
          return await resetVehicles(vehicleIds);
        }}
        confirmText="Reset Vehicles"
        cancelHandler={handleCloseModal}
        cancelText="Cancel"
      />
    )
  );
};

export default ResetGroupVehiclesModal;
