import styled from 'styled-components';
import colors from 'shared/constants/colors';
import device from 'shared/constants/sizing';

export const AlertsActionButtonsDiv = styled.div`
  display: grid;
  grid-gap: 10px;

  grid-template-areas:
    'Col'
    'Col';
  grid-template-columns: 232px;
  grid-template-rows: 38px 38px;
  @media ${device.medium} {
    display: flex;
    align-items: baseline;
    grid-gap: 10px;
  }
`;

export const CreateAlertFormWrapperDiv = styled.div`
  margin-top: 50px;
  margin-bottom: 50px;
`;

export const NameFieldDiv = styled.div`
  margin-top: 15px;

  @media ${device.medium} {
    width: 400px;
  }
`;

export const InfoTextWrapper = styled.div`
  color: ${colors.haze};
  margin-top: 0px;
  margin-bottom: 20px;
`;

export const AlertTriggerValueWrapper = styled.div`
  margin-top: 0px;
  margin-bottom: 5px;
  display: grid;
  grid-template-areas:
    'FormCol'
    'FormCol';
  grid-template-columns: 150px;
  grid-template-rows: auto auto;

  @media ${device.largeAnalytics} {
    margin-top: 0px;
    margin-bottom: 0px;
    grid-template-areas: 'FormCol FormCol ';
    grid-template-columns: max-content max-content;
    grid-template-rows: 80px;
  }
`;

export const AlertTypeDetailsWrapperDiv = styled.div`
  margin-top: 30px;
  margin-bottom: 0px;
  display: grid;
  grid-template-rows: 60px auto auto;
  grid-template-areas:
    'FormCol'
    'FormCol'
    'FormCol'
    'FormCol';

  @media ${device.largeAnalytics} {
    grid-template-areas: 'FormCol FormCol FormCol FormCol ';
    grid-template-columns: 200px max-content 220px 220px;
    grid-template-rows: 40px;
  }
`;

export const AlertTriggerValueFieldDiv = styled.div`
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 10px;
  @media ${device.largeAnalytics} {
    max-width: 80px;
    margin-left: 20px;
    margin-top: 1px;
    margin-bottom: 0px;
  }
`;

export const UnitOfMeasureDiv = styled.div`
  margin-top: 0px;
  margin-left: 0px;
  width: 200px;
  @media ${device.largeAnalytics} {
    width: 115px;
    margin-top: 1px;
    margin-left: 20px;
  }
`;

export const RecipientWrapper = styled.div`
  margin-top: 40px;
  margin-bottom: 10px;
  display: grid;
  grid-template-rows: 80px 60px max-content max-content;
  grid-template-columns: max-content;
  grid-template-areas:
    'FormCol'
    'FormCol'
    'FormCol'
    'FormCol'
    'FormCol';
  button:nth-child(3) {
    margin-bottom: 20px;
  }

  @media ${device.largeAnalytics} {
    grid-template-areas: 'FormCol FormCol FormCol FormCol FormCol';
    grid-template-columns: 200px 200px max-content max-content max-content;
    grid-template-rows: 40px;
    grid-gap: 20px;
    button:nth-child(4) {
      margin-left: -10px;
    }
    margin-top: 30px;
  }
`;

export const CooldownDelayDiv = styled.div`
  margin-top: 20px;
  @media ${device.largeAnalytics} {
    margin-bottom: 25px;
  }
  margin-bottom: 40px;
`;

export const AlertRecipientRemoveButtonWrapper = styled.div`
  margin-top: 2px;
`;
export const CreateRecipientGroupModalFormWrapper = styled.div`
  margin-left: 15px;
  margin-bottom: 25px;
`;
export const RecipientBuilderWrapper = styled.div`
  margin-top: 10px;
`;

export const RecipientHeaderWrapper = styled.div`
  margin-top: 25px;

  ${props =>
    props.logsOnly &&
    `
  display: grid;
  margin-top: 0px;
  grid-template-rows: max-content max-content;
  grid-template-columns: max-content;
  grid-template-areas:
    'FormCol'
    'FormCol';

  @media ${device.largeAnalytics} {
    margin-top: 25px;
    grid-template-areas: 'FormCol FormCol ';
    grid-template-columns: max-content max-content;
    grid-template-rows: 25px;
    grid-gap: 20px;
  }
  margin-bottom: 10px;
}
`}
`;

export const DropdownWrapper = styled.div`
  margin-left: 0px;
  margin-bottom: 30px;

  ${props =>
    props.alertAddon &&
    `
		margin-top: 10px;
    margin-bottom: 10px;
    @media ${device.largeAnalytics} {
      margin-top: 0px;
      margin-bottom: 0px;
  }
	`}



  @media ${device.largeAnalytics} {
    margin-bottom: 0px;
    margin-left: 20px;
  }

  ${props =>
    props.alertType &&
    `
		margin-left: 0px;
    margin-bottom: 10px;
    @media ${device.largeAnalytics} {
      margin-left: 0px;
      margin-bottom: 0px;
  }
	`}
`;

export const InfoIconWrapper = styled.div`
  margin-left: 5px;
  margin-top: 3px;
`;

export const RedText = styled.span`
  color: ${colors.cherry};
  font-weight: 700;
`;

export const CustomAlertRowWrapper = styled.div`
  margin-bottom: 10px;
  display: grid;
  grid-template-columns: 150px 60px 60px;
  grid-template-rows: 20px;
  grid-gap: 20px;
  grid-template-areas: 'FormCol FormCol FormCol';

  ${props =>
    props.headers &&
    `
		margin-top:25px;
	`}
`;

export const CustomAlertsTableHeader = styled.div`
  font-weight: 600;
  font-size: 10px;
  color: ${colors.haze};
  text-transform: uppercase;
  display: flex;
  justify-content: center;

  ${props =>
    props.recipientName &&
    `
		justify-content: left;
	`}
`;

export const CustomAlertCheckWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const RecipientFullName = styled.h6`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 170px;
  height: 50px;
`;

export const NotificationHeader = styled.h5`
  font-size: 19px;

  ${props =>
    props.nonPrimary &&
    `
		margin-top:5px;
	`}
`;

export const DelayCooldownWrapper = styled.div`
  margin-bottom: 20px;
  display: grid;
  grid-template-rows: 80px 60px max-content max-content;

  @media ${device.largeAnalytics} {
    grid-template-areas: 'FormCol FormCol ';
    grid-template-columns: 200px 200px;
    grid-template-rows: 40px;
    grid-gap: 20px;
    margin-bottom: 30px;
  }
`;
export const AdditionalNotificationHeaderWrapper = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const AdditionalNoficationInfoAndRemoveButtonWrapper = styled.div`
  display: grid;
  grid-template-areas: 'FormCol FormCol FormCol';
  grid-template-columns: max-content 30px 50px;
  grid-template-rows: 40px;
  margin-bottom: 30px;
`;

export const BottomOfFormValidationMessage = styled.div`
  margin-top: 20px;
`;

export const TestModeModalWrapper = styled.div`
  margin-left: 10px;
`;

export const MuteAlertsCheckboxWrapperDiv = styled.div`
  margin-bottom: 20px;
`;
