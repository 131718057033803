import React from 'react';
import { DropdownLink } from 'shared/styles/components/DropdownMenu';

const ViewLogsMenuItem = ({ vehicle }) => {
  // in the current state of the app, this comes in cased differently, this will account for both until we are fully mapped out
  const vehicleId= vehicle.vehicleId || vehicle.vehicle_id;
  return (
    <DropdownLink to={`/analyticslogs/${encodeURIComponent(vehicleId)}`}>
      View Logs
    </DropdownLink>
  );
};

export default ViewLogsMenuItem;
