import { MapPerformanceModeDiv } from 'shared/styles/components/SharedMapStyledComponents';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { setPerformanceModePreferenceInLocal } from 'shared/utilities/localStore';

const MapPerformanceModeButton = ({ isPerformanceMode, handlePerformanceModeChange }) => {
  return (
    <MapPerformanceModeDiv>
      <div
        onClick={() => {
          setPerformanceModePreferenceInLocal(!isPerformanceMode);
          handlePerformanceModeChange(!isPerformanceMode);
          location.reload();
        }}
      >
        {isPerformanceMode ? (
          <IconSvgComponent
            svgFileName={'map-performance-mode-enabled'}
            alt="Toggle Performance Mode Off "
            title="Toggle Performance Mode Off "
          />
        ) : (
          <IconSvgComponent
            svgFileName={'map-performance-mode'}
            alt="Toggle Performance Mode On for older hardware"
            title="Toggle Performance Mode On for older hardware"
          />
        )}
      </div>
    </MapPerformanceModeDiv>
  );
};

export default MapPerformanceModeButton;
