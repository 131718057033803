import GroupNameField from 'features/fleet/groups/components/form/fields/text/GroupNameField';
import { useDuplicateGroupMutation } from 'features/fleet/groups/hooks/useDuplicateGroupMutation';
import { FormRow } from 'shared/styles/components/Form';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import GenericFormModal from 'shared/ui/modals/GenericFormModal';
import { validateGroup } from 'shared/utilities/validators';

const DuplicateGroupModal = ({ modalData: { show, vehicleIds, groupName }, handleCloseModal }) => {
  const duplicateGroupMutation = useDuplicateGroupMutation();

  return (
    show && (
      <GenericFormModal
        icon={<IconSvgComponent svgFileName="plus" alt="Create" />}
        title={'Duplicate Group'}
        initialValues={{
          groupName,
        }}
        submitHandler={async (values, formikActions) => {
          let result = await duplicateGroupMutation.mutateAsync({
            groupName: values.groupName,
            vehicleIds,
          });

          formikActions.setSubmitting(false);
          handleCloseModal();
        }}
        validator={validateGroup}
        handleCloseModal={handleCloseModal}
        errorMessage={duplicateGroupMutation.isError ? duplicateGroupMutation.error : null}
        submitButtonText={'Duplicate'}
        validateOnChange={true}
        validateOnBlur={false}
      >
        {formikProps => (
          <FormRow>
            <GroupNameField formikProps={formikProps} />
          </FormRow>
        )}
      </GenericFormModal>
    )
  );
};

export default DuplicateGroupModal;
