import { apiCall } from 'core/api/API';

export const unsubscribeFromEmail = async ({ token }) => {
  return await apiCall({
    path: `/email/unsubscribe/`,
    method: 'post',
    queryStringParameters: {
      token: token,
    },
  });
};
