import { createAction } from 'redux-actions';

import { clearDebouncedRefreshToken } from 'core/api/API';
import {
  firstSigninResetPassword as firstSigninResetPasswordAPI,
  fsMapModeLogout,
  getForgottenUsername as getForgottenUsernameAPI,
  getPasswordResetCode as getPasswordResetCodeAPI,
  login,
  loginWithMfa as loginWithMfaAPI,
  logout,
  resetForgottenPassword as resetForgottenPasswordAPI,
} from 'core/api/login';
import {
  decorateUserObject,
  getUserAPI,
  setTwoFactorAPI,
  updateUserPasswordAPI,
  verifyMfaAuthCodeAPI,
} from 'core/api/users';
import {
  debouncedFetchOrganizations,
  fetchOrganizations,
  getAssociatedFSEData,
} from 'core/redux/organizations/actions';
import { addNotification, clearLoginMessage, updateLoginMessage } from 'core/redux/ui/actions';
import { mfaTypes, ORGANIZATION_ADMIN, userRoleOptions } from 'shared/constants/users';
import {
  clearAuthStore,
  getAccessToken,
  getFilteredOrgIdInLocal,
  getUsername,
  loginIsFresh,
  setFilteredOrgIdInLocal,
  setLastMapLocationInLocal,
  updateAuthStore,
} from 'shared/utilities/localStore';
import { createNotification, LEVELS } from 'shared/utilities/notification';

export const submitUserRegistration = createAction('SUBMIT_USER_REGISTRATION');
export const completeUserRegistration = createAction('COMPLETE_USER_REGISTRATION', user => user);
export const requestLogin = createAction('REQUEST_LOGIN');
export const completeLogin = createAction('COMPLETE_LOGIN');
export const rejectLogin = createAction('REJECT_LOGIN');
export const requestLoggedInStatus = createAction('REQUEST_LOGGED_IN_STATUS');
export const receiveLoggedInStatus = createAction('RECEIVE_LOGGED_IN_STATUS');
export const rejectLoggedInStatus = createAction('REJECT_LOGGED_IN_STATUS');
export const requestFirstSigninResetPassword = createAction('REQUEST_FIRST_SIGNIN_RESET_PASSWORD');
export const successFirstSigninResetPassword = createAction('SUCCESS_FIRST_SIGNIN_RESET_PASSWORD');
export const rejectFirstSigninResetPassword = createAction('REJECT_FIRST_SIGNIN_RESET_PASSWORD');
export const requestResetForgottenPassword = createAction('REQUEST_FORGOT_RESET_PASSWORD');
export const successResetForgottenPassword = createAction('SUCCESS_FORGOT_RESET_PASSWORD');
export const rejectResetForgottenPassword = createAction('REJECT_FORGOT_RESET_PASSWORD');
export const requestGetForgottenUsername = createAction('REQUEST_FORGOT_USERNAME');
export const successGetForgottenUsername = createAction('SUCCESS_FORGOT_USERNAME');
export const rejectGetForgottenUsername = createAction('REJECT_FORGOT_USERNAME');
export const requestGetPasswordResetCode = createAction('REQUEST_GET_RESET_CODE');
export const successGetPasswordResetCode = createAction('SUCCESS_GET_RESET_CODE');
export const rejectGetPasswordResetCode = createAction('REJECT_GET_RESET_CODE');
export const requestLogout = createAction('REQUEST_LOGOUT');
export const successLogout = createAction('SUCCESS_LOGOUT');
export const timedOut = createAction('TIMED_OUT');
export const resetEmail = createAction('RESET_EMAIL');
export const rejectLogout = createAction('REJECT_LOGOUT');
export const setOrgId = createAction('SET_ORG_ID');
export const beginLoadOrganizationData = createAction('BEGIN_LOAD_ORG_DATA');
export const endLoadOrganizationData = createAction('END_LOAD_ORG_DATA');
export const receiveUser = createAction('RECEIVE_USER');
export const requestUser = createAction('REQUEST_USER');
export const rejectUser = createAction('REJECT_USER');
export const requestUpdateUserPassword = createAction('REQUEST_USER_PASSWORD');
export const rejectUpdateUserPassword = createAction('REJEST_USER_PASSWORD');
export const successUpdateUserPassword = createAction('SUCCESS_USER_PASSWORD');

export const requestSetSelectedMfaMethod = createAction('REQUEST_SET_SELECTED_MFA');
export const rejectSetSelectedMfa = createAction('REJECT_SET_SELECTED_MFA');
export const successSetSelectedMfa = createAction('SUCCESS_SET_SELECTED_MFA');

export const receiveVerifyTwoFactorMethodAttempt = createAction('RECEIVE_VERIFY_TWO_FACTOR');
export const requestVerifyTwoFactorMethodAttempt = createAction('REQUEST_VERIFY_TWO_FACTOR');
export const rejectVerifyTwoFactorMethod = createAction('REJECT_VERIFY_TWO_FACTOR');
export const successVerifyTwoFactorMethod = createAction('SUCCESS_VERIFY_TWO_FACTOR');

export const requestSelectMfaMethodRedirect = createAction('REQUEST_SELECT_MFA_REDIRECT');

export const successVerifyMfaAuthCode = createAction('successVerifyMfaAuthCode');

export const setCurrentOrganization = ({ organizationId, userRole, username }) => {
  return async (dispatch, getState) => {
    try {
      //await dispatch(beginLoadOrganizationData());
      setFilteredOrgIdInLocal(organizationId);
      await dispatch(setOrgId({ organizationId })); // sets filtered org
      await dispatch(fetchOrganizations());
      await dispatch(getAssociatedFSEData({ organizationId, username }));
      await dispatch(endLoadOrganizationData());
    } catch (e) {
      console.log('error setting org id:', e);
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Setting Org ID', e),
        }),
      );
    }
  };
};

// called on refresh and 401
export const getLoggedInStatus = () => {
  return async dispatch => {
    dispatch(requestLoggedInStatus());

    try {
      const isLoggedIn = loginIsFresh();
      dispatch(receiveLoggedInStatus(isLoggedIn));

      if (isLoggedIn) {
        const username = getUsername();
        const { response: userResponse } = await getUserAPI({ username });
        const decoratedUserObject = decorateUserObject({ user: userResponse.message });

        let orgId = getFilteredOrgIdInLocal();
        if (!orgId) {
          await dispatch(
            setCurrentOrganization({
              organizationId: decoratedUserObject.organizationId,
              userRole: userResponse.message.user_role,
              username: username,
            }),
          );
        } else {
          await dispatch(
            setCurrentOrganization({
              organizationId: orgId,
              userRole: userResponse.message.user_role,
              username: username,
            }),
          );
        }
        dispatch(completeLogin({ user: decoratedUserObject }));
        dispatch(debouncedFetchOrganizations());

        return;
      }
      throw 'Not logged in!';
    } catch (e) {
      console.log('error getting logged in status:', e);
      dispatch(rejectLoggedInStatus(e));
    }
  };
};

// basic login
export const submitLogin = ({ username, password }) => {
  return async dispatch => {
    dispatch(requestLogin());
    try {
      const { response } = await login({ username, password });

      if (response.message) {
        // check for 2fa challenge
        if (Object.values(mfaTypes).find(mfaType => response.message.ChallengeName === mfaType)) {
          console.log(response.message.ChallengeParameters.USER_ID_FOR_SRP);
          dispatch(
            receiveVerifyTwoFactorMethodAttempt({
              session: response.message.Session,
              username: response.message.ChallengeParameters.USER_ID_FOR_SRP,
              mfaType: response.message.ChallengeName,
              mfaDestination: response.message.ChallengeParameters.CODE_DELIVERY_DESTINATION,
            }),
          );
        }
        if (response.message.AuthenticationResult) {
          if (response.message.NeedsMFASetup) {
            dispatch(
              requestSelectMfaMethodRedirect({ needsMfaSetup: response.message.NeedsMFASetup }),
            );
          }

          const { AuthenticationResult } = response.message;
          updateAuthStore({ ...AuthenticationResult });
          setLastMapLocationInLocal(null);
          const { response: userResponse } = await getUserAPI({ username });
          const decoratedUserObject = decorateUserObject({ user: userResponse.message });

          dispatch(
            setCurrentOrganization({
              organizationId: decoratedUserObject.organizationId,
              userRole: userResponse.message.user_role,
              username: userResponse.message.user_name,
            }),
          );
          dispatch(completeLogin({ user: decoratedUserObject }));
          dispatch(clearLoginMessage());
          dispatch(debouncedFetchOrganizations());
        }
        return true;
      }
    } catch (e) {
      dispatch(rejectLogin(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Logging In', e),
        }),
      );
      return false;
    }
  };
};

// login with MFA
export const loginWithMfa = ({ username, session, mfaType, userMfaCode }) => {
  return async dispatch => {
    dispatch(requestVerifyTwoFactorMethodAttempt());
    try {
      const { response } = await loginWithMfaAPI({ username, session, mfaType, userMfaCode });
      const { AuthenticationResult } = response.message;

      dispatch(successVerifyTwoFactorMethod());

      if (AuthenticationResult) {
        updateAuthStore({ ...AuthenticationResult });
        setLastMapLocationInLocal(null);
        const { response: userResponse } = await getUserAPI({ username });
        const decoratedUserObject = decorateUserObject({ user: userResponse.message });

        dispatch(
          setCurrentOrganization({
            organizationId: decoratedUserObject.organizationId,
            userRole: userResponse.message.user_role,
            username: userResponse.message.user_name,
          }),
        );
        dispatch(completeLogin({ user: decoratedUserObject }));
        dispatch(clearLoginMessage());
        dispatch(debouncedFetchOrganizations());
      }

      return response;
    } catch (e) {
      dispatch(rejectGetPasswordResetCode());
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            'Invalid Two-factor Authentication Code',
            e,
          ),
        }),
      );
    }
  };
};

export const setTwoFactorMethod = ({ twoFactorMethod }) => {
  return async dispatch => {
    const accessToken = getAccessToken();
    dispatch(requestSetSelectedMfaMethod());
    try {
      const { response } = await setTwoFactorAPI(accessToken, twoFactorMethod);
      if (response && response.message) {
        dispatch(
          successSetSelectedMfa({
            mfaType: twoFactorMethod,
            authenticationAppSecretCode: response.SecretCode,
            smsVerificationCode: response.verifyCode,
          }),
        );
      }
      return response;
    } catch (e) {
      console.log('error setting two-factor authentication method:', e);
      dispatch(rejectSetSelectedMfa(e));
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            'Error Setting Two-Factor Authentication Method',
            e,
          ),
        }),
      );
    }
  };
};

export const verifySoftwareTokenMfa = ({ userMfaCode }) => {
  return async dispatch => {
    const accessToken = getAccessToken();
    try {
      const { response } = await verifyMfaAuthCodeAPI(accessToken, userMfaCode);

      if (response && response.message) {
        dispatch(successVerifyMfaAuthCode());
        dispatch(
          addNotification({
            notification: createNotification(
              LEVELS.SUCCESS,
              '2 Factor Verification Method',
              'Your preferred 2 Factor Verification Method has been successfully set and paired with your Authentication App.',
            ),
          }),
        );

        const username = getUsername();
        const { response: userResponse } = await getUserAPI({ username });
        const decoratedUserObject = decorateUserObject({ user: userResponse.message });

        dispatch(
          setCurrentOrganization({
            organizationId: decoratedUserObject.organizationId,
            userRole: userResponse.message.user_role,
            username: userResponse.message.user_name,
          }),
        );
        dispatch(completeLogin({ user: decoratedUserObject }));
      }

      return true;
    } catch (e) {
      dispatch(rejectLogin(e));
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            'Error pairing 2fa with your Authentication App. Please try again or contact your administrator if you cannot login.',
            e,
          ),
        }),
      );
      return false;
    }
  };
};

export const verifySmsMfa = ({ smsVerificationCode, userMfaCode, bypass }) => {
  return async (dispatch, getState) => {
    if (bypass || (userMfaCode && smsVerificationCode == userMfaCode)) {
      dispatch(successVerifyMfaAuthCode());
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.SUCCESS,
            '2 Factor Verification Method',
            'Your preferred 2 Factor Verification Method has been successfully paired with your phone.',
          ),
        }),
      );

      if (getState().user.user.userRole === userRoleOptions[ORGANIZATION_ADMIN].value)
        // dispatch(fetchFirmwares());

        return true;
    } else {
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.ERROR,
            'Error pairing 2fa with your phone. Please try again, or contact your administrator if you cannot login.',
          ),
        }),
      );
    }
  };
};

export const getPasswordResetCode = ({ username }) => {
  return async dispatch => {
    dispatch(requestGetPasswordResetCode());

    try {
      const { response } = await getPasswordResetCodeAPI({ username });
      dispatch(successGetPasswordResetCode());

      if (response && response.message) {
        dispatch(updateLoginMessage({ message: response.message }));
      }

      return { response };
    } catch (e) {
      console.log('error getting password reset code:', e);
      dispatch(rejectGetPasswordResetCode());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Getting Password Reset Code', e),
        }),
      );
    }
  };
};

export const getForgottenUsername = ({ email }) => {
  return async dispatch => {
    dispatch(requestGetForgottenUsername());

    try {
      const { response } = await getForgottenUsernameAPI({ email });
      dispatch(successGetForgottenUsername());

      const message = 'An email with a list of your usernames has been sent to your email address.';
      dispatch(updateLoginMessage({ message }));
      return { response };
    } catch (e) {
      console.log('error requesting forgotten username:', e);
      dispatch(rejectGetForgottenUsername());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Requesting Forgotten Username', e),
        }),
      );
    }
  };
};

export const firstSigninResetPassword = ({ session, username, password }) => {
  return async dispatch => {
    dispatch(requestFirstSigninResetPassword());

    try {
      const { response } = await firstSigninResetPasswordAPI({
        session,
        username,
        password,
      });
      dispatch(successFirstSigninResetPassword());
      dispatch(requestSetSelectedMfaMethod());
      dispatch(clearLoginMessage());
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.SUCCESS,
            'Password Change',
            'Your password has been successfully changed.',
          ),
        }),
      );

      if (response.message.AuthenticationResult) {
        const { AuthenticationResult } = response.message;
        updateAuthStore({
          ...AuthenticationResult,
        });
        const {
          response: { message: user },
        } = await getUserAPI({ username });
        dispatch(completeLogin({ user: decorateUserObject({ user }) }));
        dispatch(fetchOrganizations());
      }

      return response;
    } catch (e) {
      console.log('error resetting password:', e);
      dispatch(rejectFirstSigninResetPassword());
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Resetting Password', e),
        }),
      );
    }
  };
};

export const resetForgottenPassword = ({ username, resetToken, newPassword }) => {
  return async dispatch => {
    dispatch(requestResetForgottenPassword());

    try {
      const { response } = await resetForgottenPasswordAPI({
        resetToken,
        newPassword,
      });

      if (response.message) {
        dispatch(successResetForgottenPassword());
        dispatch(clearLoginMessage());
        dispatch(
          addNotification({
            notification: createNotification(
              LEVELS.SUCCESS,
              'Password Reset',
              'Your password has been successfully changed.',
            ),
          }),
        );

        return { response };
      }
    } catch (e) {
      dispatch(rejectResetForgottenPassword(e));
      if (e == 'The link has expired.') {
        dispatch(await getPasswordResetCode({ username }));
      }
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Resetting Password', e),
        }),
      );
      return false;
    }
  };
};

export const submitLogout = ({ isTimedOut, isEmailChange }) => {
  return async (dispatch, getState) => {
    if (isTimedOut) {
      clearAuthStore();
      await dispatch(successLogout());
      await dispatch(timedOut());
    } else if (isEmailChange) {
      clearAuthStore();
      await dispatch(successLogout());
      await dispatch(resetEmail());
    } else {
      try {
        // request logout action
        dispatch(requestLogout());

        // get current token
        const accessToken = getAccessToken();

        // if there is a token
        if (accessToken) {
          // clear token
          clearAuthStore();

          const fullscreenMapMode = getState().ui.fullscreenMapMode;

          // ask to revoke token on server
          let logoutResponse = null;
          if (fullscreenMapMode) {
            const { response } = await fsMapModeLogout({ accessToken });
            logoutResponse = response;
          } else {
            const { response } = await logout({ accessToken });
            logoutResponse = response;
          }
          // send logout action
          dispatch(successLogout());

          return logoutResponse;
        } else {
          // if no access token present, just logout
          dispatch(successLogout());
        }
      } catch (e) {
        console.log('error logging out:', e);
        dispatch(rejectLogout(e));
        dispatch(
          addNotification({
            notification: createNotification(LEVELS.ERROR, 'Error Logging Out', e),
          }),
        );

        dispatch(successLogout());
      }
    }
  };
};

export const updateUserPassword = (oldPassword, newPassword) => {
  return async dispatch => {
    dispatch(requestUpdateUserPassword());
    try {
      const { response } = await updateUserPasswordAPI(oldPassword, newPassword);
      if (response && response.message) {
        clearDebouncedRefreshToken();
        dispatch(successUpdateUserPassword());
      }
      dispatch(
        addNotification({
          notification: createNotification(
            LEVELS.SUCCESS,
            'Password Reset',
            'Your password has been successfully changed.',
          ),
        }),
      );
      clearAuthStore();
      await dispatch(successLogout());
      return true;
    } catch (e) {
      console.log('error changing password:', e);
      dispatch(rejectUpdateUserPassword(e));
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error Changing Password', e),
        }),
      );
    }
  };
};
