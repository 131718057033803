import { getLastIn, getObdDisplayValue } from 'app/features/maintenance/utilities/formatting';
import { displayVehicleIcon } from 'features/fleet/vehicles/helpers/utilities';
import { ActionButtonText, TextButton } from 'shared/styles/components/Button';
import {
  AddButtonWrapper,
  AddedText,
  CheckIconWrapper,
  UnsupportedTextCardWrapper,
} from 'shared/styles/components/Maintenance';
import {
  CardCollapsedInfo,
  CardContainer,
  CardIconWrapper,
  CardLabel,
  CardName,
  CardOuterWrapper,
  CardRow,
  CardType,
  CardValue,
  VehicleIcon,
} from 'shared/styles/components/MobileCard';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

export default function ScheduleMaintenanceVehicleCard({
  vehicle,
  isSelected,
  selectedVehicles,
  setSelectedVehicles,
  selectableVehicles,
}) {
  return (
    <CardOuterWrapper>
      <CardContainer otaLogs>
        <AddButtonWrapper>
          {selectableVehicles.map((v) => v.vehicleId)?.includes(vehicle.vehicleId) ? (
            <>
              {isSelected ? (
                <CheckIconWrapper>
                  <IconSvgComponent
                    svgFileName={'check-success'}
                    title="All updates for this vehicle have completed"
                  />
                  <AddedText>Added</AddedText>
                </CheckIconWrapper>
              ) : (
                <TextButton
                  type="button"
                  onClick={() =>
                    selectedVehicles.map((sv) => sv.vehicleId)?.includes(vehicle.vehicleId)
                      ? setSelectedVehicles({
                          selectedVehicles: [
                            ...selectedVehicles.filter((sv) => sv.vehicleId !== vehicle.vehicleId),
                          ],
                        })
                      : setSelectedVehicles({ selectedVehicles: [...selectedVehicles, vehicle] })
                  }
                >
                  <ActionButtonText>Add Vehicle</ActionButtonText>
                </TextButton>
              )}
            </>
          ) : (
            <UnsupportedTextCardWrapper title="This vehicle does not support the selected interval type">
              Unsupported
            </UnsupportedTextCardWrapper>
          )}
        </AddButtonWrapper>
        <CardIconWrapper>
          <VehicleIcon svgFileName={displayVehicleIcon(vehicle.meta.vehicle_type)} alt="Vehicle" />
          <CardCollapsedInfo>
            <CardRow header>
              <CardName title={vehicle.meta.label}>{vehicle.meta.label}</CardName>
            </CardRow>
            <CardRow header>
              <CardType>Last in: {getLastIn(vehicle.obd.last_in)}</CardType>
            </CardRow>
          </CardCollapsedInfo>
        </CardIconWrapper>
        <CardRow potentialLongValue title={vehicle.obd.odometerDisplay}>
          <CardLabel>Odometer:</CardLabel>
          <CardValue>{getObdDisplayValue(vehicle.obd.odometer)}</CardValue>
        </CardRow>
        <CardRow>
          <CardLabel>Engine Hours:</CardLabel>
          <CardValue>{getObdDisplayValue(vehicle.obd.engine_hours)}</CardValue>
        </CardRow>
      </CardContainer>
    </CardOuterWrapper>
  );
}
