import React, { Fragment, useRef, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Downshift, { useCombobox } from 'downshift';

import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

import DropdownMenu, * as D from 'shared/styles/components/DropdownMenu';
import {
  CurrentSelectionContainer,
  CurrentSelectionDisplay,
  SearchResultsList,
} from 'shared/styles/components/DropdownMenu';
import colors from 'shared/constants/colors';
import { TruncatedText, TruncatedTextWithWrap } from 'shared/styles/components/TruncatedText';

import { ClearFilterIcon } from 'shared/styles/components/Icons';
import { FormLabelAnimated, FormAsterisk } from 'shared/styles/components/Form';
import { createSearchResultItemName } from 'shared/utilities/search';

// TODO: refactor
// problems:
// an if check is required because the dropdown selection is triggered twice when an item is selected
const FilteredDropdown = ({
  dropdownOptions,
  title,
  newItemLabel,
  placeholder,
  onItemSelect,
  defaultSelectedItem,
  disabled,
  restrictedHeightResults,
  narrowWidth,
  hideClearX,
  closeOnSelect,
  handleCreateNewItem,
  showCreateItem,
  required,
}) => {
  // Focus on search input when opened
  const searchInput = useRef(null);

  const [dropdownItems, setDropdownItems] = useState(dropdownOptions);
  const [userInputValue, setUserInputValue] = useState('');
  const [selectedItem, setSelectedItem] = useState(placeholder);

  const {
    isOpen,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    getInputProps,
    getComboboxProps,
    highlightedIndex,
    getItemProps,
  } = useCombobox(
    {
      items: dropdownItems,
      selectedItem,
      inputValue: userInputValue,
      onSelectedItemChange: ({ selectedItem }) => {
        setSelectedItem(selectedItem);
        setDropdownItems(dropdownOptions);
        onItemSelect(selectedItem);
        if (closeOnSelect) {
          resetSelectionFilter();
        }
      },
      onInputValueChange: ({ inputValue }) => {
        if (inputValue !== placeholder) setUserInputValue(inputValue);

        setDropdownItems(
          dropdownOptions.filter(item => item.toLowerCase().includes(inputValue.toLowerCase())),
        );
      },
    },
    [dropdownItems],
  );

  const resetSelectionFilter = () => {
    setSelectedItem(defaultSelectedItem || placeholder);
    setUserInputValue('');
    setDropdownItems(dropdownOptions);
    onItemSelect(null);
  };

  const isFiltered = () => {
    return defaultSelectedItem || (selectedItem && selectedItem !== placeholder);
  };
  useEffect(() => {
    setSelectedItem(defaultSelectedItem || placeholder);
  }, [defaultSelectedItem]);

  useEffect(() => {
    setSelectedItem(placeholder);
  }, [placeholder]);

  useEffect(() => {
    if (searchInput.current) {
      searchInput.current.focus();
    }
  }, [isOpen]);

  return (
    <div>
      <FormLabelAnimated animated {...getLabelProps()}>
        {title}
        {required && <FormAsterisk>*</FormAsterisk>}
      </FormLabelAnimated>
      <DropdownMenu active={isOpen} {...getComboboxProps()} $truncateText disabled={disabled}>
        {/*  */}
        <CurrentSelectionContainer
          filterDropdownContainer
          narrowWidth={narrowWidth}
          {...getToggleButtonProps({
            onClick: e => {
              setUserInputValue('');
              setDropdownItems(dropdownOptions);
              e.preventDefault();
            },
          })}
        >
          <CurrentSelectionDisplay>
            <TruncatedText title={selectedItem}>{selectedItem}</TruncatedText>
          </CurrentSelectionDisplay>
          <>
            {isFiltered() && !hideClearX && (
              <ClearFilterIcon
                onClick={e => {
                  resetSelectionFilter();
                  e.preventDefault();
                }}
                svgFileName={'red-x'}
                title="Clear selection"
                alt="Clear selection"
              />
            )}
            <IconSvgComponent
              svgFileName={'dropdown-caret'}
              svgStyle={styles.searchToggleButton}
              title="Change selection"
              alt="Change selection"
            />
          </>
        </CurrentSelectionContainer>
        {/*  */}
        <D.DropdownMenuContent filterdropdown="true">
          <D.DropdownMenuBody style={styles.filterDropdownBody}>
            <IconSvgComponent svgStyle={styles.searchIcon} svgFileName={'search'} alt="Search" />
            <div style={styles.searchContainer}>
              <input
                {...getInputProps({
                  style: styles.searchInput,
                  placeholder: 'Search',
                  value: userInputValue,
                  ref: searchInput,
                })}
              />
              <SearchResultsList
                $restrictedHeightResults={restrictedHeightResults}
                {...getMenuProps({})}
              >
                {isOpen && (
                  <>
                    {dropdownItems.map((item, index) => (
                      <>
                        <li
                          {...getItemProps({
                            key: `${item}${index}`,
                            index,
                            item,
                            style: styles.searchResultItem(index, highlightedIndex),
                          })}
                        >
                          <TruncatedTextWithWrap title={item}>
                            {createSearchResultItemName(userInputValue, item)}
                          </TruncatedTextWithWrap>
                        </li>
                      </>
                    ))}
                    {userInputValue?.length > 0 && dropdownItems?.length === 0 && showCreateItem ? (
                      <div
                        style={styles.createNewLabel}
                        onClick={() => {
                          handleCreateNewItem(userInputValue);
                          resetSelectionFilter();
                        }}
                      >
                        {userInputValue} ({newItemLabel})
                      </div>
                    ) : null}
                  </>
                )}
              </SearchResultsList>
            </div>
          </D.DropdownMenuBody>
        </D.DropdownMenuContent>
      </DropdownMenu>
    </div>
  );
};

export default FilteredDropdown;

const styles = {
  searchToggleButton: {
    cursor: 'pointer',
  },
  searchIcon: {
    height: '22px',
    width: '22px',
    marginRight: '10px',
  },
  filterDropdownBody: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    padding: '5px 15px 5px 15px',
    width: '300px',
    height: '70px',
  },
  searchContainer: {
    height: '100%',
    width: '100%',
  },
  searchInput: {
    border: 'none',
    height: '100%',
    width: '100%',
    color: `${colors.midnight}`,
    fontSize: '1.1rem',
  },
  searchResultItem: (index, highlightedIndex) => ({
    listStyleType: 'none',
    color: '#8a909c',
    fontSize: '1.1rem',
    backgroundColor: 'white',
    fontWeight: 'normal',
    borderTop: index === 0 ? '1px solid #EEEFF0' : 'none',
    borderBottom: '1px solid #EEEFF0',
    padding: '18px 10px 18px 20px',
    backgroundColor: highlightedIndex === index ? 'lightgray' : 'white',
  }),
  createNewLabel: {
    listStyleType: 'none',
    color: '#8a909c',
    fontSize: '1.1rem',
    backgroundColor: 'white',
    fontWeight: 'normal',
    borderTop: '1px solid #EEEFF0',
    borderBottom: '1px solid #EEEFF0',
    padding: '18px 10px 18px 20px',
    backgroundColor: 'lightgray', //: 'white',
  },
};
