import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';

import { getVehicleVINInformation as getVehicleVINInformationAPI } from 'core/api/vehicles';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { addNotification } from 'core/redux/ui/actions';
import VinPrefillConfirmationModal from 'features/fleet/vehicles/components/modals/VinPrefillConfirmationModal';
import { VehicleEditVinLookupButton } from 'shared/styles/components/Button';
import { VehicleEditSpinnerContainer } from 'shared/styles/components/Icons';
import { Spinner } from 'shared/styles/components/Spinner';
import AnimatedField from 'shared/ui/fields/AnimatedField';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { createNotification } from 'shared/utilities/notification';
import { LEVELS } from 'shared/utilities/notification';

const VehicleFormVinField = ({
  formikProps: { values, errors, touched, handleChange, handleBlur },
  setVinPrefills,
  dispatchNotification,
}) => {
  const [fetchingVINLookup, setFetchingVINLookup] = useState(false);
  const [vinPrefillConfirmationModalActive, setVinPrefillConfirmationModalActive] = useState(false);
  const [vinFillValues, setVinFillValues] = useState(null);

  const vinRef = useRef();
  // API
  const getVehicleVINInformation = async vin => {
    try {
      const { response } = await getVehicleVINInformationAPI(vin);
      if (response.message) {
        return response.message;
      }
    } catch (err) {
      dispatchNotification.vinFetchError({ err });
    }
  };

  const requestVINLookup = async VIN => {
    setFetchingVINLookup(true);

    try {
      const vehicleInfo = await getVehicleVINInformation(VIN);

      if (!vehicleInfo?.Results) throw 'No vehicle info found!';

      setVinFillValues({
        make: vehicleInfo.Results[0].Make,
        model: vehicleInfo.Results[0].Model,
        year: vehicleInfo.Results[0].ModelYear,
      });
      setFetchingVINLookup(false);
      setVinPrefillConfirmationModalActive(true);
    } catch (err) {
      logWcpError(err);
      setFetchingVINLookup(false);
    }
  };

  const clearVinField = () => {
    setVinFillValues(null);
    setVinPrefillConfirmationModalActive(false);
  };

  const vinFillConfirmHandler = () => {
    setVinPrefills(vinFillValues.make, vinFillValues.model, vinFillValues.year);
    clearVinField();
  };

  return (
    <>
      {vinPrefillConfirmationModalActive ? (
        <VinPrefillConfirmationModal
          vinFillConfirmHandler={vinFillConfirmHandler}
          clearVinField={clearVinField}
          vinFillValues={vinFillValues}
        />
      ) : null}
      <div style={{ display: 'flex' }}>
        <div style={{ flexGrow: '4' }}>
          <AnimatedField
            name="vin"
            placeholder="VIN"
            value={values.vin}
            touched={touched.vin}
            validationError={errors.vin}
            handleChange={handleChange}
            handleBlur={handleBlur}
            required
            suffixIcon={true}
            innerRef={vinRef}
          />
        </div>
        <div>
          {fetchingVINLookup ? (
            <VehicleEditSpinnerContainer>
              <Spinner />
            </VehicleEditSpinnerContainer>
          ) : (
            touched.vin &&
            (!!values.vin && !errors.vin ? (
              <VehicleEditVinLookupButton
                type="button"
                onClick={async () => await requestVINLookup(values.vin)}
                title="Lookup VIN information"
                alt="Lookup VIN information"
              >
                <IconSvgComponent svgFileName={'vin-lookup'} />
              </VehicleEditVinLookupButton>
            ) : (
              <span />
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default connect(null, dispatch => ({
  dispatchNotification: {
    vinFetchError: ({ err }) =>
      dispatch(
        addNotification({
          notification: createNotification(LEVELS.ERROR, 'Error getting VIN information', err),
        }),
      ),
  },
}))(VehicleFormVinField);
