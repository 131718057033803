import useMakeFormikField from 'app/shared/components/controls/WcpForm/hooks/useMakeFormikField';
import FilteredDropdown from 'shared/ui/dropdowns/FilteredDropdown';
import { DropdownWrapper } from 'shared/styles/components/ManageAlerts';
import useAlertFormsProviderContext from 'app/features/alerts/services/providers/AlertFormsProvider/hooks/useAlertFormsProviderContext';

const VehicleDropdown = () => {
  const {
    state: {
      alert: {
        alertDetails: {
          vehicleInfo: { vehicleId },
        },
      },
      vehicles,
    },
    actions: { updateAlertDetailsAction },
  } = useAlertFormsProviderContext();

  useMakeFormikField({ fieldName: 'vehicleId', fieldValue: vehicleId });
  const defaultSelectedVehicle = vehicles?.find(v => v.vehicle_id === vehicleId)?.meta.label;
  return (
    vehicles && (
      <DropdownWrapper>
        <FilteredDropdown
          restrictedHeightResults
          dropdownOptions={vehicles?.map(v => v.meta.label)}
          defaultSelectedItem={defaultSelectedVehicle}
          title={'Vehicle'}
          placeholder={defaultSelectedVehicle || 'All Vehicles'}
          onItemSelect={selected => {
            if (selected) {
              const v = vehicles?.find(v => v.meta.label === selected);
              updateAlertDetailsAction({ vehicleId: v.vehicle_id });
            } else {
              updateAlertDetailsAction({ vehicleId: null });
            }
          }}
        />
      </DropdownWrapper>
    )
  );
};

export default VehicleDropdown;
