import { loginIsFresh } from 'shared/utilities/localStore';
import { submitLogout } from 'core/redux/user/actions';

export const checkLogin = () => {
  if (loginIsFresh()) {
    return true;
  } else {
    submitLogout({ isTimedOut: true });
    return false;
  }
};
