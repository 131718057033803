import styled from 'styled-components';
import device from 'shared/constants/sizing';

export const RecipientItemBlockDiv = styled.div`
  display: grid;
  grid-template-rows: 40px max-content max-content;
  grid-template-columns: max-content;
  grid-template-areas:
    'FormCol'
    'FormCol'
    'FormCol';
  button:nth-child(3) {
    margin-bottom: 20px;
  }

  @media ${device.largeAnalytics} {
    grid-template-areas: 'FormCol FormCol FormCol';
    grid-template-columns: 200px max-content max-content;
    grid-template-rows: 40px;
    grid-gap: 20px;
    div:nth-child(3) {
      margin-left: -5px;
    }
    margin-top: 20px;
    margin-bottom: 0px;
  }
`;

export const RecipientRemoveButtonWrapper = styled.div`
  margin-top: 30px;
  margin-left: 0px;

  ${props =>
    props.group &&
    `
    margin-top: 27px;
  `}
`;

export const RecipientWrapper = styled.div`
  margin-top: 0px;
`;

export const RecipientsFormWrapper = styled.div`
  margin-top: 25px;
`;
