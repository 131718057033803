import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { getGroups } from 'core/api/groups';
import { addNotification } from 'core/redux/ui/actions';
import { variableScopeAllowedUserRoles } from 'shared/constants/users';
import { RemoveButton } from 'shared/styles/components/Button';
import {
  AddGroupContainerDiv,
  AssociatedGroupsDiv,
  FormSectionLabel,
  CurrentlyAssociatedGroupsLabel,
} from 'shared/styles/components/Form';
import { GroupTagDiv } from 'shared/styles/components/GroupTags';
import Combobox from 'shared/ui/dropdowns/Combobox';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import { createNotification, LEVELS } from 'shared/utilities/notification';
import { useFetchGroupsQuery } from 'features/fleet/groups/hooks/useFetchGroupsQuery';
import { sortAscendingAlpha } from 'shared/utilities/general';
import { getAssociatedGroups } from 'shared/utilities/vehicle';

const VehicleAssociatedGroups = ({ vehicle, updateVehicleAssociatedGroups, handleIsDirty }) => {
  const [fetchGroupsQuery, groups] = useFetchGroupsQuery();
  const [persistedAssociatedGroups, setPersistedAssociatedGroups] = useState([]);
  const [associatedGroups, setAssociatedGroups] = useState([]);

  useEffect(() => {
    updateVehicleAssociatedGroups(associatedGroups.map(ag => ag.name));

    handleIsDirty(!_.isEqual(persistedAssociatedGroups, associatedGroups));
  }, [associatedGroups]);

  useEffect(() => {
    if (vehicle && groups) {
      let ag = [
        ...getAssociatedGroups({ vehicle, groups }).map(g => ({
          name: g.group_name,
          isNew: false,
        })),
      ];
      setPersistedAssociatedGroups(ag);
      setAssociatedGroups(ag);
    }
  }, [groups]);

  const attachGroup = groupName => {
    setAssociatedGroups([...associatedGroups, { name: groupName, isNew: false }]);
  };

  const detachGroup = groupName => {
    setAssociatedGroups(associatedGroups.filter(ag => ag.name !== groupName));
  };

  return (
    <>
      <FormSectionLabel>Associated Groups</FormSectionLabel>
      <AssociatedGroupsDiv>
        <AddGroupContainerDiv>
          <Combobox
            restrictedHeightResults
            dropdownOptions={
              fetchGroupsQuery.isFetching
                ? []
                : groups
                    ?.sort((a1, a2) => sortAscendingAlpha(a1.group_name, a2.group_name))
                    .map(g => g.group_name)
            }
            restrictedOptions={
              fetchGroupsQuery.isFetching ? [] : associatedGroups?.map(ag => ag.name)
            }
            handleItemSelect={groupName => groupName && attachGroup(groupName)}
            retainSelection={false}
            labels={{
              fieldTitleText: 'Add to Group',
              handleCreateNewItemText: 'New Group',
              dropdownPlaceholder: 'Search for a group...',
              searchInputPlaceholder: 'Search',
            }}
            showCreateItem={true}
            handleCreateNewItem={groupName => {
              if (!associatedGroups.some(ag => ag.name === groupName))
                setAssociatedGroups([...associatedGroups, { name: groupName, isNew: true }]);
            }}
          />
        </AddGroupContainerDiv>
        <CurrentlyAssociatedGroupsLabel>Currently Associated Groups</CurrentlyAssociatedGroupsLabel>

        <div>
          {associatedGroups &&
            associatedGroups.map(({ name, isNew }) => (
              <GroupTagDiv key={name} isNew={isNew}>
                {name}
                <RemoveButton type="button">
                  <IconSvgComponent
                    svgFileName={'remove-x'}
                    alt="Remove"
                    onClick={() => detachGroup(name)}
                  />
                </RemoveButton>
              </GroupTagDiv>
            ))}
        </div>
      </AssociatedGroupsDiv>
    </>
  );
};

export default VehicleAssociatedGroups;
