import { getFirmwareOtaLogs } from 'core/api/firmware';

import { firmwareOtaLogsFetchError } from 'features/ota/firmware/helpers/notifications';
import { useGenericFetchQuery } from 'shared/hooks/useGenericFetchQuery';

export const useFetchFirmwareOtaLogsQuery = initialQueryParams => {
  const { fetch, isLoading, data } = useGenericFetchQuery({
    apiFn: getFirmwareOtaLogs,
    hookName: useFetchFirmwareOtaLogsQuery.name,
    initialQueryParams,
    errorCallback: firmwareOtaLogsFetchError,
  });

  return {
    fetch,
    isLoading,
    data,
  };
};
