export const vsgInputOptions = [
  {
    label: 'Input 2',
    value: 1,
  },
  {
    label: 'Input 3',
    value: 2,
  },
  {
    label: 'Input 4',
    value: 3,
  },
  {
    label: 'Input 5',
    value: 4,
  },
  {
    label: 'Input 6',
    value: 5,
  },
  {
    label: 'Input 7',
    value: 6,
  },
  {
    label: 'Input 8',
    value: 7,
  },
];
