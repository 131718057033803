import { MapZoomControlsDiv } from 'shared/styles/components/SharedMapStyledComponents';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const MapZoomControls = ({ mapboxApi }) => {
  return (
    <MapZoomControlsDiv>
      <div onClick={() => mapboxApi.zoomIn()}>
        <IconSvgComponent svgFileName={'zoom-in'} alt="Zoom in" />
      </div>
      <div onClick={() => mapboxApi.zoomOut()}>
        <IconSvgComponent svgFileName={'zoom-out'} alt="Zoom out" />
      </div>
    </MapZoomControlsDiv>
  );
};

export default MapZoomControls;
