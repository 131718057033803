import React from 'react';
import colors from 'shared/constants/colors';

// assets
import loadingGIF from 'assets/images/loadingBlack.gif';

export default () => {
  return (
    <div
      style={{
        ...styles.overlay,
      }}
    >
      <div style={styles.container}>
        <img src={loadingGIF} style={styles.animation} />
      </div>
    </div>
  );
};

const styles = {
  overlay: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '25px',
    height: '25px',
    opacity: 0.4,
    backgroundColor: colors.fog,
    zIndex: 100,
  },
  container: {
    position: 'relative',
    display: 'inline-block',
  },
  animation: {
    position: 'relative',
  },
  logo: {
    position: 'absolute',
    width: '40%',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
};
