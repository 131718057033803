import React from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';
import { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import Media from 'react-media';
import { sizes } from 'shared/utilities/media';

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

export default ({ onDayChange, placeholder, disabled, value }) => (
  <Media
    queries={{
      tablet: { maxWidth: sizes.tablet },
      mobile: { maxWidth: sizes.mobile },
    }}
  >
    {matches =>
      matches.tablet ? (
        <DayPickerInput
          formatDate={formatDate}
          format={'MM/dd/yyyy'}
          parseDate={parseDate}
          onDayChange={onDayChange}
          value={value}
          placeholder={placeholder ? `${placeholder}` : `${new Date()}`}
          inputProps={{
            style: styles.styledDatePickerInputMobile,
            readOnly: disabled ? true : false,
          }}
        />
      ) : (
        <DayPickerInput
          hideDayPicker
          formatDate={formatDate}
          format={'MM/dd/yyyy'}
          parseDate={parseDate}
          onDayChange={onDayChange}
          value={value}
          placeholder={placeholder ? `${placeholder}` : `${new Date()}`}
          inputProps={{
            style: styles.styledDatePickerInput,
            readOnly: disabled ? true : false,
          }}
        />
      )
    }
  </Media>
);

const styles = {
  styledDatePickerInput: {
    width: '100%',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(138, 144, 156, 0.25)',
    backgroundColor: 'transparent',
    marginTop: 6,
    fontWeight: 700,
  },
  styledDatePickerInputMobile: {
    width: '100%',
    borderTop: 0,
    borderLeft: 0,
    borderRight: 0,
    borderBottom: 1,
    borderStyle: 'solid',
    borderColor: 'rgba(138, 144, 156, 0.25)',
    backgroundColor: 'transparent',
    marginTop: 6,
    fontWeight: 700,
    fontSize: 18,
    paddingBottom: 4,
  },
};
