import { getAuthToken, apiCall } from 'core/api/API';

export const getGeofencesData = async ({ organizationId }) => {
  return await apiCall({
    path: `/geofences`,
    method: 'get',
    headers: {
      Authorization: getAuthToken(),
    },
    queryStringParameters: {
      organization_id: organizationId,
    },
  });
};

export const postGeofencesData = async ({ organizationId, geofence }) => {
  geofence.label = geofence.label.trim();
  return await apiCall({
    path: `/geofences`,
    method: 'post',
    headers: {
      Authorization: getAuthToken(),
    },
    queryStringParameters: {
      organization_id: organizationId,
    },
    body: {
      ...geofence,
    },
  });
};

export const getGeofenceLogsData = async ({ organizationId, geofenceId, startDate, endDate }) => {
  return await apiCall({
    path: `/geofences/${geofenceId}/logs`,
    method: 'get',
    headers: {
      Authorization: getAuthToken(),
    },
    queryStringParameters: {
      organization_id: organizationId,
      geofence_id: geofenceId,
      start_date: startDate,
      end_date: endDate,
    },
  });
};

export const getOrgGeofenceActivityData = async ({ organizationId, startTime, endTime }) => {
  return await apiCall({
    path: `/geofences/logs`,
    method: 'get',
    headers: {
      Authorization: getAuthToken(),
    },
    queryStringParameters: {
      organization_id: organizationId,
      start_date: startTime,
      end_date: endTime,
    },
  });
};

export const putGeofencesData = async ({ geofenceId, geofence, organizationId }) => {
  geofence.label = geofence.label.trim();
  return await apiCall({
    path: `/geofences/${geofenceId}`,
    method: 'put',
    headers: {
      Authorization: getAuthToken(),
    },
    queryStringParameters: {
      organization_id: organizationId,
    },
    body: {
      ...geofence,
    },
  });
};

export const postEnableGeofence = async ({ organizationId, geofenceIds }) => {
  return await apiCall({
    path: `/geofences/enable`,
    method: 'post',
    headers: {
      Authorization: getAuthToken(),
    },
    queryStringParameters: {
      organization_id: organizationId,
    },
    body: { geofence_ids: geofenceIds },
  });
};

export const postDisableGeofence = async ({ organizationId, geofenceIds }) => {
  return await apiCall({
    path: `/geofences/disable`,
    method: 'post',
    headers: {
      Authorization: getAuthToken(),
    },
    queryStringParameters: {
      organization_id: organizationId,
    },
    body: { geofence_ids: geofenceIds },
  });
};

export const deleteGeofencesData = async ({ organizationId, geofenceId }) => {
  return await apiCall({
    path: `/geofences/${geofenceId}`,
    method: 'del',
    headers: {
      Authorization: getAuthToken(),
    },
    queryStringParameters: {
      organization_id: organizationId,
    },
  });
};

export const getIntersectionsData = async ({ organizationId }) => {
  return await apiCall({
    path: `/geofences/categories`,
    method: 'get',
    headers: {
      Authorization: getAuthToken(),
    },
    queryStringParameters: {
      organization_id: organizationId,
    },
  });
};

export const putIntersectionsData = async ({ organizationId, intersectionsData }) => {
  return await apiCall({
    path: `/geofences/categories`,
    method: 'put',
    headers: {
      Authorization: getAuthToken(),
    },
    queryStringParameters: {
      organization_id: organizationId,
    },
    body: { categories: intersectionsData },
  });
};
