import { normalPlaybackSpeed } from 'app/features/playback/data/constants/vehiclePlaybackConstants';
import { mergePayloadWithState } from 'app/shared/utilities/reducerHelpers/mergePayloadWithState';

/*
  This reducer is used to set the playback animation state.
*/
export const setPlaybackAnimationControlsReducer = (state, payload) => {
  const playbackAnimationControls = mergePayloadWithState(payload, state.playbackAnimationControls);

  let isPlaying = playbackAnimationControls.playbackToggle === true;
  let speed = normalPlaybackSpeed / playbackAnimationControls.selectedPlaybackSpeed?.value;

  playbackAnimationControls.playbackSpeed = isPlaying ? speed : null;

  // return the updated state
  return {
    ...state,
    playbackAnimationControls,
  };
};
