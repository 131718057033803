import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getConfigurationUpdates } from 'core/api/configuration';
import ConfigurationUpdateSummaryItem from 'features/ota/configurations/components/ConfigurationUpdateSummaryItem';
import { fetchConfigurationUpdatesError } from 'features/ota/configurations/helpers/notifications';
import colors from 'shared/constants/colors';
import { firmwareStatuses } from 'shared/constants/firmwares';
import LinkButton, { ViewLogsButton } from 'shared/styles/components/LinkButton';
import {
  Actions,
  IconAndTitleWrapper,
  OtaSummaryItemWrapperDiv,
  UpdatesSummaryActionButtonsDiv,
} from 'shared/styles/components/OtaUpdates';
import PageListWrapper, { PageListHead, PageListTitle } from 'shared/styles/components/PageList';
import {
  SectionBody,
  SectionHead,
  SectionStatus,
  SectionToggle,
} from 'shared/styles/components/Section';
import CollapsibleHeader from 'shared/ui/accordions/CollapsibleHeader';
import ButtonWithLoader from 'shared/ui/buttons/ButtonWithLoader';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';
import LoadingOverlay from 'shared/ui/spinners/LoadingOverlay';

const ConfigurationUpdatesSummaryPage = () => {
  // redux
  const currentOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const dispatch = useDispatch();

  // gets firmware updates
  const fetchConfigurationUpdatesQuery = useQuery({
    queryKey: ['fetchConfigurationUpdates'],
    queryFn: () => getConfigurationUpdates(currentOrganizationId),

    select: ({ response: { message: data } }) => {
      return data;
    },
    onError: err => dispatch(fetchConfigurationUpdatesError({ err })),
  });

  // react
  const [configurationUpdateData, setConfigurationUpdateData] = useState(null);

  const getProducts = configurationUpdateData => {
    if (configurationUpdateData) {
      return Object.keys(configurationUpdateData).map(productId => {
        let firstConfigId = Object.keys(configurationUpdateData[productId])[0];
        let firstUpdate = configurationUpdateData[productId][firstConfigId][0];

        return { id: firstUpdate.product_id, name: firstUpdate.product_name };
      });
    }
  };

  useEffect(() => {
    if (fetchConfigurationUpdatesQuery.data)
      setConfigurationUpdateData(fetchConfigurationUpdatesQuery.data);
  }, [fetchConfigurationUpdatesQuery.data]);

  const hasUpdates = !_.isEmpty(configurationUpdateData);

  const getUpdatesForProduct = id => {
    let updatesByConfigId = configurationUpdateData[id];

    return Object.entries(updatesByConfigId).map(([configId, updates]) => {
      let firstUpdate = updates[0];

      return (
        <OtaSummaryItemWrapperDiv key={firstUpdate.product_id}>
          <ConfigurationUpdateSummaryItem
            showTotals={true}
            key={firstUpdate.configId}
            configurationName={firstUpdate.config_name}
            productName={firstUpdate.product_name}
            description={firstUpdate.description}
            uploadedDate={firstUpdate.creation_date}
            deviceUpdates={updates}
          />
          <>
            {
              <Actions className="col">
                <LinkButton to={`/configurations/updates/${firstUpdate.product_id}/${configId}`}>
                  View Details
                </LinkButton>
              </Actions>
            }
          </>
        </OtaSummaryItemWrapperDiv>
      );
    });
  };

  const determineAllUpdatesAreComplete = id => {
    let updatesByConfigId = configurationUpdateData[id];
    let completedForProduct = Object.entries(updatesByConfigId)
      .map(([configId, deviceUpdates]) => {
        let completed = deviceUpdates.filter(
          u => u.config_status === firmwareStatuses.UP_TO_DATE.status,
        );
        return completed.length === deviceUpdates.length;
      })
      .reduce((p, c) => [...p, c], []);
    return completedForProduct.every(p => p);
  };
  return (
    <>
      {fetchConfigurationUpdatesQuery.isFetchedAfterMount ? (
        <PageListWrapper centered={!hasUpdates}>
          {' '}
          <PageListHead stackable>
            <SectionHead>
              <PageListTitle>Configuration Updates</PageListTitle>
            </SectionHead>
            <UpdatesSummaryActionButtonsDiv>
              <ViewLogsButton to={`/configurations/updates/logs`}>View Update Logs</ViewLogsButton>
              <ButtonWithLoader
                isLoading={fetchConfigurationUpdatesQuery.isFetching}
                confirmText={'Check for Updates'}
                loadingStyleProp={'submittingWithSpinner'}
                notLoadingStyleProp={'updates'}
                clickHandler={() => {
                  fetchConfigurationUpdatesQuery.refetch();
                }}
              />
            </UpdatesSummaryActionButtonsDiv>
          </PageListHead>
          <SectionBody>
            {hasUpdates ? (
              getProducts(configurationUpdateData).map(product => (
                <div key={product.id}>
                  <CollapsibleHeader
                    ToggleComponent={({ collapsed, setCollapsed }) => {
                      return (
                        <SectionStatus onClick={() => setCollapsed(!collapsed)}>
                          <IconAndTitleWrapper>
                            {determineAllUpdatesAreComplete(product.id) && collapsed && (
                              <IconSvgComponent
                                svgFileName={'check-success'}
                                title="All updates for this device have completed"
                              />
                            )}
                            {product.name} Updates
                          </IconAndTitleWrapper>
                          <SectionToggle collapsed={collapsed} />
                        </SectionStatus>
                      );
                    }}
                  >
                    {getUpdatesForProduct(product.id)}
                  </CollapsibleHeader>
                </div>
              ))
            ) : (
              <>
                <IconSvgComponent
                  svgStyle={{
                    splashIcon: {
                      width: '187px',
                      height: '187px',
                    },
                  }}
                  svgFileName={'gears-grey'}
                  alt="Configurations"
                  beforeInjection={svg => {
                    svg.setAttribute('width', 187);
                    svg.setAttribute('height', 187);
                    svg
                      .querySelectorAll('path')
                      .forEach(path => path.setAttribute('fill', colors.midnight));
                  }}
                />
                <h4>No Updates Available</h4>
              </>
            )}
          </SectionBody>
        </PageListWrapper>
      ) : (
        <LoadingOverlay />
      )}
    </>
  );
};

export default ConfigurationUpdatesSummaryPage;
