import { ProgressPercentageDiv, ProgressBarIcon } from 'shared/styles/components/OtaUpdates';
import progressBar33 from 'assets/images/progressBar33.png';
import progressBar66 from 'assets/images/progressBar66.png';
import progressBar100 from 'assets/images/progressBar100.png';

const ProgressBar = ({ progress }) => {
  const getIcon = progress => {
    switch (progress) {
      case 33:
        return progressBar33;
      case 66:
        return progressBar66;
      case 100:
        return progressBar100;
      default:
        return null;
    }
  };
  return (
    <ProgressPercentageDiv>
      <ProgressBarIcon src={getIcon(progress)} />
      {progress}%
    </ProgressPercentageDiv>
  );
};

export default ProgressBar;

//implementation examples:

/* <ProgressBar progress={66} />
<ProgressBar progress={100} />
<ProgressBar progress={33} />
<ProgressBar progress={0} /> */
