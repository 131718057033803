import React from 'react';

import { FilteredDropdownSelectorDiv } from 'app/shared/styles/FilteredDropdownStyles';
import { ClearFilterIcon } from 'shared/styles/components/Icons';
import { TruncatedText } from 'shared/styles/components/TruncatedText';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const NewFilteredDropdownSelector = ({
  // styles
  narrowWidth,
  hideClearX,
  placeholderLabel,

  // parent state
  isOpen,
  setIsOpen,
  selectedOption,
  onClearFilter,
}) => {
  const clearFilterEnabled = selectedOption && selectedOption !== placeholderLabel && !hideClearX;

  return (
    <FilteredDropdownSelectorDiv
      filterDropdownContainer
      narrowWidth={narrowWidth}
      onMouseDown={e => {
        setIsOpen(!isOpen);
        e.preventDefault();
      }}
    >
      <div
        style={{
          marginRight: '10px',
          width: '160px',
          cursor: 'pointer',
        }}
      >
        <TruncatedText title={selectedOption?.label || placeholderLabel}>
          {selectedOption?.label || placeholderLabel}
        </TruncatedText>
      </div>
      <>
        {clearFilterEnabled && (
          <div
            // this wouldn't work on ClearFilterIcon so I wrapped in a div
            onClick={e => {
              onClearFilter();
              e.preventDefault();
            }}
          >
            <ClearFilterIcon svgFileName={'red-x'} title="Clear selection" alt="Clear selection" />
          </div>
        )}

        <IconSvgComponent
          svgFileName={'dropdown-caret'}
          svgStyle={{
            cursor: 'pointer',
          }}
          title="Change selection"
          alt="Change selection"
        />
      </>
    </FilteredDropdownSelectorDiv>
  );
};

export default NewFilteredDropdownSelector;
