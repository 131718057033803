import { maintenanceIntervalConstants } from 'app/features/maintenance/data/maintenanceIntervalConstants';

export const maintenanceIntervalDropdownData = [
  { value: maintenanceIntervalConstants.DATE, label: 'Date', unitSuffix: '' },
  {
    value: maintenanceIntervalConstants.ENGINE_HOURS,
    label: 'Engine Hours',
    unitSuffix: 'Engine Hours',
  },
  { value: maintenanceIntervalConstants.MILEAGE, label: 'Mileage', unitSuffix: 'Miles' },
  {
    value: maintenanceIntervalConstants.OIL_LIFE,
    label: 'Oil Life',
    unitSuffix: '% Oil Life Remaining',
  },
  {
    value: maintenanceIntervalConstants.TOTAL_MILEAGE,
    label: 'Total Mileage',
    unitSuffix: 'Miles Service',
  },
];
