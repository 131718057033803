import { apiCall } from 'core/api/API';

export const deleteAlertApi = async ({ alertId, organizationId }) => {
  const queryStringParameters = {
    organization_id: `${organizationId}`,
  };
  return await apiCall({
    path: `/alerts/${alertId}`,
    method: 'del',
    queryStringParameters,
  });
};
