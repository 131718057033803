import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getOrganization } from 'core/api/organizations';
import { decorateOrganizationObject } from 'core/api/organizations';
import { logWcpError } from 'app/core/error/utilities/ErrorLogger';
import { organizationFetchError } from 'features/people/organizations/helpers/notifications';

export const useFetchOrganizationQuery = ({
  enabled = true,
  enableRefetchInterval = false,
} = {}) => {
  const filteredOrganizationId = useSelector(state => state.user.filteredOrganizationId);
  const dispatch = useDispatch();
  const [organization, setOrganization] = useState(null);
  const fetchOrganizationQuery = useQuery({
    queryKey: ['fetchOrganizationQuery'],
    queryFn: () => getOrganization(filteredOrganizationId),
    enabled,
    select: ({ response: { message } }) => {
      return message;
    },
    onError: err => {
      logWcpError(err);
      dispatch(organizationFetchError({ err }));
    },
  });

  useEffect(() => {
    if (fetchOrganizationQuery.data && fetchOrganizationQuery.data.length > 0)
      setOrganization(decorateOrganizationObject(fetchOrganizationQuery.data[0]));
  }, [fetchOrganizationQuery.data]);

  return [fetchOrganizationQuery, organization];
};
