import { offlineTimeUnitConstants } from 'app/features/alerts/data/enums/offlineTimeUnitConstants';

export const offlineTimeUnitConverterToFrontend = (unitOfMeasure, offlineTime) => {
  switch (unitOfMeasure) {
    case offlineTimeUnitConstants.MINUTES:
      return +offlineTime;
    case offlineTimeUnitConstants.HOURS:
      return Math.round(offlineTime / 60);
    case offlineTimeUnitConstants.DAYS:
      return Math.round(offlineTime / 1440);
    case offlineTimeUnitConstants.WEEKS:
      return Math.round(offlineTime / 10080);

    default:
      return Math.round(offlineTime);
  }
};
