import MaintenanceBuilder from 'app/features/maintenance/components/MaintenanceBuilder/MaintenanceBuilder';
import VehicleEngineHoursField from 'app/features/maintenance/components/MaintenanceSummary/fields/VehicleEngineHoursField';
import VehicleOdometerField from 'app/features/maintenance/components/MaintenanceSummary/fields/VehicleOdometerField';
import { TextButton } from 'shared/styles/components/Button';
import { FormSectionLabel, CheckboxTextSpan } from 'shared/styles/components/Form';
import OilLifeField from 'app/features/maintenance/components/MaintenanceSummary/fields/OilLifeField';
import { CheckboxWrapperDiv } from 'shared/styles/components/VehicleEdit';
import { Checkbox } from 'shared/styles/components/Checkbox';
import CheckboxInner from 'shared/styles/components/CheckboxInner';
import { TextLink } from 'shared/styles/components/Link';
import { VehicleCommunicationMessageWrapper } from 'shared/styles/components/Maintenance';
import { MaintenanceWrapperDiv, ObdFieldsWrapperDiv } from 'shared/styles/components/VehicleEdit';

const MaintenanceSection = ({
  vehicle,
  formikProps,
  handleSetMaintenanceItems,
  handleMaintenanceItemsDirty,
  handleMaintenanceItemsValid,
  setEnhancedTelematicsEnabled,
  enhancedTelematicsEnabled,
  setEnhancedTelematicsDirty,
}) => {
  return (
    <>
      <MaintenanceWrapperDiv>
        <FormSectionLabel maintenance>
          Vehicle Maintenance
          {vehicle.meta.last_online_time ? (
            <TextButton type="button" maintenance>
              <TextLink to={`/maintenancelogs/${encodeURIComponent(vehicle.vehicle_id)}`}>
                VIEW LOGS
              </TextLink>
            </TextButton>
          ) : (
            <VehicleCommunicationMessageWrapper>
              <h6>
                Vehicle maintenance is unavailable until the vehicle has been powered on and has
                communicated with WCP
              </h6>
            </VehicleCommunicationMessageWrapper>
          )}
        </FormSectionLabel>
        <CheckboxWrapperDiv enhancedTelematics>
          <Checkbox singleCheck>
            <CheckboxInner
              isChecked={enhancedTelematicsEnabled}
              onClick={() => {
                setEnhancedTelematicsEnabled(!enhancedTelematicsEnabled);
                setEnhancedTelematicsDirty(true);
              }}
            />
          </Checkbox>
          <CheckboxTextSpan>Enable Enhanced Telematics</CheckboxTextSpan>
        </CheckboxWrapperDiv>
        {vehicle.meta.last_online_time && (
          <ObdFieldsWrapperDiv>
            <VehicleOdometerField formikProps={formikProps} />
            {vehicle.obd.engine_hours && <VehicleEngineHoursField formikProps={formikProps} />}
            {enhancedTelematicsEnabled && vehicle.obd?.oil_life && (
              <OilLifeField formikProps={formikProps} />
            )}
          </ObdFieldsWrapperDiv>
        )}
      </MaintenanceWrapperDiv>
      {vehicle.meta.last_online_time && (
        <MaintenanceBuilder
          initialState={{ vehicleMaintenanceItems: vehicle.maintenance }}
          handleSetMaintenanceItems={handleSetMaintenanceItems}
          handleMaintenanceItemsDirty={handleMaintenanceItemsDirty}
          handleMaintenanceItemsValid={handleMaintenanceItemsValid}
          vehicle={vehicle}
        />
      )}
    </>
  );
};

export default MaintenanceSection;
