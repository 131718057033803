import { addDisplayCommas } from 'app/features/maintenance/utilities/formatting';
import { getUserNameDisplayFromUserList } from 'app/shared/utilities/getUserNameDisplayFromUserList';
import {
  CardContainer,
  CardIconWrapper,
  CardLabel,
  CardName,
  CardOuterWrapper,
  CardRow,
  CardValue,
} from 'shared/styles/components/MobileCard';
import { ActionMenuBtnWrapper } from 'shared/styles/components/MobileCard';

const ManageAlertsLogCard = ({ log, actionMenuComponent }) => {
  return (
    <CardOuterWrapper>
      <CardContainer maintenanceEvent>
        <ActionMenuBtnWrapper>{actionMenuComponent(log)}</ActionMenuBtnWrapper>
        <CardIconWrapper>
          <CardRow header>
            {/* need to update after backend is returning this which they are not currently */}
            <CardName potentialLongValue title={log.vehicleName}>
              {log.vehicleName}
            </CardName>
          </CardRow>
        </CardIconWrapper>
        <CardRow potentialLongValue>
          <CardLabel>Timestamp:</CardLabel>
          <CardValue title={log.formattedTime}>{log.formattedTime}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Condition:</CardLabel>
          <CardValue title={log.alertTypeDisplay}>
            {/*    THE OLD WAY IS BELOW, WE DONT NEED TO CONVERT ANYMORE BC THIS VALUE IS GETTING OVERWRITTEN WITH THE DISPLAY VALUE, IS THIS WHAT WE WANT?
            {alertTypeDropdownOptions.getLabelByValue(log.alertType)} */}
            {log.alertTypeDisplay}
          </CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Alert Value:</CardLabel>
          <CardValue>{log.triggerValueDisplay}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Measured Value:</CardLabel>
          <CardValue red>{addDisplayCommas(log.measuredValueDisplay)}</CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Recipient:</CardLabel>
          <CardValue title={log.formattedUserDisplayNames}>
            {log.testModeEnabled ? 'TEST MODE - ' : ''}
            {log.formattedUserDisplayNames}
          </CardValue>
        </CardRow>
        <CardRow potentialLongValue>
          <CardLabel>Method:</CardLabel>
          {/* new field to add to mapping that does the work of knowing what to display here based on text, email, or custom if mixed */}
          <CardValue>{log.aggregateAlertMethod}</CardValue>
        </CardRow>
      </CardContainer>
    </CardOuterWrapper>
  );
};

export default ManageAlertsLogCard;
