import React from 'react';
import { DropdownLink } from 'shared/styles/components/DropdownMenu';

const ViewActivityLogsMenuItem = ({ username }) => {
  return (
    <DropdownLink to={`/activityLogs/${encodeURIComponent(username)}`}>
      View Activity Logs
    </DropdownLink>
  );
};

export default ViewActivityLogsMenuItem;
