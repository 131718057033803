import { mapRecipientDataToFrontend } from 'app/features/recipientGroups/utilities/recipientGroupMapping';
import { apiCall } from 'core/api/API';

export const getRecipientGroupByIdApi = async ({ organizationId, recipientGroupId }) => {
  console.log('!!!getRecipientGroupByIdApi!!!', recipientGroupId);
  const apiResponse = await apiCall({
    method: 'get',
    path: `/user_group/${recipientGroupId}`,
    queryStringParameters: {
      organization_id: `${organizationId}`,
    },
  });

  const recipientGroup = mapRecipientDataToFrontend(apiResponse.data.message);

  return recipientGroup;
};
