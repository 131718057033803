import FirmwareUpdateSummaryByVehicleItem from 'features/ota/firmware/components/byVehicle/FirmwareUpdateSummaryByVehicleItem';
import useGetFirmwareCounts from 'features/ota/firmware/hooks/useGetFirmwareCounts';
import LinkButton from 'shared/styles/components/LinkButton';
import {
  Actions,
  ByVehicleSummaryItems,
  ByVehicleSummaryItemWrapper,
  CompletedCount,
  IconAndTitleWrapper,
  OtaSummaryItemByVehicleWrapperDiv,
} from 'shared/styles/components/OtaUpdates';
import { SectionStatus, SectionToggle } from 'shared/styles/components/Section';
import CollapsibleHeader from 'shared/ui/accordions/CollapsibleHeader';
import IconSvgComponent from 'shared/ui/icons/IconSvgComponent';

const FirmwareUpdatesSummaryByVehicleGroupContainer = ({
  vehicle,
  updates,
  allExpanded,
  setCollapsedIndividualItems,
  collapsedIndividualItems,
}) => {
  const { vehicleId, label } = vehicle;

  const { completedCount, totalCount, allComplete } = useGetFirmwareCounts({ updates });

  // group all updates by productName and version
  const groupedUpdates = updates?.reduce((gu, u) => {
    const k = `${u.productName}/${u.version}`;
    gu[k] = gu[k] || [];
    gu[k].push(u);
    return gu;
  }, {});

  return (
    <div key={vehicleId}>
      <CollapsibleHeader
        initialCollapsedState={!allExpanded}
        ToggleComponent={({ collapsed, setCollapsed }) => (
          <SectionStatus
            onClick={() => {
              setCollapsed(!collapsed);
              collapsed
                ? setCollapsedIndividualItems(collapsedIndividualItems - 1)
                : setCollapsedIndividualItems(collapsedIndividualItems + 1);
            }}
          >
            <IconAndTitleWrapper>
              {collapsed && allComplete && (
                <IconSvgComponent
                  svgFileName={'check-success'}
                  title="All updates for this vehicle have completed"
                />
              )}
              {label} Updates{' '}
              <CompletedCount>{`(${completedCount} of ${totalCount} Completed)`}</CompletedCount>
            </IconAndTitleWrapper>

            <SectionToggle collapsed={collapsed} />
          </SectionStatus>
        )}
      >
        <ByVehicleSummaryItemWrapper>
          <Actions>
            <LinkButton to={`/updates/${vehicleId}`}>View Details</LinkButton>
          </Actions>
          <ByVehicleSummaryItems>
            {groupedUpdates
              ? Object.entries(groupedUpdates).map(([k, updates]) => {
                  const [productName, version] = k.split('/');
                  return (
                    <OtaSummaryItemByVehicleWrapperDiv key={version + productName}>
                      <FirmwareUpdateSummaryByVehicleItem
                        key={productName}
                        releaseDate={updates[0].releaseDate}
                        releaseNotes={updates[0].releaseNotes}
                        channel={updates[0].channel}
                        version={version}
                        showTotals={true}
                        productName={productName}
                        allDeviceUpdatesForProduct={updates}
                      />
                    </OtaSummaryItemByVehicleWrapperDiv>
                  );
                })
              : null}
          </ByVehicleSummaryItems>
        </ByVehicleSummaryItemWrapper>
      </CollapsibleHeader>
    </div>
  );
};

export default FirmwareUpdatesSummaryByVehicleGroupContainer;
