import React, { useState } from 'react';

import VehicleCreateFormContainer from 'features/fleet/vehicles/components/forms/VehicleCreateFormContainer';
import PageListWrapper from 'shared/styles/components/PageList';
import { useNavigate } from 'react-router-dom';

const VehicleCreatePage = ({ userState }) => {
  const navigate = useNavigate();
  const navigateBackToVehicleList = () => {
    navigate('/vehicles');
  };

  return (
    <PageListWrapper>
      <VehicleCreateFormContainer
        handleRedirect={navigateBackToVehicleList}
        userState={userState}
      />
    </PageListWrapper>
  );
};

export default VehicleCreatePage;
