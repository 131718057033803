/**
 * @ External Dependecies
 */
import styled from 'styled-components';
import colors from 'shared/constants/colors';
import { Link } from 'react-router-dom';

export default styled.a`
  text-decoration: none;
  font-size: 14px;
  font-weight: 700;
  line-height: 27px;
  letter-spacing: 0.26px;
  color: ${colors.midnight};
  transition: color 0.3s;

  &:hover {
    color: ${colors.midnight};
  }

  ${props =>
    props.red &&
    `
		color: ${colors.cherry};
	`}
`;

export const TextLink = styled(Link)`
  text-decoration: none;
`;
